import { combineDateAndHours } from 'utils/data/dayjs.utils';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import { ID } from '@datorama/akita';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';

export interface ExecutionCreationDto {
  serviceId: number;
  plannedFrom?: string;
  plannedTo?: string;
  quantity1: number;
  parentExecutionId?: number;
  documents?: number[];
  comments?: string[];
  asap?: boolean;
  force?: boolean;
  status?: ExecutionStatusEnum;
  quantity2?: number;
}

export function formatToExecutionCreationDto(
  execution: ExecutionDetailsUi,
  parentExecutionId?: ID,
  documents?: number[],
  force = false
): ExecutionCreationDto {
  const {
    serviceId,
    selectedDate,
    plannedFrom,
    plannedTo,
    nbContainers,
    comments,
    asap,
    status,
    wasteAmount,
  } = execution;
  return {
    serviceId: Number(serviceId ?? ''),
    plannedFrom:
      selectedDate && plannedFrom && !asap
        ? combineDateAndHours(selectedDate, plannedFrom)
        : undefined,
    plannedTo:
      selectedDate && plannedTo && !asap ? combineDateAndHours(selectedDate, plannedTo) : undefined,
    quantity1: nbContainers,
    comments: comments,
    parentExecutionId: parentExecutionId ? Number(parentExecutionId) : undefined,
    documents,
    asap,
    force,
    status,
    quantity2: wasteAmount ? Number(wasteAmount) : undefined,
  };
}
