import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Colors } from 'constants/Colors';

const TableMenuStyle = {
  '& .MuiDataGrid-menuList': {
    padding: 0,
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiListItemIcon-root': {
      alignItems: 'center',
      display: 'flex',
      height: '15px',
      justifyContent: 'center',
      minWidth: 0,
      marginRight: '15px',
      width: '15px',
    },
  },
};

const getCellColor = (color: string) => `
  &.${color} {
    color: ${Colors[color]};
  }
`;

const NewTableStyled = styled(DataGridPro)`
  background-color: ${Colors.white};
  border: none;
  border-radius: 4px;
  padding: 0 24.5px;

  & .MuiInputBase-root {
    width: initial;
  }

  &.single-row-selection {
    & .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
      display: none;
    }
  }

  & .MuiDataGrid-overlay {
    background-color: transparent;
  }

  & .MuiDataGrid-pinnedColumnHeaders {
    background-color: white;
    border-left: 1px solid ${Colors.textWithOpacity};
  }

  & .MuiDataGrid-columnHeaders {
    background-color: ${Colors.white};
    border-bottom: 1px solid ${Colors.textWithOpacity};

    & .MuiDataGrid-columnHeadersInner {
      font-size: 14px;
      font-weight: 700;
    }
  }

  & .MuiDataGrid-row {
    border-bottom: 1px solid ${Colors.background};

    & .MuiDataGrid-cell {
      font-weight: 400;
      font-size: 14px;

      &.MuiDataGrid-cell--editing {
        padding: 0 10px;
      }

      &.bold {
        font-weight: 700;
      }

      &.lineThrough {
        text-decoration: line-through;
      }

      &.secondary {
        color: ${Colors.textWithOpacity};
      }

      &.pre-line {
        white-space: pre-line;
      }

      &.with-space .MuiDataGrid-actionsCell {
        grid-gap: 8px;
      }

      ${Object.keys(Colors).map(getCellColor)}
      & .MuiFilledInput-root {
        border-radius: 4px;
        height: 28px !important;
      }

      & .MuiSelect-select {
        background-color: ${Colors.background};
        border-radius: 4px;
        font-weight: 700;
        padding: 4px 10px;
      }

      & .MuiFilledInput-input {
        font-size: 12px;
        padding: 4px 14px;
        text-align: center;
      }
    }

    & .MuiDataGrid-actionsCell {
      grid-gap: 0px;
      outline: none !important;
    }
  }

  & .MuiDataGrid-pinnedColumns {
    background-color: white;
    border-left: 1px solid ${Colors.textWithOpacity};
  }

  & .MuiDataGrid-footerContainer {
    border-top: 1px solid ${Colors.textWithOpacity};
    height: 50px;
    min-height: 0;
  }

  & .MuiTablePagination-toolbar {
    height: 50px;
    min-height: 0;
  }

  & .MuiTablePagination-root {
    color: ${alpha(Colors.text, 0.3)};
    font-size: 12px;
    font-weight: 500;
  }

  & .MuiTablePagination-select {
    color: ${alpha(Colors.text, 0.3)};
    font-size: 12px;
    font-weight: 500;
  }

  & .MuiTablePagination-selectIcon {
    color: ${alpha(Colors.text, 0.3)};
  }
`;

export { NewTableStyled, TableMenuStyle };
