import { EntityStore, StoreConfig } from '@datorama/akita';
import { Step } from 'react-joyride';
import { onbordingSteps } from './onbording.data';

export interface OnbordingState {
  run: boolean;
  openNewDemande: boolean;
  stepIndex: number;
  isOnbording?: boolean;
  steps: Step[];
}

const initStore = (): OnbordingState => {
  return {
    run: true,
    openNewDemande: false,
    stepIndex: 0,
    isOnbording: false,
    steps: onbordingSteps(),
  };
};

@StoreConfig({
  name: 'onbording',
})
export class OnbordingStore extends EntityStore<OnbordingState> {
  constructor() {
    super(initStore());
  }

  setIsonbording = (isOnbording: boolean) => {
    this.update((state) => ({
      ...state,
      isOnbording: isOnbording,
    }));

    if (!isOnbording && localStorage.getItem('isOnbording') === 'false') {
      localStorage.setItem('isOnbording', 'true');
    }
  };

  getRun = () => {
    return this.getValue().run;
  };

  getOpenNewDemande = () => {
    return this.getValue().openNewDemande;
  };

  setRun = (run: boolean) => {
    this.update((state) => ({
      ...state,
      run: run,
    }));
  };

  setOpenNewDemande = (openNewDemande: boolean) => {
    this.update((state) => ({
      ...state,
      openNewDemande: openNewDemande,
    }));
  };

  setStepIndex = (stepIndex: number) => {
    this.update((state) => ({
      ...state,
      stepIndex: stepIndex,
    }));
  };
}

export const onbordingStore = new OnbordingStore();
