import { ID } from '@datorama/akita';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { ClientOrganisationUnitDto } from 'api/dto/client/ClientOrganisationUnitDto';
import { SearchOptionDto } from 'api/dto/filters/SearchOptionDto';
import { ContainerLightDto } from 'api/dto/services/ContainerLightDto';
import { WasteLightDto } from 'api/dto/services/WasteLightDto';
import axios, { AxiosRequestConfig } from 'axios';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';
import {
  ExpenseValoStatsUi,
  ServiceExpenseUi,
  WasteExpenseValoUi,
} from 'store/dashboard/financeScreenStats/finance-screen-stats.model';
import { Issue, IssueStatus, ManageIssue } from 'store/issues/issues.model';
import { Bookmarks, BookmarkServiceDtoLight } from 'store/operations/bookmark';
import { ExecutionIssueKind } from 'store/operations/executionIssueKinds';
import { OperationFilters } from 'store/operations/operationExecutions/hooks/useFetchExecutionSearch';
import { Organisation } from 'store/organisation/organisations';
import env from 'utils/env';
import { LocalStorageKey } from '../constants/LocalStorageKey';
import { AuditOfferSupplierDto } from '../store/audit/auditModel';
import { ContractDto, ContractFiltersEnum } from '../store/contracts/contracts.model';
import { EcologyStats } from '../store/dashboard/ecologyScreenStats/ecology-screen-stats.model';
import {
  IssueCountStatsUi,
  IssueKindUi,
} from '../store/dashboard/operationScreenStats/operation-screen-stats.model';
import { Invoice, InvoiceFiltersEnum } from '../store/invoice';
import { FilterOrderBy } from '../store/operations/operationExecutions/enums/filter-order-by';
import { SupplierOrganisationUnitLight } from '../store/organisation/organisationUnits';
import { requestAzureAccessToken } from '../utils/azure-config';
import { ExecutionStatusEnum } from '../utils/enums/ExecutionStatusEnum';
import { ServiceKindTypeEnum } from '../utils/enums/ServiceKindTypeEnum';
import { ResponseError } from '../utils/errors/response.error';
import { getBaseUrl } from '../utils/getBaseUrl';
import {
  ActivityDto,
  ActivityEntityType,
  ActivityExecutionDto,
} from './dto/activities/ActivityDto';
import { AuditOperationResponseDto } from './dto/Audit/AuditOperationResponseDto';
import { AuditProposalInfoUnprotectedDto } from './dto/Audit/AuditProposalInfoUnprotectedDto';
import { AuditProposalSupplierDto } from './dto/Audit/AuditProposalSupplierDto';
import { ClientDto } from './dto/client/ClientDto';
import { PaginationResponseDto } from './dto/common/PaginationResponseDto';
import { OperationEvolutionDto } from './dto/dashboard/operations/OperationEvolutionDto';
import { OperationStatsDto } from './dto/dashboard/operations/OperationStatsDto';
import { PickUpDoneDto } from './dto/dashboard/operations/PickUpDoneDto';
import { WasteAverageQuantityDto } from './dto/dashboard/operations/WasteAverageQuantityDto';
import { WasteTotalQuantityDto } from './dto/dashboard/operations/WasteTotalQuantityDto';
import { WasteDashboardDto } from './dto/dashboard/wastes/WasteDashboardDto';
import { DocumentDto } from './dto/documents/DocumentDto';
import { DocumentUpdateDto } from './dto/documents/DocumentUpdateDto';
import { CommentExecutionDto } from './dto/execution/CommentExecutionDto';
import { ExecutionCountPerDayDto } from './dto/execution/execution-count-per-day.dto';
import { ExecutionBetweenDatesDto } from './dto/execution/ExecutionBetweenDatesDto';
import { ExecutionChangeStatusDto } from './dto/execution/ExecutionChangeStatusDto';
import { ExecutionCompleteDto } from './dto/execution/ExecutionCompleteDto';
import { ExecutionCountStatusDto } from './dto/execution/ExecutionCountStatusDto';
import { ExecutionCreationDto } from './dto/execution/ExecutionCreationDto';
import { ExecutionDto } from './dto/execution/ExecutionDto';
import { ExecutionIssueDto } from './dto/execution/ExecutionIssueDto';
import { ExecutionUpdateDto } from './dto/execution/ExecutionUpdateDto';
import { ExecutionUpdateTimeDto } from './dto/execution/ExecutionUpdateTimeDto';
import { IssueCreationDto } from './dto/execution/IssueCreationDto';
import { IssueUpdateDto } from './dto/execution/IssueUpdateDto';
import { SearchKindDto } from './dto/filters/SearchKindDto';
import { NotificationDto } from './dto/notifications/NotificationDto';
import { HaulingDestinationDto } from './dto/services/HaulingDestinationDto';
import { ServiceKindLightDto } from './dto/services/ServiceKindLightDto';
import { ServiceWasteContainerDto } from './dto/services/ServiceWasteContainerDto';
import { SupplierDto } from './dto/supplier/SupplierDto';
import { UserDto } from './dto/user/UserDto';
import { UserPreferencesDto } from './dto/user/UserPreferencesDto';
import { UserReviewCheckDto } from '../store/dashboard/userReviews/user-reviews.model';

const APIAxios = axios.create({
  baseURL: env.API_URL,
});

class MetawasteApi {
  token?: string;

  constructor() {
    try {
      this.setToken(atob(localStorage.getItem(LocalStorageKey.AccessToken) || ''));
    } catch (e) {
      this.setToken(localStorage.getItem(LocalStorageKey.AccessToken) || '');
    }
  }

  setToken = (newToken: string) => {
    this.token = newToken;
    APIAxios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  };

  getMe(): Promise<UserDto> {
    return this._makeRequest({
      url: `/user/about/me`,
      method: 'GET',
    });
  }

  userReadNotifications(hasReadNotificationsUntil: Date) {
    return this._makeRequest({
      url: `/user`,
      method: 'PATCH',
      data: {
        hasReadNotificationsUntil,
      },
    });
  }

  userUpdatePreferences(data: UserPreferencesDto) {
    return this._makeRequest({
      url: `/user/preferences`,
      method: 'PATCH',
      data,
    });
  }

  getClients(
    clientName?: string,
    offset?: number,
    limit?: number
  ): Promise<PaginationResponseDto<ClientDto>> {
    return this._makeRequest({
      url: `/clients`,
      method: 'GET',
      params: {
        clientName,
        offset,
        limit,
      },
    });
  }

  searchClients(
    clientName?: string,
    offset?: number,
    limit?: number
  ): Promise<PaginationResponseDto<ClientDto>> {
    return this._makeRequest({
      url: `/clients/search`,
      method: 'GET',
      params: {
        clientName,
        offset,
        limit,
      },
    });
  }

  getClient(clientId: ID): Promise<ClientDto> {
    return this._makeRequest({
      url: `/clients/${clientId}`,
      method: 'GET',
    });
  }

  getNotifs(): Promise<NotificationDto[]> {
    return this._makeRequest({
      url: `/user/notifications`,
      method: 'GET',
    });
  }

  getSuppliers(
    supplierName?: string,
    offset?: number,
    limit?: number
  ): Promise<PaginationResponseDto<SupplierDto>> {
    return this._makeRequest({
      url: `/suppliers`,
      method: 'GET',
      params: {
        supplierName,
        offset,
        limit,
      },
    });
  }

  getSupplier(supplierId: ID): Promise<SupplierDto> {
    return this._makeRequest({
      url: `/suppliers/${supplierId}`,
      method: 'GET',
    });
  }

  getServiceKinds(clientOrgUnitId: ID): Promise<ServiceKindLightDto[]> {
    return this._makeRequest({
      url: `/executions/${clientOrgUnitId}/servicesKind`,
      method: 'GET',
    });
  }

  getIssueKinds(): Promise<ExecutionIssueKind[]> {
    return this._makeRequest({
      url: `/issue-kinds/available`,
      method: 'GET',
    });
  }

  getServices(
    clientOrgUnitId: ID,
    params: { serviceKindType?: ServiceKindTypeEnum; serviceKindId?: ID; supplierOrgUnitIds?: ID[] }
  ): Promise<ServiceWasteContainerDto[]> {
    return this._makeRequest({
      url: `/executions/${clientOrgUnitId}/available-services`,
      method: 'GET',
      params: {
        ...params,
        supplierOrgUnitIds: params.supplierOrgUnitIds?.map((id) => id.toString()).join(','),
      },
    });
  }

  getClientsFromSupplierUnit(supplierOrgUnitId: ID): Promise<any> {
    return this._makeRequest({
      url: `/executions/clients-available/`,
      method: 'GET',
      params: {
        supplierOrgUnitId,
      },
    });
  }

  getHaulingDestination(clientOrgUnitId: ID, servicesId: ID): Promise<HaulingDestinationDto> {
    return this._makeRequest({
      url: `/executions/${clientOrgUnitId}/${servicesId}/hauling-destination`,
      method: 'GET',
    });
  }

  getCountByStatus(
    organisationId?: ID,
    orgUnitIds?: ID[],
    searchString?: string,
    from?: Date,
    to?: Date
  ): Promise<ExecutionCountStatusDto[]> {
    return this._makeRequest({
      url: `/executions/countByStatus`,
      method: 'GET',
      params: {
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        searchString,
        from,
        to,
      },
    });
  }

  createExecution(body: ExecutionCreationDto): Promise<ExecutionDto> {
    return this._makeRequest({
      url: `/executions`,
      method: 'POST',
      data: body,
    });
  }

  createIssue(body: IssueCreationDto, executionId: ID): Promise<ExecutionIssueDto> {
    return this._makeRequest({
      url: `/executions/${executionId}/issues`,
      method: 'POST',
      data: body,
    });
  }

  updateIssue(body: IssueUpdateDto, issueId: ID): Promise<ExecutionIssueDto> {
    return this._makeRequest({
      url: `/issues/${issueId}`,
      method: 'PATCH',
      data: body,
    });
  }

  getIssues(executionId: ID): Promise<ExecutionIssueDto[]> {
    return this._makeRequest({
      url: `/executions/${executionId}/issues`,
      method: 'GET',
    });
  }

  countExecutionsForPlanning(
    from: string,
    to: string,
    organisationId?: ID,
    orgUnitIds?: ID[]
  ): Promise<ExecutionCountPerDayDto[]> {
    return this._makeRequest({
      url: `/executions/countBetweenDates`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        exceptStatus: ExecutionStatusEnum.ARCHIVED,
      },
    });
  }

  getPlanning(
    from: string,
    to: string,
    organisationId?: ID,
    orgUnitIds?: ID[],
    requestFilters?: { [key: string]: string | undefined },
    sortBy?: string,
    orderBy?: 'ASC' | 'DESC'
  ): Promise<ExecutionBetweenDatesDto[]> {
    return this._makeRequest({
      url: `/executions/searchBetweenDates`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        exceptStatus: ExecutionStatusEnum.ARCHIVED,
        sortBy,
        orderBy,
        ...requestFilters,
      },
    });
  }

  getExecutionsFromExecutionGroup(executionGroupId: ID): Promise<ExecutionDto[]> {
    return this._makeRequest({
      url: `/executions/executionGroup/${executionGroupId}`,
      method: 'GET',
    });
  }

  getWastes(
    from: Date,
    to: Date,
    organisationId: ID,
    orgUnitIds?: ID[]
  ): Promise<WasteDashboardDto[]> {
    return this._makeRequest({
      url: `/waste/count`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
      },
    });
  }

  deleteExecution(mainExecutionId: ID) {
    return this._makeRequest({
      url: `/executions/${mainExecutionId}`,
      method: 'DELETE',
    });
  }

  createComment(executionId: ID, content: string): Promise<CommentExecutionDto> {
    return this._makeRequest({
      url: `/executions/${executionId}/comments`,
      method: 'POST',
      data: {
        content: content,
      },
    });
  }

  getComment(executionId: ID): Promise<CommentExecutionDto[]> {
    return this._makeRequest({
      url: `/executions/${executionId}/comments`,
      method: 'GET',
    });
  }

  getOperationsStats(
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[]
  ): Promise<OperationStatsDto> {
    return this._makeRequest({
      url: `/executions/stats/getTicketsCount`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitIds,
      },
    });
  }

  getWasteAverageQuantity(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitId?: ID,
    organisationId?: ID
  ): Promise<WasteAverageQuantityDto> {
    return this._makeRequest({
      url: `/executions/stats/averageQuantity`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitId,
      },
    });
  }

  getWasteTotalQuantity(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitId?: ID,
    organisationId?: ID
  ): Promise<WasteTotalQuantityDto> {
    return this._makeRequest({
      url: `/executions/stats/totalWeight`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitId,
      },
    });
  }

  getPickUpDone(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitId?: ID,
    organisationId?: ID
  ): Promise<PickUpDoneDto> {
    return this._makeRequest({
      url: `/executions/stats/execution-count`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitId,
        status: ExecutionStatusEnum.EXECUTED,
      },
    });
  }

  getExpensesCSV(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitIds?: ID[],
    organisationId?: ID
  ): Promise<any> {
    return this._makeRequest({
      responseType: 'blob',
      url: `finance-stats/expenses/csv`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        dateFilterKind: 'INVOICING',
      },
    });
  }

  getIssueKindStats(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined,
    filters: { [p: string]: string | undefined }
  ): Promise<IssueKindUi[]> {
    return this._makeRequest({
      url: `/issues/stats/issue-kinds`,
      method: 'GET',
      params: {
        wasteIDs,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        ...filters,
        from,
        to,
      },
    });
  }

  getCo2Evolution(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined,
    filters: { [p: string]: string | undefined }
  ): Promise<EcologyStats[]> {
    return this._makeRequest({
      url: `/executions/stats/co2`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        ...filters,
      },
    });
  }

  getOperationEvolution(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined,
    filters: { [p: string]: string | undefined }
  ): Promise<OperationEvolutionDto> {
    return this._makeRequest({
      url: `/executions/stats/evolution`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        ...filters,
      },
    });
  }

  getIssueCount(
    from: Date,
    to: Date,
    wasteIDs: string[],
    orgUnitIds?: ID[],
    organisationId?: ID,
    issueStatus?: string,
    withIssue?: boolean,
    exceptStatus?: string
  ): Promise<IssueCountStatsUi> {
    return this._makeRequest({
      url: `/issues/stats/issue-count`,
      method: 'GET',
      params: {
        from,
        to,
        wasteIDs,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        issueStatus,
        withIssue,
        exceptStatus,
      },
    });
  }

  getSearchExecution(
    limit: number,
    page: number,
    orderBy?: { sortBy?: string; orderBy: FilterOrderBy },
    organisationId?: ID,
    orgUnitIds?: ID[],
    operationFilters?: OperationFilters,
    requestFilters?: { [key: string]: string | undefined }
  ): Promise<PaginationResponseDto<ExecutionDto>> {
    return this._makeRequest({
      url: `executions/search`,
      method: 'GET',
      params: {
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        limit,
        page,
        orderBy: orderBy?.orderBy,
        sortBy: orderBy?.sortBy,
        ...operationFilters,
        ...requestFilters,
      },
    });
  }

  getSearchExecutionForMultipleModal(
    page: number,
    from?: Date,
    to?: Date,
    organisationId?: ID,
    orgUnitId?: ID,
    filters?: TableFilter[]
  ): Promise<PaginationResponseDto<ExecutionDto>> {
    return this._makeRequest({
      url: `executions/search`,
      method: 'GET',
      params: {
        organisationId,
        orgUnitId,
        page: page,
        from,
        to,
        equalFromTo: true,
        exceptStatus: ExecutionStatusEnum.ARCHIVED,
        ...toRequestFilters(filters || []),
      },
    });
  }

  getSearchClientsOrganisation(searchOptions: SearchOptionDto): Promise<Organisation> {
    return this._makeRequest({
      url: `filters/client-organisation`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchClientsOrganisationUnits(
    searchOptions: SearchOptionDto
  ): Promise<ClientOrganisationUnitDto[]> {
    return this._makeRequest({
      url: `filters/client-organisation-unit`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchWastes(searchOptions: SearchOptionDto): Promise<WasteLightDto[]> {
    return this._makeRequest({
      url: `filters/wastes`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchContainers(searchOptions: SearchOptionDto): Promise<ContainerLightDto[]> {
    return this._makeRequest({
      url: `filters/containers`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchServiceKinds(searchOptions: SearchOptionDto): Promise<ServiceKindLightDto[]> {
    return this._makeRequest({
      url: `filters/service-kinds`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchServiceKindsUnprotected(searchOptions: SearchKindDto): Promise<ServiceKindLightDto[]> {
    return this._makeRequestWithoutToken({
      url: `admin/service-kind/unprotected`,
      method: 'GET',
      params: {
        searchString: searchOptions.searchString,
        family: searchOptions.family,
      },
    });
  }

  getSearchSuppliersOrganisation(searchOptions: SearchOptionDto) {
    return this._makeRequest({
      url: `filters/supplier-organisation`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getSearchSupplierOrganisationUnits(searchOptions: SearchOptionDto) {
    return this._makeRequest({
      url: `filters/supplier-organisation-unit`,
      method: 'GET',
      params: {
        orgId: searchOptions.orgId,
        orgUnitId: searchOptions.orgUnitIds?.map((id) => id.toString()).join(','),
        searchString: searchOptions.searchString,
      },
    });
  }

  getExecution(executionId: ID): Promise<ExecutionDto[]> {
    return this._makeRequest({
      url: `executions/${executionId}`,
      method: 'GET',
    });
  }

  putExecution(executionId: ID, body: ExecutionUpdateDto): Promise<ExecutionDto> {
    return this._makeRequest({
      url: `/executions/${executionId}`,
      method: 'PUT',
      data: body,
    });
  }

  patchArchiveExecution(executionId: ID): Promise<void> {
    return this._makeRequest({
      url: `/executions/${executionId}/archive`,
      method: 'PATCH',
    });
  }

  patchExecutionTime(executionId: ID, body: ExecutionUpdateTimeDto): Promise<void> {
    return this._makeRequest({
      url: `/executions/${executionId}/execution-date`,
      method: 'PATCH',
      data: body,
    });
  }

  patchAcceptOrRejectExecution(executionId: ID, body: ExecutionChangeStatusDto): Promise<void> {
    return this._makeRequest({
      url: `/executions/${executionId}/acceptOrReject`,
      method: 'PATCH',
      data: body,
    });
  }

  patchCompleteExecution(executionId: ID, body: ExecutionCompleteDto): Promise<any> {
    return this._makeRequest({
      url: `/executions/${executionId}/complete`,
      method: 'PATCH',
      data: body,
    });
  }

  putEditCompleteExecution(executionId: ID, body: ExecutionCompleteDto): Promise<any> {
    return this._makeRequest({
      url: `/executions/${executionId}/edit-execution-completed`,
      method: 'PUT',
      data: body,
    });
  }

  putEditExecutionRejected(executionId: ID, body: ExecutionUpdateDto): Promise<void> {
    return this._makeRequest({
      url: `/executions/${executionId}/edit-execution-rejected`,
      method: 'PUT',
      data: body,
    });
  }

  createDocument(body: FormData): Promise<DocumentDto> {
    return this._makeRequest({
      url: `/documents/upload`,
      method: 'POST',
      data: body,
    });
  }

  editExecutionDocuments(executionId: ID, body: DocumentUpdateDto): Promise<void> {
    return this._makeRequest({
      url: `/executions/${executionId}/documents`,
      method: 'PATCH',
      data: body,
    });
  }

  editIssueDocuments(issueId: ID, body: DocumentUpdateDto): Promise<void> {
    return this._makeRequest({
      url: `/issues/${issueId}/documents`,
      method: 'PATCH',
      data: body,
    });
  }

  getDocumentUrl(documentId: ID): string {
    return `${getBaseUrl()}/documents/${documentId}/stream`;
  }

  getDocumentData(documentId: ID): Promise<Blob> {
    return this._makeRequest({
      url: this.getDocumentUrl(documentId),
      method: 'GET',
      responseType: 'blob',
    });
  }

  getActivitiesExecution(
    status: ExecutionStatusEnum,
    limit: number,
    orgId: ID,
    orgUnitIds?: ID[]
  ): Promise<ActivityDto<ActivityExecutionDto>[]> {
    return this._makeRequest({
      url: `/activities`,
      method: 'GET',
      params: {
        type: ActivityEntityType.EXECUTION,
        executionStatus: status,
        limit,
        organisationId: orgId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
      },
    });
  }

  private async _makeRequest<T>(
    config: AxiosRequestConfig,
    retries = 2,
    err: any = null
  ): Promise<T> {
    if (!retries) {
      return Promise.reject(err);
    }
    if (!this.token) {
      await new Promise((r) => setTimeout(r, 300));
      return this._makeRequest(config, retries, null);
    }
    return APIAxios.request({
      ...config,
    })
      .then((resp) => resp.data)
      .catch(async (error) => {
        if (error.response) {
          if (config.responseType === 'blob') {
            const data = JSON.parse(await error.response.data.text());
            throw new ResponseError(data?.message || error.message, data);
          }
          const isUnauthorized =
            error?.response?.data?.statusCode === 403 &&
            error?.response?.data?.message === 'Forbidden resource' &&
            config.url !== '/auth/login';
          if (isUnauthorized) {
            await requestAzureAccessToken();
            return this._makeRequest(config, retries - 1, error);
          }
          throw new ResponseError(
            error?.response?.data?.message || error.message,
            error?.response?.data?.data
          );
        }
      });
  }

  getExpenseValoEvolution(
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined
  ): Promise<ExpenseValoStatsUi> {
    return this._makeRequest({
      url: `/finance-stats/expense-valo-evolution`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        dateFilterKind,
      },
    });
  }

  getServiceExpense(
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined
  ): Promise<ServiceExpenseUi[]> {
    return this._makeRequest({
      url: `/finance-stats/services-expenses`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        dateFilterKind,
      },
    });
  }

  getWasteExpense(
    from: Date,
    to: Date,
    dateFilterKind: 'OPERATIONS' | 'INVOICING',
    orgUnitIds: ID[] | undefined,
    organisationId: ID | undefined
  ): Promise<WasteExpenseValoUi[]> {
    return this._makeRequest({
      url: `/finance-stats/waste-expense-valo`,
      method: 'GET',
      params: {
        from,
        to,
        organisationId,
        orgUnitIds: orgUnitIds?.map((id) => id.toString()).join(','),
        dateFilterKind,
      },
    });
  }

  private _makeRequestWithoutToken<T>(
    config: AxiosRequestConfig,
    retries = 2,
    err: any = null
  ): Promise<T> {
    if (!retries) {
      return Promise.reject(err);
    }

    return APIAxios.request({
      ...config,
    })
      .then((resp) => resp.data)
      .catch((error) => {
        if (error.response) {
          throw new ResponseError(
            error?.response?.data?.message || error.message,
            error?.response?.data?.data
          );
        }
      });
  }

  getInvoices(
    organisationId: ID,
    organisationUnitIDs: ID[] | undefined,
    searchString: string,
    page: number,
    rowPerPage: number,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    status: InvoiceFiltersEnum
  ): Promise<PaginationResponseDto<Invoice>> {
    return this._makeRequest({
      url: `/invoice`,
      method: 'GET',
      params: {
        clientOrganisationIds: organisationId,
        clientOrganisationUnitIds: organisationUnitIDs?.map((id) => id.toString()).join(','),
        searchString: searchString,
        page: page,
        limit: rowPerPage,
        dueFrom: fromDate,
        dueTo: toDate,
        status: status,
      },
    });
  }

  downloadInvoice(invoiceId: ID) {
    return this._makeRequest({
      url: `/invoice/${invoiceId}/pdf`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  getContracts(
    organisationId: ID,
    organisationUnitIDs: ID[] | undefined,
    searchString: string,
    page: number,
    rowPerPage: number,
    status: ContractFiltersEnum,
    filters: { [p: string]: string | undefined }
  ): Promise<PaginationResponseDto<ContractDto>> {
    return this._makeRequest({
      url: `/contract`,
      method: 'GET',
      params: {
        clientOrganisationIds: organisationId,
        clientOrganisationUnitIds: organisationUnitIDs?.map((id) => id.toString()).join(','),
        searchString: searchString,
        page: page,
        limit: rowPerPage,
        status: status,
        wasteIds: filters.wasteIds,
        containerIds: filters.containerIds,
      },
    });
  }

  getContract(contractId: ID): Promise<ContractDto> {
    return this._makeRequest({
      url: `/contract/${contractId}`,
      method: 'GET',
    });
  }

  getSupplierByContract(contractId: ID): Promise<SupplierOrganisationUnitLight[]> {
    return this._makeRequest({
      url: `/contract/${contractId}/suppliers`,
      method: 'GET',
    });
  }

  getBookmarks(organisationUnitIDs: ID[]): Promise<Bookmarks[]> {
    return this._makeRequest({
      url: `/bookmark-service/${organisationUnitIDs[0]}`,
      method: 'GET',
    });
  }

  putBookmark(body: BookmarkServiceDtoLight): Promise<Bookmarks> {
    return this._makeRequest({
      url: '/bookmark-service',
      method: 'POST',
      data: body,
    });
  }

  deleteBookmark(bookmarkId: ID) {
    return this._makeRequest({
      url: `/bookmark-service/${bookmarkId}`,
      method: 'DELETE',
    });
  }

  getAuditOfferSupplier(auditProposalSupplierId: ID): Promise<AuditProposalSupplierDto[]> {
    return this._makeRequestWithoutToken({
      url: `/admin/audit/proposal-supplier/unprotected/${auditProposalSupplierId}/audit-offer-supplier`,
      method: 'GET',
    });
  }

  getContainerDetailsForAuditOffer(auditOperationId: ID): Promise<AuditOperationResponseDto> {
    return this._makeRequestWithoutToken({
      url: `/admin/audit/operation/unprotected/${auditOperationId}/details`,
      method: 'GET',
    });
  }

  getAuditInformationsByAuditProposalSupplierId(
    auditProposalSupplierId: ID
  ): Promise<AuditProposalInfoUnprotectedDto> {
    return this._makeRequestWithoutToken({
      url: `/admin/audit/proposal-supplier/unprotected/${auditProposalSupplierId}/getInformations`,
      method: 'GET',
    });
  }

  patchAuditProposalSupplier(
    auditProposalSupplierId: ID,
    body: AuditOfferSupplierDto
  ): Promise<AuditProposalSupplierDto> {
    return this._makeRequestWithoutToken({
      url: `/admin/audit/proposal-supplier/unprotected/${auditProposalSupplierId}`,
      method: 'PATCH',
      data: body,
    });
  }

  checkUserReview(): Promise<UserReviewCheckDto> {
    return this._makeRequest({
      url: `/user-review/check`,
      method: 'GET',
    });
  }

  createUserReview(
    status: string,
    rating?: number
  ): Promise<{ id: number; userId: number; status: string; rating?: number }> {
    return this._makeRequest({
      url: `/user-review`,
      method: 'POST',
      data: {
        status,
        rating,
      },
    });
  }

  getIssue(
    page: number,
    limit: number,
    sortBy: string,
    orderBy: GridSortDirection,
    filters: { [p: string]: string | undefined },
    organisationUnitIds: ID[] | undefined,
    organisationId?: ID,
    issueStatus?: IssueStatus,
    from?: Date,
    to?: Date,
    searchString?: string
  ): Promise<PaginationResponseDto<Issue>> {
    return this._makeRequest({
      url: `/issues`,
      method: 'GET',
      params: {
        page,
        limit,
        sortBy,
        orderBy: orderBy?.toUpperCase(),
        ...filters,
        issueStatus,
        from,
        to,
        searchString,
        clientOrganisationUnitIds: organisationUnitIds?.map((id) => id.toString()).join(','),
        clientOrganisationIds: organisationId?.toString() ?? '',
      },
    });
  }

  getIssueById(issueId: ID): Promise<ManageIssue> {
    return this._makeRequest({
      url: `/issues/${issueId}`,
      method: 'GET',
    });
  }
}

export const metawasteApi = new MetawasteApi();
