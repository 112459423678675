import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';

export interface DashboardExecutionsState extends EntityState<ExecutionDto> {
  total: number;
  totalToConfirm: number;
}

const initialState: DashboardExecutionsState = {
  total: 0,
  totalToConfirm: 0,
};

@StoreConfig({
  name: 'dashboardExecutions',
  resettable: true,
})
export class DashboardExecutionsStore extends EntityStore<DashboardExecutionsState> {
  constructor() {
    super(initialState);
  }
}

export const dashboardExecutionsStore = new DashboardExecutionsStore();
