import React, { FC, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Stack, Theme } from '@mui/material';
import { useOperationsListStyles } from './OperationsList.style';
import { executionColumns } from './table.utils';
import { isMobile } from 'utils/isMobile';
import { Colors } from 'style/materialStylings';
import { Search } from '@mui/icons-material';
import MaterialTextfieldBase from 'components/MaterialTextfield/MaterialTextfieldBase';
import ActionOperationsScreen from '../bottomModal/ActionOperations.screen';
import MaterialDatagrid from 'components/MaterialDatagrid';
import { operationExecutionsQuery } from 'store/operations/operationExecutions';
import { ID } from '@datorama/akita';
import { createExecutionService } from 'store/operations/createExecution';
import { useTranslation } from 'react-i18next';
import {
  OperationFilters,
  useFetchExecutionSearch,
} from 'store/operations/operationExecutions/hooks/useFetchExecutionSearch';
import env from 'utils/env';
import SelectComponent from 'components/Select/Select.component';
import { execStatusSelectItems } from 'utils/enums/ExecutionStatusEnum';
import { ThemeProvider } from '@mui/styles';
import { filtersTheme } from 'components/Filters/Filters.theme';
import DateRangePickerComponent from 'components/DateRangePicker/DateRangePicker.component';
import CheckboxComponent from 'components/CheckboxComponent/Checkbox.component';
import { useObservable } from '@ngneat/react-rxjs';

const OperationsListComponent: FC = () => {
  const [page, setPage] = useState<number>(0);
  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const [loading] = useObservable(operationExecutionsQuery.selectLoading());
  const [filters, setFilters] = useState<OperationFilters>({});
  const classes = useOperationsListStyles();
  const isMobiles = isMobile();
  const { t } = useTranslation();

  const { executions, total } = useFetchExecutionSearch(page, filters);

  const updateFilters = (key: string) => (value: any) => setFilters({ ...filters, [key]: value });

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const handleOpenModal = (ids: ID[]) => {
    const execution = executions.find((execution) => ids.includes(execution.id));
    if (execution) {
      createExecutionService.openModal(execution.id);
    }
  };

  return (
    <ThemeProvider
      theme={(theme: Theme) => ({
        ...theme,
        components: {
          ...theme.components,
          ...filtersTheme,
        },
      })}
    >
      <Stack spacing={2} direction="column">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <DateRangePickerComponent
              size="small"
              color="secondary"
              inputWidth={150}
              placeholderStart={t('fe.operations.filters.from')}
              placeholderEnd={t('fe.operations.filters.to')}
              startDate={filters.from}
              endDate={filters.to}
              handleChangeStartDate={updateFilters('from')}
              handleChangeEndDate={updateFilters('to')}
            />
            <SelectComponent
              size="small"
              color="secondary"
              inputWidth={150}
              items={execStatusSelectItems()}
              value={filters.status}
              handleChange={updateFilters('status')}
              placeholder={t('fe.operations.filters.status')}
            />
            <SelectComponent
              size="small"
              color="secondary"
              inputWidth={150}
              items={[
                { label: t('fe.operations.filters.typeOptions.onDemand'), value: false },
                { label: t('fe.operations.filters.typeOptions.scheduled'), value: true },
              ]}
              value={filters.onlyWithSchedule}
              handleChange={updateFilters('onlyWithSchedule')}
              placeholder={t('fe.operations.filters.type')}
            />
          </Stack>
          <Stack alignItems="flex-end">
            <CheckboxComponent
              handleChange={updateFilters('toPlan')}
              value={filters.toPlan}
              label={t('fe.operations.filters.onlyToPlan')}
            />
          </Stack>
        </Stack>
        {/*<Grid item>*/}

        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <FilterTabs*/}
        {/*    tabs={tabs}*/}
        {/*    fullWidth={!isMobiles}*/}
        {/*    value={filteredStatusIdx}*/}
        {/*    handleChange={setFilteredStatusIdx}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <MaterialTextfieldBase
            type="search"
            startIcon={<Search htmlColor={Colors.darkGray} />}
            placeholder={t('fe.operations.searchBarPlaceholder')}
            color="secondary"
            value={filters.searchString}
            handleChange={updateFilters('searchString')}
          />
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.cardContainer}>
            <CardContent>
              <MaterialDatagrid
                loading={loading}
                autoHeight
                onRowSelected={handleOpenModal}
                dataByPage={env.PAGINATION}
                totalDataPage={total}
                onPageChange={setPage}
                page={page}
                data={executions}
                rowHeight={isMobiles ? 170 : 75}
                defaultSortField={isMobiles ? 'pickupDateMobile' : 'pickupDate'}
                columns={executionColumns({
                  isDesktop: !isMobiles,
                  openModal: () => setIsBottomModalOpen(true),
                })}
              />
            </CardContent>
          </Card>
        </Grid>
        {isBottomModalOpen && isMobiles && (
          <ActionOperationsScreen handleClickCloseModal={() => setIsBottomModalOpen(false)} />
        )}
      </Stack>
    </ThemeProvider>
  );
};

export default OperationsListComponent;
