import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield';
import { sessionQuery } from 'store/session';

const SettingsDetailsComponent = () => {
  const [firstName, setFirstName] = useState<string | undefined>('');
  const [lastName, setLastName] = useState<string | undefined>('');
  const [mail, setMail] = useState<string | undefined>('');
  const { t } = useTranslation();

  useEffect(() => {
    const _fistname = sessionQuery.firstName$.subscribe(setFirstName);
    const _lastname = sessionQuery.lastName$.subscribe(setLastName);
    const _email = sessionQuery.email$.subscribe(setMail);
    return () => {
      _fistname.unsubscribe();
      _lastname.unsubscribe();
      _email.unsubscribe();
    };
  }, []);

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          {t('fe.settings.subtitle.details')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          readOnly
          label={t('fe.settings.name')}
          type="text"
          placeholder={t('fe.settings.placeholder.name')}
          color="primary"
          value={firstName}
          handleChange={setFirstName}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          readOnly
          label={t('fe.settings.surname')}
          type="text"
          placeholder={t('fe.settings.placeholder.surname')}
          color="primary"
          value={lastName!}
          handleChange={setLastName}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          readOnly
          label={t('fe.settings.mail')}
          type="text"
          placeholder={t('fe.settings.placeholder.mail')}
          color="primary"
          value={mail}
          handleChange={setMail}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsDetailsComponent;
