import React from 'react';
import { GridColDef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as IconDownload } from 'assets/icons/rework/downloadinline.svg';
import dayjs from 'dayjs';
import { Colors } from 'constants/Colors';
import { InvoiceFilterSelectItems, InvoiceStatusEnum } from 'store/invoice';
import { t } from 'i18next';

interface ColumnTypes {
  [key: string]: GridColDef;
}

function getColumnTypes<T extends { [keyName: string]: GridColDef }>(types: T) {
  return types;
}

interface GetColumnsParams {
  // intl: IntlShape;
  // currentStatus: number;
  // userRole: UserTypes;
  isDesktop: boolean;
  openModal?: () => void;
  onClick?: (value: string) => void;
}

export const invoicesColumns = (params: GetColumnsParams): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    invoiceRef: {
      field: 'invoiceRef',
      headerName: t('fe.invoicesTable.reference'),
      flex: 1,
    },
    documentDate: {
      field: 'documentDate',
      headerName: t('fe.invoicesTable.documentDate'),
      flex: 1,
      renderCell: (params) => <Typography>{dayjs(params.value).format('DD.MM.YYYY')}</Typography>,
    },
    dueDate: {
      field: 'dueDate',
      headerName: t('fe.invoicesTable.dueDate'),
      flex: 1,
      renderCell: (params) => <Typography>{dayjs(params.value).format('DD.MM.YYYY')}</Typography>,
    },
    unitName: {
      field: 'unitName',
      headerName: t('fe.invoicesTable.clientUnit'),
      flex: 1,
    },
    totalAmountNoVatInCents: {
      field: 'totalAmountNoVatInCents',
      headerName: t('fe.invoicesTable.amountNoVat'),
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {new Intl.NumberFormat('de-CH', {
            currency: params.row?.currency,
            style: 'currency',
          }).format(params.value / 100)}
        </Typography>
      ),
    },
    totalAmountVatIncludedInCents: {
      field: 'totalAmountVatIncludedInCents',
      headerName: t('fe.invoicesTable.amountVatIncluded'),
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {new Intl.NumberFormat('de-CH', {
            currency: params.row?.currency,
            style: 'currency',
          }).format(params.value / 100)}
        </Typography>
      ),
    },
    status: {
      field: 'status',
      headerName: t('fe.invoicesTable.status'),
      flex: 1,
      renderCell: (renderParams: GridRenderCellParams<InvoiceStatusEnum>) => {
        const status = InvoiceFilterSelectItems().find((item) => item.value === renderParams.value);
        return (
          <Stack
            sx={{
              background: status?.backgroundColor,
              padding: '10px',
              borderRadius: '4px',
              width: '130px',
              textAlign: 'center',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontStyle="11px" fontWeight={400}>
              {status?.label}
            </Typography>
          </Stack>
        );
      },
    },
    actions: {
      field: 'actions',
      headerClassName: 'hiddenHeader',
      cellClassName: 'noPaddingRight',
      sortable: false,
      filterable: false,
      align: 'right',
      flex: 1,
      renderCell: (renderParams) => (
        <div>
          <IconButton
            onClick={() => (params.onClick ? params.onClick(renderParams?.row?.id) : undefined)}
          >
            <IconDownload color={Colors.green} />
          </IconButton>
        </div>
      ),
    },
  });
  return [
    COLUMN_TYPES.invoiceRef,
    COLUMN_TYPES.documentDate,
    COLUMN_TYPES.dueDate,
    COLUMN_TYPES.unitName,
    COLUMN_TYPES.totalAmountNoVatInCents,
    COLUMN_TYPES.totalAmountVatIncludedInCents,
    COLUMN_TYPES.status,
    COLUMN_TYPES.actions,
  ];
};
