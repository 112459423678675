import React, { FC } from 'react';
import { useListItemsStyles } from './ListItems.style';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';

interface ListItemsProps {
  data: {
    title?: string;
    // eslint-disable-next-line no-undef
    icon?: JSX.Element;
  }[];
}

const ListItems: FC<ListItemsProps> = (props) => {
  const { data = [] } = props;
  const classes = useListItemsStyles();

  return (
    <List>
      {data.map((data) => (
        <ListItem key={data.title} button>
          <ListItemIcon sx={{ minWidth: '0px !important' }}>{data.icon}</ListItemIcon>
          <ListItemText primary={data.title} />
        </ListItem>
      ))}
      <Divider variant="middle" className={classes.dividerContainer} />
    </List>
  );
};

export default ListItems;
