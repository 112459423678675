import { QueryEntity } from '@datorama/akita';
import { ContractState, ContractsStore, contractsStore } from './contracts.store';

export class ContractsQuery extends QueryEntity<ContractState> {
  constructor(protected store: ContractsStore) {
    super(store);
  }

  contracts$ = this.select((state) => state.contracts);
  search$ = this.select((state) => state.search);
  total$ = this.select((state) => state.total);
  status$ = this.select((state) => state.status);
}

export const contractsQuery = new ContractsQuery(contractsStore);
