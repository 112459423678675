import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCreateStyles = makeStyles((theme: Theme) => ({
  loadingCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buttonPosition: {
    marginTop: '26px !important',
    display: 'flex',
    float: 'right',
    [theme.breakpoints.only('xs')]: {
      padding: '11px 16px !important',
    },
  },
}));
