import React from 'react';
import { Icon, MenuItem, Select, SxProps, Theme } from '@mui/material';
import { useMaterialDropdownBaseStyles } from './MaterialDropdownBase.style';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export interface MaterialDropdownBaseProps {
  color?: 'primary' | 'secondary';
  label?: string;
  name?: string;
  handleChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: any;
  error?: string;
  value?: any;
  items?: MaterialDropdownItem[];
  isIconPicker?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
}

const MaterialDropdownBase = (props: MaterialDropdownBaseProps) => {
  const {
    color = 'primary',
    name,
    fullWidth = true,
    handleChange,
    required,
    disabled,
    placeholder,
    readOnly,
    defaultValue,
    error,
    value = '',
    items,
    isIconPicker = false,
    className,
    sx,
  } = props;
  const classes = useMaterialDropdownBaseStyles();

  return (
    <Select
      className={className}
      variant="filled"
      color={color}
      sx={sx}
      classes={{
        filled: !defaultValue && !value ? classes.placeholder : undefined,
      }}
      onChange={(evt) => handleChange?.(evt.target.value)}
      fullWidth={fullWidth}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      name={name}
      readOnly={readOnly}
      error={!!error}
      value={value}
      displayEmpty
      disableUnderline
    >
      {(placeholder || defaultValue) && (
        <MenuItem value="" className={!defaultValue ? classes.placeholder : undefined}>
          {defaultValue || placeholder || 'None'}
        </MenuItem>
      )}
      {items?.map((item) =>
        isIconPicker ? (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            <Icon classes={{ root: classes.iconRoot }}>
              <img className={classes.imageIcon} src={item.label} />
            </Icon>
          </MenuItem>
        ) : (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        )
      )}
    </Select>
  );
};

export default MaterialDropdownBase;
