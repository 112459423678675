import React, { FC } from 'react';
import SideModal from 'components/Modal/SideModal';
import ActivitiesPanel from '../activitiesPanel/Activities.panel';

interface ActivitiesModalPanelProps {
  handleClickCloseSideBar?: () => void;
  buttonShow?: boolean;
  dateCalendarSelected: Date;
  setDate: (newDate: Date) => void;
}

const ActivitiesModalPanel: FC<ActivitiesModalPanelProps> = (props) => {
  const { handleClickCloseSideBar, buttonShow, dateCalendarSelected, setDate } = props;

  return (
    <SideModal handleClickCloseModal={handleClickCloseSideBar}>
      <ActivitiesPanel
        buttonShow={buttonShow}
        dateCalendarSelected={dateCalendarSelected}
        setDate={setDate}
      />
    </SideModal>
  );
};

export default ActivitiesModalPanel;
