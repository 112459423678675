import { Query } from '@datorama/akita';
import {
  FinanceScreenStats,
  FinanceScreenStatsStore,
  financeScreenStatsStore,
} from './finance-screen-stats.store';

export class FinanceScreenStatsQuery extends Query<FinanceScreenStats> {
  constructor(protected store: FinanceScreenStatsStore) {
    super(store);
  }

  isExpenseValoEvolutionLoading$ = this.select((stats) => stats.expenseValoEvolution.isLoading);
  getExpenseValoEvolutionStats$ = this.select((stats) => stats.expenseValoEvolution.stats);

  isServiceExpenseLoading$ = this.select((stats) => stats.serviceExpense.isLoading);
  getServiceExpenseStats$ = this.select((stats) => stats.serviceExpense.stats);

  isWasteExpenseValoLoading$ = this.select((stats) => stats.wasteExpenseValo.isLoading);
  getwasteExpenseValoStats$ = this.select((stats) => stats.wasteExpenseValo.stats);
}

export const financeScreenStatsQuery = new FinanceScreenStatsQuery(financeScreenStatsStore);
