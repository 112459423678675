import { QueryEntity } from '@datorama/akita';
import { NotificationsState, NotificationsStore, notificationsStore } from './notifications.store';

export class NotificationsQuery extends QueryEntity<NotificationsState> {
  constructor(protected store: NotificationsStore) {
    super(store);
  }

  getNotifUser$ = this.selectAll();

  numberNewNotifications$ = this.selectCount((notification) => notification.hasSeen === false);
}

export const notificationsQuery = new NotificationsQuery(notificationsStore);
