import { Checkbox, LabelDisplayedRowsArgs, Stack } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridFeatureMode,
  GridLocaleText,
  GridRowEditStartParams,
  GridRowEditStartReasons,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSortItem,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewTableStyled, TableMenuStyle } from './NewTable.styled';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GridStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { RadioChecked, RadioLess, RadioUnchecked } from 'assets/icons';
import { Colors } from 'constants/Colors';
import { TableConfig } from 'constants/table.constants';
import { NoRowsOverlay } from './NoRows.overlay';

interface TableComponentProps {
  columns?: GridColDef[];
  data?: GridRowsProp;
  disableEdition?: boolean;
  disablePagination?: boolean;
  disableRowSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  handlePageChange?: (page: number) => void;
  handlePageSizeChange?: (pageSize: number) => void;
  handleRowChanged?: (row: any, oldRow: GridRowModel) => GridValidRowModel;
  handleRowSelected?: (rows: any[]) => void;
  handleSortModelChange?: (newModel: GridSortItem) => void;
  loading?: boolean;
  page?: number;
  pageSize?: number;
  paginationMode?: GridFeatureMode;
  rowsSelected?: any[];
  singleRowSelection?: boolean;
  sortingMode?: GridFeatureMode;
  sortModel?: GridSortItem[];
  totalRows?: number;
  autoHeight?: boolean;
  setIsEditing?: (value: boolean) => void;
  isRowSelectable?: (params: GridRowParams) => boolean;
  editableFields?: string[];
  state?: GridStatePro;
  handleStateChange?: (state: GridStatePro) => void;
  fullScreen?: boolean;
  columnThreshold?: number;
  isCellEditable?: (params: GridCellParams) => boolean;
}

const TableComponent = (props: TableComponentProps) => {
  const {
    columns = [],
    data = [],
    disablePagination,
    disableRowSelection,
    disableEdition,
    handlePageChange,
    handlePageSizeChange,
    handleRowChanged,
    handleRowSelected,
    handleSortModelChange,
    loading,
    page,
    pageSize,
    paginationMode = 'server',
    rowsSelected = [],
    singleRowSelection,
    sortingMode = 'server',
    sortModel,
    totalRows,
    disableRowSelectionOnClick = false,
    autoHeight,
    setIsEditing,
    isRowSelectable,
    editableFields,
    fullScreen,
    state,
    handleStateChange,
    isCellEditable,
    columnThreshold = 3,
  } = props;

  const { t } = useTranslation();

  const apiRef = useGridApiRef();

  const [columnSizes, setColumnSizes] = React.useState<any>({});

  return (
    <Stack
      flex={1}
      width={fullScreen ? '100%' : undefined}
      sx={{
        '& .grey-row': {
          backgroundColor: Colors.error,
        },
        '& .MuiTablePagination-input': {
          width: '100px', // Adjust width as needed
        },
      }}
    >
      <NewTableStyled
        apiRef={apiRef}
        loading={loading}
        className={singleRowSelection ? 'single-row-selection' : undefined}
        disableColumnMenu
        disableColumnFilter
        isCellEditable={isCellEditable}
        onColumnWidthChange={() => apiRef.current.exportState()}
        onRowEditStart={(params: GridRowEditStartParams, event: MuiEvent<any>) => {
          if (disableEdition) {
            event.defaultMuiPrevented = true;
            return;
          }
          if (
            params.reason === GridRowEditStartReasons.deleteKeyDown ||
            (params.reason === GridRowEditStartReasons.printableKeyDown && event.keyCode !== 69)
          ) {
            event.defaultMuiPrevented = true;
          }

          if (params.field && editableFields && !editableFields.includes(params.field)) {
            event.defaultMuiPrevented = true;
            if (setIsEditing) {
              setIsEditing(true);
            }
            apiRef.current.startRowEditMode({ id: params.id, fieldToFocus: editableFields[0] });
          }

          if (setIsEditing) {
            setIsEditing(true);
          }
        }}
        onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
          if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = false;
          }
          if (setIsEditing) {
            setIsEditing(false);
          }
        }}
        hideFooterRowCount={disablePagination}
        hideFooter={disablePagination && disableRowSelection}
        pinnedColumns={{ right: ['actions'] }}
        columnHeaderHeight={TableConfig.headerHeight}
        rowHeight={TableConfig.rowHeight}
        pageSizeOptions={[10, 20, TableConfig.pagination, 100, 250]}
        onPaginationModelChange={(model) => {
          if (
            JSON.stringify(model) !== JSON.stringify({ page: page ?? 0, pageSize: pageSize ?? 50 })
          ) {
            handlePageSizeChange?.(model.pageSize);
            handlePageChange?.(model.page);
          }
        }}
        isRowSelectable={isRowSelectable}
        paginationModel={{ pageSize: pageSize ?? 50, page: page ?? 1 }}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        autoHeight={autoHeight}
        components={{
          BaseCheckbox: React.forwardRef((params, ref) => (
            <Checkbox
              {...params}
              ref={ref}
              checkedIcon={<RadioChecked />}
              indeterminateIcon={<RadioLess />}
              icon={<RadioUnchecked />}
              sx={params.disabled ? { display: 'none' } : undefined}
            />
          )),
          NoResultsOverlay: () => <NoRowsOverlay />,
          NoRowsOverlay: () => <NoRowsOverlay />,
          // eslint-disable-next-line react/no-multi-comp
          ColumnSortedDescendingIcon: () => <ExpandLess />,
          // eslint-disable-next-line react/no-multi-comp
          ColumnSortedAscendingIcon: () => <ExpandMore />,
        }}
        componentsProps={{
          basePopper: {
            sx: TableMenuStyle,
          },
        }}
        editMode="row"
        processRowUpdate={handleRowChanged}
        onProcessRowUpdateError={(err) => console.log(err)}
        sortingMode={sortingMode}
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(value) =>
          value.length ? handleSortModelChange?.(value[0]!) : undefined
        }
        sortModel={sortModel ? sortModel : undefined}
        columns={columns.map((c) => {
          const width = columnSizes[c.field];
          if (width) {
            c.width = width;
          }
          return c;
        })}
        checkboxSelection={!disableRowSelection}
        keepNonExistentRowsSelected
        onRowSelectionModelChange={(selected) => {
          if (singleRowSelection)
            handleRowSelected?.(selected.length ? selected.slice(selected.length - 1) : []);
          else handleRowSelected?.(selected);
        }}
        onColumnResize={(params) => {
          columnSizes[params.colDef.field] = params.width;
          setColumnSizes(columnSizes);
        }}
        rowSelectionModel={rowsSelected}
        rows={data}
        localeText={
          {
            footerRowSelected: (count: number) => t('table.rowSelected', { count }),
            footerTotalRows: '',
            MuiTablePagination: {
              labelRowsPerPage: null,
              labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) =>
                t('table.displayedRows', { ...paginationInfo }),
            },
          } as Partial<GridLocaleText>
        }
        rowCount={totalRows}
        paginationMode={paginationMode}
        pagination={!disablePagination}
        columnThreshold={columnThreshold}
      />
    </Stack>
  );
};

export default TableComponent;
