import { ID } from '@datorama/akita';
import { Collapse, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as ArrowDownLine } from 'assets/icons/rework/arrowDownLine.svg';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExecutionDateField from 'screens/auth/modals/executionModal/details/forms/infoFields/ExecutionDate.field';
import ExecutionDestinationField from 'screens/auth/modals/executionModal/details/forms/infoFields/ExecutionDestination.field';
import ExecutionNbContainersField from 'screens/auth/modals/executionModal/details/forms/infoFields/ExecutionNbContainers.field';
import ExecutionServiceField from 'screens/auth/modals/executionModal/details/forms/infoFields/ExecutionService.field';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import { Transitions } from 'style/materialStylings';

interface ExecutionInfoFormProps {
  executionId: ID;
  updateExecutionField: (name: string) => (value: any) => void;
  isOnbording?: boolean;
}

const ObExecutionInfoForm: FC<ExecutionInfoFormProps> = (props) => {
  const { executionId, updateExecutionField, isOnbording } = props;

  const [execution, setExecution] = useState<ExecutionDetailsUi>({} as ExecutionDetailsUi);
  const [isExpanded, setIsExpanded] = useState(true);

  const { t } = useTranslation();

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {t('fe.executionModal.executionInfosForm.title')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            <ArrowDownLine
              width={14}
              height={14}
              style={{
                transform: isExpanded ? undefined : 'rotate(180deg)',
                transition: Transitions.cubic,
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={isExpanded} style={{ width: '100%', paddingTop: 6 }}>
        <Grid container rowSpacing={2}>
          <ExecutionDateField
            execution={execution}
            updateExecutionField={updateExecutionField}
            isOnbording
          />
          <>
            <ExecutionServiceField
              execution={execution}
              updateExecutionField={updateExecutionField}
            />
            <ExecutionNbContainersField
              execution={execution}
              updateExecutionField={updateExecutionField}
            />
          </>
          {!isOnbording && <ExecutionDestinationField execution={execution} />}
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default ObExecutionInfoForm;
