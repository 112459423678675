import React from 'react';
import { Stack, styled } from '@mui/material';
import Loader from 'components/Loader/Loader';

const Container = styled(Stack)`
  background-color: white;
  height: 100vh;
  width: 100%;
`;

const Logo = styled('img')`
  height: 18vh;
  margin-bottom: 50px;
`;

const LoadingScreen = () => {
  return (
    <Container alignItems="center" justifyContent="center">
      <Logo alt="metawaste logo" src="/images/metawaste-logo-no-lines.png" />
      <Loader />
    </Container>
  );
};

export default LoadingScreen;
