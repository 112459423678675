import { ExecutionIssueStore, executionIssueStore } from './execution-issue.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import {
  createNewIssueDetailsFromDto,
  createNewIssueDetailsUi,
  IssueDetailsUi,
} from './execution-issue.model';
import { issueDocumentsService, IssueDocumentsService } from '../issueDocuments';
import { formatToIssueUpdateDto } from 'api/dto/execution/IssueUpdateDto';
import { formatToIssueCreationDto } from 'api/dto/execution/IssueCreationDto';
import i18n from 'utils/data/i18n.utils';

export class ExecutionIssueService {
  constructor(
    private store: ExecutionIssueStore,
    private issueDocumentsService: IssueDocumentsService
  ) {}

  createIssue = async (issue: IssueDetailsUi, executionId: ID) => {
    const body = formatToIssueCreationDto(
      issue,
      this.issueDocumentsService.documentIdsForIssue(issue.id)
    );
    this.store.setIsSaving(issue.id);
    const [newIssue, e] = await resolve(metawasteApi.createIssue(body, executionId));
    this.store.setIsSaving(undefined);
    if (!newIssue || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.create.issue'));
    }
    this.store.setActive(null);
    this.store.remove(issue.id);
    this.store.add(createNewIssueDetailsFromDto(newIssue, executionId));
  };

  updateIssue = async (issue: IssueDetailsUi) => {
    await this.issueDocumentsService.updateDocumentsForIssue(issue.id);
    const documentIds = this.issueDocumentsService.documentIdsForIssue(issue.id);
    const body = formatToIssueUpdateDto(issue, documentIds);
    this.store.setIsSaving(issue.id);
    const [newIssue, e] = await resolve(metawasteApi.updateIssue(body, issue.id));
    this.store.setIsSaving(undefined);
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.updateIssue'));
    }
    this.store.setActive(null);
  };

  openModal = (issue?: IssueDetailsUi) => {
    if (issue) {
      this.store.setActive(issue.id);
      this.issueDocumentsService.populateDocumentsFromIssue(issue);
    } else {
      const newIssue = createNewIssueDetailsUi();
      this.store.add(newIssue);
      this.store.setActive(newIssue.id);
      this.issueDocumentsService.populateDocumentsFromIssue(newIssue);
    }
    this.store.setIsModalOpen(true);
  };

  fetchIssues = async (executionId: ID) => {
    const [issues, e] = await resolve(metawasteApi.getIssues(executionId));
    if (!issues || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issue'));
    }
    const issuesTab = issues.map((issue) => createNewIssueDetailsFromDto(issue, executionId));
    this.store.upsertMany(issuesTab);
  };

  closeModal = (currentIssue?: IssueDetailsUi) => {
    this.store.setIsModalOpen(false);
    this.store.setActive(null);
    this.issueDocumentsService.resetStore();
    if (currentIssue && !currentIssue.dto) {
      this.store.remove(currentIssue.id);
    }
  };

  resetStore = () => this.store.reset();

  setIssueDetail = (value: IssueDetailsUi) => {
    this.store.updateActive(value);
  };

  removeIssue = (issueId: ID) => {
    this.store.remove(issueId);
  };
}

export const executionIssueService = new ExecutionIssueService(
  executionIssueStore,
  issueDocumentsService
);
