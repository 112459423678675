import { Checkbox } from '@mui/material';
import React from 'react';
import { CheckboxChecked, CheckboxUnchecked } from 'assets/icons';

interface CustomCheckboxProps {
  handleChange?: (checked: boolean) => void;
  readOnly?: boolean;
  value?: boolean;
}

export const CustomCheckbox = (props: CustomCheckboxProps) => {
  const { handleChange, readOnly, value } = props;

  return (
    <Checkbox
      {...props}
      onChange={handleChange ? (evt, checked) => handleChange(checked) : undefined}
      value={value}
      readOnly={readOnly}
      checkedIcon={<CheckboxChecked />}
      icon={<CheckboxUnchecked />}
    />
  );
};
