import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    rightDiv: {
      width: '60% !important',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      '& > .MuiIconButton-root': {
        backgroundColor: Colors.white,
        borderRadius: 4,
        height: 40,
        width: 34,
      },
      [theme.breakpoints.only('xs')]: {
        width: '100% !important',
        justifyContent: 'flex-start',
        marginTop: '10px !important',
      },
    },
    typographyValue: {
      marginLeft: '8px !important',
      marginRight: '8px !important',
    },
  });

export default classes;
