import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors, Sizes } from 'style/materialStylings';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: Sizes.drawerWidth,
      height: '100%',
      backgroundColor: 'white',
    },
    logoContainer: {
      position: 'absolute',
      top: 20,
    },
    logo: {
      width: Sizes.drawerWidth,
      padding: '18px 5px',
    },
    tabsContainer: {
      minWidth: 0,
      borderLeft: 1,
      borderColor: 'divider',
    },
    tabsContainerSmall: {
      '& .MuiTabs-indicator': {
        height: '2px !important',
      },
    },
    tab: {
      minWidth: '8px !important',
      padding: '14px 6px 14px 0px !important',
      borderRadius: `0px !important`,
      color: `${Colors.darkGray} !important`,
      marginRight: '23px !important',
      fontWeight: `400 !important`,
      fontSize: '16px !important',
      '& > svg': {
        width: '18px !important',
        height: 'auto !important',
      },
      '&.MuiTab-root.Mui-selected': {
        color: `${Colors.secondary} !important`,
        backgroundColor: 'transparent',
        fontWeight: '700 !important',
        fontSize: '16px !important',
      },
    },
    tabSmall: {
      fontSize: '14px !important',
      '&.MuiTab-root.Mui-selected': {
        fontSize: '14px !important',
      },
    },
  });

export default classes;
