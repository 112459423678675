import { Store, StoreConfig } from '@datorama/akita';
import { IssueCountStatsUi, IssueKindUi } from './operation-screen-stats.model';
import { PickUpDoneDto } from 'api/dto/dashboard/operations/PickUpDoneDto';
import { OperationEvolutionDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';

export interface OperationScreenStats {
  issueCount: {
    stats: IssueCountStatsUi;
    isLoading: boolean;
  };
  issueKindStats: {
    stats: IssueKindUi[];
    isLoading: boolean;
  };
  wasteQuantity: {
    average: number;
    total: number;
    isLoading: boolean;
  };
  pickUpDone: {
    stats: PickUpDoneDto;
    isLoading: boolean;
  };
  operationEvolution: {
    stats: OperationEvolutionDto;
    isLoading: boolean;
  };
}

export function createInitialState(): OperationScreenStats {
  return {
    issueCount: {
      stats: {
        issueCount: 0,
        oldExecutionCount: 0,
        previousIssueCount: 0,
        executionCount: 0,
        higherKind: {
          issueCount: 0,
          issueKindName: '',
        },
        oldHigherKind: {
          issueCount: 0,
          issueKindName: '',
        },
      },
      isLoading: false,
    },
    issueKindStats: {
      stats: [],
      isLoading: false,
    },
    wasteQuantity: {
      average: 0,
      total: 0,
      isLoading: false,
    },
    pickUpDone: {
      stats: {
        numberExecutionPerDay: 0,
        oldNumberExecutionPerDay: 0,
        operationsTicketsCount: 0,
        previousTicketsCount: 0,
      },
      isLoading: false,
    },
    operationEvolution: {
      stats: {
        evolution: {
          current: [],
          old: [],
        },
        execCreatedCount: {
          current: 0,
          old: 0,
        },
        execExecutedCount: {
          current: 0,
          old: 0,
        },
        issueCount: {
          current: 0,
          old: 0,
        },
        execWithIssueCount: {
          current: 0,
          old: 0,
        },
        execPickupWeight: {
          current: 0,
          old: 0,
        },
        execProcessingWeight: {
          current: 0,
          old: 0,
        },
        execValorisationWeight: {
          current: 0,
          old: 0,
        },
        execExecutedPickupCount: {
          current: 0,
          old: 0,
        },
        execTheoPickupWeight: {
          current: 0,
          old: 0,
        },
      },
      isLoading: false,
    },
  };
}

@StoreConfig({ name: 'operationScreenStats' })
export class OperationScreenStatsStore extends Store<OperationScreenStats> {
  constructor() {
    super(createInitialState());
  }

  setIssueCountLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      issueCount: {
        ...state.issueCount,
        isLoading: loader,
      },
    }));

  updateIssueCountStats = (issuesStats: IssueCountStatsUi) =>
    this.update((state) => ({
      ...state,
      issueCount: {
        stats: issuesStats,
        isLoading: false,
      },
    }));

  setWasteAverageQuantityLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      wasteQuantity: {
        ...state.wasteQuantity,
        isLoading: loader,
      },
    }));

  updateWasteQuantitytStats = (average: number, total: number) =>
    this.update((state) => ({
      ...state,
      wasteQuantity: {
        average,
        total,
        isLoading: false,
      },
    }));

  setPickUpDoneLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      pickUpDone: {
        ...state.pickUpDone,
        isLoading: loader,
      },
    }));

  updatePickUpDoneStats = (pickUp: PickUpDoneDto) =>
    this.update((state) => ({
      ...state,
      pickUpDone: {
        stats: pickUp,
        isLoading: false,
      },
    }));

  setOperationEvolutionLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      operationEvolution: {
        ...state.operationEvolution,
        isLoading: loader,
      },
    }));

  updateOperationEvolution = (evolution: OperationEvolutionDto) =>
    this.update((state) => ({
      ...state,
      operationEvolution: {
        stats: evolution,
        isLoading: false,
      },
    }));

  setIssueKindLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      issueKindStats: {
        ...state.issueKindStats,
        isLoading: loader,
      },
    }));

  updateIssueKindStats = (issuesStats: IssueKindUi[]) =>
    this.update((state) => ({
      ...state,
      issueKindStats: {
        stats: issuesStats,
        isLoading: false,
      },
    }));
}

export const operationScreenStatsStore = new OperationScreenStatsStore();
