import React from 'react';
import Page from 'components/Page';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RoutePath, sidebarPages } from 'constants/Routes';
import ContractDetailScreen from './contracts/Contract.detail.screen';

const AuthedPage = React.memo((props: { hasAccessToFinance?: boolean }) => {
  return (
    <Page drawerOpen={true} hideMenus={false}>
      <Switch>
        {sidebarPages(props.hasAccessToFinance).map((page) => (
          <Route key={`route-${page.route}`} exact path={page.route} component={page.component} />
        ))}
        <Route
          path={RoutePath.Contract}
          key="route-contract"
          exact
          component={ContractDetailScreen}
        />
        <Redirect to={sidebarPages()[0].route} />
      </Switch>
    </Page>
  );
});

export default AuthedPage;
