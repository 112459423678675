import { IconButton, InputLabel, Typography } from '@mui/material';
import React, { FC } from 'react';
import style from './StepButtons.style';
import { WithStyles, withStyles } from '@mui/styles';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

interface StepButtonsProps extends WithStyles<typeof style> {
  required?: boolean;
  error?: string;
  label: string;
  value: number;
  handleChange?: (value: number) => void;
  readonly?: boolean;
}

const StepButtons: FC<StepButtonsProps> = (props: StepButtonsProps) => {
  const { classes, required, error, label, value, handleChange, readonly } = props;

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <div className={classes.rightDiv}>
        {!readonly && (
          <IconButton onClick={() => handleChange?.(value - 1)}>
            <RemoveIcon />
          </IconButton>
        )}
        <Typography className={classes.typographyValue}>{value}</Typography>
        {!readonly && (
          <IconButton onClick={() => handleChange?.(value + 1)}>
            <AddIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default withStyles(style)(StepButtons);
