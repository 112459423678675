import { createStyles, makeStyles } from '@mui/styles';
import { emphasize, Theme } from '@mui/material';

const style = (theme: Theme) =>
  createStyles({
    datePicker: {
      '& .MuiIconButton-root': {
        color: theme.palette.text.primary + ' !important',
      },
    },
    eventDayContainer: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    eventDayContainerGlass: {
      backgroundColor: 'rgba(41, 189, 82, 0.07)',
      '&:hover': {
        backgroundColor: 'rgba(41, 189, 82, 0.17)',
      },
    },
    eventDayLayout: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'space-between',
    },
  });

const eventDayContainerStyles = makeStyles({
  main: {
    borderLeft: '3px solid rgba(41, 189, 82, 0.8)',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: (props: any) => props.backgroundColor,
    '&:hover': {
      backgroundColor: (props: any) => emphasize(props.backgroundColor, 0.12),
    },
  },
});

export { style, eventDayContainerStyles };
