import { ID } from '@datorama/akita';
import { metawasteApi } from 'api/Axios';
import { resolve } from 'utils/data/promise-utils';
import SnackError from 'utils/models/snackError';
import {
  ExecutionMonthPlanningStore,
  executionMonthPlanningStore,
} from './execution-month-planning.store';
import dayjs from 'dayjs';

export class ExecutionMonthPlanningService {
  constructor(private store: ExecutionMonthPlanningStore) {}

  fetchExecutionPlanning = async (from: string, to: string, orgId?: ID, orgUnitIds?: ID[]) => {
    if (!orgId) {
      this.store.setLoading(false);
      return;
    }
    this.store.setLoading(true);

    const [planning, e] = await resolve(
      metawasteApi.countExecutionsForPlanning(from, to, orgId, orgUnitIds)
    );
    this.store.setLoading(false);
    if (e || !planning) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    this.store.set(planning);
  };

  async fetchExecutionForDay(day: Date, orgId?: ID, orgUnitIds?: ID[]) {
    if (!orgId) {
      this.store.setLoading(false);
      return;
    }
    this.store.setLoading(true);

    const [executions, e] = await resolve(
      metawasteApi.getPlanning(
        dayjs(day).startOf('day').toISOString(),
        dayjs(day).endOf('day').toISOString(),
        orgId,
        orgUnitIds,
        undefined,
        'execution.plannedFrom',
        'ASC'
      )
    );
    this.store.setLoading(false);
    if (e || !executions) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    return executions;
  }
}

export const executionMonthPlanningService = new ExecutionMonthPlanningService(
  executionMonthPlanningStore
);
