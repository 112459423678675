import { Query } from '@datorama/akita';

import { Session } from './session.model';
import { sessionStore, SessionStore } from './session.store';
import {
  ADMIN_USER_TYPES,
  MANAGING_USER_TYPES,
  PATRICK_USER_TYPES,
  UserTypes,
} from 'constants/UserTypes';

export class SessionQuery extends Query<Session> {
  constructor(protected store: SessionStore) {
    super(store);
  }

  userId$ = this.select((session) => session.user?.id);

  name$ = this.select((session) => {
    const { firstName = '', lastName = '' } = session.user ?? {};
    return `${firstName} ${lastName}`.replace(/\b\w/g, (l) => l.toUpperCase());
  });
  initials$ = this.select((session) => {
    const { firstName = '', lastName = '' } = session.user ?? {};
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  });
  isMorgan$ = this.select((session) =>
    session.user?.userRoles?.some((it) => MANAGING_USER_TYPES.has(it))
  );
  isAdmin$ = this.select((session) =>
    session.user?.userRoles?.some((it) => ADMIN_USER_TYPES.has(it))
  );
  isPatrick$ = this.select((session) =>
    session.user?.userRoles?.some((it) => PATRICK_USER_TYPES.has(it))
  );
  isInternal$ = this.select((session) =>
    session.user?.userRoles?.some((it) => UserTypes.InternalSiteOperator === it)
  );
  isExternal$ = this.select((session) =>
    session.user?.userRoles?.some((it) => UserTypes.ExternalSiteOperator === it)
  );

  firstName$ = this.select((session) => session.user?.firstName);
  roles$ = this.select((session) => session.user?.userRoles);
  lastName$ = this.select((session) => session.user?.lastName);
  email$ = this.select((session) => session.user?.email);
  organisations$ = this.select((session) => session.user?.organisations ?? []);
  hasStatsAccess$ = this.select((session) => session.user?.hasStatsAccess);

  currentUser = this.getValue().user;

  get isMorgan() {
    return this.getValue().user?.userRoles?.some((it) => MANAGING_USER_TYPES.has(it));
  }

  get isAdmin() {
    return this.getValue().user?.userRoles?.some((it) => ADMIN_USER_TYPES.has(it));
  }

  get isPatrick() {
    return this.getValue().user?.userRoles?.some((it) => PATRICK_USER_TYPES.has(it));
  }

  isWorkSite = () => this.getValue().user?.userRoles?.some((it) => it === UserTypes.Worksite);

  userPreferences = () => this.getValue().user?.userPreferences;
}

export const sessionQuery = new SessionQuery(sessionStore);
