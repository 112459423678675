import React, { FC } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import style from './RankingLittleCard.style';
// eslint-disable-next-line no-restricted-imports
import StatisticTrendData from '../../TrendData/StatisticTrendData';

interface RankingLittleCardProps extends WithStyles<typeof style> {
  title?: string;
  background?: 'info.light' | 'info.dark';
  firstDataStatistic?: number;
  secondDataStatistic?: number;
  firstDescription?: string;
  secondDescription?: string;
}

const RankingLittleCard: FC<RankingLittleCardProps> = (props) => {
  const {
    classes,
    title,
    background,
    firstDataStatistic,
    secondDataStatistic,
    firstDescription,
    secondDescription,
  } = props;

  return (
    <Card sx={{ backgroundColor: background }} className={classes.container}>
      <CardContent>
        <Typography
          variant="h4"
          color={background === 'info.light' ? 'black' : 'white'}
          className={classes.title}
        >
          {title}
        </Typography>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <Grid item xs={6} className={classes.grid}>
              {firstDataStatistic && (
                <StatisticTrendData
                  stat={firstDataStatistic}
                  backgroundCard={background}
                  icon={false}
                  nameOfCard="ranking"
                />
              )}
              <Typography color="black" className={classes.typography}>
                {firstDescription}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {secondDataStatistic && (
                <StatisticTrendData
                  stat={secondDataStatistic}
                  backgroundCard={background}
                  icon={false}
                  nameOfCard="ranking"
                />
              )}
              <Typography color="black" className={classes.typography}>
                {secondDescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(RankingLittleCard);
