import { Badge, Divider, Grid, ListItemButton, Typography } from '@mui/material';
import { useNotificationModalStyles } from '../NotificationModalStyle';
import { useTranslation } from 'react-i18next';
import { translateNotificationsStatus, TypeNotifications } from 'utils/enums/TypeNotifications';
import { NotificationUi } from 'store/dashboard/notifications';
import React from 'react';
import { ReactComponent as Chat } from 'assets/icons/rework/notifications/newcomment.svg';
import { ReactComponent as Warning } from 'assets/icons/rework/notifications/newissue.svg';
import { ReactComponent as DarkWarning } from 'assets/icons/rework/notifications/issuemodification.svg';
import { ReactComponent as ResolvedIssue } from 'assets/icons/rework/notifications/resolvedissue.svg';
import { ReactComponent as Modified } from 'assets/icons/rework/collection_modified.svg';
import { ReactComponent as Accepted } from 'assets/icons/rework/collection_accepted.svg';
import { ReactComponent as Refused } from 'assets/icons/rework/collection_refused.svg';
import { ReactComponent as NewRequest } from 'assets/icons/rework/notifications/newrequest.svg';
import { capitalizeFirstLetter } from 'utils/data/string.utils';
import dayjs from 'utils/data/dayjs.utils';

interface NotificationProps {
  notification: NotificationUi;
  type: TypeNotifications;
  hasSeen: boolean;
  notifClick: (value: NotificationUi) => void;
  receivedAt: Date;
  signature?: string;
}

const NotificationCard = (props: NotificationProps) => {
  const { notification, type, notifClick, signature, receivedAt, hasSeen } = props;
  const classes = useNotificationModalStyles();
  const { t } = useTranslation();

  const getIcon = (type: TypeNotifications) => {
    switch (type) {
      case TypeNotifications.EXECUTION_NEW_COMMENT:
        return <Chat width={46} height={43} />;
      case TypeNotifications.EXECUTION_NEW_ISSUE:
        return <Warning width={46} height={43} />;
      case TypeNotifications.EXECUTION_ISSUE_UPDATED:
        return <DarkWarning width={46} height={43} />;
      case TypeNotifications.EXECUTION_ISSUE_CLOSED:
        return <ResolvedIssue width={46} height={43} />;
      case TypeNotifications.EXECUTION_UPDATED:
        return <Modified width={46} height={43} />;
      case TypeNotifications.EXECUTION_ACCEPTED:
      case TypeNotifications.EXECUTION_ENDED:
        return <Accepted width={46} height={43} />;
      case TypeNotifications.EXECUTION_DENIED:
      case TypeNotifications.EXECUTION_DELETED:
        return <Refused width={46} height={43} />;
      case TypeNotifications.EXECUTION_TO_BE_TREATED:
        return <NewRequest width={46} height={43} />;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <ListItemButton onClick={() => notifClick(notification)}>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container alignItems="center">
                {!hasSeen && <Badge color="success" variant="dot" style={{ marginRight: 10 }} />}
                <Typography style={{ fontSize: '14px' }}>
                  {translateNotificationsStatus(type, signature)}
                </Typography>
              </Grid>
              <Typography
                className={classes.subtitle}
                style={{ paddingLeft: !hasSeen ? 12 : undefined, fontSize: '12px' }}
              >
                {capitalizeFirstLetter(dayjs(receivedAt).fromNow())}
              </Typography>
            </Grid>
            <Grid item>{getIcon(type)}</Grid>
          </Grid>
        </ListItemButton>
      </Grid>
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
    </>
  );
};

export default NotificationCard;
