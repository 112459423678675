import { CircularProgress, Grid } from '@mui/material';
import React from 'react';
import CardOperations from 'components/Card/CardOperations/CardOperations';
import { operationsStatsService } from 'store/dashboard/operationStats';
import { isMobile } from 'utils/isMobile';
import { useOperationsScreenStyles } from '../Operations.screen.style';
import { useTranslation } from 'react-i18next';
import { NoResultComponent } from './NoResult';
import { OperationEvolutionDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';

interface OperationStatIssueCountProps {
  isLoading?: boolean;
  evolution?: OperationEvolutionDto;
}

export const OperationStatIssueCount = (props: OperationStatIssueCountProps) => {
  const { isLoading, evolution } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();
  const classes = useOperationsScreenStyles();
  if (isLoading) {
    return <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />;
  }
  if (!evolution) {
    return <NoResultComponent />;
  }

  return (
    <Grid container spacing={2} className={classes.collectesCardContainer}>
      <Grid item xs={isMobiles ? 12 : 6}>
        <CardOperations
          data={evolution.execWithIssueCount.current.toString()}
          dataStatistic={
            operationsStatsService.calculatePercentage(
              evolution.execWithIssueCount.current ?? 0,
              evolution.execCreatedCount.current ?? 0
            ) -
            operationsStatsService.calculatePercentage(
              evolution.execWithIssueCount.old ?? 0,
              evolution.execCreatedCount.old ?? 0
            )
          }
          background="error.light"
          inverse={true}
          percentage={`/${evolution.execCreatedCount.current}`}
          description={t('fe.dashboard.morgan.stats.operations.issue.amountIssue', {
            amount: operationsStatsService.calculatePercentage(
              evolution.execWithIssueCount.current ?? 0,
              evolution.execCreatedCount.current ?? 0
            ),
          })}
        />
      </Grid>
      {/*<Grid item xs={isMobiles ? 12 : 6}>*/}
      {/*  <CardOperations*/}
      {/*    data={operationsStatsService*/}
      {/*      .calculatePercentage(*/}
      {/*        issueCountStats?.higherKind.issueCount ?? 0,*/}
      {/*        issueCountStats?.issueCount ?? 0*/}
      {/*      )*/}
      {/*      .toString()}*/}
      {/*    dataStatistic={operationsStatsService.calculatePercentageEvolution(*/}
      {/*      operationsStatsService.calculatePercentage(*/}
      {/*        issueCountStats?.oldHigherKind.issueCount ?? 0,*/}
      {/*        issueCountStats?.previousIssueCount ?? 0*/}
      {/*      ),*/}
      {/*      operationsStatsService.calculatePercentage(*/}
      {/*        issueCountStats?.higherKind.issueCount ?? 0,*/}
      {/*        issueCountStats?.issueCount ?? 0*/}
      {/*      )*/}
      {/*    )}*/}
      {/*    background="error.light"*/}
      {/*    percentage="%"*/}
      {/*    description={*/}
      {/*      issueCountStats?.higherKind.issueKindName*/}
      {/*        ? t('fe.dashboard.morgan.stats.operations.issue.percentageInIssue', {*/}
      {/*            cause: translateIssueKindName(issueCountStats?.higherKind.issueKindName),*/}
      {/*          })*/}
      {/*        : t('fe.dashboard.morgan.stats.operations.issue.none')*/}
      {/*    }*/}
      {/*  />*/}
      {/*</Grid>*/}
    </Grid>
  );
};
