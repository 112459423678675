import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IssueDocument } from './issue-document.model';

export interface IssueDocumentsState extends EntityState<IssueDocument> {
  originalDocuments: IssueDocument[];
}

const initialState: IssueDocumentsState = {
  originalDocuments: [],
};

@StoreConfig({
  name: 'issueDocuments',
  resettable: true,
})
export class IssueDocumentsStore extends EntityStore<IssueDocumentsState> {
  constructor() {
    super(initialState);
  }
}

export const issueDocumentsStore = new IssueDocumentsStore();
