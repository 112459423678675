import React from 'react';
import Page from 'components/Page';
import { Redirect, Route, Switch } from 'react-router-dom';
import { obSidebarPages } from 'constants/Routes';

const ObAuthedPage = React.memo(() => {
  return (
    <Page drawerOpen={true} hideMenus={false}>
      <Switch>
        {obSidebarPages().map((page) => (
          <Route key={`route-${page.route}`} exact path={page.route} component={page.component} />
        ))}
        <Redirect to={obSidebarPages()[0].route} />
      </Switch>
    </Page>
  );
});

export default ObAuthedPage;
