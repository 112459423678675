import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSearchDropdownBaseStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textfield: {
    width: '60% !important',
  },
  placeholder: {
    color: theme.palette.text.primary,
    fontFamily: 'Marianne-Regular',
    fontWeight: 400,
    opacity: 1,
  },
  multiSelect: {
    width: '100%',
  },
  filledInput: {
    fontSize: 16,
    fontWeight: 400,
    minWidth: 170,
    '& > :first-of-type': {
      textOverflow: 'ellipsis',
    },
    '& > :last-child': {
      position: 'absolute',
      right: 7,
    },
  },
  searchField: {
    padding: '10px 16px 0px 16px !important',
  },
}));
