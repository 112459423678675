import useWindowSize from 'utils/hooks/useWindowSize';
import { MediaDimensions } from 'style/media';

interface DeviceTypes {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
}

export const calculateDeviceType = (width: number): DeviceTypes => {
  const isTablet = width <= MediaDimensions.Tablet && width > MediaDimensions.Mobile;
  const isMobile = width <= MediaDimensions.Mobile;
  const isDesktop = width > MediaDimensions.Tablet;

  return { isDesktop, isMobile, isTablet };
};

const useDevice = (): DeviceTypes => {
  const { width } = useWindowSize();
  return calculateDeviceType(width);
};

export default useDevice;
