import { ID } from '@datorama/akita';
import { DocumentDto } from 'api/dto/documents/DocumentDto';

export interface IssueDocument {
  id: ID;
  issueId: ID;
  tmpFile?: File;
  dto?: DocumentDto;
}

export const createIssueDocument = (
  issueId: ID,
  dto: DocumentDto,
  tmpFile?: File
): IssueDocument => ({
  id: dto.id,
  issueId,
  tmpFile,
  dto,
});
