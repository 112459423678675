import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { dashboardExecutionsService } from '../dashboard-executions.service';
import SnackError from 'utils/models/snackError';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { dashboardExecutionsQuery } from '../dashboard-executions.query';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { createExecutionQuery } from 'store/operations/createExecution';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { filtersQuery } from 'store/common/filters/filters.query';

export const useFetchExecutionPatrickWaiting = (
  currentTab: MetawasteTab,
  page?: number,
  limit?: number
) => {
  const { enqueueSnackbar } = useSnackbar();

  const page$ = useRef(new Subject<number>()).current;
  const currentTab$ = useRef(new Subject<MetawasteTab>()).current;

  const [executions, setExecutions] = useState<ExecutionDto[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const _queryParams = combineLatest([
      currentTab$,
      page$,
      createExecutionQuery.needToReloadExecutions$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitIds$,
      filtersQuery.currentFilters$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([currentTab, page, _, activeOrgId, activeOrgUnitIds, currentFilters]) => {
        dashboardExecutionsService
          .fetchExecutionPatrickExecutions(
            currentTab,
            page,
            activeOrgId,
            activeOrgUnitIds,
            currentFilters,
            limit
          )
          .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
      });
    const _total = dashboardExecutionsQuery.total$.subscribe(setTotal);
    const _executions = dashboardExecutionsQuery.executions$.subscribe(setExecutions);
    return () => {
      _queryParams.unsubscribe();
      _total.unsubscribe();
      _executions.unsubscribe();
    };
  }, []);

  useEffect(() => page$.next(page), [page]);
  useEffect(() => currentTab$.next(currentTab), [currentTab]);

  return { executions, total };
};
