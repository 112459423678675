import i18n from 'utils/data/i18n.utils';
import { t } from 'i18next';

export enum ServiceKindTypeEnum {
  OTHER = 'OTHER',
  PICKUP = 'PICKUP',
  PROCESSING = 'PROCESSING',
  RENTING = 'RENTING',
  VALORISATION = 'VALORISATION',
  CLEANING = 'CLEANING',
  DOCUMENT = 'DOCUMENT',
  PACKAGE = 'PACKAGE',
  POSE_WITHDRAWAL = 'POSE_WITHDRAWAL',
}

export namespace ServiceKindType {
  export const selectItems = () => [
    { label: t('global.serviceKindType.other'), value: ServiceKindTypeEnum.OTHER },
    { label: t('global.serviceKindType.pickup'), value: ServiceKindTypeEnum.PICKUP },
    { label: t('global.serviceKindType.processing'), value: ServiceKindTypeEnum.PROCESSING },
    { label: t('global.serviceKindType.renting'), value: ServiceKindTypeEnum.RENTING },
    {
      label: t('global.serviceKindType.valorisation'),
      value: ServiceKindTypeEnum.VALORISATION,
    },
    {
      label: t('global.serviceKindType.cleaning'),
      value: ServiceKindTypeEnum.CLEANING,
    },
    {
      label: t('global.serviceKindType.document'),
      value: ServiceKindTypeEnum.DOCUMENT,
    },
    { label: t('global.serviceKindType.package'), value: ServiceKindTypeEnum.PACKAGE },
  ];
}

export enum UnitOfMeasure {
  TON = 'TON',
  UNIT = 'UNIT',
  DAY = 'DAY',
  MONTH = 'MONTH',
  KM = 'KM',
}

export function getUnitOfMeasureLabel(unit: UnitOfMeasure): string {
  switch (unit) {
    case UnitOfMeasure.TON:
      return i18n.t('fe.global.unitOfMeasure.ton');
    case UnitOfMeasure.UNIT:
      return i18n.t('fe.global.unitOfMeasure.unit');
    case UnitOfMeasure.DAY:
      return i18n.t('fe.global.unitOfMeasure.day');
    case UnitOfMeasure.MONTH:
      return i18n.t('fe.global.unitOfMeasure.month');
    case UnitOfMeasure.KM:
      return i18n.t('fe.global.unitOfMeasure.km');
    default:
      return '';
  }
}
