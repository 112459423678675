import { OptionsObject, VariantType } from 'notistack';

class SnackError extends Error {
  constructor(key: string, variant: VariantType, errorCode?: number, ...params: any[]) {
    super(...params);

    this.name = 'SnackError';

    this.text = key;
    this.options = { variant };
    this.errorCode = errorCode;
  }

  readonly text: string;
  readonly options: OptionsObject;
  readonly errorCode?: number;
}

export const handleBasicSnackError = (errorKey = 'errors.serverError'): SnackError => {
  return new SnackError(errorKey, 'error');
};

export default SnackError;
