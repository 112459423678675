import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Invoice } from './invoice.model';

const initialState: InvoiceState = {
  invoices: [],
  search: '',
  total: 0,
};

export interface InvoiceState extends EntityState<Invoice> {
  invoices: Invoice[];
  search: string;
  total: number;
}

@StoreConfig({ name: 'invoice', resettable: true })
export class InvoiceStore extends EntityStore<InvoiceState> {
  constructor() {
    super(initialState);
  }
}

export const invoiceStore = new InvoiceStore();
