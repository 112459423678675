import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Bookmarks } from './bookmarkModel';

export interface BookmarksState extends EntityState<Bookmarks> {
  bookmarks: Bookmarks[];
  ui: {
    isEditModalOpen: boolean;
  };
}

const initialState: BookmarksState = {
  bookmarks: [],
  ui: {
    isEditModalOpen: false,
  },
};

@StoreConfig({
  name: 'bookmark',
})
export class BookmarkStore extends EntityStore<BookmarksState> {
  constructor() {
    super(initialState);
  }

  setBookmarks = (value: Bookmarks[]) => {
    this.update((state) => ({
      ...state,
      bookmarks: value,
    }));
  };

  isEditModalOpen = (isEditModalOpen: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isEditModalOpen,
      },
    }));
  };
}

export const bookmarkStore = new BookmarkStore();
