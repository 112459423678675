import { QueryEntity } from '@datorama/akita';
import { ActivitiesState, activitiesStore, ActivitiesStore } from './activities.store';

export class ActivitiesQuery extends QueryEntity<ActivitiesState> {
  constructor(protected store: ActivitiesStore) {
    super(store);
  }

  executionActivities$ = this.selectAll();
}

export const activitiesQuery = new ActivitiesQuery(activitiesStore);
