import { QueryEntity } from '@datorama/akita';
import { BookmarkStore, BookmarksState, bookmarkStore } from './bookmark.store';

export class BookmarkQuery extends QueryEntity<BookmarksState> {
  constructor(protected store: BookmarkStore) {
    super(store);
  }
  bookmarks$ = this.select((state) => state.bookmarks);
  isEditModalOpen$ = this.select((state) => state.ui.isEditModalOpen);
}

export const bookmarkQuery = new BookmarkQuery(bookmarkStore);
