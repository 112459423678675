import { combineDateAndHours } from 'utils/data/dayjs.utils';
import { ExecutionDetailsUi } from 'store/operations/createExecution';

export interface ExecutionUpdateTimeDto {
  plannedFrom?: string | null;
  plannedTo?: string | null;
}

export function formatToExecutionUpdateTimeDto(
  execution: ExecutionDetailsUi
): ExecutionUpdateTimeDto {
  const { selectedDate, plannedFrom, plannedTo, asap } = execution;
  return {
    plannedFrom:
      selectedDate && plannedFrom && !asap ? combineDateAndHours(selectedDate, plannedFrom) : null,
    plannedTo:
      selectedDate && plannedTo && !asap ? combineDateAndHours(selectedDate, plannedTo) : null,
  };
}
