import React, { FC } from 'react';
import BottomModal from 'components/Modal/BottomModal';
import { ReactComponent as DownloadIcon } from 'assets/icons/rework/downloadinline.svg';
import { ReactComponent as IconDeleteBin } from 'assets/icons/rework/archive_line.svg';
import { useCreateMobileStyles } from 'components/Modal/BottomModal/CreateMobile.style';
import { Box } from '@mui/material';
import ListItems from 'components/ListItems/ListItems';

interface ActionsInvoiceScreenProps {
  handleClickCloseModal?: () => void;
  state?: boolean;
}

const ActionsInvoiceScreen: FC<ActionsInvoiceScreenProps> = (props) => {
  const { handleClickCloseModal, state } = props;
  const classes = useCreateMobileStyles();

  return (
    <BottomModal handleClickCloseModal={handleClickCloseModal} title="Add new">
      <Box sx={{ width: '100%' }}>
        <ListItems
          data={[
            { title: 'Télécharger', icon: <DownloadIcon className={classes.iconContainer} /> },
            { title: 'Document', icon: <IconDeleteBin className={classes.iconContainer} /> },
          ]}
        />
      </Box>
    </BottomModal>
  );
};

export default ActionsInvoiceScreen;
