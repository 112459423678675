import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { SnackbarModel } from '../snackbar.model';
import { snackbarService } from '../snackbar.service';
import { snackbarQuery } from '../snackbar.query';
import { ID } from '@datorama/akita';

let displayed: ID[] = [];

export const useSnackBarNotifier = () => {
  const [notifications, setNotifications] = useState<SnackbarModel[]>([]);
  // const notifications = useSelector(store => store.app.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: ID) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    const _messages = snackbarQuery.messages$.subscribe(setNotifications);
    return () => {
      _messages.unsubscribe();
    };
  }, []);

  useEffect(() => {
    notifications.forEach(({ id, message, options = {} }) => {
      // if (dismissed) {
      //   // dismiss snackbar using notistack
      //   closeSnackbar(key);
      //   return;
      // }

      // do nothing if snackbar is already displayed
      if (displayed.includes(id)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key: id,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          snackbarService.removeMessage(myKey);
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(id);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);
};
