import React, { FC } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import style from './CommentRow.style';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CommentRowProps extends WithStyles<typeof style> {
  name?: string;
  comment?: string;
  date?: string;
  dateTime?: string;
  onLeft?: boolean;
}

const CommentRow: FC<CommentRowProps> = (props) => {
  const { classes, name, dateTime, comment, date, onLeft } = props;
  const todoVar = '';
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={onLeft ? 'flex-start' : 'flex-end'}>
      <Grid item xs={12}>
        <Typography color="text.primary" className={classes.name} align={onLeft ? 'left' : 'right'}>
          {name} {onLeft && todoVar}
        </Typography>
      </Grid>
      <Grid item justifyContent="end">
        <Grid container alignItems="center">
          {!onLeft && (
            <Grid item style={{ marginRight: 15, marginBottom: 16 }} alignItems="center">
              <Typography>
                <span className={classes.date}>
                  {t('fe.executionModal.executionCommentForm.commentRowDate', {
                    dateCreation: date,
                    dateTime: dateTime,
                  })}
                </span>
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Box
              sx={{ backgroundColor: !onLeft ? 'text.primary' : 'white' }}
              className={classes.commentContainer}
            >
              <Typography
                color={!onLeft ? 'white' : 'text.primary'}
                className={classes.commentMessage}
              >
                {comment}
              </Typography>
            </Box>
          </Grid>
          {onLeft && (
            <Grid item style={{ marginLeft: 15, marginBottom: 16 }} alignItems="center">
              <Typography>
                <span className={classes.date}>
                  {t('fe.executionModal.executionCommentForm.commentRowDate', {
                    dateCreation: date,
                    dateTime: dateTime,
                  })}
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(CommentRow);
