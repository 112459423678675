import { activitiesStore, ActivitiesStore } from './activities.store';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { metawasteApi } from 'api/Axios';
import { ActivityDto, ActivityExecutionDto } from 'api/dto/activities/ActivityDto';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import { resolve } from 'utils/data/promise-utils';
import { ID } from '@datorama/akita';

export class ActivitiesService {
  constructor(private store: ActivitiesStore) {}

  async fetchExecutionActivities(tabType: MetawasteTab, orgId: ID, orgUnitIds?: ID[]) {
    this.store.set([]);
    this.store.setLoading(true);

    let request: Promise<ActivityDto<ActivityExecutionDto>[]> | undefined;
    switch (tabType) {
      case MetawasteTab.LAST_CREATED:
        request = metawasteApi.getActivitiesExecution(
          ExecutionStatusEnum.CREATED,
          5,
          orgId,
          orgUnitIds
        );
        break;
      case MetawasteTab.LAST_ACCEPTED:
        request = metawasteApi.getActivitiesExecution(
          ExecutionStatusEnum.PLANNED,
          5,
          orgId,
          orgUnitIds
        );
        break;
      case MetawasteTab.LAST_COMPLETED:
        request = metawasteApi.getActivitiesExecution(
          ExecutionStatusEnum.EXECUTED,
          5,
          orgId,
          orgUnitIds
        );
        break;
      default:
        request = undefined;
        break;
    }

    if (!request) {
      return;
    }

    const [activities, e] = await resolve(request);

    this.store.setLoading(false);
    this.store.set(activities || []);
  }
}

export const activitiesService = new ActivitiesService(activitiesStore);
