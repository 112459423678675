import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      width: '100%',
      textAlign: 'center',
      height: '50px',
    },
    typographyValue: {
      fontWeight: 700 + '!important',
      margin: '15px 0 !important',
    },
    value: {
      display: 'flex',
      height: '100%',
    },
    description: {
      display: 'flex',
      marginTop: '5px !important',
    },
    police: {
      fontSize: '12px !important',
    },
  });

export default classes;
