import { EventCalendar } from 'components/FullCalendar/TestData';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import { Colors } from 'style/materialStylings';

interface StyleEvent {
  color: string;
  background: string;
}

const backgroundStripe = (primaryColor: string, secondaryColor: string) =>
  `repeating-linear-gradient(-55deg,${primaryColor},${primaryColor} 5px,${secondaryColor} 5px,${secondaryColor} 15px)`;

const getStyleFromEvent = (eventInfo: EventCalendar): StyleEvent => {
  const styleEvent: StyleEvent = {
    color: '3px solid ',
    background: '1C',
  };
  switch (eventInfo.extendedProps?.status) {
    case ExecutionStatusEnum.REJECTED:
      styleEvent.color += Colors.redStatus;
      styleEvent.background = Colors.redStatus + styleEvent.background;
      break;
    case ExecutionStatusEnum.CREATED:
      styleEvent.color += Colors.blueStatus;
      styleEvent.background = backgroundStripe(Colors.blueStatus + '4C', Colors.blueStatus + '1C');
      break;
    case ExecutionStatusEnum.EXECUTED:
      styleEvent.color += Colors.greyStatus;
      styleEvent.background = Colors.greyStatus + styleEvent.background;
      break;
    case ExecutionStatusEnum.PLANNED:
      styleEvent.color += Colors.greenStatus;
      styleEvent.background = Colors.greenStatus + styleEvent.background;
      break;
    case ExecutionStatusEnum.ACTIVE:
      styleEvent.color += Colors.greenStatus;
      styleEvent.background = styleEvent.color + styleEvent.background;
      break;
  }
  return styleEvent;
};

export default getStyleFromEvent;
