import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { usePageStyles } from './Page.style';

interface PageAppBarProps {
  // eslint-disable-next-line no-undef
  children?: JSX.Element[] | JSX.Element;
  drawerOpen: boolean;
  hideMenus: boolean;
}

const Page = (props: PageAppBarProps) => {
  const { children, drawerOpen, hideMenus } = props;
  const classes = usePageStyles();

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  let className = classes.content;

  if (hideMenus) {
    className = classes.contentHideMenus;
  } else if (drawerOpen && !isMobile) {
    className = classes.contentDrawerOpen;
  } else if (isMobile) {
    className = classes.contentBottomBar;
  }

  return (
    <main className={className} style={{ height }}>
      {children}
    </main>
  );
};

export default Page;
