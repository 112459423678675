import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useBottomModalStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'flex-start !important',
    width: '100% !important',
    paddingBottom: '15px !important',
  },

  iconPosition: {
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    width: '100% !important',
    paddingTop: '15px !important',
  },

  boxSize: {
    padding: '20px !important',
  },
}));
