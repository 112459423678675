import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    inputLabel: {
      color: `#474747 !important`,
      fontSize: '12px !important',
      paddingBottom: '4px !important',
    },
  });

export default classes;
