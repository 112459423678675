import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    textfield: {
      width: '60% !important',
      [theme.breakpoints.only('xs')]: {
        marginTop: '8px !important',
        width: '100% !important',
      },
    },
    placeholder: {
      color: theme.palette.text.primary,
      fontFamily: 'Marianne-Regular',
      fontWeight: 400,
      opacity: 1,
    },
    multiSelect: {
      width: '100%',
    },
    filledInput: {
      fontSize: 16,
      fontWeight: 400,
      minWidth: 170,
      '& > :first-of-type': {
        textOverflow: 'ellipsis',
      },
      '& > :last-child': {
        position: 'absolute',
        right: 7,
      },
    },
  });

export default classes;
