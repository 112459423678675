import {
  formatSupplierOrgUnitDtoToOrganisationUnit,
  SupplierOrganisationUnitDto,
} from './SupplierOrganisationUnitDto';
import { AddressDto } from '../client/AddressDto';
import { Organisation } from 'store/organisation/organisations';

export interface SupplierDto {
  id: number;
  name: string;
  supplierOrganisationUnits: SupplierOrganisationUnitDto[];
  address: AddressDto;
  invoicingSource: string;
}

export function formatSupplierDtoToOrganisation({
  id,
  name,
  supplierOrganisationUnits,
  address,
  invoicingSource,
}: SupplierDto): Organisation {
  return {
    id,
    name,
    organisationUnits: supplierOrganisationUnits.map(formatSupplierOrgUnitDtoToOrganisationUnit),
    address,
    invoicingSource,
  };
}
