import React, { FC, useEffect, useState } from 'react';
import AppBarDropdown from 'components/AppBar/AppBarDropdown/AppBarDropdown';
import { ReactComponent as IconLocalisation } from 'assets/icons/rework/icon_localisation.svg';
import { useSnackbar } from 'notistack';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { ID } from '@datorama/akita';
import {
  organisationUnitsQuery,
  organisationUnitsService,
} from 'store/organisation/organisationUnits';
import { useTranslation } from 'react-i18next';

const selectItems = [
  { label: 'Value 1', value: '1' },
  { label: 'Value 2', value: '2' },
  { label: 'Value 3', value: '3' },
];

const AppBarDropdownOrgUnit: FC = () => {
  const [searchCurrentValue, setSearchCurrentValue] = useState('');
  const [orgUnits, setOrgUnits] = useState<AutocompleteData[]>([]);
  const [orgUnitActiveIds, setOrgUnitActiveIds] = useState<ID[]>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<ID[]>();

  useEffect(() => {
    const _orgUnitsAsAutocomplete = organisationUnitsQuery.orgUnitsAsAutocomplete$.subscribe(
      setOrgUnits
    );
    const _orgUnitActiveIds = organisationUnitsQuery.activeOrgUnitIds$.subscribe(
      setOrgUnitActiveIds
    );
    const _orgUnitIds = organisationUnitsQuery.activeOrgUnitIds$.subscribe(setSelectedOrgUnits);

    return () => {
      _orgUnitsAsAutocomplete.unsubscribe();
      _orgUnitActiveIds.unsubscribe();
      _orgUnitIds.unsubscribe();
    };
  }, [enqueueSnackbar]);

  return (
    <AppBarDropdown
      icon={<IconLocalisation />}
      label="Type de dechet 2"
      handleChange={(value) => {
        if (value && selectedOrgUnits) {
          if (selectedOrgUnits?.includes(value)) {
            // setSelectedOrgUnits(selectedOrgUnits.filter((it) => it !== value));
            organisationUnitsService.setOrgUnitsAsActive(
              selectedOrgUnits.filter((it) => it !== value)
            );
          } else {
            // setSelectedOrgUnits([...selectedOrgUnits, value]);
            organisationUnitsService.setOrgUnitsAsActive([...selectedOrgUnits, value]);
          }
        } else {
          organisationUnitsService.setOrgUnitsAsActive([]);
        }
      }}
      // error={errors.currentEmail}
      value={orgUnits.find((it) => orgUnitActiveIds?.includes(it.id))}
      values={orgUnits.filter((it) => selectedOrgUnits?.includes(it.id))}
      data={organisationUnitsQuery.filterOrgUnits(orgUnits, searchCurrentValue)}
      placeholder={t('fe.appBar.orgUnitPlaceholder')}
      handleSearchChange={setSearchCurrentValue}
    />
  );
};

export default AppBarDropdownOrgUnit;
