import { SelectItem } from 'components/Select/SelectItem.interface';
import { useEffect, useState } from 'react';
import { ManageIssue } from '../issues.model';
import { issueService } from '../issues.service';

interface useGetIssuesByIdProps {
  issueId: number;
}

export const useGetIssues = (props: useGetIssuesByIdProps) => {
  const { issueId } = props;

  const [issues, setIssues] = useState<ManageIssue>();
  const [client, setClient] = useState<SelectItem>();
  const [clientUnits, setClientUnits] = useState<SelectItem>();
  const [supplier, setSupplier] = useState<SelectItem>();
  const [supplierUnits, setSupplierUnits] = useState<SelectItem>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getIssuesById = async () => {
      const issues = await issueService.getIssueById(issueId);
      setIssues(issues);
      if (issues.clientOrganisationUnit) {
        setClientUnits({
          label: issues.clientOrganisationUnit?.name ?? '',
          value: issues.clientOrganisationUnit?.id,
        });
        setClient({
          label: issues.clientOrganisationUnit?.clientOrganisation?.name ?? '',
          value: issues.clientOrganisationUnit?.clientOrganisation?.id,
        });
      }
      if (issues.supplierOrganisationUnit) {
        setSupplierUnits({
          label: issues.supplierOrganisationUnit?.name ?? '',
          value: issues.supplierOrganisationUnit?.id,
        });
        setSupplier({
          label: issues.supplierOrganisationUnit?.supplierOrganisation?.name ?? '',
          value: issues.supplierOrganisationUnit?.supplierOrganisation?.id,
        });
      }
      setLoading(false);
    };
    setLoading(true);
    getIssuesById().catch((err) => console.log(err));
  }, [issueId]);

  const updateIssue = (key: string) => (value: any) => setIssues({ ...issues, [key]: value });

  return {
    issues,
    loading,
    client,
    clientUnits,
    supplier,
    supplierUnits,
    updateIssue,
  };
};
