import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import MonthCalendar from 'components/MonthCalendar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActivitiesPanelStyles } from 'screens/auth/dashboard/common/activitiesPanel/Activities.panel.style';
import { useFetchPlanningMonth } from 'store/dashboard/executionMonthPlanning/hooks/useFetchPlanningMonth';

interface ActivitiesPanelProps {
  buttonShow?: boolean;
  setDate: (newDate: Date) => void;
  dateCalendarSelected: Date;
}

const ObActivitesPanel = (props: ActivitiesPanelProps) => {
  const { buttonShow, setDate, dateCalendarSelected } = props;
  const [monthSelected, setMonthSelected] = useState<Date>(new Date());
  const classes = useActivitiesPanelStyles();
  const { planning } = useFetchPlanningMonth(monthSelected);
  const { t } = useTranslation();

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid className="step8" item xs={12} container justifyContent="center" style={{ flex: 1 }}>
          <MonthCalendar
            month={monthSelected}
            setMonth={setMonthSelected}
            selectedDays={dateCalendarSelected}
            handleChange={setDate}
            activities={planning}
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>

        <Grid item style={{ flex: 5, marginTop: '10px' }}>
          <Grid container justifyContent="center" style={{ paddingTop: '20px' }}>
            <Typography>{t('fe.activitiesPanel.nothing')}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObActivitesPanel;
