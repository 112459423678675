import React, { FC } from 'react';
import { ExecutionGroupDto } from 'api/dto/execution/ExecutionGroupDto';
import { Chip, Divider, Popover, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../container';

interface GroupProps {
  executionGroup: ExecutionGroupDto;
}

export const Group: FC<GroupProps> = (props) => {
  const { executionGroup } = props;
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      direction="column"
      spacing={1}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography variant="h5">
        {executionGroup.executionsRecap.length} {t('fe.operations.group.tickets')}
      </Typography>
      <Chip variant="outlined" label={t('fe.dashboard.patrick.table.groupPickup')} />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack
          direction="column"
          p={1}
          divider={<Divider />}
          spacing={1}
          sx={{ minWidth: '300px' }}
        >
          {executionGroup.executionsRecap.map((e) => (
            <Container
              key={(e.container?.name || '') + (e.waste?.name || '') + e.quantity}
              container={t('fe.dashboard.patrick.table.quantity', {
                quantity: e.quantity,
              })}
              containerName={t(e.container?.nameTranslateKey || e.container?.name || '')}
              wasteName={t(e.waste?.nameTranslateKey || e.waste?.name || '')}
            />
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};
