import { Grid, Stack, Typography } from '@mui/material';
import { ContractDto } from 'store/contracts/contracts.model';
import { Colors } from 'style/materialStylings';
import ContractServiceCard from './card/Contract.service.card';
import { t } from 'i18next';

interface ContractDetailSummaryProps {
  contract: ContractDto;
}

function isDateExpired(date: Date) {
  const today = new Date();
  return date < today;
}

function transformDate(date: Date) {
  const dateArray = date.toString().split('-');
  return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
}

function ContractDetailSummary(props: ContractDetailSummaryProps) {
  const { contract } = props;

  return (
    <div>
      <Stack spacing={2} direction="column">
        <Stack spacing={2} direction="row">
          <Stack
            spacing={1}
            direction="column"
            sx={{ width: '535px', height: '268px' }}
            bgcolor={Colors.white}
            pl={2}
            pr={8}
          >
            <Stack spacing={1} direction="row" justifyContent="space-between" my={2}>
              <Typography fontSize="16px" fontWeight="700">
                {t('fe.contracts.details.information')}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.reference')}
              </Typography>
              <Typography variant="body2">{contract.contractReference}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.startDate')}
              </Typography>
              {contract.startDate && (
                <Typography
                  variant="body2"
                  color={isDateExpired(contract?.startDate) ? 'error' : 'initial'}
                >
                  {transformDate(contract.startDate)}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.endDate')}
              </Typography>
              {contract.endDate && (
                <Typography
                  variant="body2"
                  color={isDateExpired(contract.endDate) ? 'error' : 'initial'}
                >
                  {transformDate(contract.endDate)}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.waste')}
              </Typography>
              <Typography variant="body2">{contract.waste}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.container')}
              </Typography>
              <Typography variant="body2">{contract.container}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" color={Colors.gray}>
                {t('fe.contracts.details.clientUnit')}
              </Typography>
              <Typography variant="body2">{contract.clientOrganisationUnitName}</Typography>
            </Stack>
          </Stack>
          {contract.invoiceAddress && (
            <Stack
              spacing={1}
              direction="column"
              sx={{ width: '785px', height: '240px' }}
              bgcolor={Colors.white}
              pl={2}
              pr={8}
            >
              <Stack spacing={1} direction="row" justifyContent="space-between" my={2}>
                <Typography fontSize="16px" fontWeight="700">
                  {t('fe.contracts.details.invoicingInformation')}
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography variant="body2" color={Colors.gray}>
                  {t('fe.contracts.details.invoicingAddress')}
                </Typography>
                <Typography variant="body2">{contract.invoiceAddress?.addressCode}</Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography variant="body2" color={Colors.gray}>
                  {t('fe.contracts.details.invoicingAddressLine1')}
                </Typography>
                <Typography variant="body2">{contract.invoiceAddress?.street}</Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography variant="body2" color={Colors.gray}>
                  {t('fe.contracts.details.zipCode')}
                </Typography>
                <Typography variant="body2">{contract.invoiceAddress?.zip}</Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography variant="body2" color={Colors.gray}>
                  {t('fe.contracts.details.city')}
                </Typography>
                <Typography variant="body2">{contract.invoiceAddress?.city}</Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography variant="body2" color={Colors.gray}>
                  {t('fe.contracts.details.country')}
                </Typography>
                <Typography variant="body2">{contract.invoiceAddress?.country}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack
          spacing={2}
          direction="column"
          width="auto"
          height="auto"
          bgcolor={Colors.white}
          px={2}
          py={2}
          justifyContent="center"
        >
          <Typography fontSize="16px" fontWeight="700" px={1}>
            {t('fe.contracts.details.services')}
          </Typography>
          <Stack px={1}>
            <Grid container spacing={2}>
              {contract.services.map((service, index) => (
                <Grid item xs={6} key={index}>
                  <ContractServiceCard service={service} contract={contract} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default ContractDetailSummary;
