import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createExecutionQuery } from 'store/operations/createExecution';
import { sessionQuery } from 'store/session';

export interface CreateExecutionButtonComponentProps {
  onClick: () => void;
  styleButton?: string;
  styleText?: string;
  canOpen?: boolean;
}

const CreateExecutionButtonComponent = (props: CreateExecutionButtonComponentProps) => {
  const { onClick, styleButton, styleText, canOpen } = props;
  const { t } = useTranslation();
  const [canOpenModalForCreation, setCanOpenModalForCreation] = useState(canOpen ?? false);

  useEffect(() => {
    if (!canOpen) {
      const _canOpenModalForCreation = createExecutionQuery.canOpenModalForCreation$.subscribe(
        setCanOpenModalForCreation
      );
      return () => {
        _canOpenModalForCreation.unsubscribe();
      };
    }
  }, []);

  return (
    <Button
      variant="contained"
      size="large"
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={!canOpenModalForCreation}
      className={styleButton}
    >
      <span className={styleText}>
        {t(
          sessionQuery.isPatrick
            ? 'fe.operations.createExecutionButtonSupplier'
            : 'fe.operations.createExecutionButton'
        )}
      </span>
    </Button>
  );
};

export default CreateExecutionButtonComponent;
