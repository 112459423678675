import { metawasteApi } from 'api/Axios';
import { resolve } from 'utils/data/promise-utils';
import { guid, ID } from '@datorama/akita';
import { handleBasicSnackError } from 'utils/models/snackError';
import { CommentExecutionStore, executionStore } from './execution-comment.store';
import { CommentExecutionDto } from 'api/dto/execution/CommentExecutionDto';
import i18n from 'utils/data/i18n.utils';

export class CommentExecutionService {
  constructor(protected store: CommentExecutionStore) {}

  fetchComment = async (executionId: ID) => {
    const [comments, error] = await resolve(metawasteApi.getComment(executionId));
    if (comments) {
      this.store.upsertMany(comments);
    }
  };

  pushComment = (commentBody: string, executionId: ID, userId?: ID) => {
    const newComment: CommentExecutionDto = {
      executionId: executionId,
      content: commentBody,
      id: guid(),
      createdAt: new Date(),
      author: {
        id: userId ?? 0,
      },
    };
    this.store.add(newComment);
  };

  createComment = async (executionId: ID, commentBody: string) => {
    const [comExecution, e] = await resolve(metawasteApi.createComment(executionId, commentBody));
    if (comExecution) {
      this.store.add(comExecution);
      return;
    }
    throw handleBasicSnackError(i18n.t('fe.errors.create.comment'));
  };

  resetStore = () => {
    this.store.reset();
  };
}

export const commentExecutionServices = new CommentExecutionService(executionStore);
