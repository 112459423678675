import React, { useEffect, useState } from 'react';
import { ID } from '@datorama/akita';
import { Card, CardContent, Grid } from '@mui/material';
import { useDashboardPatrickStyles } from '../DashboardPatrick.style';
import { useFetchExecutionPatrickWaiting } from 'store/dashboard/dashboardExecutions/hook/useFetchExecutionPatrickExecutions';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import MaterialDatagrid from 'components/MaterialDatagrid';
import { isMobile } from 'utils/isMobile';
import {
  dashboardExecutionsQuery,
  dashboardExecutionsService,
} from 'store/dashboard/dashboardExecutions';
import { createExecutionService } from 'store/operations/createExecution';
import env from 'utils/env';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { useObservable } from '@ngneat/react-rxjs';
import { executionColumns } from 'screens/auth/operations/list/table.utils';
import { patrickPickupColumns } from './table.utils';
import { sessionQuery } from 'store/session';

interface Props {
  currentTab: { tabType: MetawasteTab; label: string };
  limit?: number;
}

const ExecutionsWaitingComponents = (props: Props) => {
  const [isMorgan] = useObservable(sessionQuery.isMorgan$);
  const { currentTab, limit } = props;
  const classes = useDashboardPatrickStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = isMobile();

  const [page, setPage] = useState<number>(0);
  const { executions, total } = useFetchExecutionPatrickWaiting(currentTab.tabType, page, limit);
  const [isLoading] = useObservable(dashboardExecutionsQuery.selectLoading());

  useEffect(() => {
    setPage(0);
  }, [currentTab]);

  const handleOpenModal = (ids: ID[], startRefuse?: boolean) => {
    const execution = executions.find((execution) => ids.includes(execution.id));
    if (execution) {
      createExecutionService.openModal(execution.id, { startRefuse });
    }
  };

  const handleAcceptOrReject = (executionId: ID, isAccepted: boolean) => {
    if (isAccepted) {
      dashboardExecutionsService
        .acceptOrReject(executionId, isAccepted, undefined)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    } else {
      handleOpenModal([executionId], true);
    }
  };

  return (
    <Grid item xs={12} className={classes.GridCardContainer}>
      <Card className={classes.cardContainer}>
        <CardContent>
          <MaterialDatagrid
            autoHeight
            loading={isLoading}
            onRowSelected={handleOpenModal}
            hideHeader
            rowHeight={isMobiles ? 170 : 78}
            dataByPage={env.PAGINATION}
            totalDataPage={total}
            onPageChange={setPage}
            hideFooter={limit != null}
            hideFooterPagination={limit != null}
            page={page}
            data={isLoading ? [] : executions}
            columns={
              currentTab.tabType === MetawasteTab.TO_CONFIRM
                ? patrickPickupColumns({
                    isDesktop: !isMobiles,
                    handleClick: !isLoading ? handleAcceptOrReject : undefined,
                  })
                : executionColumns({
                    isDesktop: !isMobiles,
                    isMorgan,
                    isCompleted: currentTab.tabType === MetawasteTab.COMPLETED,
                  })
            }
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ExecutionsWaitingComponents;
