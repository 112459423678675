import { Step } from 'react-joyride';
import { Colors } from 'style/materialStylings';
import i18n from 'utils/data/i18n.utils';

export const onbordingStyle = {
  options: {
    zIndex: 10000,
    primaryColor: Colors.primary,
  },
  overlay: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

export const onbordingSteps = (): Step[] => [
  {
    target: 'body',
    placement: 'center',
    title: i18n.t('fe.joyride.welcome.title'),
    disableBeacon: true,
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.welcome.content'),
  },
  {
    target: '.step1',
    disableOverlayClose: true,
    title: i18n.t('fe.joyride.step1.title'),
    placement: 'bottom',
    spotlightPadding: 20,
    content: i18n.t('fe.joyride.step1.content'),
  },
  {
    target: '.step2',
    title: i18n.t('fe.joyride.step2.title'),
    placement: 'bottom',
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step2.content'),
  },
  {
    title: i18n.t('fe.joyride.step3.title'),
    target: '.step3',
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step3.content'),
  },
  {
    target: '.step4',
    title: i18n.t('fe.joyride.step4.title'),
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step4.content'),
  },
  {
    target: '.step5',
    title: i18n.t('fe.joyride.step5.title'),
    placement: 'bottom',
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step5.content'),
  },
  {
    target: '.step6',
    title: i18n.t('fe.joyride.step6.title'),
    placement: 'bottom',
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step6.content'),
  },
  {
    target: '.step7',
    title: i18n.t('fe.joyride.step7.title'),
    placement: 'bottom',
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step7.content'),
  },
  {
    target: '.step8',
    title: i18n.t('fe.joyride.step8.title'),
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step8.content'),
  },
  {
    target: '.step9',
    title: i18n.t('fe.joyride.step9.title'),
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step9.content'),
  },
  {
    target: '.step10',
    title: i18n.t('fe.joyride.step10.title'),
    disableOverlayClose: true,
    content: i18n.t('fe.joyride.step10.content'),
  },
  {
    target: '.step11',
    title: i18n.t('fe.joyride.step11.title'),
    content: i18n.t('fe.joyride.step11.content'),
  },
  {
    target: '.step12',
    title: i18n.t('fe.joyride.step12.title'),
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    spotlightClicks: true,
    content: i18n.t('fe.joyride.step12.content'),
  },
  {
    target: '.step13',
    title: i18n.t('fe.joyride.step13.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step13.title'),
  },
  {
    target: '.step14',
    title: i18n.t('fe.joyride.step14.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step14.content'),
  },
  {
    target: '.step15',
    title: i18n.t('fe.joyride.step15.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step15.content'),
  },
  {
    target: '.step16',
    title: i18n.t('fe.joyride.step16.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step16.content'),
  },
  {
    target: '.step17',
    title: i18n.t('fe.joyride.step17.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step17.content'),
  },
  {
    target: '.step18',
    title: i18n.t('fe.joyride.step18.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step18.content'),
  },
  {
    target: '.step19',
    title: i18n.t('fe.joyride.step19.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step19.content'),
  },
  {
    target: '.step20',
    title: i18n.t('fe.joyride.step20.title'),
    disableOverlayClose: true,
    placement: 'left',
    content: i18n.t('fe.joyride.step20.content'),
  },
];
