import { DashboardExecutionsStore, dashboardExecutionsStore } from './dashboard-executions.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import env from 'utils/env';
import i18n from 'utils/data/i18n.utils';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { PaginationResponseDto } from 'api/dto/common/PaginationResponseDto';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';

export class DashboardExecutionsService {
  constructor(private store: DashboardExecutionsStore) {}

  fetchExecutionPatrickExecutions = async (
    currentTab: MetawasteTab,
    page: number,
    orgId?: ID,
    orgUnitIds?: ID[],
    currentFilters?: TableFilter[],
    limit?: number
  ) => {
    if (!orgId) {
      this.store.setLoading(false);
      return;
    }
    this.store.setLoading(true);
    let request: Promise<PaginationResponseDto<ExecutionDto>> = Promise.resolve({
      data: [],
      total: 0,
      count: 0,
      page: 0,
      pageCount: 0,
    });
    switch (currentTab) {
      case MetawasteTab.TO_CONFIRM:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'ASC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.CREATED },
          undefined
        );
        break;
      case MetawasteTab.TO_TREAT:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'ASC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.PLANNED, from: new Date(), toPlan: false },
          undefined
        );
        break;
      case MetawasteTab.TO_COMPLETE:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'DESC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.PLANNED, to: new Date(), toPlan: false },
          undefined
        );
        break;
      case MetawasteTab.WAITING_FOR_CONFIRMATION:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'DESC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.CREATED },
          toRequestFilters(currentFilters || [])
        );
        break;
      case MetawasteTab.PLANNED:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'DESC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.PLANNED },
          toRequestFilters(currentFilters || [])
        );
        break;
      case MetawasteTab.COMPLETED:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          { sortBy: 'execution.plannedFrom', orderBy: 'DESC' },
          orgId,
          orgUnitIds,
          { status: ExecutionStatusEnum.EXECUTED },
          toRequestFilters(currentFilters || [])
        );
        break;
      case MetawasteTab.LAST_ACCEPTED:
      case MetawasteTab.LAST_COMPLETED:
      case MetawasteTab.LAST_CREATED:
        request = metawasteApi.getSearchExecution(
          limit || env.PAGINATION,
          page + 1,
          {
            sortBy: 'execution.createdAt',
            orderBy: 'DESC',
          },
          orgId,
          orgUnitIds
        );
        break;
      case MetawasteTab.STATS:
        return;
    }
    const [search, e] = await resolve(request);

    this.store.setLoading(false);
    if (e || !search) {
      throw handleBasicSnackError(i18n.t('fe.errors.searching'));
    }
    this.store.update((state) => ({
      ...state,
      total: search.total,
      totalToConfirm: currentTab == MetawasteTab.TO_CONFIRM ? search.total : state.totalToConfirm,
    }));
    this.store.set(search.data);
  };

  acceptOrReject = async (executionId: ID, accepted: boolean, justification?: string) => {
    this.store.setLoading(true);
    const [_, e] = await resolve(
      metawasteApi.patchAcceptOrRejectExecution(executionId, {
        accepted,
        justification,
      })
    );

    this.store.setLoading(false);
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.acceptOrReject'));
    }
    this.store.update((state) => ({
      ...state,
      totalToConfirm: state.totalToConfirm - 1,
    }));

    this.store.remove(executionId);
  };
}

export const dashboardExecutionsService = new DashboardExecutionsService(dashboardExecutionsStore);
