const fonts = `
  @font-face {
    font-family: 'CircularStd';
    font-weight: 400;
    src: local('CircularStd'), local('CircularStd-Book'), url('./CircularStd/CircularStd-Book.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-weight: 500;
    src: local('CircularStd'), local('CircularStd-Medium'), url('./CircularStd/CircularStd-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-weight: 700;
    src: local('CircularStd'), local('CircularStd-Bold'), url('./CircularStd/CircularStd-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-weight: 800;
    src: local('CircularStd'), local('CircularStd-Black'), url('./CircularStd/CircularStd-Black.woff2') format('woff2');
  }
  `;

export default fonts;
