import { InputLabel, Stack, SxProps, TextField, Theme } from '@mui/material';
import { SelectItem } from 'components/Select/SelectItem.interface';
import { KeyboardEventHandler } from 'react';

export enum LocalesNumber {
  EN = '+44',
  FR = '+33',
  DE = '+49',
  IT = '+39',
  SU = '+41',
}

export const selectCallingCodeItems = () =>
  [
    { value: LocalesNumber.FR, label: '+33' },
    { value: LocalesNumber.EN, label: '+44' },
    { value: LocalesNumber.DE, label: '+49' },
    { value: LocalesNumber.IT, label: '+39' },
    { value: LocalesNumber.SU, label: '+41' },
  ] as SelectItem[];

interface TextFieldComponentProps {
  color?: 'primary' | 'secondary';
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  error?: string;
  handleBlur?: (value: string) => void;
  handleChange?: (value: string) => void;
  handleClick?: () => void;
  handleKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  inputWidth?: number;
  label?: string;
  labelWidth?: number;
  minRows?: number;
  multiline?: boolean;
  pattern?: RegExp;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rows?: number;
  startAdornment?: JSX.Element;
  value?: string;
  fullWidth?: boolean;
  fontStyle?: SxProps<Theme>;
  inputStyle?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}

const TextFieldComponent = (props: TextFieldComponentProps) => {
  const {
    color = 'primary',
    defaultValue,
    disabled,
    endAdornment,
    error,
    handleBlur,
    handleChange,
    handleClick,
    handleKeyPress,
    inputWidth,
    label,
    labelWidth,
    minRows,
    multiline,
    pattern,
    placeholder,
    readOnly,
    required,
    rows,
    startAdornment,
    value,
    fullWidth,
    fontStyle,
    sx,
    inputStyle,
  } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      flex={fullWidth ? 1 : undefined}
    >
      {!!label && (
        <InputLabel
          error={!!error}
          sx={{ ...fontStyle, width: labelWidth ? `${labelWidth}px` : undefined }}
        >
          {label + (required ? '*' : '')}
        </InputLabel>
      )}
      <TextField
        sx={{
          flex: !inputWidth ? 1 : undefined,
          width: inputWidth ? `${inputWidth}px` : undefined,
          '& .MuiFilledInput-root': {
            ...((inputStyle || {}) as any),
          },
          ...sx,
        }}
        multiline={multiline}
        minRows={minRows}
        rows={rows}
        color={color}
        required={required}
        disabled={disabled}
        placeholder={!readOnly ? placeholder : undefined}
        defaultValue={defaultValue}
        value={value}
        onClick={handleClick}
        onBlur={(evt) => handleBlur?.(evt.target.value)}
        onChange={(evt) => {
          const value = evt.target.value;

          if (pattern && value && !pattern.test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1);
            return;
          }

          handleChange?.(value);
        }}
        onKeyPress={handleKeyPress}
        focused={readOnly ? false : undefined}
        InputProps={{
          'aria-label': handleClick ? 'clickable' : undefined,
          readOnly,
          startAdornment,
          endAdornment,
        }}
        inputProps={{ pattern }}
        error={!!error}
        helperText={error}
      />
    </Stack>
  );
};

export default TextFieldComponent;
