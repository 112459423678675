import { EcologyStats } from './ecology-screen-stats.model';
import { Store, StoreConfig } from '@datorama/akita';

export interface EcologyStatsState {
  evolutionStats: {
    loading: boolean;
    value: EcologyStats[];
  };
}

export function createInitialStats(): EcologyStatsState {
  return {
    evolutionStats: {
      loading: false,
      value: [],
    },
  };
}

@StoreConfig({ name: 'ecologyScreenStats' })
export class EcologyScreenStatsStore extends Store<EcologyStatsState> {
  constructor() {
    super(createInitialStats());
  }

  setEvolutionStatsLoading = (loading: boolean) => {
    this.update((state) => ({
      ...state,
      evolutionStats: {
        ...state.evolutionStats,
        loading,
      },
    }));
  };

  updateEcologyStats(stats: EcologyStats[]) {
    this.update((state) => ({
      ...state,
      evolutionStats: {
        loading: false,
        value: stats,
      },
    }));
  }
}

export const ecologyScreenStatsStore = new EcologyScreenStatsStore();
