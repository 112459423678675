import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactComponent as RecyclingTruck } from 'assets/icons/truckPickup.svg';
import { ReactComponent as TruckWithClock } from 'assets/icons/truckWithClock.svg';
import { useActivitiesPanelStyles } from './Activities.panel.style';
import { isMobile } from 'utils/isMobile';
import MonthCalendar from 'components/MonthCalendar';
import RowActivities from 'components/RowActivities/RowActivities';
import { filterHourAndMinute } from 'utils/data/date.utils';
import { useFetchPlanningMonth } from 'store/dashboard/executionMonthPlanning/hooks/useFetchPlanningMonth';
import { createExecutionService } from 'store/operations/createExecution';
import { ID } from '@datorama/akita';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'utils/dates/format';
import { useFetchPlanningDay } from 'store/dashboard/executionMonthPlanning/hooks/useFetchPlanningDay';
import { executionMonthPlanningQuery } from 'store/dashboard/executionMonthPlanning';
import { useObservable } from '@ngneat/react-rxjs';

interface ActivitiesPanelProps {
  buttonShow?: boolean;
  setDate: (newDate: Date) => void;
  dateCalendarSelected: Date;
}

const ActivitiesPanel: FC<ActivitiesPanelProps> = (props) => {
  const { buttonShow, setDate, dateCalendarSelected } = props;
  //const [dateCalendarSelected, setDateCalendarSelected] = useState<Date>(new Date());
  const [monthSelected, setMonthSelected] = useState<Date>(new Date());
  const [loading] = useObservable(executionMonthPlanningQuery.loading$);
  const [currentSmallTab, setCurrentSmallTab] = useState(0);
  const isMobiles = isMobile();
  const classes = useActivitiesPanelStyles();
  const { planning } = useFetchPlanningMonth(monthSelected);
  const { executions } = useFetchPlanningDay(dateCalendarSelected);
  const { t } = useTranslation();

  const handleOpenModal = (executionId?: ID, dateSelected?: Date) => {
    createExecutionService.openModal(
      executionId,
      isBefore(dateSelected) ? undefined : { dateSelected }
    );
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid item xs={12} container justifyContent="center" style={{ flex: 1 }}>
          <MonthCalendar
            month={monthSelected}
            setMonth={setMonthSelected}
            selectedDays={dateCalendarSelected}
            handleChange={setDate}
            activities={planning}
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>

        <Grid item style={{ flex: 5, marginTop: '10px' }}>
          {loading && (
            <Stack alignItems="center">
              <CircularProgress size={30} />
            </Stack>
          )}
          {!loading && executions.length === 0 && (
            <Grid container justifyContent="center" style={{ paddingTop: '20px' }}>
              <Typography>{t('fe.activitiesPanel.nothing')}</Typography>
            </Grid>
          )}
          {!loading &&
            executions.map((data, idx) => (
              <React.Fragment key={data.extendedProps?.id}>
                <ListItemButton
                  onClick={() => handleOpenModal(data.extendedProps?.id)}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <RowActivities
                    title={t('fe.activitiesPanel.row.title', { execution: data.title })}
                    description={t('fe.activitiesPanel.row.description', {
                      from: filterHourAndMinute(data.start),
                      to: filterHourAndMinute(data.end),
                    })}
                    icon={
                      data.extendedProps?.isRecurrent === true ? (
                        <RecyclingTruck />
                      ) : (
                        <TruckWithClock />
                      )
                    }
                    issue={data.extendedProps?.hasIssue}
                    status={data.extendedProps?.status}
                    isRecurent={data.extendedProps?.isRecurrent}
                  />
                </ListItemButton>
                {idx !== executions.length - 1 && (
                  <Divider sx={{ bgcolor: '#D4DFEC', borderColor: '#D4DFEC' }} />
                )}
              </React.Fragment>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActivitiesPanel;
