import { Stack } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridTreeNode,
  GridTreeNodeWithRender,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import SelectComponent from 'components/Select/Select.component';
import { SelectItem } from 'components/Select/SelectItem.interface';
import { ColumnProps } from '../columnTypes.table';

import { Array } from 'utils/extensions/array.extensions';
import { ReactElement } from 'react';

interface SelectColumnComponentProps extends GridRenderEditCellParams {
  options: SelectItem[];
  customRenderValue?: (row: any) => ReactElement;
}

const SelectColumnComponent = (props: SelectColumnComponentProps) => {
  const { id, field, options, value, customRenderValue } = props;

  const apiRef = useGridApiContext();

  return (
    <Stack flex={1}>
      <SelectComponent
        items={options}
        disableUnderline
        customRenderValue={customRenderValue}
        handleChange={(newValue) => apiRef.current.setEditCellValue({ id, field, value: newValue })}
        value={value}
      />
    </Stack>
  );
};

interface SelectColumnProps extends ColumnProps {
  cellClassName(params: GridCellParams<any, any, any, GridTreeNode>): any;
  field: string;
  label: string | undefined;
  fullWidth: any;
  minWidth: number;
  sortable: boolean;
  valueGetter:
    | ((params: GridValueGetterParams<any, any, GridTreeNodeWithRender>) => any)
    | undefined;
  valueFormatter: ((params: GridValueFormatterParams<any>) => any) | undefined;
  width: number;
  disabled?: (row: any) => boolean;
  hideInput?: (row: any) => boolean;
  customRenderValue?: (row: any) => ReactElement;
  options?: SelectItem[];
}

export const COLUMN_TYPE_SELECT = (props: SelectColumnProps): GridColDef => ({
  cellClassName: (params) => Array.mapNotNull([props.cellClassName?.(params)])?.join(' ') ?? '',
  field: props.field ?? '',
  headerName: props.label,
  flex: props.fullWidth ? 1 : undefined,
  minWidth: props.minWidth ?? 50,
  sortable: props.sortable ?? false,
  editable: !props.disabled,
  renderCell: (params) => {
    if (props.hideInput?.(params.row)) {
      return params.value;
    }
    return (
      <Stack flex={1}>
        <SelectComponent
          readOnly
          disableUnderline
          customRenderValue={props.customRenderValue}
          items={props.options ?? []}
          value={params.value}
        />
      </Stack>
    );
  },
  renderEditCell: (params) => {
    if (props.hideInput?.(params.row)) {
      return params.value;
    }
    return (
      <SelectColumnComponent
        {...params}
        disableUnderline
        customRenderValue={props.customRenderValue}
        options={props.options ?? []}
      />
    );
  },
  valueGetter: props.valueGetter,
  valueFormatter: props.valueFormatter,
  width: props.width ?? 150,
});
