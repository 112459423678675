import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import { useMaterialDropdownBaseStyles } from '../MaterialDropdown/MaterialDropdownBase.style';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { PaperComponentForward } from './PaperComponent';

export interface MaterialAutocompleteBaseProps {
  color?: 'primary' | 'secondary';
  label?: string;
  name?: string;
  handleChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: any;
  error?: string;
  value?: any;
  items?: MaterialDropdownItem[];
  isIconPicker?: boolean;
  className?: string;
}

const MaterialAutocompleteBase = (props: MaterialAutocompleteBaseProps) => {
  const {
    color = 'primary',
    name,
    fullWidth = true,
    handleChange,
    required,
    disabled,
    placeholder,
    readOnly,
    defaultValue,
    error,
    value = '',
    items = [],
    isIconPicker = false,
    className,
  } = props;

  const classes = useMaterialDropdownBaseStyles();

  let options: MaterialDropdownItem[] = items;

  if (placeholder) {
    options = [{ label: placeholder || '', value: '' }, ...items];
  }

  const currentValue = options.find((o) => o.value === value);

  return (
    <Autocomplete
      className={className}
      // variant="filled"
      // classes={{
      //   filled: !defaultValue && !value ? classes.placeholder : undefined,
      // }}
      isOptionEqualToValue={(option, value) => {
        return option.value === value?.value;
      }}
      renderOption={(props, item: MaterialDropdownItem) => {
        if (item.label === placeholder) {
          return (
            <MenuItem
              {...props}
              key={item.value}
              value=""
              className={!defaultValue ? classes.placeholder : undefined}
            >
              {defaultValue || placeholder || 'None'}
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={`${item.label} ${item.value}`}
            value={item.value}
            {...props}
            style={{ width: 'fit-content', minWidth: '100%' }}
          >
            {item.label}
          </MenuItem>
        );
      }}
      getOptionLabel={(o) => {
        if (o.value === '') {
          return '';
        }
        return o.label;
      }}
      options={options}
      onChange={(evt, newValue: any) => {
        if (!newValue || newValue?.value === '') {
          handleChange?.(undefined);
        }
        handleChange?.(newValue?.value);
      }}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      disabled={disabled}
      readOnly={readOnly}
      value={currentValue}
      PaperComponent={PaperComponentForward}
      renderInput={(params) => (
        <TextField
          {...params}
          // classes={{
          //   root: !defaultValue && !value ? classes.placeholder : undefined,
          // }}
          color={color}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: { input: classes.placeholder },
          }}
          variant="filled"
          required={required}
          name={name}
          error={!!error}
        />
      )}
    />
  );
};

export default MaterialAutocompleteBase;
