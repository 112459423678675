import { ID } from '@datorama/akita';
import { ServiceKindLight } from 'store/operations/createExecution';
import env from 'utils/env';
import { ExecutionCreationRuleEnum } from 'utils/enums/ExecutionCreationRuleEnum';

export interface ServiceKindLightDto {
  id: ID;
  code: string;
  kind: string;
  kindTranslateKey?: string;
  executionCreationRule: ExecutionCreationRuleEnum;
  execUnitName1: string;
  execUnitName2: string;
}

export function formatToServiceKindLight({
  id,
  code,
  kind,
  kindTranslateKey,
  executionCreationRule,
  execUnitName1,
  execUnitName2,
}: ServiceKindLightDto): ServiceKindLight {
  return {
    id,
    code,
    kind,
    kindTranslateKey,
    isDisplay: true,
    executionCreationRule,
    execUnitName1,
    execUnitName2,
  };
}

export function formatToServiceKindLightWithDisplay({
  id,
  code,
  kind,
  kindTranslateKey,
  executionCreationRule,
}: ServiceKindLightDto): ServiceKindLight {
  return {
    id,
    code,
    kind,
    kindTranslateKey,
    isDisplay: code !== env.SERVICE_KIND_COLLECTE_FORFAIT_CODE,
    executionCreationRule,
  };
}
