import { Card, CardContent, Grid, Typography } from '@mui/material';
import { OperationEvolutionDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';
import { OpUnitType } from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationActivities } from 'screens/auth/dashboard/morgan/operations/components/OperationActivities';
import { OperationAverageQuantityComponent } from 'screens/auth/dashboard/morgan/operations/components/OperationAverageQuantityComponent';
import { OperationEvolutionComponent } from 'screens/auth/dashboard/morgan/operations/components/OperationEvolutionComponent';
import { OperationPickUpDoneComponent } from 'screens/auth/dashboard/morgan/operations/components/OperationPickUpDoneComponent';
import { OperationsIssueReasons } from 'screens/auth/dashboard/morgan/operations/components/OperationsIssueReasons';
import { OperationStatIssueCount } from 'screens/auth/dashboard/morgan/operations/components/OperationStatIssueCount';
import { isMobile } from 'utils/isMobile';

interface OperationSCreenProps {
  from: Date;
  to: Date;
  dropDownDashboard: OpUnitType;
  wasteIds: string[];
}

const fakeData: OperationEvolutionDto = {
  evolution: {
    current: [
      {
        date: '2020-01-01',
        execCount: 0,
        issueCount: 0,
      },
      {
        date: '2020-01-02',
        execCount: 0,
        issueCount: 0,
      },
      {
        date: '2020-01-03',
        execCount: 0,
        issueCount: 0,
      },
    ],
    old: [
      {
        date: '2020-01-01',
        execCount: 0,
        issueCount: 0,
      },
      {
        date: '2020-01-02',
        execCount: 0,
        issueCount: 0,
      },
      {
        date: '2020-01-03',
        execCount: 0,
        issueCount: 0,
      },
    ],
  },
  execExecutedCount: {
    current: 0,
    old: 0,
  },
  issueCount: {
    current: 0,
    old: 0,
  },
  execCreatedCount: {
    current: 0,
    old: 0,
  },
  execExecutedPickupCount: {
    current: 0,
    old: 0,
  },
  execWithIssueCount: {
    current: 0,
    old: 0,
  },
  execPickupWeight: {
    current: 0,
    old: 0,
  },
  execProcessingWeight: {
    current: 0,
    old: 0,
  },
  execTheoPickupWeight: {
    current: 0,
    old: 0,
  },
  execValorisationWeight: {
    current: 0,
    old: 0,
  },
};

const ObOperations: FC<OperationSCreenProps> = (props) => {
  const { from, to, wasteIds, dropDownDashboard } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
        <Card>
          <CardContent>
            <Typography variant="h3">
              {t('fe.dashboard.morgan.stats.operations.activities.title')}
            </Typography>
            <OperationActivities isOnbording />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.collect.title')}
              </Typography>
              <OperationPickUpDoneComponent evolution={fakeData} isLoading={false} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={isMobiles ? 12 : 6}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.issue.title')}
              </Typography>
              <OperationStatIssueCount isLoading={false} evolution={fakeData} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={isMobiles ? 12 : 5}>
          <OperationEvolutionComponent
            operationEvolutionStats={fakeData}
            isLoading={false}
            dropDownDashboard={dropDownDashboard}
            from={from}
          />
        </Grid>
        <Grid item xs={isMobiles ? 12 : 3.5}>
          <Card
            style={{
              height: '400px',
              // filter: 'blur(1.5px)',
              // opacity: 0.5,
            }}
          >
            <CardContent style={{ position: 'relative' }}>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.tonnage.title')}
              </Typography>
              <OperationAverageQuantityComponent average={0} total={0} isLoading={false} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={isMobiles ? 12 : 3.5}>
          <Card style={{ height: '400px' }}>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.issueReasons.title')}
              </Typography>
              <OperationsIssueReasons isLoading={false} issueKinds={[]} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ObOperations;
