import { TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { ErrorOutline } from '@mui/icons-material';

export interface MaterialNumberTextfieldProps {
  label?: string;
  fullWidth?: boolean;
  name?: string;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  color?: 'primary' | 'secondary' | 'edit-table';
  pattern?: RegExp;
  handleClick?: () => void;
  handleChange?: (value: number) => void;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  defaultValue?: string;
  value?: string;
  handleOnEnterPress?: () => void;
  error?: string;
  size?: 'small';
  className?: string;
  isLoading?: boolean;
  autofocus?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  customStyle?: React.CSSProperties;
}

const MaterialNumberTextfield: FC<MaterialNumberTextfieldProps> = (
  props: MaterialNumberTextfieldProps
) => {
  const {
    className,
    customStyle,
    color = 'primary',
    defaultValue,
    error,
    fullWidth = true,
    handleChange,
    handleClick,
    label,
    name,
    placeholder,
    readOnly,
    startIcon,
    endIcon,
    disabled,
    pattern,
    required,
    size,
    type,
    value = '',
    handleOnEnterPress,
    multiline,
    autofocus,
    rows,
    isLoading,
  } = props;

  const renderEndIcon = (): React.ReactNode | undefined => {
    return error ? <ErrorOutline color="error" /> : endIcon;
  };

  const additionalProps = {};

  if (type == 'number') {
    additionalProps['onWheel'] = (e: any) => e.target.blur();
  }
  const [inputValue, setInputValue] = useState(value || '');

  return (
    <TextField
      variant="filled"
      margin="normal"
      className={className}
      size={size}
      hiddenLabel
      color={color}
      style={customStyle}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      autoFocus={autofocus}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder || label}
      name={name}
      type={type}
      value={inputValue}
      {...additionalProps}
      onChange={(evt) => {
        const value = evt.target.value.replace(',', '.').slice(0, 15);
        evt.target.value = value;

        if (pattern && value && !pattern.test(value.toString())) {
          evt.target.value = value.slice(0, value.length - 1);
          return;
        }

        setInputValue(value);
        if (!value) return;

        const floatValue = parseFloat(value);
        if (!!value && !isNaN(floatValue)) {
          handleChange?.(floatValue);
        }
      }}
      focused={readOnly ? false : undefined}
      InputProps={{
        disableUnderline: true,
        readOnly,
        'aria-label': handleClick ? 'clickable' : undefined,
        onClick: handleClick,
        startAdornment: startIcon,
        endAdornment: renderEndIcon(),
      }}
      inputProps={{ pattern }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOnEnterPress?.();
        }
      }}
      error={!!error}
      helperText={error}
    />
  );
};

export default MaterialNumberTextfield;
