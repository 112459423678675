import { InputLabel } from '@mui/material';
import React, { FC } from 'react';
import style from './MaterialMultipleDatePicker.style';
import { WithStyles, withStyles } from '@mui/styles';
import MaterialDatePickerBase from '../MaterialDatePickerBase';

interface MaterialMultipleDatePickerProps extends WithStyles<typeof style> {
  label?: string;
  labelFrom?: string;
  labelTo?: string;
  name?: string;
  withIcon?: boolean;
  required?: boolean;
  readOnly?: boolean;
  valueFrom?: Date | null;
  color?: 'primary' | 'secondary' | 'edit-table';
  handleChangeFrom: (value: Date | any) => void;
  valueTo?: Date | null;
  handleChangeTo: (value: Date | any) => void;
  error?: string;
}

const MaterialMultipleDatePicker: FC<MaterialMultipleDatePickerProps> = (
  props: MaterialMultipleDatePickerProps
) => {
  const {
    withIcon,
    classes,
    color = 'primary',
    error,
    label,
    labelFrom,
    labelTo,
    valueFrom,
    valueTo,
    handleChangeFrom,
    handleChangeTo,
    required,
  } = props;

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <div className={classes.textfields}>
        <InputLabel>{labelFrom}</InputLabel>
        <MaterialDatePickerBase
          handleChange={handleChangeFrom}
          value={valueFrom}
          color={color}
          {...props}
          withIcon={false}
        />
        <InputLabel>{labelTo}</InputLabel>
        <MaterialDatePickerBase
          handleChange={handleChangeTo}
          value={valueTo}
          color={color}
          {...props}
          withIcon={withIcon}
        />
      </div>
    </div>
  );
};

export default withStyles(style)(MaterialMultipleDatePicker);
