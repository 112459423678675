export enum ActivityEntityType {
  ISSUE = 'ISSUE',
  EXECUTION = 'EXECUTION',
}

export interface ActivityExecutionDto {
  wasteName: string;
  containerNameTranslateKey: string;
  clientWasteName: string;
  wasteNameTranslateKey: string;
  plannedFrom: Date;
  plannedTo: Date;
}

export interface ActivityDto<T> {
  entityId: string;
  entityType: ActivityEntityType;
  lastActivityAt: Date;
  entity: T;
  authorLastname: string;
  authorFirstname: string;
}
