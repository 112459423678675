import React, { FC } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CardOperations from 'components/Card/CardOperations';
import { useDashboardMorganStyles } from '../DashboardMorgan.style';
import { isMobile } from 'utils/isMobile';
import EcologicEvolution from 'components/Graph/EcologicEvolution';
import { useTranslation } from 'react-i18next';
import { OpUnitType } from 'dayjs';
import { useObservable } from '@ngneat/react-rxjs';
import { ecologyScreenStatsQuery } from 'store/dashboard/ecologyScreenStats';
import { Colors } from 'constants/Colors';

interface EcologyScreenProps {
  from: Date;
  to: Date;
  wasteIds: string[];
  dropDownDashboard: OpUnitType;
}

const EcologicScreen: FC<EcologyScreenProps> = (props) => {
  const { from, to, wasteIds, dropDownDashboard } = props;
  const isMobiles = isMobile();
  const classes = useDashboardMorganStyles();
  const { t } = useTranslation();

  const [ecologyEvolutionsStats] = useObservable(ecologyScreenStatsQuery.getEvolutionStats$);
  const total = ecologyEvolutionsStats.value.reduce(
    (acc, curr) => acc + curr.pickupCo2 + curr.processingCo2,
    0
  );

  const totalQuantity = ecologyEvolutionsStats.value.reduce(
    (acc, curr) => acc + curr.processingTotalQuantity,
    0
  );

  const average = total / totalQuantity;

  const recyclingRate =
    (ecologyEvolutionsStats.value.reduce((acc, curr) => acc + curr.nonMixedWasteTotalQuantity, 0) /
      totalQuantity) *
    100;

  return (
    <>
      <Grid item xs={12}>
        <Card style={{ height: '500px' }}>
          <CardContent>
            <Typography variant="h3" className={classes.cardTypography}>
              {t('fe.dashboard.morgan.stats.ecologic.evolution.title')}
            </Typography>
            <EcologicEvolution
              evolutionStats={ecologyEvolutionsStats.value}
              dropDownDashboard={dropDownDashboard}
              currentDate={from}
            />
            <Grid item container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Box sx={{ width: 10, height: 10, backgroundColor: Colors.primary }} />
                  <Typography variant="body1" fontSize={12} style={{ marginLeft: 8 }}>
                    {t('fe.dashboard.morgan.stats.ecologic.evolution.pickupCo2')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Box sx={{ width: 10, height: 10, backgroundColor: Colors.blue }} />
                  <Typography variant="body1" fontSize={12} style={{ marginLeft: 8 }}>
                    {t('fe.dashboard.morgan.stats.ecologic.evolution.processingCo2')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Box sx={{ width: 10, height: 10, backgroundColor: Colors.warning }} />
                  <Typography variant="body1" fontSize={12} style={{ marginLeft: 8 }}>
                    {t('fe.dashboard.morgan.stats.ecologic.evolution.burnCo2')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={isMobiles ? 12 : 4}>
          <Card>
            <CardContent>
              <Grid item>
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.ecologic.recyclingRateTitle')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                  {t('fe.dashboard.morgan.stats.ecologic.recyclingRateTitleSubtitle')}
                </Typography>
              </Grid>
              <Grid item style={{ height: '100%', padding: '10px 0' }}>
                <CardOperations
                  data={recyclingRate.toFixed(2)}
                  background="ecoGreen.main"
                  percentage="%"
                  description={t('fe.dashboard.morgan.stats.ecologic.recyclingRateDescription')}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={isMobiles ? 12 : 8}>
          <Card>
            <CardContent>
              <Grid item>
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.ecologic.CarbonPrint.title')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                  {t('fe.dashboard.morgan.stats.ecologic.CarbonPrint.subtitle')}
                </Typography>
              </Grid>
              <Grid container spacing={2} style={{ padding: '10px 0' }}>
                <Grid item xs={isMobiles ? 12 : 6}>
                  <CardOperations
                    title={t('fe.dashboard.morgan.stats.ecologic.CarbonPrint.onPeriod')}
                    data={total.toFixed(2)}
                    background="ecoGray.main"
                    percentage=" tCO2"
                  />
                </Grid>
                <Grid item xs={isMobiles ? 12 : 6}>
                  <CardOperations
                    title={t('fe.dashboard.morgan.stats.ecologic.CarbonPrint.average')}
                    data={average.toFixed(2)}
                    background="ecoGray.main"
                    percentage=" tCO2"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EcologicScreen;
