import { Component } from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';
import { iconSrc } from 'constants/Icons';
import { Grid, Typography } from '@mui/material';

const ErrorIcon = iconSrc.ExclamationSvg;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, errorStack: error.stack };
  }

  // TODO: add logging to logger service (ex. Rollbar)
  // public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   console.error()
  // }

  render() {
    const { hasError, errorStack } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Grid container justifyContent="center" alignContent="center" height="100vh">
          <Grid item xs={7}>
            <Typography variant="h2">Oops, something went wrong!</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography>
              Please contact{' '}
              <a href="mailto:support@metawaste.com">support@srs-globalservices.com</a> and explain
              what you were trying to do
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {errorStack && (
              <>
                <Typography variant="h5">Error logs:</Typography>
                <Typography>{errorStack}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
