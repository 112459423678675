import React, { FC } from 'react';
import { isMobile } from 'utils/isMobile';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOperationScreenStats } from 'store/dashboard/operationScreenStats/hooks/useOperationScreenStats';
import { OperationEvolutionComponent } from './components/OperationEvolutionComponent';
import { OperationsIssueReasons } from './components/OperationsIssueReasons';
import { OperationAverageQuantityComponent } from './components/OperationAverageQuantityComponent';
import { OperationPickUpDoneComponent } from './components/OperationPickUpDoneComponent';
import { OperationStatIssueCount } from './components/OperationStatIssueCount';
import { OpUnitType } from 'dayjs';
import { OperationActivities } from './components/OperationActivities';

interface OperationSCreenProps {
  from: Date;
  to: Date;
  dropDownDashboard: OpUnitType;
  wasteIds: string[];
}

const OperationsScreen: FC<OperationSCreenProps> = (props) => {
  const { from, to, wasteIds, dropDownDashboard } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();
  const {
    isIssueKindsLoading,
    issueKinds,
    isEvolutionOperationLoading,
    operationEvolutionStats,
  } = useOperationScreenStats(from, to, wasteIds);

  return (
    <>
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
        <Card style={{ opacity: isEvolutionOperationLoading ? 0.5 : 1 }}>
          <CardContent>
            <Typography variant="h3">
              {t('fe.dashboard.morgan.stats.operations.activities.title')}
            </Typography>
            <OperationActivities />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={isMobiles ? 12 : 6}>
          <Card style={{ opacity: isEvolutionOperationLoading ? 0.5 : 1 }}>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.collect.title')}
              </Typography>
              <OperationPickUpDoneComponent
                evolution={operationEvolutionStats}
                isLoading={isEvolutionOperationLoading}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={isMobiles ? 12 : 6}>
          <Card style={{ opacity: isEvolutionOperationLoading ? 0.5 : 1 }}>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.issue.title')}
              </Typography>
              <OperationStatIssueCount
                isLoading={isEvolutionOperationLoading}
                evolution={operationEvolutionStats}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={isMobiles ? 12 : 5}>
          <OperationEvolutionComponent
            operationEvolutionStats={operationEvolutionStats}
            isLoading={isEvolutionOperationLoading}
            dropDownDashboard={dropDownDashboard}
            from={from}
          />
        </Grid>
        <Grid item xs={isMobiles ? 12 : 3.5}>
          <Card
            style={{
              height: '400px',
              opacity: isEvolutionOperationLoading ? 0.5 : 1,
              // filter: 'blur(1.5px)',
              // opacity: 0.5,
            }}
          >
            <CardContent style={{ position: 'relative' }}>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.tonnage.title')}
              </Typography>
              <OperationAverageQuantityComponent
                average={
                  operationEvolutionStats.execPickupWeight.current /
                  operationEvolutionStats.execExecutedPickupCount.current
                }
                total={operationEvolutionStats.execPickupWeight.current}
                isLoading={isEvolutionOperationLoading}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={isMobiles ? 12 : 3.5}>
          <Card style={{ height: '400px', opacity: isIssueKindsLoading ? 0.5 : 1 }}>
            <CardContent>
              <Typography variant="h3">
                {t('fe.dashboard.morgan.stats.operations.issueReasons.title')}
              </Typography>
              <OperationsIssueReasons isLoading={isIssueKindsLoading} issueKinds={issueKinds} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OperationsScreen;
