import {
  Alert,
  AppBar,
  Grid,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { ReactComponent as IconFaq } from 'assets/icons/rework/icon_faq.svg';
import { ReactComponent as IconNotification } from 'assets/icons/rework/icon_notification.svg';
import { ReactComponent as IconSettings } from 'assets/icons/rework/icon_settings.svg';
import { ReactComponent as IconBurger } from 'assets/icons/rework/menuBurger.svg';
import { useAppBarHeaderStyles } from 'components/AppBar/AppBarHeader/AppBarHeader.style';
import AvatarComponent from 'components/AvatarComponent';
import { usePopup } from 'components/Modal/Popup/PopupService';
import SideModal from 'components/Modal/SideModal';
import ScrollDownMenu from 'components/ScrollDownMenu/ScrollDownMenu';
import { Cypress } from 'constants/Cypress';
import { iconSrc } from 'constants/Icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationModal from 'screens/auth/modals/notifications/NotificationModal';
import SettingsScreen from 'screens/auth/modals/settings/Settings.screen';
import { sessionService } from 'store/session';
import { getHelpMenu } from 'utils/data/helpMenu.utils';
import { isMobile } from 'utils/isMobile';
import ObAppBarDropdownOrganisation from './ObAppBarDropdownClient/ObAppBarDropdownOrganisation';
import ObAppBarDropdownOrgUnit from './ObAppBarDropdownClientOrgUnit/ObAppBarDropdownOrgUnit';
import { useObservable } from 'react-use';
import { onbordingQuery } from 'store/onbording/onbording.query';
import { onbordingService } from 'store/onbording/onbording.service';
import ObExecutionModalScreen from './ObExecutionModal.screen';
import { LoadingButton } from '@mui/lab';

interface AppBarHeaderProps {
  handleClick?: () => void;
}

const ObAppBarHeader: FC<AppBarHeaderProps> = (props: AppBarHeaderProps) => {
  const classes = useAppBarHeaderStyles();
  const isMobiles = isMobile();

  const { t } = useTranslation();
  const popConfirm = usePopup();
  const menuDropwDownItems = getHelpMenu();

  const [isSideModalSettingsOpen, setIsSideModalSettingsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openNewDemande = useObservable(onbordingQuery.openNewDemande$);

  const handleLogout = () => {
    popConfirm({
      title: t('fe.appBar.logout.title'),
      description: t('fe.appBar.logout.description'),
      buttons: [
        {
          label: t('fe.appBar.logout.button'),
          color: 'error',
          onClick: sessionService.logout,
        },
      ],
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBarShift}>
      <Toolbar>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          direction={{ xs: 'column-reverse', lg: 'row' }}
          className={classes.reponsiveWidth}
        >
          <Grid item className={classes.reponsiveWidth}>
            <Grid container item justifyContent={{ xs: 'space-between' }} alignItems="center">
              <ObAppBarDropdownOrganisation />
              <>
                <img src={iconSrc.spacer} className={classes.divider} />
                <ObAppBarDropdownOrgUnit />
              </>
            </Grid>
          </Grid>
          <Grid item className={classes.reponsiveWidth}>
            <Grid
              container
              item
              justifyContent={{ xs: 'space-between', lg: 'end' }}
              alignItems="center"
              columnSpacing={{ xs: 0, lg: 1 }}
            >
              <Grid item className={classes.hideForDesktop}>
                <IconButton onClick={props.handleClick}>
                  <IconBurger />
                </IconButton>
              </Grid>
              <Stack direction="row" alignItems="center" spacing={2} p={1}>
                <Grid
                  sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}
                  item
                  className={classes.iconContainer}
                >
                  <Alert sx={{ fontSize: '12px' }} variant="outlined" severity="info" icon={false}>
                    {t('fe.appBar.supportMessage')}
                    <br />
                    <Link href="mailto:support@metawaste.com">
                      <strong>support@metawaste.com</strong>
                    </Link>{' '}
                    /{' '}
                    <Link href="tel:0264704561">
                      <strong>026 470 45 61</strong>
                    </Link>
                  </Alert>
                </Grid>
                <Stack direction="row" alignItems="center">
                  <Grid item className="step11">
                    <ScrollDownMenu mainIcon={<IconFaq />} items={[]} />
                  </Grid>
                  <Grid item className={classes.iconContainer}>
                    <Tooltip title={t('fe.appBar.tooltips.settings') ?? ''}>
                      <IconButton className="step10">
                        <IconSettings />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.iconContainer}>
                    <Tooltip title={t('fe.appBar.tooltips.notifications') ?? ''}>
                      <IconButton className="step9">
                        <Badge badgeContent={<> </>} variant="standard" color="warning">
                          <IconNotification />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.buttonContainer}
                      startIcon={<AvatarComponent isBig={!isMobiles} initials="" />}
                      size="small"
                      aria-describedby="id"
                      data-cy={Cypress.HeaderAccountDropdown}
                      onClick={handleLogout}
                    >
                      <span className={classes.hideForMobile}>Morgan Freeman</span>
                    </Button>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {isSideModalSettingsOpen && (
            <SettingsScreen handleClickCloseModal={() => setIsSideModalSettingsOpen(false)} />
          )}
          {isModalOpen && (
            <SideModal handleClickCloseModal={() => setIsModalOpen(false)}>
              <NotificationModal />
            </SideModal>
          )}
          {openNewDemande && (
            <SideModal handleClickCloseModal={() => onbordingService.handleOpenNewDemande(false)}>
              <Typography variant="h2" style={{ fontSize: '24px' }}>
                Nouvelle opération
              </Typography>
              <ObExecutionModalScreen />
              <Stack
                direction="row"
                spacing={2}
                alignItems="end"
                justifyContent="end"
                height="calc(100vh - 52rem)"
              >
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ mr: '15px' }}
                  onClick={() => undefined}
                >
                  {t('fe.bookmarks.addButton')}
                </Button>
                <LoadingButton
                  variant="contained"
                  size="large"
                  loading={false}
                  onClick={() => undefined}
                >
                  {t('fe.executionModal.buttons.accept')}
                </LoadingButton>
              </Stack>
            </SideModal>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ObAppBarHeader;
