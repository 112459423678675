import React from 'react';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SideModal from 'components/Modal/SideModal';

interface SidebarMobileProps {
  tabs: {
    title: string;
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    // eslint-disable-next-line no-undef
    iconSelected: JSX.Element;
  }[];
  value?: number;
  handleChange?: (newValue: number) => void;
  handleClickCloseSideBar?: () => void;
}

const SidebarMobile = (props: SidebarMobileProps) => {
  const { tabs = [], value, handleChange, handleClickCloseSideBar } = props;

  return (
    <SideModal handleClickCloseModal={handleClickCloseSideBar}>
      <List>
        {tabs.map((data, index) => (
          <div key={data.title}>
            <ListItemButton onClick={() => handleChange?.(index)}>
              <ListItemIcon
                sx={{
                  minWidth: '0px !important',
                  color: value === index ? 'primary.dark' : 'secondary',
                }}
              >
                {value === index ? data.iconSelected : data.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  marginLeft: '17px',
                  color: value === index ? 'primary.dark' : 'secondary',
                }}
                primary={data.title}
              />
            </ListItemButton>
            <Divider variant="middle" />
          </div>
        ))}
      </List>
    </SideModal>
  );
};

export default SidebarMobile;
