import { QueryEntity } from '@datorama/akita';
import {
  ExecutionIssueKindsState,
  ExecutionIssueKindsStore,
  executionIssueKindsStore,
} from './execution-issue-kinds.store';
import { map } from 'rxjs/internal/operators';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import { removeDuplicateCallback } from 'utils/data/array.utils';
import { translateIssueKind } from 'utils/enums/IssueKinds';

export class ExecutionIssueKindsQuery extends QueryEntity<ExecutionIssueKindsState> {
  constructor(protected store: ExecutionIssueKindsStore) {
    super(store);
  }

  get numberIssueKinds() {
    return this.getAll().length;
  }

  getAllIssueKinds$ = this.selectAll().pipe(
    map((issueKinds) => {
      const formattedIssues = removeDuplicateCallback(issueKinds, (a, b) => a.name === b.name);
      return formattedIssues.map(
        (issueKind): MaterialDropdownItem => ({
          value: issueKind.id,
          label: translateIssueKind(issueKind.name),
        })
      );
    })
  );
}

export const executionIssueKindsQuery = new ExecutionIssueKindsQuery(executionIssueKindsStore);
