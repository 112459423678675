import { sessionStore, SessionStore } from './session.store';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import SnackError from 'utils/models/snackError';
import { formatToUser } from 'api/dto/user/UserDto';
import { organisationsService } from '../organisation/organisations';
import { organisationUnitsService } from '../organisation/organisationUnits';
import { msalInstance } from 'utils/azure-config';
import { LocalStorageKey } from 'constants/LocalStorageKey';
import { UserPreferencesDto } from 'api/dto/user/UserPreferencesDto';
import { filtersService } from 'store/common/filters/filters.service';

export class SessionService {
  constructor(private store: SessionStore) {}

  fetchMe = async () => {
    this.store.setLoading(true);

    const [user, e] = await resolve(metawasteApi.getMe());
    this.store.setLoading(false);
    if (e || !user) {
      switch (e?.message) {
        default:
          throw new SnackError('errors.serverError', 'error');
      }
    }
    this.store.update((state) => ({
      ...state,
      user: formatToUser(user),
    }));
  };

  userUpdatePreferences = async (newPreferences: UserPreferencesDto) => {
    await resolve(metawasteApi.userUpdatePreferences(newPreferences));
    this.store.update((state) => ({
      ...state,
      user: {
        ...state.user!,
        userPreferences: newPreferences,
      },
    }));
  };

  logout = () => {
    filtersService.reset();
    organisationsService.removeActiveOrganisation();
    organisationUnitsService.removeActiveOrgUnit();
    localStorage.removeItem(LocalStorageKey.AccessToken);
    msalInstance.logoutRedirect().catch((e) => console.error(e));
  };
}

export const sessionService = new SessionService(sessionStore);
