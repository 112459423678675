/* eslint-disable react/no-children-prop */
import React, { createContext, useContext, useState } from 'react';
import Popup, { PopupProps } from './Popup';

const PopupServiceContext = createContext<(options: PopupProps) => void>(Promise.reject);

export const usePopup = () => useContext(PopupServiceContext);

export const PopupServiceProvider = ({ children }) => {
  const [popupState, setPopupState] = useState<PopupProps | null>(null);

  const openConfirmation = (options: PopupProps) => {
    setPopupState(options);
  };

  const handleClose = () => {
    popupState?.handleClose?.();
    setPopupState(null);
  };

  const handleSubmit = () => {
    setPopupState(null);
  };

  return (
    <>
      <PopupServiceContext.Provider value={openConfirmation} children={children} />

      {popupState && (
        <Popup
          handleClose={handleClose}
          title={popupState.title}
          description={popupState.description}
          buttons={popupState.buttons}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};
