import {
  OperationScreenStatsStore,
  operationScreenStatsStore,
} from './operation-screen-stats.store';
import { ID } from '@datorama/akita';
import { metawasteApi } from 'api/Axios';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';

export class OperationScreenStatsService {
  constructor(private store: OperationScreenStatsStore) {}

  private getIssueCountStats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[]
  ) => {
    const [issueCount, e] = await resolve(
      metawasteApi.getIssueCount(from, to, wasteIDs, orgUnitIds, organisationId)
    );
    if (!issueCount || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueCount'));
    }
    this.store.updateIssueCountStats(issueCount);
    return issueCount;
  };

  private getIssueKindsStats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[],
    currentFilters?: TableFilter[]
  ) => {
    const [issueKinds, e] = await resolve(
      metawasteApi.getIssueKindStats(
        from,
        to,
        wasteIDs,
        orgUnitIds,
        organisationId,
        toRequestFilters(currentFilters || [])
      )
    );
    if (!issueKinds || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueKind'));
    }
    this.store.updateIssueKindStats(issueKinds);

    return issueKinds;
  };

  private getWasteQuantityStats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitId?: ID
  ) => {
    const reqAverage = resolve(
      metawasteApi.getWasteAverageQuantity(from, to, wasteIDs, orgUnitId, organisationId)
    );
    const reqTotal = await resolve(
      metawasteApi.getWasteTotalQuantity(from, to, wasteIDs, orgUnitId, organisationId)
    );
    const [[average, e], [total, er]] = await Promise.all([reqAverage, reqTotal]);
    if (!average || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.average'));
    }
    if (!total || er) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.average'));
    }
    this.store.updateWasteQuantitytStats(average.weightAverageAmountInKilograms, total.totalWeight);
    return average;
  };

  private getPickUpDoneStats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitId?: ID
  ) => {
    const [pickUp, e] = await resolve(
      metawasteApi.getPickUpDone(from, to, wasteIDs, orgUnitId, organisationId)
    );
    if (!pickUp || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.pickUp'));
    }
    this.store.updatePickUpDoneStats(pickUp);
    return pickUp;
  };
  private getOperationOperationStat = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[],
    currentFilters?: TableFilter[]
  ) => {
    const [evolution, e] = await resolve(
      metawasteApi.getOperationEvolution(
        from,
        to,
        wasteIDs,
        orgUnitIds,
        organisationId,
        toRequestFilters(currentFilters || [])
      )
    );
    if (!evolution || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.evolution'));
    }
    this.store.updateOperationEvolution(evolution);
    return evolution;
  };

  fetchstats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[],
    currentFilters?: TableFilter[]
  ) => {
    this.store.setIssueCountLoading(true);
    this.store.setIssueKindLoading(true);
    this.store.setWasteAverageQuantityLoading(true);
    this.store.setPickUpDoneLoading(true);
    this.store.setOperationEvolutionLoading(true);

    const issueKindStatsQuery = this.getIssueKindsStats(
      from,
      to,
      wasteIDs,
      organisationId,
      orgUnitIds,
      currentFilters
    );
    const operationEvolutionQuery = this.getOperationOperationStat(
      from,
      to,
      wasteIDs,
      organisationId,
      orgUnitIds,
      currentFilters
    );
    await Promise.all([issueKindStatsQuery, operationEvolutionQuery]);
  };
}

export const operationScreenStatsService = new OperationScreenStatsService(
  operationScreenStatsStore
);
