import { Service } from 'store/operations/createExecution';
import {
  ButtonBase,
  Card,
  CardContent,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import SelectComponent from 'components/Select/Select.component';
import { filtersTheme } from 'components/Filters/Filters.theme';
import { ThemeProvider } from '@mui/styles';
import { Close, Search } from '@mui/icons-material';
import { Colors } from 'style/materialStylings';
import MaterialTextfieldBase from 'components/MaterialTextfield/MaterialTextfieldBase';
import { ID } from '@datorama/akita';

interface SelectServiceModalProps {
  services: Service[];
  wastes: MaterialDropdownItem[];
  containers: MaterialDropdownItem[];
  handleClose: () => void;
  handleSelectService: (service: Service) => void;
}

const SelectServiceModal = (props: SelectServiceModalProps) => {
  const { services, handleClose, wastes, containers, handleSelectService } = props;

  const { t } = useTranslation();

  const [filterSearch, setFilterSearch] = React.useState<string>('');
  const [filterWasteId, setFilterWasteId] = React.useState<number | null>(null);
  const [filterContainerId, setFilterContainerId] = React.useState<number | null>(null);

  const [filteredServices, setFilteredServices] = React.useState<
    { wasteId: ID; wasteName: string; services: Service[] }[]
  >([]);

  useEffect(() => {
    setFilteredServices(
      services
        .filter((s) => s.clientWasteName.toLowerCase().includes(filterSearch.toLowerCase()))
        .filter((s) => (filterWasteId ? s.waste?.id === filterWasteId : true))
        .filter((s) => (filterContainerId ? s.container?.id === filterContainerId : true))
        .reduce((acc: { wasteId: ID; wasteName: string; services: Service[] }[], val) => {
          const wasteId = val.waste?.id;
          const wasteName = val.waste?.name || '';

          const existingIndex = acc.findIndex((it) => it.wasteId === wasteId);

          if (existingIndex > -1) {
            acc[existingIndex].services.push(val);
          } else {
            acc.push({
              wasteId,
              wasteName,
              services: [val],
            });
          }

          return acc;
        }, [])
        .sort((a, b) => a.wasteName.localeCompare(b.wasteName))
    );
  }, [filterSearch, filterWasteId, filterContainerId, services]);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="xl">
      <ThemeProvider
        theme={(theme: Theme) => ({
          ...theme,
          components: {
            ...theme.components,
            ...filtersTheme,
          },
        })}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={handleClose}>
          <Close />
        </IconButton>
        <Stack width="100%" bgcolor="#F4F6F8" direction="column" spacing={5} padding="30px">
          <Typography variant="h3" textAlign="center">
            {t('fe.operations.selectServices.title')}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Stack sx={{ width: { xs: 300, md: 600 } }}>
              <MaterialTextfieldBase
                type="search"
                startIcon={<Search htmlColor={Colors.darkGray} />}
                placeholder={t('fe.global.search')}
                color="secondary"
                value={filterSearch}
                handleChange={setFilterSearch}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <SelectComponent
                color="secondary"
                inputWidth={300}
                placeholder={t('fe.executionModal.executionInfosForm.serviceTrashPlaceholder')}
                handleChange={(value) => {
                  setFilterWasteId(value);
                }}
                value={filterWasteId}
                items={wastes}
              />
              <SelectComponent
                color="secondary"
                inputWidth={300}
                placeholder={t('fe.executionModal.executionInfosForm.serviceContainerPlaceholder')}
                handleChange={(value) => {
                  setFilterContainerId(value);
                }}
                value={filterContainerId}
                items={containers}
              />
            </Stack>
          </Stack>
          <Typography textAlign="center" variant="subtitle1">
            {t('fe.executionModal.executionInfosForm.selectServiceHint')}
          </Typography>
          <Stack direction="column" spacing={2}>
            {filteredServices.map((waste) => (
              <Stack direction="column" key={waste.wasteId} spacing={1}>
                <Typography variant="h2">{waste.wasteName}</Typography>
                <Divider sx={{ opacity: 0.5 }} />
                <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
                  {waste.services.map((service) => (
                    <ButtonBase key={service.id} onClick={() => handleSelectService(service)}>
                      <Card sx={{ width: { xs: 250, md: 400 }, height: { xs: 200, md: 150 } }}>
                        <CardContent>
                          <Stack direction="column" justifyContent="space-between" height="100%">
                            <Stack spacing={1} direction="column">
                              <Typography textAlign="left" variant="h3">
                                {service.clientWasteName}
                              </Typography>
                              <Typography textAlign="left" variant="body2">
                                {service.waste?.name} - {service.container?.name}
                              </Typography>
                            </Stack>
                            <Typography textAlign="end" variant="subtitle1">
                              {service.supplierUnitName}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </ButtonBase>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </ThemeProvider>
    </Dialog>
  );
};

export default SelectServiceModal;
