import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, CircularProgress, InputLabel, Stack, TextField } from '@mui/material';
import { Observable } from 'rxjs';

import { ArrowDown } from 'assets/icons/index';
import { useEffectFn } from '@ngneat/effects-hooks';

import { searchEffect, SelectItem } from './SelectItem.interface';

interface MultiSelectWithSearchComponentProps {
  color?: 'primary' | 'secondary';
  error?: string;
  getOptions: (search?: string) => Observable<SelectItem[]>;
  handleChange?: (values: SelectItem[]) => void;
  label?: string;
  labelWidth?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  values?: SelectItem[];
}

const AsyncMultiSelectWithSearchComponent = (props: MultiSelectWithSearchComponentProps) => {
  const {
    color = 'primary',
    error,
    getOptions,
    handleChange,
    label,
    labelWidth,
    placeholder,
    readOnly,
    required,
    values = [],
  } = props;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const searchOptions = useEffectFn(searchEffect);
  const [loading, setLoading] = useState(!readOnly && open && options.length === 0);

  useEffect(() => {
    setLoading(!readOnly && open && options.length === 0);
  }, [readOnly, open, options.length]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else if (!readOnly) {
      setLoading(true);
      searchOptions({ getOptions, setLoading, setOptions });
    }
  }, [open, readOnly]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      {!!label && (
        <InputLabel error={!!error} sx={{ width: labelWidth ? `${labelWidth}px` : undefined }}>
          {label + (required ? '*' : '')}
        </InputLabel>
      )}
      <Autocomplete
        multiple
        disableCloseOnSelect
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option: SelectItem) => option.label}
        isOptionEqualToValue={(option: SelectItem, value: any) => option.value === value.value}
        options={options}
        loading={loading}
        value={values}
        readOnly={readOnly}
        color={color}
        filterOptions={(options) => options}
        onChange={(evt, values) => handleChange?.(values)}
        onInputChange={(evt, inputValue) => {
          if (evt?.type !== 'click') {
            searchOptions({ search: inputValue, getOptions, setLoading, setOptions });
          }
        }}
        renderTags={(values: any[], getTagProps) =>
          values.map((value: any, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="filled"
              color={color}
              size="small"
              label={value.label}
              {...getTagProps({ index })}
              onDelete={
                !readOnly
                  ? () => handleChange?.(values.filter((_, valueIndex) => valueIndex !== index))
                  : undefined
              }
            />
          ))
        }
        popupIcon={!readOnly ? <ArrowDown /> : null}
        renderInput={(params) => (
          <TextField
            {...params}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: !values.length && !readOnly ? placeholder : undefined,
            }}
            color={color}
            error={!!error}
            helperText={error}
          />
        )}
      />
    </Stack>
  );
};

export default AsyncMultiSelectWithSearchComponent;
