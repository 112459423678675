import React, { useState } from 'react';
import DashboardMorganScreen from 'screens/auth/dashboard/morgan/DashboardMorgan.screen';
import DashboardPatrickScreen from 'screens/auth/dashboard/patrick/DashboardPatrick.screen';

export interface DashboardUserContainerProps {
  isMorgan?: boolean;
}

const DashboardUserContainer = (props: DashboardUserContainerProps) => {
  const { isMorgan } = props;
  const [dateCalendarSelected, setDateCalendarSelected] = useState<Date>(new Date());

  if (isMorgan === true) {
    return (
      <DashboardMorganScreen
        setDate={setDateCalendarSelected}
        dateCalendarSelected={dateCalendarSelected}
      />
    );
  }

  if (isMorgan === false) {
    return (
      <DashboardPatrickScreen
        setDate={setDateCalendarSelected}
        dateCalendarSelected={dateCalendarSelected}
      />
    );
  }
  return <div />;
};

export default DashboardUserContainer;
