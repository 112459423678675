import { OperationEvolutionDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';
import { Box, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useOperationsScreenStyles } from '../Operations.screen.style';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RectFill } from 'assets/icons/rework/rect-barchart.svg';
import BarchartOperations from 'components/Graph/BarchartOperations';
import { OpUnitType } from 'dayjs';
import { getYearStats } from 'utils/data/barchart.utils';

interface OperationEvolutionComponentProps {
  isLoading?: boolean;
  operationEvolutionStats: OperationEvolutionDto;
  dropDownDashboard: OpUnitType;
  from: Date;
}

export const OperationEvolutionComponent = (props: OperationEvolutionComponentProps) => {
  const { isLoading, operationEvolutionStats, dropDownDashboard, from } = props;
  const classes = useOperationsScreenStyles();
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Card style={{ height: '400px', opacity: 0.5 }}>
        <CardContent>
          <Typography variant="h3">
            {t('fe.dashboard.morgan.stats.operations.evolution.title')}
          </Typography>
          <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{ height: '400px' }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h3">
            {t('fe.dashboard.morgan.stats.operations.evolution.title')}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RectFill className={classes.gridLabelPosition} />
            <Typography variant="body1" className={classes.gridLabelPosition}>
              N
            </Typography>
          </div>
        </Stack>
        <BarchartOperations
          currentData={
            dropDownDashboard === 'year'
              ? getYearStats(operationEvolutionStats.evolution.current, from)
              : operationEvolutionStats.evolution.current
          }
          dropDownDashboard={dropDownDashboard}
          oldData={[]}
          currentDate={from}
        />
      </CardContent>
      <Grid
        container
        item
        justifyContent="space-around"
        alignItems="center"
        className={classes.gridLegendBarChartContainer}
      >
        <Grid item>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.gridLegendPosition}
          >
            <Box className={classes.boxLegendBarChartCollect} />
            <Typography variant="body1" fontSize={12}>
              {t('fe.dashboard.morgan.stats.operations.evolution.collect')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.gridLegendPosition}
          >
            <Box className={classes.boxLegendBarChartNonConformity} />
            <Typography variant="body1" fontSize={12}>
              {t('fe.dashboard.morgan.stats.operations.evolution.nonConformity')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
