import React, { useEffect, useRef } from 'react';
import { WithStyles, withStyles } from '@mui/styles';

import { style } from './FullCalendar.style';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { EventCalendar } from './TestData';
import './FullCalendar.css';
import { isMobile } from 'utils/isMobile';
import renderDayHeader from 'components/FullCalendar/components/renderDayHeader';
import renderSlotLabel from 'components/FullCalendar/components/render/renderSlots';
import renderEventContent from 'components/FullCalendar/components/render/renderEvent';

const isMobiles = isMobile();

interface FullCalendarMetawasteProps extends WithStyles<typeof style> {
  events: EventCalendar[];
  onEventSelected: (value: EventCalendar) => void;
  onDateSelected: (value: DateClickArg) => void;
  goToDate: Date;
}

const FullCalendarMetawaste = (props: FullCalendarMetawasteProps) => {
  const { classes, events, goToDate, onEventSelected, onDateSelected } = props;

  const calendar = useRef<FullCalendar>(null);

  useEffect(() => {
    calendar.current?.getApi().gotoDate(goToDate);
  }, [goToDate]);

  return (
    <FullCalendar
      contentHeight="auto"
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      slotMinTime="07:00:00"
      slotMaxTime="19:00:00"
      dateClick={onDateSelected}
      ref={calendar}
      events={events}
      eventContent={(obj) => renderEventContent(obj.event as EventCalendar, classes)}
      allDaySlot={true}
      allDayText=""
      initialDate={new Date()}
      slotEventOverlap={false}
      headerToolbar={false}
      height={isMobiles ? 670 : 650}
      eventBackgroundColor="rgba(0, 0, 0, 0)"
      eventBorderColor="rgba(0, 0, 0, 0)"
      firstDay={1}
      locales={allLocales}
      locale="fr"
      nowIndicator={true}
      eventClick={(evt) => onEventSelected(evt.event as EventCalendar)}
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      }}
      slotLabelContent={renderSlotLabel}
      // dayHeaderFormat={{ weekday: 'long', month: 'short', day: 'numeric', omitCommas: true }}
      dayHeaderContent={renderDayHeader}
    />
  );
};

export default withStyles(style)(FullCalendarMetawaste);
