import { Button, Stack, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import ModalComponent from 'components/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { userReviewsService } from 'store/dashboard/userReviews/user-reviews.service';
import { UserReviewStatus } from 'store/dashboard/userReviews/user-reviews.model';
import { Colors } from 'constants/Colors';
import { styled } from '@mui/styles';
import ThumbUp from '@mui/icons-material/Recommend';

export interface UserReportModalProps {
  handleClose: () => void;
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: Colors.primary,
  },
  '& .MuiRating-iconHover': {
    color: Colors.primary,
  },
});

const UserReviewModal = (props: UserReportModalProps) => {
  const { handleClose } = props;
  const { t } = useTranslation();
  const [rating, setRating] = React.useState<number | null>(1);

  const onClose = () => {
    userReviewsService.postReview(UserReviewStatus.DISMISSED).then((res) => {
      console.log('res', res);
    });
    handleClose();
  };

  const handleSubmit = () => {
    if (!rating) return console.log('rating is required');
    userReviewsService.postReview(UserReviewStatus.ANSWERED, rating);
    handleClose();
  };

  const handleRatingChange = (event: React.SyntheticEvent, newValue: number | null) => {
    if (newValue !== null && newValue >= 1 && newValue <= 5) {
      setRating(newValue);
    }
  };

  return (
    <ModalComponent
      handleClose={() => onClose()}
      title={t('fe.userReviews.modal.title')}
      maxWidth="sm"
      maxHeight="85vh"
    >
      <Stack>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography>{t('fe.userReviews.modal.description')}</Typography>
          <StyledRating
            icon={<ThumbUp sx={{ width: 64, height: 64 }} />}
            emptyIcon={<ThumbUp sx={{ width: 64, height: 64 }} />}
            value={rating}
            onChange={handleRatingChange}
          />
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => handleSubmit()}
          >
            <span>{t('fe.userReviews.modal.sendButton')}</span>
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  );
};

export default UserReviewModal;
