import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { useEffect, useRef } from 'react';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { activitiesService } from 'store/dashboard/activities';
import { combineLatest, Subject } from 'rxjs';

export const useActivities = (tabType: MetawasteTab) => {
  const tabType$ = useRef(new Subject<MetawasteTab>()).current;

  useEffect(() => {
    const _ = combineLatest([
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitIds$,
      tabType$,
    ]).subscribe(([orgId, orgUnitIds, tabType]) => {
      if (orgId) {
        activitiesService
          .fetchExecutionActivities(tabType, orgId, orgUnitIds)
          .catch((e) => console.error(e));
      }
    });
  }, []);

  useEffect(() => tabType$.next(tabType), [tabType]);
};
