import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import { COLUMN_TYPE_ACTIONS } from './columnTypes/actions.columnType';
import { COLUMN_TYPE_SELECT } from './columnTypes/select.columnType';
import { COLUMN_TYPE_TEXT, COLUMN_TYPE_TEXT_BOLD } from './columnTypes/text.columnType';
import { COLUMN_TYPE_TEXT_WITH_ACTION } from './columnTypes/textWithAction.columnType';
import { COLUMN_TYPE_CUSTOM } from './columnTypes/custom.columnType';
import { ReactNode } from 'react';

export interface ColumnProps {
  cellClassName?: (props: GridCellParams) => string;
  color?: string;
  field?: string;
  label?: string;
  fullWidth?: boolean;
  minWidth?: number;
  sortable?: boolean;
  valueGetter?: (props: GridValueGetterParams) => string | number;
  valueFormatter?: (props: GridValueFormatterParams) => string;
  valueSetter?: (props: GridValueSetterParams) => string | number;
  width?: number;
  editable?: boolean;
  renderCell: (props: GridRenderCellParams) => ReactNode;
}

interface ColumnTypes {
  [key: string]: (props: any) => GridColDef;
}

function getColumnTypes<T extends { [keyName: string]: (props: any) => GridColDef }>(types: T) {
  return types;
}

const COLUMN_TYPES: ColumnTypes = getColumnTypes({
  TEXT: COLUMN_TYPE_TEXT,
  CUSTOM: COLUMN_TYPE_CUSTOM,
  TEXT_BOLD: COLUMN_TYPE_TEXT_BOLD,
  TEXT_WITH_ACTION: COLUMN_TYPE_TEXT_WITH_ACTION,
  SELECT: COLUMN_TYPE_SELECT,
  ACTIONS: COLUMN_TYPE_ACTIONS,
});

export default COLUMN_TYPES;
