import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimePicker } from '@mui/x-date-pickers-pro';

import dayjs, { Dayjs } from 'dayjs';
import { Colors } from 'constants/Colors';
import { TextField } from '@mui/material';

interface TimeTextFieldComponentProps {
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  error?: string;
  handleChange?: (value: Date | null | undefined) => void;
  inputWidth?: number;
  label?: string;
  labelWidth?: number;
  readOnly?: boolean;
  required?: boolean;
  value?: Date | null;
}

const TimeTextFieldComponent = (props: TimeTextFieldComponentProps) => {
  const {
    color,
    disabled,
    error,
    handleChange,
    inputWidth,
    label,
    labelWidth,
    readOnly,
    required,
    value,
  } = props;

  const accessTimeColor = () => {
    if (error && !disabled) return Colors.error;
    if (value) {
      return dayjs(value).isValid() ? Colors.primary : Colors.error;
    }
    return Colors.text;
  };

  return (
    <TimePicker
      value={value ? dayjs(value) : null}
      onChange={(newValue: Dayjs | null) => handleChange?.(newValue?.toDate())}
      readOnly={readOnly}
      disabled={disabled}
      ampm={false}
      components={{
        OpenPickerIcon: AccessTimeIcon,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            flex: !inputWidth ? 1 : undefined,
            width: inputWidth ? `${inputWidth}px` : undefined,
          }}
          color={color}
          required={required}
          focused={readOnly ? false : undefined}
          InputProps={{
            ...params.InputProps,
            startAdornment: <AccessTimeIcon htmlColor={accessTimeColor()} fontSize="small" />,
          }}
          error={!!error && !disabled}
          helperText={!disabled ? error : undefined}
        />
      )}
    />
  );
};

export default TimeTextFieldComponent;
