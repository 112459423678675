import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDataOperationStatsStyle = makeStyles((theme: Theme) => ({
  responsiveGridCard: {
    marginBottom: '36px !important',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexWrap: 'nowrap !important',
      overflowX: 'auto',
      marginBottom: '16px !important',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  responsiveWidthCard: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '70%',
    },
  },
}));
