import { ID, QueryEntity } from '@datorama/akita';
import {
  ExecutionDocumentsState,
  ExecutionDocumentsStore,
  executionDocumentsStore,
} from './execution-documents.store';

export class ExecutionDocumentsQuery extends QueryEntity<ExecutionDocumentsState> {
  constructor(protected store: ExecutionDocumentsStore) {
    super(store);
  }

  documentsFromExecution$(executionId: ID) {
    return this.selectAll({ filterBy: (doc) => doc.executionId === executionId });
  }

  documentsFromExecution(executionId: ID) {
    return this.getAll({ filterBy: (doc) => doc.executionId === executionId });
  }

  documentIdsFromExecution(executionId: ID) {
    return this.documentsFromExecution(executionId).map((doc) => Number(doc.id));
  }

  documentsUpdateFromExecution(executionId: ID) {
    const docs = this.documentsFromExecution(executionId);
    const originalDocs = this.getValue().originalDocuments.filter(
      (doc) => doc.executionId === executionId
    );
    const documentsToAdd = docs
      .filter((doc) => !originalDocs.some((originalDoc) => originalDoc.id === doc.id))
      .map((doc) => Number(doc.id));
    const documentsToDelete = originalDocs
      .filter((originalDoc) => !docs.some((doc) => doc.id === originalDoc.id))
      .map((doc) => Number(doc.id));
    return { documentsToAdd, documentsToDelete };
  }
}

export const executionDocumentsQuery = new ExecutionDocumentsQuery(executionDocumentsStore);
