import React from 'react';
import { Box, Chip, InputLabel } from '@mui/material';

import { useMaterialMultiDropdownTagStyles } from './MaterialMultiDropdownTag.style';
import MaterialMultiDropdownBase from '../MaterialMultiDropdownBase';

interface MaterialDropdownItem {
  label: string;
  value: any;
}

export interface MaterialMultiDropdownTagProps {
  color?: 'primary' | 'secondary';
  label?: string;
  name?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: any;
  error?: string;
  selectedValues?: any[];
  items?: MaterialDropdownItem[];
  handleChange?: (itemsSelected: any[]) => void;
}

const MaterialMultiDropdownTag = (props: MaterialMultiDropdownTagProps) => {
  const {
    handleChange,
    placeholder,
    readOnly,
    defaultValue,
    selectedValues,
    items,
    label,
    error,
    required,
  } = props;
  const classes = useMaterialMultiDropdownTagStyles();

  const handleDelete = (value: any) => {
    handleChange?.((selectedValues || []).filter((v) => v !== value));
  };

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error} className={classes.inputLabel}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <MaterialMultiDropdownBase
        {...props}
        className={classes.textfield}
        renderValue={(selected: any) => {
          if (selected.length === 0) {
            return placeholder ?? defaultValue ?? 'None';
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  onDelete={readOnly ? undefined : () => handleDelete(value)}
                  onMouseDown={(e) => e.stopPropagation()}
                  label={items?.find((it) => it.value === value)?.label ?? ''}
                />
              ))}
            </Box>
          );
        }}
      />
    </div>
  );
};

export default MaterialMultiDropdownTag;
