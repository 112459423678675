import { Divider, Stack, SxProps } from '@mui/material';
import DatePickerComponent from 'components/datePicker/DatePicker.component';
import SelectComponent from 'components/Select/Select.component';
import TextFieldComponent from 'components/textfield/TextField.component';
import TimeTextFieldComponent from 'components/timePicker/TimeTextFieldComponent';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IssueCategorySelectItems,
  IssueKind,
  IssueStatus,
  IssueStatusSelectItems,
  ManageIssue,
} from 'store/issues/issues.model';
import { Sizes } from 'style/materialStylings';

interface IssueDetailsFormProps {
  issue: ManageIssue;
  updateIssue: (data: ManageIssue) => void;
}

const IssueDetailsForm = (props: IssueDetailsFormProps) => {
  const { issue, updateIssue } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [issueKinds, setIssueKinds] = useState<IssueKind[]>([]);

  const styled: SxProps = {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none !important',
    },
  };

  return (
    <>
      <Stack spacing={2}>
        <SelectComponent
          disableUnderline
          readOnly
          labelWidth={Sizes.drawerInputLabelWidth}
          label={t('fe.operations.nonCompliance.manage.category')}
          placeholder={t('global.selectOption')}
          value={issue?.issueKind?.type ?? ''}
          items={IssueCategorySelectItems()}
          required
        />
        <TextFieldComponent
          readOnly
          sx={styled}
          labelWidth={Sizes.drawerInputLabelWidth}
          label={t('fe.operations.nonCompliance.manage.type')}
          placeholder={t('global.selectOption')}
          value={t(issue?.issueKind?.translationKey ?? '')}
          required
        />
        <Stack direction="row" spacing={2}>
          <DatePickerComponent
            disabledUnderline
            labelWidth={130}
            disablePast
            readOnly
            label={t('fe.operations.nonCompliance.manage.dateIssue')}
            placeholder={t('global.selectDate')}
            value={issue?.reportedAt}
            required
          />
          <TimeTextFieldComponent
            value={issue?.dateHour}
            readOnly
            handleChange={(dateHour) => updateIssue({ dateHour: dateHour ?? undefined })}
            label={t('fe.operations.nonCompliance.manage.hourIssue')}
            labelWidth={Sizes.drawerInputLabelWidth}
            required
          />
        </Stack>
        <TextFieldComponent
          sx={styled}
          multiline
          readOnly
          minRows={5}
          labelWidth={Sizes.drawerInputLabelWidth}
          label={t('fe.operations.nonCompliance.manage.description')}
          placeholder={t('fe.operations.nonCompliance.manage.description')}
          value={issue?.description}
        />
      </Stack>
      <Divider variant="fullWidth" orientation="horizontal" />
      <TextFieldComponent
        readOnly
        sx={styled}
        labelWidth={Sizes.drawerInputLabelWidth}
        label={t('global.status')}
        placeholder={t('global.selectOption')}
        value={
          issue?.status
            ? IssueStatusSelectItems().find((i) => i.value === issue?.status)?.label ?? ''
            : ''
        }
        required
      />
      {issue?.status === IssueStatus.CLOSED && (
        <Stack spacing={2}>
          {issue.resolution ? (
            <TextFieldComponent
              sx={styled}
              multiline
              minRows={5}
              readOnly
              labelWidth={Sizes.drawerInputLabelWidth}
              label={t('fe.operations.nonCompliance.manage.resolution')}
              placeholder={t('fe.operations.nonCompliance.manage.resolution')}
              value={issue?.resolution}
            />
          ) : undefined}
          {issue?.resolver ? (
            <TextFieldComponent
              sx={styled}
              labelWidth={Sizes.drawerInputLabelWidth}
              label={t('global.resolvedBy')}
              value={`${issue?.resolver?.firstName} ${issue?.resolver?.lastName}`}
              readOnly
            />
          ) : undefined}
          {issue?.resolvedAt && (
            <Stack direction="row">
              <DatePickerComponent
                labelWidth={Sizes.datePickerWidth}
                disablePast
                label={t('fe.operations.nonCompliance.manage.closedAtIssue')}
                placeholder={t('global.selectDate')}
                value={new Date(issue?.resolvedAt)}
                readOnly
                required
              />
              <TimeTextFieldComponent
                value={issue.resolvedAt}
                label={t('fe.operations.nonCompliance.manage.hourIssue')}
                labelWidth={Sizes.drawerInputLabelWidth}
                readOnly
                required
              />
            </Stack>
          )}
        </Stack>
      )}
      <Divider variant="fullWidth" orientation="horizontal" />
    </>
  );
};

export default IssueDetailsForm;
