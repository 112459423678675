import { filtersStore, FiltersStore } from 'store/common/filters/filters.store';
import { SearchOptionDto } from 'api/dto/filters/SearchOptionDto';
import { metawasteApi } from 'api/Axios';
import { from } from 'rxjs';
import {
  initTableFilters,
  TableFilter,
  TableFilterType,
} from 'components/Select/SelectItem.interface';
import { SearchKindDto } from 'api/dto/filters/SearchKindDto';

export class FiltersService {
  constructor(private store: FiltersStore) {}

  fetchSupplierOrganisationUnits(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchSupplierOrganisationUnits(searchOptions));
  }

  fetchSuppliersOrganisation(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchSuppliersOrganisation(searchOptions));
  }

  fetchClientsOrganisation(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchClientsOrganisation(searchOptions));
  }

  fetchClientsOrganisationUnit(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchClientsOrganisationUnits(searchOptions));
  }

  fetchWastes(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchWastes(searchOptions));
  }

  fetchContainers(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchContainers(searchOptions));
  }

  fetchServiceKinds(searchOptions: SearchOptionDto) {
    return from(metawasteApi.getSearchServiceKinds(searchOptions));
  }

  fetchServiceKindsUnprotected(searchOptions: SearchKindDto) {
    return from(metawasteApi.getSearchServiceKindsUnprotected(searchOptions));
  }

  setCurrentFilters = (newFilters: TableFilter[]) =>
    this.store.update((state) => ({ ...state, currentFilters: newFilters }));

  reset() {
    this.store.update((state) => ({
      ...state,
      currentFilters: initTableFilters([
        TableFilterType.CLIENT_ORGANISATION,
        TableFilterType.CLIENT_ORGANISATION_UNIT,
        TableFilterType.SUPPLIER_ORGANISATION,
        TableFilterType.SUPPLIER_ORGANISATION_UNIT,
        TableFilterType.SERVICE_KIND,
        TableFilterType.WASTE,
        TableFilterType.CONTAINER,
      ]),
    }));
  }
}

export const filtersService = new FiltersService(filtersStore);
