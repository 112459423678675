import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Close } from 'assets/icons';
import { StepMerged } from 'react-joyride';
import i18n from 'utils/data/i18n.utils';

const CustomTooltip = ({ step, backProps, primaryProps, skipProps, tooltipProps, isLastStep }) => {
  const hideButton = step.hideCloseButton;

  return (
    <Stack {...tooltipProps} style={{ background: 'white', padding: '10px', borderRadius: '10px' }}>
      {!hideButton && (
        <Stack width="100%" alignItems="end">
          <IconButton style={{ width: 'fit-content' }} {...skipProps}>
            <Close width={15} height={15} />
          </IconButton>
        </Stack>
      )}
      <Stack spacing={2} alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px" fontWeight={900}>
            {step.title}
          </Typography>
        </Stack>
        <Stack maxWidth="90%">{step.content}</Stack>
      </Stack>
      {!hideButton && (
        <Stack
          direction="row"
          justifyContent={(step as StepMerged).disableBeacon ? 'end' : 'space-between'}
          mt="15px"
        >
          {!(step as StepMerged).disableBeacon && (
            <Button {...backProps}>
              <Typography fontSize="12px">{i18n.t('fe.joyride.back')}</Typography>
            </Button>
          )}
          <Button {...primaryProps} variant="contained">
            <Typography fontSize="14px">
              {isLastStep ? i18n.t('fe.joyride.finish') : i18n.t('fe.joyride.next')}
            </Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomTooltip;
