import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'constants/Colors';

export const useMaterialDropdownBaseStyles = makeStyles((theme: Theme) => ({
  placeholder: {
    '&::placeholder': {
      color: `${Colors.textWithOpacity} !important`,
      fontWeight: '500 !important',
      opacity: '0.5 !important',
    },
    color: theme.palette.text.primary,
    fontWeight: 400,
    opacity: 0.5,
  },
  imageIcon: {
    height: '100%',
    width: '100%',
  },
  iconRoot: {
    textAlign: 'center',
  },
}));
