import React, { FC } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import style from './CardBadges.style';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { isMobile } from 'utils/isMobile';

interface CardBadgesProps extends WithStyles<typeof style> {
  title: string;
  data: {
    icon?: React.ReactNode;
    description?: string;
  }[];
}

const CardBadges: FC<CardBadgesProps> = (props) => {
  const { classes, title, data = [] } = props;
  const isMobiles = isMobile();

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Typography
          color="text.primary"
          variant="subtitle2"
          fontSize={isMobiles ? 12 : 13}
          className={classes.title}
        >
          {title}
        </Typography>
        <Grid item>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            {data.map((data) => (
              <Grid key={data.description} item>
                <div className={classes.icon}>{data.icon}</div>
                <Typography
                  color="secondary.dark"
                  variant="subtitle2"
                  className={classes.description}
                >
                  {data.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(CardBadges);
