import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import RankingLittleCard from '../Card/CardRanking/LittleCard/RankingLittleCard';
import CardOperations from '../Card/CardOperations/CardOperations';
import CardStatistic from '../Card/CardStatistic/CardStatistic';
import RankingTrophyCard from '../Card/CardRanking/TrophyCard/RankingTrophyCard';
import { ReactComponent as TrophyActive } from 'assets/icons/TrophyActive.svg';
import CardBadges from '../Card/CardBadges/CardBadges';
import AddIcon from '@mui/icons-material/Add';
import Sidebar from '../Sidebar';
import { ReactComponent as DashboardOutlined } from 'assets/icons/sidebarDashboardOutlined.svg';
import { ReactComponent as DocumentsOutlined } from 'assets/icons/sidebarDocumentsOutlined.svg';
import { ReactComponent as FactureOutlined } from 'assets/icons/sidebarFactureOutlined.svg';
import { ReactComponent as OperationsOutlined } from 'assets/icons/sidebarOperationsOutlined.svg';
import { ReactComponent as RapportsOutlined } from 'assets/icons/sidebarRapportsOutlined.svg';
import { ReactComponent as DashboardFilled } from 'assets/icons/sidebarDashboardFilled.svg';
import { ReactComponent as DocumentsFilled } from 'assets/icons/sidebarDocumentsFilled.svg';
import { ReactComponent as FactureFilled } from 'assets/icons/sidebarFactureFilled.svg';
import { ReactComponent as OperationsFilled } from 'assets/icons/sidebarOperationsFilled.svg';
import { ReactComponent as RapportsFilled } from 'assets/icons/sidebarRapportsFilled.svg';
import { Colors, Sizes } from 'style/materialStylings';
import MaterialBasicTextfield from '../MaterialTextfield/MaterialBasicTextfield';
import MaterialAutocomplete from '../MaterialAutocomplete';
import StepButtons from '../StepButtons';
import MaterialTimeMultipleTextfield from '../MaterialTimeTextfield/MaterialTimeMultipleTextfield';
import MaterialTimeSingleTextfield from '../MaterialTimeTextfield/MaterialTimeSingleTextfield';
import MaterialDropdownForm from '../MaterialDropdown/MaterialDropdownForm';
import MaterialDropdownDashboard from '../MaterialDropdown/MaterialDropdownDashboard';
import MaterialMultiDropdownDashboard from '../MaterialMultiDropdown/MaterialMultiDropdownDashboard';
import MaterialDatePickerButton from '../MaterialDatePicker/MaterialDatePickerButton';
import PhotoDropdown from '../NonConformity/PhotoDropdown';
import NonConformityItem from '../NonConformity/NonConformityItem';
import CommentRow from '../CommentRow';
import { usePopup } from '../Modal/Popup/PopupService';
import FileItem from 'components/FileItem';
import SplitBarStat from '../SplitBar/SplitBarStat';
import FilterTabs from '../Tabs/FilterTabs';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import MaterialTextfieldBase from '../MaterialTextfield/MaterialTextfieldBase';
import MaterialLoginTextfield from '../MaterialTextfield/MaterialLoginTextfield';
import RowActivities from '../RowActivities/RowActivities';
import { ReactComponent as RecyclingTruck } from 'assets/icons/truckPickup.svg';
import RankingTooltipInfo from '../RankingTooltipInfo/RankingTooltipInfo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SelectLang from 'components/Login/SelectLang';
import AppBarDropdown from '../AppBar/AppBarDropdown';
import MaterialMultipleDatePicker from 'components/MaterialDatePicker/MaterialMultipleDatePicker';
// eslint-disable-next-line no-restricted-imports
import MaterialSingleDatePicker from '../MaterialDatePicker/MaterialSingleDatePicker';
import MaterialMultiDropdownTag from '../MaterialMultiDropdown/MaterialMultiDropdownTag';
import CollectScreen from 'screens/auth/modals/executionModal/ExecutionModal.screen';
import { AutocompleteData } from 'utils/models/autocomplete-data';

const autocompleteData = [
  {
    id: '61e96630d988a1d63d493c3d',
    title: 'do voluptate',
    description: 'est nulla laboris',
  },
  {
    id: '61e966309e4f93239f83ae15',
    title: 'exercitation laborum',
    description: 'velit qui qui',
  },
  {
    id: '61e96630f60756297effa96e',
    title: 'qui dolor',
    description: 'eu sint quis',
  },
  {
    id: '61e96630fc558e8448518399',
    title: 'esse sint',
    description: 'voluptate cupidatat cupidatat',
  },
  {
    id: '61e96630fe58a64f5f3a64c9',
    title: 'est exercitation',
    description: 'esse non elit',
  },
  {
    id: '61e9663073642ea15406bc83',
    title: 'ad fugiat',
    description: 'aute minim esse',
  },
];

const DemoPage: FC = () => {
  const [dropdownDashboard, setDropdownDashboard] = useState('week');
  const [dropdown, setDropdown] = useState('');
  const [open, setOpen] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState<string[]>([]);
  const [startDateButton, setStartDateButton] = useState<Date | undefined>(new Date());
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentFilterTab, setCurrentFilterTab] = useState<number>(0);
  const [sidebarValue, setSidebarValue] = useState(0);
  const [textfieldValue, setTextfieldValue] = useState('');
  const [currentAutocomplete, setCurrentAutocomplete] = useState('');
  const [autocompletePredictions, setAutocompletePredictions] = useState<AutocompleteData[]>(
    autocompleteData
  );
  const [timeFrom, setTimeFrom] = React.useState<Date | null>(null);
  const [timeTo, setTimeTo] = React.useState<Date | null>(null);
  const [stepButtonsValue, setStepButtonsValue] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const popConfirm = usePopup();
  const [languageValue, setLanguageValue] = useState('FR');

  useEffect(() => {
    if (autocompleteData.some((it) => it.title === currentAutocomplete)) {
      setAutocompletePredictions(autocompleteData);
    } else {
      setAutocompletePredictions(
        autocompleteData.filter((item) => item.title?.includes(currentAutocomplete) === true)
      );
    }
  }, [currentAutocomplete]);

  // eslint-disable-next-line react/no-multi-comp
  const Cards = () => (
    <Grid item xs={12}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item>
          <RankingLittleCard
            title="Taux de recyclage"
            background="info.dark"
            firstDataStatistic={15}
            secondDataStatistic={-23}
            firstDescription="Que la 26 ème entreprise"
            secondDescription="Que la 1 ère entreprise"
          />
        </Grid>
        <Grid item>
          <CardOperations
            data="102"
            dataStatistic={57}
            background="secondary.light"
            description="Total des collectes effectuées"
          />
        </Grid>
        <Grid item>
          <CardOperations
            data="60"
            dataStatistic={-37}
            background="secondary.light"
            description="Moyenne de collecte effectuées par jour"
          />
        </Grid>
        <Grid item>
          <CardOperations
            data="48"
            dataStatistic={37}
            background="error.light"
            percentage="/300"
            percentageSubTitle="50%"
            description="des collectes effectuées ont des non-conformités"
          />
        </Grid>
        <Grid item>
          <CardOperations
            data="36"
            dataStatistic={-5}
            background="error.light"
            percentage="%"
            description="sont dû à un contenant deffectueux"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  // eslint-disable-next-line react/no-multi-comp
  const Cards2 = () => (
    <Grid item xs={12}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item>
          <CardStatistic
            title="Finances"
            data="75 650"
            devise="CHF"
            description="Dépenses total des collectes sur la période"
            dataStatistic={0}
            handleClick={() => undefined}
            value={0}
          />
        </Grid>
        <Grid item>
          <CardStatistic
            data="150"
            devise="Tickets"
            description="Nombre de tickets crée sur la période"
            dataStatistic={24}
            handleClick={() => undefined}
          />
        </Grid>
        <Grid item>
          <CardStatistic
            title="Impact écologique"
            data="258"
            devise="tCO2"
            description="Impact écologique sur la période"
            dataStatistic={-24}
            handleClick={() => undefined}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  // eslint-disable-next-line react/no-multi-comp
  const Cards3 = () => (
    <Grid item xs={12}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item>
          <RankingTrophyCard
            description="20 fois gagnant du meilleur recycleur"
            icon={<TrophyActive />}
          />
        </Grid>
        <Grid item>
          {/* <CardColored
            data="5 000"
            devise="CHF"
            dataStatistic={37}
            background="blueColored.main"
            title="Retrocession"
            subTitle="dolor sit amet, consectetur adipiscing elit."
          /> */}
        </Grid>
        <Grid item>
          {/* <CardColored
            data="2 500"
            devise="CHF"
            dataStatistic={-37}
            background="greenColored.main"
            title="Dépenses moyenne par collecte"
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );

  // eslint-disable-next-line react/no-multi-comp
  const Cards4 = () => (
    <Grid item xs={12}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <CardBadges
            title="Trophés"
            data={[
              { icon: <TrophyActive />, description: 'Ponctualité' },
              { icon: <TrophyActive />, description: 'Réactivité' },
              { icon: <TrophyActive />, description: 'Qualité' },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  // eslint-disable-next-line react/no-multi-comp
  const Buttons = () => (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item>
        <Button variant="contained" size="large" startIcon={<AddIcon />}>
          Success Snack
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" size="large" startIcon={<AddIcon />} disabled={true}>
          Button
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" size="medium" startIcon={<AddIcon />}>
          Error Snack
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" size="medium" startIcon={<AddIcon />} disabled={true}>
          Button
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="large" startIcon={<AddIcon />}>
          Info Snack
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="large" startIcon={<AddIcon />} disabled={true}>
          Button
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          size="medium"
          startIcon={<AddIcon />}
          onClick={() =>
            popConfirm({
              title: 'Facture générée avec succès',
              description:
                'La facture que vous avez générée sera validée par les administrateurs de MetaWaste, puis envoyée à votre client.',
              buttons: [
                {
                  label: 'cancel',
                  color: 'error',
                  onClick: () => undefined,
                },
                {
                  label: 'OK',
                  color: 'primary',
                  onClick: () => undefined,
                },
              ],
            })
          }
        >
          Popup test
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="medium" startIcon={<AddIcon />} disabled={true}>
          Button
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          ButtonTest
        </Button>
        {open && <CollectScreen />}
      </Grid>
      <Grid item>
        <Button variant="outlined" size="small" startIcon={<AddIcon />}>
          Button
        </Button>
      </Grid>
      <Grid item>
        <MaterialDatePickerButton
          value={startDateButton ? new Date(startDateButton) : undefined}
          handleChange={setStartDateButton}
          minDate={new Date()}
        />
      </Grid>
      <Grid item>
        <Button variant="text">Button</Button>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Sidebar
        value={sidebarValue}
        handleChange={setSidebarValue}
        tabs={[
          { title: 'Dashboard', icon: <DashboardOutlined />, iconSelected: <DashboardFilled /> },
          { title: 'Documents', icon: <DocumentsOutlined />, iconSelected: <DocumentsFilled /> },
          { title: 'Factures', icon: <FactureOutlined />, iconSelected: <FactureFilled /> },
          { title: 'Operations', icon: <OperationsOutlined />, iconSelected: <OperationsFilled /> },
          { title: 'Rapports', icon: <RapportsOutlined />, iconSelected: <RapportsFilled /> },
        ]}
      />
      <main
        style={{
          backgroundColor: Colors.background,
          marginLeft: Sizes.drawerWidth,
        }}
      >
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12}>
            Demo
            <Typography variant="h1">h1</Typography>
            <Typography variant="h2">h2</Typography>
            <Typography variant="h3">h3</Typography>
            <Typography variant="h4">h4</Typography>
            <Typography variant="h5">h5</Typography>
            <Typography variant="h6">h6</Typography>
            <Typography variant="subtitle1">subtitle1</Typography>
            <Typography variant="subtitle2">subtitle2</Typography>
            <Typography>Body</Typography>
          </Grid>
          <Grid item xs={3}>
            <MaterialDropdownDashboard
              required
              // error="Field required"
              color="secondary"
              handleChange={(value) => setDropdownDashboard(value)}
              value={dropdownDashboard}
              items={[
                { label: 'Semaine', value: 'week' },
                { label: 'Mois', value: 'month' },
                { label: 'Année', value: 'year' },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <MaterialMultiDropdownDashboard
              placeholder="Tous les déchets"
              required
              // error="Field required"
              color="secondary"
              handleChange={(value) => setMultiDropdown(value)}
              selectedValues={multiDropdown}
              items={[
                { label: 'Value 1', value: '1' },
                { label: 'Value 2', value: '2' },
                { label: 'Value 3', value: '3' },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            {/*<BasicTab tabs={['Test1', 'Test2']} value={currentTab} handleChange={setCurrentTab} />*/}
            {/*<BasicTab*/}
            {/*  tabs={['Test1', 'Test2']}*/}
            {/*  value={currentTab}*/}
            {/*  handleChange={setCurrentTab}*/}
            {/*  size="small"*/}
            {/*/>*/}
          </Grid>
          <Grid item xs={12} style={{ margin: 10 }}>
            <MaterialTextfieldBase
              type="search"
              startIcon={<Search htmlColor={Colors.darkGray} />}
              placeholder="Rechercher un id de ticket, un site..."
              color="secondary"
              value={textfieldValue}
              handleChange={setTextfieldValue}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: 10 }}>
            <FilterTabs
              tabs={[
                'TOUS (167)',
                'EN ATTENTE (101)',
                'PLANIFIÉ (13)',
                'EN COURS (2)',
                'SUSPENDU (38)',
                'TERMINÉ (12)',
                'REJETÉ (1)',
              ]}
              value={currentFilterTab}
              handleChange={setCurrentFilterTab}
            />
          </Grid>
          <Grid item xs={8} style={{ margin: 10, backgroundColor: '#fff', padding: 10 }}>
            <MaterialLoginTextfield
              label="Email"
              type="email"
              // startIcon={<AlternateEmail color="primary" fontSize="small" />}
              placeholder="Enter email"
              color="primary"
              value={textfieldValue}
              handleChange={setTextfieldValue}
            />
            <MaterialLoginTextfield
              label="Email"
              type="email"
              error=" "
              // startIcon={<AlternateEmail color="primary" fontSize="small" />}
              placeholder="Enter email"
              color="primary"
              value={textfieldValue}
              handleChange={setTextfieldValue}
            />
            <MaterialLoginTextfield
              label="Password"
              type={showPassword ? 'text' : 'password'}
              endIcon={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <VisibilityOff color="secondary" fontSize="small" />
                  ) : (
                    <Visibility color="secondary" fontSize="small" />
                  )}
                </IconButton>
              }
              placeholder="Enter password"
              color="primary"
              value={textfieldValue}
              handleChange={setTextfieldValue}
            />
            <RankingTooltipInfo
              icon={
                <span>
                  Tooltip <ErrorOutlineIcon color="warning" />
                </span>
              }
            />
            <RowActivities
              title="No 157854"
              description="Entre 12:00 et 14:00"
              icon={<RecyclingTruck />}
              issue={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: '50px' }}>
            <SplitBarStat
              data={[
                { name: 'Hygiène', value: 10, color: '#51A2ED' },
                { name: 'Acier', value: 3, color: '#848F9D' },
                { name: 'Carton', value: 48, color: '#FFAB60' },
                { name: 'Verre', value: 40, color: '#51C973' },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Buttons />
          </Grid>
          <Cards />
          <Cards2 />
          <Cards3 />
          <Cards4 />
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: '#F4F6F8',
              border: '1rem solid #fff',
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={11}>
                <MaterialBasicTextfield
                  label="Email"
                  type="email"
                  // startIcon={<AlternateEmail color="primary" fontSize="small" />}
                  required
                  placeholder="Enter email"
                  color="secondary"
                  value={textfieldValue}
                  handleChange={setTextfieldValue}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialBasicTextfield
                  label="Description"
                  // startIcon={<AlternateEmail color="primary" fontSize="small" />}
                  required
                  multiline
                  rows={4}
                  placeholder="Écrivez ici"
                  color="secondary"
                  value={textfieldValue}
                  handleChange={setTextfieldValue}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialDropdownForm
                  label="Test label"
                  required
                  // error="Field required"
                  color="secondary"
                  placeholder="Test placeholder"
                  handleChange={(value) => setDropdown(value)}
                  value={dropdown}
                  items={[
                    { label: 'Value 1', value: '1' },
                    { label: 'Value 2', value: '2' },
                    { label: 'Value 3', value: '3' },
                  ]}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialAutocomplete
                  label="Type de dechet"
                  color="secondary"
                  handleChange={(value) => setCurrentAutocomplete(value)}
                  // error={errors.currentEmail}
                  value={currentAutocomplete}
                  data={autocompletePredictions}
                  placeholder="Entrez votre type de déchet"
                />
              </Grid>
              <Grid item xs={11}>
                <AppBarDropdown
                  label="Type de dechet 2"
                  color="secondary"
                  handleChange={(value) => setCurrentAutocomplete(value?.toString() ?? '')}
                  // error={errors.currentEmail}
                  // value={currentAutocomplete}
                  data={autocompletePredictions}
                  placeholder="Entrez votre type de déchet"
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialMultipleDatePicker
                  label="Start date"
                  labelFrom="Du"
                  labelTo="à"
                  valueFrom={startDate ? new Date(startDate) : undefined}
                  valueTo={endDate ? new Date(endDate) : undefined}
                  handleChangeFrom={setStartDate}
                  handleChangeTo={setEndDate}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialSingleDatePicker
                  label="Start date"
                  value={startDate ? new Date(startDate) : undefined}
                  handleChange={setStartDate}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialTimeMultipleTextfield
                  label="Trajet"
                  labelFrom="De"
                  labelTo="à"
                  required
                  valueFrom={timeFrom}
                  valueTo={timeTo}
                  handleChangeFrom={setTimeFrom}
                  handleChangeTo={setTimeTo}
                />
              </Grid>
              <Grid item xs={11}>
                <MaterialTimeSingleTextfield
                  label="Heure de signalement"
                  color="secondary"
                  required
                  value={timeFrom}
                  handleChange={setTimeFrom}
                />
              </Grid>
              <Grid item xs={11}>
                <StepButtons
                  required
                  label="Nombre de contenants"
                  value={stepButtonsValue}
                  handleChange={setStepButtonsValue}
                />
              </Grid>
              <Grid item xs={11}>
                <PhotoDropdown onNewImage={() => undefined} />
              </Grid>
              <Grid item xs={11}>
                <NonConformityItem title="ISSU-DE-AV7" subtitle="Crée par Morgan C le 15/10/21." />
              </Grid>
              <Grid item xs={11}>
                <CommentRow
                  name="Morgan Freeman"
                  comment="Lorem ipsum dolor sit amet"
                  date={`${new Date().toLocaleDateString()}`}
                  onLeft={true}
                />
                <CommentRow
                  name="Morgan Freeman"
                  comment="Lorem ipsum dolor sit amet"
                  date={`${new Date().toLocaleDateString()}`}
                  onLeft={false}
                />
              </Grid>
              <Grid item xs={3}>
                <FileItem handleClose={() => undefined} title="Suivi 1.pdf" size="158ko" />
              </Grid>
              <Grid item xs={11}>
                <MaterialMultiDropdownTag
                  label="Test label"
                  required
                  color="primary"
                  placeholder="Test placeholder"
                  handleChange={(value) => setMultiDropdown(value)}
                  selectedValues={multiDropdown}
                  items={[
                    { label: 'Valuedsauhdhashdasu 1', value: '1' },
                    { label: 'Valuedsaidsjiidsaisadsai 2', value: '2' },
                    { label: 'Valdsadskadosasaddsaijdasue 3', value: '3' },
                    { label: 'Value 1', value: '4' },
                    { label: 'Value 2', value: '5' },
                    { label: 'Value 3', value: '6' },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} style={{ backgroundColor: '#fff' }}>
            {/* <FullCalendar events={myEventList} /> */}
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <SelectLang
                languages={[
                  { label: 'Anglais', value: 'EN' },
                  { label: 'Français', value: 'FR' },
                  { label: 'Allemand', value: 'DE' },
                  { label: 'Espagnol', value: 'ES' },
                ]}
                handleClick={setLanguageValue}
                value={languageValue}
              />
            </Grid>
            <Grid container sx={{ margin: '20px' }}>
              <Grid item>{/*<MonthCalendar />*/}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default DemoPage;
