import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExecutionIssueKind } from './execution-issue-kind.model';

export type ExecutionIssueKindsState = EntityState<ExecutionIssueKind>;

@StoreConfig({
  name: 'executionIssueKinds',
})
export class ExecutionIssueKindsStore extends EntityStore<ExecutionIssueKindsState> {
  constructor() {
    super();
  }
}

export const executionIssueKindsStore = new ExecutionIssueKindsStore();
