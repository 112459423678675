import React, { FC } from 'react';
import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import style from './CardOperations.style';
import StatisticTrendData from '../TrendData/StatisticTrendData';
import { ReactComponent as Foyer } from 'assets/icons/foyer.svg';
import { ReactComponent as Tree } from 'assets/icons/tree.svg';
import { isMobile } from 'utils/isMobile';
import { NoResultComponent } from 'screens/auth/dashboard/common/components/NoResult';

interface CardOperationsProps extends WithStyles<typeof style> {
  background?:
    | 'error.light'
    | 'secondary.light'
    | 'blueColored.main'
    | 'greenColored.main'
    | 'white'
    | 'ecoGreen.main'
    | 'ecoGray.main';
  title?: string;
  data?: string;
  ecoData?: {
    tree?: string;
    foyer?: string;
  }[];
  dataStatistic?: number;
  description?: string;
  percentage?: string;
  percentageSubTitle?: string;
  isLoading?: boolean;
  inverse?: boolean;
  height?: number;
}

const CardOperations: FC<CardOperationsProps> = (props) => {
  const isMobiles = isMobile();
  const {
    classes,
    background,
    title,
    data,
    ecoData = [],
    dataStatistic,
    description,
    percentage,
    percentageSubTitle,
    isLoading,
    inverse = false,
    height,
  } = props;

  if (data === '0' && !isLoading) {
    return (
      <Card sx={{ backgroundColor: background }} className={classes.cardContainer}>
        <CardContent>
          <Typography color="text.primary" variant="subtitle2" fontSize={isMobiles ? 12 : 13}>
            {title}
          </Typography>
          <NoResultComponent />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={{ backgroundColor: background }}
      className={classes.cardContainer}
      style={{ opacity: isLoading ? 0.5 : 1, height: height }}
    >
      {dataStatistic !== undefined && dataStatistic !== 0 && !isLoading && (
        <StatisticTrendData
          stat={dataStatistic}
          backgroundCard={background}
          icon={true}
          inverse={inverse}
          nameOfCard="operations"
        />
      )}
      <Typography color="text.primary" variant="subtitle2" fontSize={isMobiles ? 12 : 13}>
        {title}
      </Typography>
      <CardContent sx={{ height: 'auto' }}>
        {isLoading ? (
          <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
        ) : (
          <>
            <Typography className={classes.data}>
              {data}
              {percentage === '%' ? (
                percentage
              ) : (
                <span className={classes.subTitle}> {percentage}</span>
              )}
            </Typography>
            <Typography className={classes.subTitle}>
              <span className={classes.percentage}>{percentageSubTitle}</span> {description}
            </Typography>
            {ecoData.map((data) => (
              <div key={data.tree} className={classes.ecoDataContainer}>
                <div>
                  <Typography className={classes.TypographyEco}>
                    <Foyer className={classes.icon} />
                    {data.tree}
                  </Typography>
                </div>
                <div className={classes.iconSpace}>
                  <Typography className={classes.TypographyEco}>
                    <Tree className={classes.icon} />
                    {data.foyer}
                  </Typography>
                </div>
              </div>
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(CardOperations);
