import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExecutionCountStatusDto } from 'api/dto/execution/ExecutionCountStatusDto';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';

export interface OperationExecutionsState extends EntityState<ExecutionDto> {
  status: ExecutionCountStatusDto[];
  total: number;
}

const initialState: OperationExecutionsState = {
  status: [],
  total: 0,
};

@StoreConfig({
  name: 'operationExecutions',
  resettable: true,
})
export class OperationExecutionsStore extends EntityStore<OperationExecutionsState> {
  constructor() {
    super(initialState);
  }
}

export const operationExecutionsStore = new OperationExecutionsStore();
