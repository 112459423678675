import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSideModalStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    minHeight: '100% !important',
    maxHeight: '100% !important',
    margin: '0px !important',
    borderRadius: '0px !important',
    [theme.breakpoints.only('xs')]: {
      width: '100% !important',
      padding: '0px 10px !important',
    },
  },
  root: {
    margin: '0px !important',
  },
  container: {
    justifyContent: 'flex-end !important',
  },
  iconPosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
