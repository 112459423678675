import { Typography } from '@mui/material';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import React from 'react';
import { EventCalendar } from 'components/FullCalendar/TestData';
import { WithStyles, withStyles } from '@mui/styles';
import { style } from 'components/FullCalendar/FullCalendar.style';

interface WebCardProps extends WithStyles<typeof style> {
  eventInfo: EventCalendar;
}

const WebCard = (props: WebCardProps) => {
  const { classes, eventInfo } = props;
  return (
    <div style={{ padding: '5px' }}>
      <div className={classes.eventDayLayout}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center !important',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            color="secondary"
            variant="h6"
            fontSize={12}
            style={{
              textDecoration:
                eventInfo.extendedProps?.status === ExecutionStatusEnum.REJECTED
                  ? 'line-through'
                  : 'none',
            }}
          >
            {eventInfo.title}
          </Typography>
          {eventInfo.extendedProps?.hasIssue && (
            <WarningAmberRoundedIcon color="error" fontSize="small" />
          )}
        </div>
      </div>
      <Typography
        style={{
          color: eventInfo.extendedProps?.color,
          fontSize: 12,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          // width: '100px',
        }}
        sx={{
          whiteSpace: 'nowrap',
          textDecoration:
            eventInfo.extendedProps?.status === ExecutionStatusEnum.REJECTED
              ? 'line-through'
              : 'none',
        }}
      >
        {eventInfo?.extendedProps?.wasteName}
      </Typography>
    </div>
  );
};

export default withStyles(style)(WebCard);
