import React, { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { withStyles, WithStyles } from '@mui/styles';
import style from './PhotoDropdown.style';
import { useTranslation } from 'react-i18next';

interface PhotoDropdownProps extends WithStyles<typeof style> {
  onNewImage: (newImage: File) => void;
  url?: string;
}

const PhotoDropdown: FC<PhotoDropdownProps> = (props) => {
  const { classes, onNewImage, url } = props;
  const { t } = useTranslation();

  const inputFileRef = React.useRef<any | undefined>();

  return (
    <Box
      className={classes.boxContainer}
      style={{ backgroundImage: url ? `url('${url}')` : undefined }}
    >
      <div className={classes.divPosition}>
        <input
          className={classes.input}
          type="file"
          accept="image/*"
          ref={inputFileRef}
          onChange={(e) => onNewImage((e.target as any).files[0])}
        />
        <IconButton
          className={classes.iconButtonContainer}
          aria-label="upload picture"
          component="span"
          onClick={() => inputFileRef.current.click()}
        >
          <AddIcon className={classes.icon} />
        </IconButton>
        <Typography color="secondary.dark" className={classes.typography}>
          {t('fe.issueModal.pictureDropdown')}
        </Typography>
      </div>
    </Box>
  );
};

export default withStyles(style)(PhotoDropdown);
