import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { IconButton, TextField } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { Colors } from 'style/materialStylings';
import dayjs from 'utils/data/dayjs.utils';
import { useTranslation } from 'react-i18next';

interface MaterialDatePickerBaseProps {
  className?: string;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  color?: 'primary' | 'secondary' | 'edit-table';
  handleChange: (value: any | null) => void;
  disabled?: boolean;
  readOnly?: boolean;
  value?: Date | null;
  error?: string;
  minDate?: Date;
  withIcon?: boolean;
  customStyle?: React.CSSProperties;
}

const MaterialDatePickerBase = (props: MaterialDatePickerBaseProps) => {
  const {
    className,
    withIcon,
    color = 'primary',
    error,
    fullWidth = true,
    handleChange,
    label,
    disabled,
    readOnly,
    required,
    minDate,
    value = null,
    customStyle,
  } = props;
  const { t } = useTranslation();
  const [openPicker, setOpenPicker] = useState(false);

  const dateRangeColor = () => {
    if (value) {
      return dayjs(value).isValid() ? Colors.secondary : Colors.red;
    }
    return Colors.darkGray;
  };

  return (
    <DatePicker
      open={openPicker}
      onOpen={() => !readOnly && setOpenPicker(true)}
      onClose={() => setOpenPicker(false)}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      minDate={minDate}
      readOnly={readOnly}
      // cancelText={
      //   <Button variant="outlined" size="medium">
      //     {t('fe.cancel')}
      //   </Button>
      // }
      // okText={
      //   <Button variant="contained" size="large">
      //     {t('fe.ok')}
      //   </Button>
      // }
      renderInput={(params) => (
        <TextField
          {...params}
          className={className}
          variant="filled"
          hiddenLabel
          fullWidth={fullWidth}
          style={customStyle}
          color={color}
          InputProps={{
            ...params.InputProps,
            startAdornment: withIcon && (
              <IconButton
                onClick={() => setOpenPicker(true)}
                disabled={readOnly}
                style={{ padding: 0 }}
              >
                <DateRangeIcon fontSize="small" htmlColor={dateRangeColor()} />
              </IconButton>
            ),
            endAdornment: null,
            disableUnderline: true,
          }}
        />
      )}
      inputFormat="dd/MM/yyyy"
    />
  );
};

export default MaterialDatePickerBase;
