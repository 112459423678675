import { ID } from '@datorama/akita';
import { OrganisationUnit } from 'store/organisation/organisationUnits';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export interface OrganisationUserDto {
  id: ID;
  name: string;
  organisationUnits: OrganisationUnit[];
}

export const organisationUserToMaterialDropdownItem = (
  organisation: OrganisationUserDto
): MaterialDropdownItem => {
  return {
    value: organisation.id,
    label: organisation.name,
  };
};
