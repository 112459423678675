import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Colors, Sizes } from 'style/materialStylings';

export const usePageStyles = makeStyles((theme: Theme) => ({
  content: {
    backgroundColor: Colors.background,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    paddingTop: 80,
  },
  contentHideMenus: {
    backgroundColor: Colors.background,
    marginLeft: 0,
    paddingTop: 0,
  },
  contentDrawerOpen: {
    backgroundColor: Colors.background,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: Sizes.drawerWidth,
    paddingTop: 80,
  },
  contentBottomBar: {
    backgroundColor: Colors.background,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    marginTop: '80px',
  },
}));
