const errorsMessage = () => ({ NO_LINES_FOR_THIS_PERIOD: 'fe.errors.noLinesToExportForPeriod' });

export class ResponseError extends Error {
  data?: any;

  constructor(message: string, data: any = null) {
    if (Object.keys(errorsMessage()).includes(message)) {
      message = errorsMessage()[message];
    }
    super(message);
    this.data = data;
  }
}
