import batteries, { ReactComponent as batteriesSVG } from 'assets/icons/wasteTypes/batteries.svg';
import cans, { ReactComponent as cansSVG } from 'assets/icons/wasteTypes/cans.svg';
import defaultIcon, { ReactComponent as defaultIconSVG } from 'assets/icons/wasteTypes/default.svg';
import electronics, {
  ReactComponent as electronicsSVG,
} from 'assets/icons/wasteTypes/electronics.svg';
import glass, { ReactComponent as glassSVG } from 'assets/icons/wasteTypes/glass.svg';
import green, { ReactComponent as greenSVG } from 'assets/icons/wasteTypes/green.svg';
import industrialwaste, {
  ReactComponent as industrialwasteSVG,
} from 'assets/icons/wasteTypes/industrialwaste.svg';
import infectious, {
  ReactComponent as infectiousSVG,
} from 'assets/icons/wasteTypes/infectious.svg';
import liquids, { ReactComponent as liquidsSVG } from 'assets/icons/wasteTypes/liquids.svg';
import metals, { ReactComponent as metalsSVG } from 'assets/icons/wasteTypes/metals.svg';
import paint, { ReactComponent as paintSVG } from 'assets/icons/wasteTypes/paint.svg';
import paper, { ReactComponent as paperSVG } from 'assets/icons/wasteTypes/paper_cartboard.svg';
import printerpowder, {
  ReactComponent as printerpowderSVG,
} from 'assets/icons/wasteTypes/printerpowder.svg';
import tableware, { ReactComponent as tablewareSVG } from 'assets/icons/wasteTypes/tableware.svg';
import wood, { ReactComponent as woodSVG } from 'assets/icons/wasteTypes/wood.svg';
import xrays, { ReactComponent as xraysSVG } from 'assets/icons/wasteTypes/xrays.svg';
import kitchen, { ReactComponent as kitchenSVG } from 'assets/icons/wasteTypes/kitchen.svg';

export enum WasteTypeIconNames {
  batteries = 'BATTERIE',
  textiles = 'TEXTILES',
  services = 'SERVICES',
  transports = 'TRANSPORTS',
  industrialwaste = 'INERTES',
  electronics = 'ELECTRONIQUE',
  glass = 'VERRE',
  liquids = 'BIOCARBURANT',
  admin = 'FRAIS ADMINISTRATIF',
  infectious = 'DECHETS SPECIAUX',
  plastics = 'PLASTIQUES',
  traitement = 'TRAITEMENT',
  mixed = 'DECHETS MELANGES',
  metals = 'METAUX NON FERREUX',
  cans = 'METAUX FERREUX',
  carburants = 'CARBURANTS',
  paint = 'PEINTURE',
  paper_cartboard = 'PAPIERS CARTONS',
  green = 'ORGANIQUE',
  printerpowder = 'printerpowder',
  tableware = 'tableware',
  wood = 'BOIS',
  xrays = 'RAYONS-X',
  organics = 'ORGANIQUES',
}

export const wasteTypeIconSrc = {
  [WasteTypeIconNames.batteries]: batteries,
  [WasteTypeIconNames.textiles]: defaultIcon,
  [WasteTypeIconNames.services]: defaultIcon,
  [WasteTypeIconNames.transports]: defaultIcon,
  [WasteTypeIconNames.electronics]: electronics,
  [WasteTypeIconNames.glass]: glass,
  [WasteTypeIconNames.green]: green,
  [WasteTypeIconNames.admin]: defaultIcon,
  [WasteTypeIconNames.industrialwaste]: industrialwaste,
  [WasteTypeIconNames.infectious]: infectious,
  [WasteTypeIconNames.plastics]: industrialwaste,
  [WasteTypeIconNames.liquids]: liquids,
  [WasteTypeIconNames.mixed]: industrialwaste,
  [WasteTypeIconNames.cans]: cans,
  [WasteTypeIconNames.metals]: metals,
  [WasteTypeIconNames.carburants]: liquids,
  [WasteTypeIconNames.paint]: paint,
  [WasteTypeIconNames.paper_cartboard]: paper,
  [WasteTypeIconNames.traitement]: defaultIcon,
  [WasteTypeIconNames.printerpowder]: printerpowder,
  [WasteTypeIconNames.tableware]: tableware,
  [WasteTypeIconNames.wood]: wood,
  [WasteTypeIconNames.xrays]: xrays,
  [WasteTypeIconNames.organics]: kitchen,
};

export const wasteTypeIconSvg = {
  [WasteTypeIconNames.batteries]: batteriesSVG,
  [WasteTypeIconNames.textiles]: defaultIconSVG,
  [WasteTypeIconNames.services]: defaultIconSVG,
  [WasteTypeIconNames.transports]: defaultIconSVG,
  [WasteTypeIconNames.electronics]: electronicsSVG,
  [WasteTypeIconNames.glass]: glassSVG,
  [WasteTypeIconNames.green]: greenSVG,
  [WasteTypeIconNames.admin]: defaultIconSVG,
  [WasteTypeIconNames.industrialwaste]: industrialwasteSVG,
  [WasteTypeIconNames.infectious]: infectiousSVG,
  [WasteTypeIconNames.plastics]: industrialwasteSVG,
  [WasteTypeIconNames.liquids]: liquidsSVG,
  [WasteTypeIconNames.mixed]: industrialwasteSVG,
  [WasteTypeIconNames.cans]: cansSVG,
  [WasteTypeIconNames.metals]: metalsSVG,
  [WasteTypeIconNames.carburants]: liquidsSVG,
  [WasteTypeIconNames.paint]: paintSVG,
  [WasteTypeIconNames.paper_cartboard]: paperSVG,
  [WasteTypeIconNames.traitement]: defaultIconSVG,
  [WasteTypeIconNames.printerpowder]: printerpowderSVG,
  [WasteTypeIconNames.tableware]: tablewareSVG,
  [WasteTypeIconNames.wood]: woodSVG,
  [WasteTypeIconNames.xrays]: xraysSVG,
  [WasteTypeIconNames.organics]: kitchenSVG,
};

const availableIcons = new Set(Object.values(WasteTypeIconNames));

export const getWasteTypeIconOrDefault = (iconName: string) => {
  if (!iconName || !availableIcons.has(iconName as WasteTypeIconNames)) {
    return wasteTypeIconSrc[WasteTypeIconNames.services];
  }
  return wasteTypeIconSrc[iconName];
};
