import { IStorageService } from './StorageService.types';
import { LocalStorageKey, LocalStorageKeyJson, TJsonDataTypes } from 'constants/LocalStorageKey';
import { IStorageSetOptions } from 'utils/StorageService/StorageService.types';
import { isObject } from 'rxjs/internal-compatibility';

export class StorageService implements IStorageService {
  private storage: Storage;

  constructor(storage) {
    this.storage = storage;
  }

  /** Returns all keys in the storage */
  getKeys(): string[] {
    return Object.keys(this.storage);
  }

  /** Returns options passed to the key (ex. userId) */
  getKeyOptions(key: string): { [key: string]: string } {
    const options = key.split('/');
    const regex = /#(?<id>.+)\((?<value>.+)\)/g;
    return options.reduce((obj, option) => {
      const match = [...key.matchAll(regex)][0];
      const matchGroups = match?.groups;

      const id = matchGroups?.id ?? match?.[1];
      const value = matchGroups?.value ?? match?.[2];

      if (id && value) {
        return { ...obj, [id]: value };
      }
      return obj;
    }, {});
  }

  getItem(itemName: LocalStorageKey, options?: IStorageSetOptions): string | null {
    return this.storage.getItem(this.formatItemName(itemName, options));
  }

  getItemJson<Key extends LocalStorageKeyJson>(
    itemName: Key,
    options?: IStorageSetOptions
  ): TJsonDataTypes[Key] | null {
    const value = this.storage.getItem(this.formatItemName(itemName, options));
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  setItem(itemName: LocalStorageKey, value: string, options?: IStorageSetOptions): void {
    this.storage.setItem(this.formatItemName(itemName, options), value);
  }

  setItemJson<Key extends LocalStorageKeyJson>(
    itemName: Key,
    value: TJsonDataTypes[Key],
    options?: IStorageSetOptions
  ) {
    const formattedItemName = this.formatItemName(itemName, options);
    const currentValue = this.getItemJson(itemName);
    if (isObject(value)) {
      this.storage.setItem(formattedItemName, JSON.stringify({ ...currentValue, ...value }));
      return;
    }
    this.storage.setItem(formattedItemName, JSON.stringify(value));
  }

  removeItem(itemName: LocalStorageKey | LocalStorageKeyJson, options?: IStorageSetOptions): void {
    this.storage.removeItem(this.formatItemName(itemName, options));
  }

  removeKey(key: string): void {
    this.storage.removeItem(key);
  }

  private formatSingleOption(key: string, value: string | undefined) {
    if (!value) return '';
    return `/#${key}(${value})`;
  }

  /** Formats the key with provided options (ex. userId => my_storage_key/#userId(value) ) */
  private formatItemName(
    itemName: LocalStorageKey | LocalStorageKeyJson,
    options: IStorageSetOptions | undefined
  ): string {
    if (options) {
      const formattedOptions = Object.entries(options)
        .map(([key, value]) => this.formatSingleOption(key, value))
        .join('');
      return `${itemName}${formattedOptions}`;
    }
    return itemName;
  }
}

export const storageService = new StorageService(window.localStorage);
