import { WasteLightDto } from './WasteLightDto';
import { ContainerLightDto } from './ContainerLightDto';
import { ServiceKindLight } from 'store/operations/createExecution';

export enum ScheduleEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum WeekDayEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum NumeralDayEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
  LAST = 'LAST',
}

export interface ScheduleDto {
  id: number;
  name: string;
  frequency: ScheduleEnum;
  weeklyFrequencyNumber: number;
  monthlyFrequencyNumber: number;
  dailyFrequencyNumber: number;
  dailyEveryWeekDay: boolean;
  dayOfMonth: number;
  daysOfWeek: number[];
  weekDayForMonth: WeekDayEnum;
  numeralDayOfMonth: NumeralDayEnum;
  fromDate: Date;
  toDate: Date;
  theoreticalQuantity: number;
  numberOfOccurrencesAhead: number;
  startHour: number;
  endHour: number;
  startMinutes: number;
  endMinutes: number;
}

export interface ServiceLightDto {
  supplierOrganisationUnitName: string;
  executionCount: number;
  id: number;
  clientWasteName: string;
  waste: WasteLightDto;
  container: ContainerLightDto;
  kind: ServiceKindLight;
  supplierOrganisationName: string;
  pricePeriods: PricePeriodDto[];
  activeSchedule: ScheduleDto;
}

export interface PricePeriodDto {
  id: number;
  generatedBy: ScheduleGeneratorSourceEnum;
  fromDate: Date;
  toDate: Date;
  sellPrices: SellPriceDto[];
}

export interface SellPriceDto {
  id: number;
  unit: string;
  unitCostInCents: number;
  unitPriceInCents: number;
  minQuantity: number;
  maxQuantity: number;
  validFromDate: Date;
  validToDate: Date;
  currency: string;
}

export enum ScheduleGeneratorSourceEnum {
  METAWASTE = 'METAWASTE',
  DATAHUB = 'DATAHUB',
}
