import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

const classes = (theme: Theme) =>
  createStyles({
    tabsContainer: {
      minWidth: 0,
      borderLeft: 1,
      borderColor: 'divider',
      '& .MuiTabs-indicator': {
        height: '2px !important',
        colors: `${Colors.secondary} !important`,
        backgroundColor: `${Colors.secondary} !important`,
      },
      '& .MuiTabs-scroller.MuiTabs-fixed': {
        [theme.breakpoints.down('md')]: {
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      },
    },
    tab: {
      minHeight: '0px !important',
      minWidth: '8px !important',
      padding: '4px 0px !important',
      borderRadius: `0px !important`,
      color: `${Colors.secondary} !important`,
      margin: '0px 6px !important',
      fontWeight: `400 !important`,
      fontSize: '12px !important',
      '& > svg': {
        width: '18px !important',
        height: 'auto !important',
      },
      '&.MuiTab-root.Mui-selected': {
        color: `${Colors.secondary} !important`,
        backgroundColor: 'transparent',
        fontWeight: '700 !important',
        fontSize: '12px !important',
      },
      [theme.breakpoints.down('md')]: {
        flex: 'none !important',
        flexWrap: 'nowrap !important',
        padding: '4px 10px !important',
        whiteSpace: 'nowrap',
        overflow: 'auto',
      },
    },
  });

export default classes;
