import React from 'react';

import { useMaterialDatagridStyles } from './MaterialDatagrid.style';
import {
  DataGrid,
  GridColumns,
  GridFeatureMode,
  GridLinkOperator,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid';
import { CustomNoRowsOverlay } from './NoRowsOverlay';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { isMobile } from 'utils/isMobile';
import { ID } from '@datorama/akita';

interface MaterialDatagridProps {
  color?: 'primary';
  columns: GridColumns;
  disableRowSelection?: boolean;
  data: GridRowsProp;
  defaultSortField?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  rowHeight?: number;
  onRowSelected?: (ids: ID[]) => void;
  paginationMode?: GridFeatureMode;
  onPageChange?: (page: number) => void;
  page?: number;
  dataByPage?: number;
  rowSelected?: any;
  totalDataPage?: number;
  search?: string;
  loading?: boolean;
  autoHeight?: boolean;
  hideFooterPagination?: boolean;
}

const MaterialDatagrid = (props: MaterialDatagridProps) => {
  const {
    color,
    columns,
    data,
    defaultSortField,
    disableRowSelection = false,
    hideHeader,
    hideFooter,
    rowHeight,
    loading,
    onRowSelected,
    rowSelected,
    search,
    onPageChange,
    paginationMode,
    page,
    dataByPage,
    totalDataPage,
    autoHeight,
    hideFooterPagination,
  } = props;
  const classes = useMaterialDatagridStyles({ hideHeader });
  const isMobiles = isMobile();

  const sortModel: GridSortModel = defaultSortField
    ? [
        {
          field:
            defaultSortField ||
            columns.find((col) => col.sortable !== false)?.field ||
            columns[0].field,
          sort: 'asc',
        },
      ]
    : [];

  return (
    <div className={classes.container} color={color}>
      <DataGrid
        hideFooter={hideFooter || false}
        disableColumnMenu
        autoHeight={autoHeight}
        hideFooterPagination={hideFooterPagination}
        disableColumnFilter
        hideFooterSelectedRowCount
        isRowSelectable={() => !disableRowSelection}
        headerHeight={hideHeader || isMobiles ? 0 : 30}
        rowHeight={rowHeight ?? 64}
        rowsPerPageOptions={[10, 25, 50, 100]}
        classes={{
          // columnsContainer: hideHeader ? classes.hideHeaderContainer : "MuiDataGrid-columnsContainer",
          row: disableRowSelection ? 'MuiDataGrid-row' : classes.selectableRow,
        }}
        components={{
          // eslint-disable-next-line react/no-multi-comp
          NoRowsOverlay: () => <CustomNoRowsOverlay />,
          // eslint-disable-next-line react/no-multi-comp
          NoResultsOverlay: () => <CustomNoRowsOverlay />,
          // eslint-disable-next-line react/no-multi-comp
          ColumnSortedDescendingIcon: () => <ExpandLess />,
          // eslint-disable-next-line react/no-multi-comp
          ColumnSortedAscendingIcon: () => <ExpandMore />,
        }}
        pageSize={dataByPage}
        initialState={{
          sorting: { sortModel },
        }}
        page={page}
        rowCount={totalDataPage}
        loading={loading}
        paginationMode={paginationMode}
        onSelectionModelChange={onRowSelected}
        filterModel={{
          linkOperator: GridLinkOperator.Or,
          items: search
            ? columns
                .filter((col) => col.filterable !== false)
                .map((col) => ({
                  id: col.field,
                  columnField: col.field,
                  operatorValue: 'contains',
                  value: search,
                }))
            : [],
        }}
        selectionModel={
          rowSelected
            ? Object.prototype.toString.call(rowSelected) === '[object Array]'
              ? rowSelected
              : [rowSelected]
            : []
        }
        sortingOrder={['asc', 'desc']}
        onPageChange={onPageChange}
        pagination={true}
        rows={data}
        columns={columns}
      />
    </div>
  );
};

export default MaterialDatagrid;
