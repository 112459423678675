import { Grow, Paper } from '@mui/material';
import React, { forwardRef } from 'react';

function PaperComponent(paperProps, ref) {
  return (
    <Grow in>
      <Paper {...paperProps} ref={ref} />
    </Grow>
  );
}
export const PaperComponentForward = forwardRef(PaperComponent);
