import { AuditProposalSupplierDto } from 'api/dto/Audit/AuditProposalSupplierDto';
import { EntityStore, StoreConfig } from '@datorama/akita';

@StoreConfig({
  name: 'auditProposalSupplier',
  resettable: true,
})
export class AuditStore extends EntityStore {
  constructor() {
    super();
  }

  setAuditProposalSupplier = (value: AuditProposalSupplierDto[]) => {
    this.update((state) => ({
      ...state,
      auditProposalSupplier: value,
    }));
  };
}

export const auditStore = new AuditStore();
