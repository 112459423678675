import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';
import { UserReviewStatus } from './user-reviews.model';

export class UserReviewsService {
  checkUserReview = async () => {
    const [userReviews, e] = await resolve(metawasteApi.checkUserReview());
    if (!userReviews || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.checkUserReview'));
    }

    return userReviews;
  };

  postReview = async (status: UserReviewStatus, rating?: number) => {
    const [userReviews, e] = await resolve(metawasteApi.createUserReview(status, rating));
    if (!userReviews || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.postUserReview'));
    }
    return userReviews;
  };
}

export const userReviewsService = new UserReviewsService();
