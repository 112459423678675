import { AddressDto } from '../client/AddressDto';
import { OrganisationUnit } from 'store/organisation/organisationUnits';

export interface SupplierOrganisationUnitDto {
  id: number;
  name?: string;
  address: AddressDto;
}

export function formatSupplierOrgUnitDtoToOrganisationUnit({
  id,
  name,
  address,
}: SupplierOrganisationUnitDto): OrganisationUnit {
  return {
    id,
    name: name ?? '',
    address,
  };
}
