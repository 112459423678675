import { ID, QueryEntity } from '@datorama/akita';
import {
  ExecutionIssueState,
  ExecutionIssueStore,
  executionIssueStore,
} from './execution-issue.store';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import dayjs from 'utils/data/dayjs.utils';

export class ExecutionIssueQuery extends QueryEntity<ExecutionIssueState> {
  constructor(protected store: ExecutionIssueStore) {
    super(store);
  }

  getIssuesFromExecution$(executionId: ID) {
    return this.selectAll().pipe(
      map((issues) => issues.filter((issue) => issue.executionId === executionId))
    );
  }

  selectedIssue$ = combineLatest([this.selectAll(), this.selectActiveId()]).pipe(
    map(([issues, activeId]) => issues.find((issue) => issue.id === activeId))
  );

  isModalOpen$ = this.select((state) => state.isModalOpen);
  isSaving$ = this.select((state) => state.isSaving !== undefined);

  isButtonDisabled$ = combineLatest([this.selectedIssue$, this.isSaving$]).pipe(
    map(
      ([issue, isSaving]) =>
        isSaving ||
        issue === undefined ||
        issue.issueKindId === undefined ||
        issue.description === undefined ||
        issue.reportedAtDate === undefined ||
        !dayjs(issue.reportedAtDate).isValid() ||
        issue.reportedAtTime === undefined ||
        !dayjs(issue.reportedAtDate).isValid()
    )
  );
}

export const executionIssueQuery = new ExecutionIssueQuery(executionIssueStore);
