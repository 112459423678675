import { Typography } from '@mui/material';
import { GridActionsColDef, GridRowParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Colors } from 'constants/Colors';
import { ColumnProps } from '../columnTypes.table';

import { Array } from 'utils/extensions/array.extensions';

interface TextWithActionColumnProps extends ColumnProps {
  actionLabel: (row: any) => any;
  handleClick?: (row: any) => void;
  textButton?: boolean;
}

export const COLUMN_TYPE_TEXT_WITH_ACTION = (
  props: TextWithActionColumnProps
): GridActionsColDef => ({
  cellClassName: (params) =>
    Array.mapNotNull([props.color, props.cellClassName?.(params as any)])?.join(' ') ?? '',
  field: props.field ?? '',
  headerName: props.label,
  minWidth: props.minWidth ?? 50,
  sortable: props.sortable ?? false,
  align: props.textButton ? undefined : 'center',
  type: 'actions',
  getActions: (params: GridRowParams) => {
    return [
      <GridActionsCellItem
        key="action"
        style={{
          border: `1px solid ${Colors.primary}`,
          borderRadius: props.textButton ? 4 : undefined,
          height: 25,
          width: props.textButton ? 'auto' : 25,
        }}
        icon={
          <Typography fontSize="14px" color={props.color ? Colors[props.color] : undefined}>
            {props.actionLabel(params.row)}
          </Typography>
        }
        onClick={() => props.handleClick?.(params.row)}
        label=""
        showInMenu={false}
      />,
    ];
  },
  valueGetter: props?.valueGetter,
  valueFormatter: props.valueFormatter,
  width: props.width ?? 150,
});
