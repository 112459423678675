import { Card, CardContent, Collapse, Grid } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { createExecutionService, ExecutionDetailsUi } from 'store/operations/createExecution';
import { isMobile } from 'utils/isMobile';
import ExecutionCommentsForm from './forms/ExecutionComments.form';
import ExecutionDocumentsForm from './forms/ExecutionDocuments.form';
import ExecutionInfoForm from './forms/ExecutionInfo.form';
import ExecutionIssueForm from './forms/ExecutionIssue.form';
import ExecutionHeaderForm from './header/ExecutionHeader.form';

interface ExecutionDetailsComponentProps {
  execution: ExecutionDetailsUi;
}

const ExecutionDetailsComponent: FC<ExecutionDetailsComponentProps> = (props) => {
  const { execution } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = isMobile();
  const classes = useCreateStyles();

  const updateExecution = (newValue: ExecutionDetailsUi) => {
    createExecutionService.setExecutionDetail(newValue);
  };

  const updateExecutionField = (name: string) => (value: any) => {
    if (execution) {
      updateExecution({ ...execution, [name]: value });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
        padding: '20px 10px',
      }}
    >
      <CardContent sx={{ padding: '10px' }}>
        <Grid container item xs={12} rowSpacing={3}>
          <ExecutionHeaderForm
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            executionId={execution.id}
          />
          <Collapse in={isExpanded} style={{ width: '100%', paddingTop: 6 }}>
            <Grid container item xs={12} rowSpacing={3}>
              <ExecutionInfoForm
                executionId={execution.id}
                updateExecutionField={updateExecutionField}
              />
              {/*<ExecutionTransportForm />*/}
              <ExecutionCommentsForm
                execution={execution}
                updateExecutionField={updateExecutionField}
              />
              <ExecutionDocumentsForm execution={execution} />

              {execution.dto?.status && <ExecutionIssueForm execution={execution} />}
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExecutionDetailsComponent;
