import dayjs from 'dayjs';
import frLocale from 'dayjs/locale/fr';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const DATE_FORMAT_MONTH_ABBREVIATION_AND_YEAR_ABBREVIATION = 'MMM YY'; // AUG

dayjs.locale(frLocale);

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

export const globalWeekDaysShort = dayjs
  .weekdaysShort(false)
  .slice(1)
  .concat(dayjs.weekdaysShort(false)[0]);
export const globalWeekDays = dayjs.weekdays(false).slice(1).concat(dayjs.weekdays(false)[0]);

export const getMonthAbbrevWithYear = (date?: Date) => {
  return dayjs(date).format(DATE_FORMAT_MONTH_ABBREVIATION_AND_YEAR_ABBREVIATION);
};

export const combineDateAndHours = (selectedDate: Date, selectedHours: Date): Date => {
  const date = dayjs(selectedDate).format('DD/MM/YYYY');
  const hours = dayjs(selectedHours).format('HH:mm:ss');
  return dayjs(`${date} ${hours}`, 'DD/MM/YYYY HH:mm:ss').toDate();
};

export default dayjs;
