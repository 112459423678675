import { Colors } from 'constants/Colors';
import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

export const filtersTheme = {
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
      fullWidth: true,
    },
    styleOverrides: {
      root: (props) => ({
        backgroundColor: props.ownerState.color === 'primary' ? Colors.background : Colors.white,
        borderRadius: 6,
        width: '100%',
        '&.Mui-disabled': {
          backgroundColor: props.ownerState.color === 'primary' ? Colors.background : Colors.white,
          opacity: 0.5,
        },
        '&.Mui-focused': {
          backgroundColor: props.ownerState.color === 'primary' ? Colors.background : Colors.white,
        },
        '&[aria-label=clickable]': {
          cursor: 'pointer',
          '& .MuiFilledInput-input': {
            cursor: 'pointer',
          },
        },
      }),
      sizeSmall: {
        backgroundColor: Colors.white,
        borderRadius: 4,
        height: '30px !important',
        '&.Mui-focused': {
          backgroundColor: Colors.white,
        },
      },
      input: {
        color: Colors.text,
        fontSize: 16,
        fontWeight: 400,
        padding: '9px 14px',
        '&::placeholder': {
          color: Colors.textWithOpacity,
          opacity: 1,
        },
      },
      inputSizeSmall: {
        color: Colors.text + ' !important',
        fontSize: 14,
        fontWeight: 400,
        padding: '4px 10px',
        '&::placeholder': {
          color: Colors.text,
          opacity: 1,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.down('lg')]: {
          width: '100px',
        },
      },
    },
  },
};
