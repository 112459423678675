import React, { FC, useState } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { PeriodeStatsDTO } from 'store/dashboard/financeScreenStats/finance-screen-stats.model';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack } from '@mui/material';
import { OpUnitType } from 'dayjs';
import { useDebounce } from 'react-use';
import { getPeriodLabels } from 'utils/data/barchart.utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface TotalExpensesProps {
  isLoading?: boolean;
  dropDownDashboard: OpUnitType;
  currentData: PeriodeStatsDTO[];
  oldData: PeriodeStatsDTO[];
  currentDate: Date;
}

const TotalExpenses: FC<TotalExpensesProps> = (props) => {
  const { currentData, oldData, isLoading, dropDownDashboard, currentDate } = props;
  const { t } = useTranslation();

  const [labels, setLabels] = useState(getPeriodLabels(dropDownDashboard, currentDate));
  useDebounce(() => setLabels(getPeriodLabels(dropDownDashboard, currentDate)), 50, [
    t,
    dropDownDashboard,
  ]);

  if (isLoading) {
    return (
      <Stack style={{ height: '100%', width: '100% !important', margin: '30px 0px' }}>
        <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
      </Stack>
    );
  }

  return (
    <div style={{ height: '100%', width: '100% !important', margin: '30px 0px' }}>
      <Line
        height="100%"
        options={{
          interaction: {
            mode: 'index',
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('de-CH', {
                      style: 'currency',
                      currency: 'CHF',
                    }).format(context.parsed.y);
                  }
                  return label;
                },
              },
              displayColors: true,
              backgroundColor: 'white',
              bodyColor: 'black',
              titleColor: 'transparent',
              bodyFont: {
                size: 14,
                weight: 'bold',
              },
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          elements: {
            point: {
              radius: 0,
              hoverRadius: 5,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 10,
                },
              },
              stacked: true,
            },
            y: {
              type: 'linear',
              min: 0,
              beginAtZero: true,
              ticks: {
                font: {
                  size: 10,
                },
              },
              stacked: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: t('fe.dashboard.morgan.stats.finance.evolution.totalAmountNoVatInCents'),
              data: currentData.map((item) =>
                item.totalExpensesInCents ? item.totalExpensesInCents / 100 : 0
              ),
              borderColor: 'black',
              cubicInterpolationMode: 'monotone',
              pointHoverBorderColor: 'white',
              pointHoverBackgroundColor: 'black',
              pointHoverBorderWidth: 3,
              backgroundColor: 'black',
              stack: 'Stack 0',
            },
            {
              label: t('fe.dashboard.morgan.stats.finance.evolution.totalValorisation'),
              data: currentData.map((item) =>
                item.totalValorisationInCents ? item.totalValorisationInCents / 100 : 0
              ),
              borderColor: 'rgba(230, 67, 60)',
              cubicInterpolationMode: 'monotone',
              pointHoverBorderColor: 'white',
              pointHoverBackgroundColor: 'rgba(230, 67, 60)',
              pointHoverBorderWidth: 3,
              backgroundColor: 'rgba(230, 67, 60)',
              stack: 'Stack 0',
            },
          ],
        }}
      />
    </div>
  );
};

export default TotalExpenses;
