import { Search } from '@mui/icons-material';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import MaterialDatagrid from 'components/MaterialDatagrid';
import MaterialTextfieldBase from 'components/MaterialTextfield/MaterialTextfieldBase';
import SelectComponent from 'components/Select/Select.component';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { ContractFilterSelectItems, ContractFiltersEnum } from 'store/contracts/contracts.model';
import { Colors } from 'style/materialStylings';
import { useIsMobile } from 'utils/isMobile';
import { useContractStyles } from './Contracts.style';
import { contractsColumns } from './table.utils';
import { useHistory } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { organisationsQuery } from 'store/organisation/organisations';
import { useSnackbar } from 'notistack';
import { contractsService } from 'store/contracts/contracts.service';
import SnackError from 'utils/models/snackError';
import { contractsQuery } from 'store/contracts/contracts.query';
import { useDebounce } from 'react-use';
import env from 'utils/env';
import { TableFilterType } from 'components/Select/SelectItem.interface';
import TableFiltersComponent from 'components/Filters/Filters.component';
import { filtersQuery } from 'store/common/filters/filters.query';

const ContractsScreen: FC = () => {
  const classes = useContractStyles();
  const isMobiles = useIsMobile();
  const [textfieldValue, setTextfieldValue] = useState('');
  const [filterValue, setFilterValue] = useState<ContractFiltersEnum>(ContractFiltersEnum.ALL);
  const history = useHistory();
  const [organisationUnitIDs] = useObservable(organisationUnitsQuery.activeOrgUnitIds$);
  const [organisationId] = useObservable(organisationsQuery.activeOrganisationId$);
  const { enqueueSnackbar } = useSnackbar();
  const [contracts] = useObservable(contractsQuery.contracts$);
  const [search] = useObservable(contractsQuery.search$);
  const [total] = useObservable(contractsQuery.total$);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(env.PAGINATION);
  const [isLoading] = useObservable(contractsQuery.selectLoading());
  const [filters] = useObservable(filtersQuery.currentFilters$);

  useDebounce(
    () => {
      contractsService.setSearch(textfieldValue);
    },
    500,
    [textfieldValue]
  );

  useEffect(() => {
    contractsService.setStatus(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (organisationId) {
      contractsService
        .getContracts(organisationId, organisationUnitIDs, page, rowPerPage, filters)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    } else {
      contractsService.resetContracts();
    }
  }, [
    enqueueSnackbar,
    organisationId,
    organisationUnitIDs,
    search,
    page,
    rowPerPage,
    filterValue,
    filters,
  ]);

  return (
    <Stack direction="column" spacing={3} className={classes.container}>
      <Stack direction="column" flex={1} spacing={2}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Stack direction="column" height="100%" spacing={2} justifyContent="space-between">
            <Typography
              variant="h2"
              fontSize={isMobiles ? 23 : 25}
              ml={0}
              className={classes.title}
            >
              {t('fe.contracts.title')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SelectComponent
            size="small"
            disableUnderline
            color="secondary"
            inputWidth={300}
            value={filterValue}
            handleChange={(value) => setFilterValue(value)}
            items={ContractFilterSelectItems()}
          />
          <MaterialTextfieldBase
            type="search"
            startIcon={<Search htmlColor={Colors.darkGray} />}
            placeholder="Rechercher un contrat par nom ou référence..."
            color="secondary"
            value={textfieldValue}
            handleChange={setTextfieldValue}
          />
          <TableFiltersComponent
            filtersTypes={[TableFilterType.WASTE, TableFilterType.CONTAINER]}
          />
        </Stack>
        <Grid item xs={12} style={{ height: '80vh' }}>
          <Card className={classes.cardContainer}>
            <CardContent>
              <MaterialDatagrid
                data={contracts ?? []}
                paginationMode="server"
                dataByPage={rowPerPage}
                totalDataPage={total}
                loading={isLoading}
                page={page}
                onPageChange={setPage}
                columns={contractsColumns({
                  isDesktop: !isMobiles,
                  onClick: (value) => history.push('/contracts/' + value),
                })}
              />
            </CardContent>
          </Card>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ContractsScreen;
