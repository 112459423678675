import { Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import style from './FilterTabs.style';
import { WithStyles, withStyles } from '@mui/styles';

interface FilterTabsProps extends WithStyles<typeof style> {
  handleChange?: (value: number) => void;
  tabs?: string[];
  value?: number;
  fullWidth?: boolean;
}

const FilterTabs: FC<FilterTabsProps> = (props: FilterTabsProps) => {
  const { classes, handleChange, tabs, value, fullWidth = true } = props;

  return (
    <Tabs
      variant={fullWidth === true ? 'fullWidth' : 'scrollable'}
      value={value || 0}
      onChange={(evt, value) => handleChange?.(value)}
      scrollButtons={false}
      className={classes.tabsContainer}
    >
      {tabs?.map((tab, index) => (
        <Tab
          key={`Tab - ${tab}`}
          label={tab}
          value={index}
          disabled={!handleChange}
          className={classes.tab}
        />
      ))}
    </Tabs>
  );
};

export default withStyles(style)(FilterTabs);
