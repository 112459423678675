import { QueryEntity } from '@datorama/akita';
import {
  ExecutionMonthPlanningState,
  ExecutionMonthPlanningStore,
  executionMonthPlanningStore,
} from './execution-month-planning.store';
import { map } from 'rxjs/operators';
import { formatExecCountPerDayToEventCalendar } from 'api/dto/execution/ExecutionBetweenDatesDto';

export class ExecutionMonthPlanningQuery extends QueryEntity<ExecutionMonthPlanningState> {
  loading$ = this.select((state) => state.loading);
  planning$ = this.selectAll().pipe(
    map((executions) =>
      executions
        // .filter((execution) => execution.status !== ExecutionStatusEnum.REJECTED)
        .map(formatExecCountPerDayToEventCalendar)
    )
  );

  constructor(protected store: ExecutionMonthPlanningStore) {
    super(store);
  }
}

export const executionMonthPlanningQuery = new ExecutionMonthPlanningQuery(
  executionMonthPlanningStore
);
