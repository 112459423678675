import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDashboardScreenStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '10px 15px',
    height: '100%',
    [theme.breakpoints.only('xs')]: {
      padding: '10px 0px',
    },
  },
  switch: {
    top: 84,
    [theme.breakpoints.only('xs')]: {
      left: 10,
    },
  },
  sideModal: {
    paddingTop: '0px !important',
  },
  dashboardPosition: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100%',
  },
  dashboardSize: {
    padding: '30px 15px 0px 15px !important',
    width: '100%',
  },
  activitiesSize: {
    padding: '20px 0px 20px 0px !important',
  },
}));
