import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useInvoicesStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px 14px',
    marginTop: '5px !important',
    [theme.breakpoints.down('lg')]: {
      padding: '18px 14px 0px 14px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '18px 0px 0px',
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: '15px !important',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: '0px 5px !important',
    },
  },
  hideForMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
}));
