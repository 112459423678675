import React, { useMemo } from 'react';
import { FormHelperText, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { ArrowDown } from 'assets/icons/index';

export interface SelectItem {
  label: string;
  value: any;
  data?: any;
  backgroundColor?: string;
}

interface SelectComponentProps {
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  error?: string;
  handleChange?: (value: any) => void;
  inputWidth?: number;
  items?: SelectItem[];
  label?: string;
  labelWidth?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: 'small';
  value?: any;
  customRenderValue?: any;
  disableUnderline?: boolean;
}

const SelectComponent = (props: SelectComponentProps) => {
  const {
    color = 'primary',
    disabled,
    error,
    handleChange,
    inputWidth,
    items,
    label,
    labelWidth,
    placeholder,
    readOnly,
    required,
    size,
    value = '',
    customRenderValue,
    disableUnderline,
  } = props;

  const currentItem = useMemo(() => items?.find((it) => it.value === value), [items, value]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      {!!label && (
        <InputLabel
          error={!!error && !disabled}
          sx={{ width: labelWidth ? `${labelWidth}px` : undefined }}
        >
          {label + (required ? '*' : '')}
        </InputLabel>
      )}
      <Stack flex={!inputWidth ? 1 : undefined}>
        <Select
          SelectDisplayProps={{
            style: {
              cursor: readOnly ? 'default' : undefined,
              backgroundColor: currentItem?.backgroundColor,
            },
          }}
          IconComponent={!readOnly ? (params) => <ArrowDown {...params} /> : () => null}
          color={color}
          sx={{ flex: 1, width: inputWidth ? `${inputWidth}px` : undefined }}
          onChange={(evt) => handleChange?.(evt.target.value)}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          renderValue={customRenderValue}
          disableUnderline={disableUnderline}
          MenuProps={{
            'aria-label': size,
          }}
          size={size}
          value={value ?? ''}
        >
          {!readOnly && placeholder && <MenuItem value="">{placeholder}</MenuItem>}
          {items?.map((item) => (
            <MenuItem
              key={`${item.label} ${item.value}`}
              value={item.value}
              style={{ backgroundColor: item.backgroundColor }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {!!error && !disabled && (
          <FormHelperText error sx={{ mx: '14px' }}>
            {error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};

export default SelectComponent;
