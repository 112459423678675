import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    name: {
      marginBottom: '8px !important',
      fontSize: '12px !important',
    },
    date: {
      fontSize: '12px',
      color: theme.palette.secondary.dark,
    },
    commentContainer: {
      width: '100%',
      maxHeight: 'auto',
      marginBottom: 16,
    },
    commentMessage: {
      padding: '10px',
      fontSize: '16px',
    },
  });

export default classes;
