import { Query } from '@datorama/akita';
import {
  OperationScreenStats,
  OperationScreenStatsStore,
  operationScreenStatsStore,
} from './operation-screen-stats.store';

export class OperationScreenStatsQuery extends Query<OperationScreenStats> {
  constructor(protected store: OperationScreenStatsStore) {
    super(store);
  }

  isIssueCountLoading$ = this.select((stats) => stats.issueCount.isLoading);
  isIssueKindsLoading$ = this.select((stats) => stats.issueKindStats.isLoading);
  isAverageQuantityLoading$ = this.select((stats) => stats.wasteQuantity.isLoading);
  isPickUpDoneLoading$ = this.select((stats) => stats.pickUpDone.isLoading);
  getIssueCountStats$ = this.select((stats) => stats.issueCount.stats);
  getIssueKindsStats$ = this.select((stats) => stats.issueKindStats.stats);
  getWasteQuantityStats$ = this.select((stats) => stats.wasteQuantity);
  getPickUpDoneStats$ = this.select((stats) => stats.pickUpDone.stats);
  isOperationLoading$ = this.select((stats) => stats.operationEvolution.isLoading);
  getEvolutionOperationStats$ = this.select((stats) => stats.operationEvolution.stats);
}

export const operationScreenStatsQuery = new OperationScreenStatsQuery(operationScreenStatsStore);
