import { guid, ID } from '@datorama/akita';
import { WasteLightDto } from 'api/dto/services/WasteLightDto';
import { ContainerLightDto } from 'api/dto/services/ContainerLightDto';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { ServiceLightDto } from 'api/dto/services/ServiceLightDto';
import { ServiceKindLightDto } from 'api/dto/services/ServiceKindLightDto';
import { ExecutionCreationRuleEnum } from 'utils/enums/ExecutionCreationRuleEnum';
import { UserLightDto } from 'api/dto/user/UserLightDto';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import { BookmarkServiceDto } from '../bookmark';
import { ServiceKindTypeEnum } from 'utils/enums/ServiceKindTypeEnum';

export interface CreateExecutionInfoState {
  serviceKinds?: ServiceKindLight[];
  services?: Service[];
  // execution: ExecutionCreation;
  ui: CreateExecutionUIState;
}

export interface CreateExecutionOpenModalOptions {
  startRefuse?: boolean;
  dateSelected?: Date;
  hourSelected?: Date;
}

export interface CreateExecutionUIState {
  isSaving: boolean;
  isSavingMarkAsComplete: boolean;
  isAccepting: boolean;
  isDenying: boolean;
  isModalOpen: boolean;
  isEditModalOpen: boolean;
  highlightDates: boolean;
}

export interface ServiceKindLight {
  id: ID;
  type?: ServiceKindTypeEnum;
  code: string;
  kind?: string;
  kindTranslateKey?: string;
  execUnitName1?: string;
  execUnitName2?: string;
  isDisplay: boolean;
  executionCreationRule: ExecutionCreationRuleEnum;
}

export interface Service {
  id: ID;
  clientWasteName: string;
  waste: WasteLightDto;
  container: ContainerLightDto;
  kind: ServiceKindLightDto;
  supplierOrganisationName: string;
  supplierUnitName: string;
}

export interface HaulingDestination {
  haulingOrganisationUnitId: ID;
  haulingOrganisationUnitName: string;
  destinationOrganisationUnitId: ID;
  destinationOrganisationUnitName: string;
  availableServicesForHaulier: ServiceLightDto[];
}

export interface ExecutionDetailsUi {
  id: ID;
  createdAt?: Date;
  serviceKindId?: ID;
  wasteId?: ID;
  containerId?: ID;
  serviceId?: ID;
  selectedDate?: Date | null;
  plannedFrom: Date | null;
  plannedTo: Date | null;
  nbContainers: number;
  comments?: string[];
  haulingOrganisationUnitName?: string;
  destinationOrganisationUnitName?: string;
  haulingOrganisationId?: ID;
  destinationOrganisationId?: ID;
  haulingUnitId?: ID;
  destinationUnitId?: ID;
  availableServicesForHaulier?: ID[];
  parentExecutionId?: ID;
  justification?: string;
  authorName?: string;
  executedAt?: Date | null;
  wasteAmount?: number;
  asap?: boolean;
  dto?: ExecutionDto;
  status?: ExecutionStatusEnum;
}

export interface AvailableClientUnit {
  clientOrganisationUnitId: number;
  clientOrganisationUnitName: string;
  clientOrganisationId: number;
  clientOrganisationName: string;
}

export interface IdenticalExecution {
  id: ID;
  plannedFrom: Date;
  plannedTo: Date;
  name: string;
  status: ExecutionStatusEnum;
  createdBy: UserLightDto;
  isRecurrent: boolean;
}

export const initExecutionDetails = (
  plannedFrom: Date | null,
  plannedTo: Date | null,
  nbContainers: number,
  selectedDate?: Date
): ExecutionDetailsUi => ({
  id: guid(),
  plannedFrom,
  plannedTo,
  nbContainers,
  selectedDate: selectedDate ?? null,
  comments: [],
  asap: true,
});

export const initExecutionDetailsFromParent = (
  parentExecution: ExecutionDetailsUi
): ExecutionDetailsUi => ({
  id: guid(),
  serviceKindId: parentExecution.serviceKindId,
  selectedDate: parentExecution.selectedDate,
  plannedFrom: parentExecution.plannedFrom,
  plannedTo: parentExecution.plannedTo,
  nbContainers: 1,
  parentExecutionId: parentExecution.id,
  asap: parentExecution.asap,
});

export const initExecutionDetailsFromDto = (execution: ExecutionDto): ExecutionDetailsUi => ({
  id: execution.id,
  createdAt: execution.createdAt,
  serviceKindId: execution.service.kind.id,
  selectedDate: execution.plannedFrom,
  plannedFrom: execution.plannedFrom,
  plannedTo: execution.plannedTo,
  nbContainers: execution.quantity1,
  wasteId: execution.service.waste.id,
  containerId: execution.service.container.id,
  serviceId: execution.service.id,
  parentExecutionId:
    execution.id === execution.executionGroup?.mainExecutionId
      ? undefined
      : execution.executionGroup?.mainExecutionId,
  justification: execution.justification ? execution.justification : undefined,
  authorName: execution.author
    ? `${execution.author?.firstName} ${execution.author?.lastName.charAt(0).toUpperCase()}`
    : undefined,
  executedAt: execution.executedAt ? execution.executedAt : undefined,
  wasteAmount:
    execution.quantity2 !== undefined && execution.quantity2 !== null
      ? execution.quantity2
      : undefined,
  asap: !execution.plannedFrom,
  dto: execution,
});

export const initExecutionDetailsFromBookmark = (
  bookmarkService: BookmarkServiceDto,
  plannedFrom: Date | null,
  plannedTo: Date | null,
  parentExecutionId?: ID
): ExecutionDetailsUi => ({
  id: guid(),
  comments: [],
  asap: true,
  plannedFrom,
  plannedTo,
  parentExecutionId,
  nbContainers: bookmarkService.quantity ?? 1,
  wasteId: bookmarkService.service.waste.id,
  containerId: bookmarkService.service.container.id,
  serviceId: bookmarkService.service.id,
});
