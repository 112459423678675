import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useNotificationModalStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingBottom: '25px',
  },
  subtitle: {
    paddingTop: 3,
    opacity: 0.5,
  },
  divider: {
    padding: '5px 0 5px 0 !important',
    opacity: 0.5,
  },
}));
