const APP_DEV_MODE =
  process.env.REACT_APP_STAGE === 'preprod' || process.env.NODE_ENV !== 'production';

const env = {
  API_URL: process.env.REACT_APP_API_URL || '/api/',
  PORT: process.env.PORT,
  USE_LOCAL_TRANSLATION: APP_DEV_MODE
    ? process.env.REACT_APP_USE_LOCAL_TRANSLATION
    : 'NGINX_REPLACE_USE_LOCAL_TRANSLATION',
  AZURE_CLIENT_ID: APP_DEV_MODE
    ? process.env.REACT_APP_AZURE_CLIENT_ID
    : 'NGINX_REPLACE_AZURE_CLIENT_ID',
  AZURE_AUTHORITY: APP_DEV_MODE
    ? process.env.REACT_APP_AZURE_AUTHORITY
    : 'NGINX_REPLACE_AZURE_AUTHORITY',
  AZURE_REDIRECT_URI: APP_DEV_MODE
    ? process.env.REACT_APP_AZURE_REDIRECT_URI
    : 'NGINX_REPLACE_AZURE_REDIRECT_URI',
  AZURE_KNOWN_AUTHORITY: APP_DEV_MODE
    ? process.env.REACT_APP_AZURE_KNOWN_AUTHORITY
    : 'NGINX_REPLACE_AZURE_KNOWN_AUTHORITY',
  AZURE_STORAGE_URL: APP_DEV_MODE
    ? process.env.REACT_APP_AZURE_STORAGE_URL
    : 'NGINX_REPLACE_AZURE_STORAGE_URL',
  PAGINATION: 20,
  SERVICE_KIND_COLLECTE_CODE: '5006',
  SERVICE_KIND_COLLECTE_FORFAIT_CODE: '000251',
  ALERT_MSG_KEY: 'fe.alertMessage1',
};

export default env;
