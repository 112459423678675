import { ID } from '@datorama/akita';
import { combineDateAndHours } from 'utils/data/dayjs.utils';
import { IssueDetailsUi } from 'store/operations/executionIssue';

export interface IssueCreationDto {
  description?: string;
  issueKind: ID;
  reportedAt: string;
  documents: number[];
  phoneNumber?: string;
}

export function formatToIssueCreationDto(
  issue: IssueDetailsUi,
  documentIds: number[]
): IssueCreationDto {
  const { description, reportedAtDate, reportedAtTime, issueKindId, phoneNumber } = issue;
  return {
    issueKind: Number(issueKindId ?? ''),
    reportedAt: combineDateAndHours(reportedAtDate, reportedAtTime),
    description,
    documents: documentIds,
    phoneNumber,
  };
}
