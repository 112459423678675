import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useMaterialTagStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '4px 8px',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
    [theme.breakpoints.only('xs')]: {
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      textOverflow: 'ellipsis !important',
      // width: '55px !important',
      textTransform: 'none',
    },
  },
}));
