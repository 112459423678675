import { FC, useEffect, useState } from 'react';
import { ContractDto } from 'store/contracts/contracts.model';
import { useContractStyles } from './Contracts.style';
import { useIsMobile } from 'utils/isMobile';
import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrowLeft.svg';
import { useHistory, useParams } from 'react-router-dom';
import { RoutePath } from 'constants/Routes';
import BasicTab from 'components/Tabs/BasicTabs/BasicTabs';
import { contractsTabs, MetawasteTab } from 'utils/enums/MetawasteTab';
import ContractDetailSummary from './Contract.detail.summary';
import { contractsService } from 'store/contracts/contracts.service';
import ContractDetailDetails from './Contract.detail.details';

interface ContractDetailScreenParams {
  id: string;
}

const ContractDetailScreen: FC = () => {
  const classes = useContractStyles();
  const isMobiles = useIsMobile();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<{ tabType: MetawasteTab; label: string }>(
    contractsTabs()[0]
  );
  const { id } = useParams<ContractDetailScreenParams>();
  const [currentContact, setCurrentContact] = useState<ContractDto>();

  useEffect(() => {
    console.log('id', id);
    contractsService
      .getContractById(Number(id))
      .then((data) => setCurrentContact(data))
      .catch((e) => console.log(e));
  }, [id]);

  const displayCorrectTab = () => {
    if (currentTab.tabType === MetawasteTab.SUMMARY && currentContact) {
      return <ContractDetailSummary contract={currentContact} />;
    }
    if (currentTab.tabType === MetawasteTab.DETAILS) {
      return <ContractDetailDetails />;
    }
  };

  return (
    <Stack direction="column" spacing={3} className={classes.container}>
      <Stack direction="column" flex={1} spacing={2}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Stack direction="row" height="100%" spacing={2} justifyContent="space-between">
            <IconButton onClick={() => history.push(RoutePath.Contracts)}>
              <ArrowLeft />
            </IconButton>
            <Typography
              variant="h2"
              fontSize={isMobiles ? 23 : 25}
              ml={0}
              className={classes.title}
            >
              {currentContact?.contractName ?? currentContact?.contractReference}
            </Typography>
          </Stack>
        </Stack>
        <BasicTab tabs={contractsTabs()} value={currentTab} handleChange={setCurrentTab} />
      </Stack>
      {displayCorrectTab()}
    </Stack>
  );
};

export default ContractDetailScreen;
