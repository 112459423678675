import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { Close } from 'assets/icons';

interface AlertComponentProps {
  actions?: JSX.Element | JSX.Element[];
  description?: string;
  handleClose: () => void;
  title?: string;
}

const AlertComponent = (props: AlertComponentProps) => {
  const { actions, description, handleClose, title } = props;

  return (
    <Dialog open={true} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack pt="60px" pb={actions ? '20px' : '60px'}>
          {description}
        </Stack>
      </DialogContent>
      {!!actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default AlertComponent;
