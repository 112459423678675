import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { ExecutionBetweenDatesDto } from 'api/dto/execution/ExecutionBetweenDatesDto';

export interface ExecutionWeekPlanningState extends EntityState<ExecutionBetweenDatesDto> {
  filteredWasteIds: ID[];
}

const initialState: ExecutionWeekPlanningState = {
  filteredWasteIds: [],
};

@StoreConfig({
  name: 'ExecutionWeekPlanning',
  resettable: true,
})
export class ExecutionWeekPlanningStore extends EntityStore<ExecutionWeekPlanningState> {
  constructor() {
    super(initialState);
  }
}

export const executionWeekPlanningStore = new ExecutionWeekPlanningStore();
