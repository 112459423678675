import i18n from 'utils/data/i18n.utils';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export enum IssueStatusEnum {
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
}

export const issueStatus: string[] = Object.values(IssueStatusEnum);
export const issueStatusItems = (): MaterialDropdownItem[] =>
  issueStatus.map((status) => ({
    label: translateIssuesStatus(status),
    value: status,
  }));

export const translateIssuesStatus = (status: string) => i18n.t(`database.issueStatus.${status}`);
