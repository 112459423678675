import { ID } from '@datorama/akita';
import { PayloadDto } from 'api/dto/notifications/PayloadDto';
import { NotificationDto } from 'api/dto/notifications/NotificationDto';
import { TypeNotifications } from 'utils/enums/TypeNotifications';
import { getTicketSignatureFromId } from 'store/dashboard/notifications/utils/notifications.utils';

export interface NotificationUi {
  id: ID;
  payload: PayloadDto;
  type: TypeNotifications;
  hasSeen: boolean;
  receivedSince: Date;
  ticketSignature?: string;
}

export function formatNotificationDtoToUi(dto: NotificationDto): NotificationUi {
  return {
    id: dto.id,
    payload: dto.payload,
    type: dto.type,
    hasSeen: dto.hasSeen,
    receivedSince: dto.sentAt,
    ticketSignature: getTicketSignatureFromId(dto.type, dto.payload),
  };
}
