import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';

import style from './MaterialMultiDropdownDashboard.style';
import MaterialMultiDropdownBase, {
  MaterialMultiDropdownBaseProps,
} from '../MaterialMultiDropdownBase';

interface MaterialMultiDropdownDashboardProps
  extends WithStyles<typeof style>,
    MaterialMultiDropdownBaseProps {}

const MaterialMultiDropdownDashboard = (props: MaterialMultiDropdownDashboardProps) => {
  const { items, classes, placeholder, defaultValue } = props;

  return (
    <MaterialMultiDropdownBase
      {...props}
      className={classes.wrapper}
      renderValue={(selected: any) => {
        if (selected.length === 0) {
          return placeholder ?? defaultValue ?? 'None';
        }
        return `${placeholder} ${selected.length ? `(${selected.length})` : ''}`;
        // return (
        //   items
        //     ?.filter((it) => selected.some((sel) => sel === it.value))
        //     ?.map((it) => it.label)
        //     ?.join(', ') ?? ''
        // );
      }}
    />
  );
};

export default withStyles(style)(MaterialMultiDropdownDashboard);
