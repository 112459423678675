import { QueryEntity } from '@datorama/akita';
import {
  DashboardExecutionsStore,
  DashboardExecutionsState,
  dashboardExecutionsStore,
} from './dashboard-executions.store';

export class DashboardExecutionsQuery extends QueryEntity<DashboardExecutionsState> {
  constructor(protected store: DashboardExecutionsStore) {
    super(store);
  }

  executions$ = this.selectAll();
  total$ = this.select((state) => state.total);
  totalToConfirm$ = this.select((state) => state.totalToConfirm);
}

export const dashboardExecutionsQuery = new DashboardExecutionsQuery(dashboardExecutionsStore);
