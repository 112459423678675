import { Divider, Drawer, Stack, SxProps, Typography } from '@mui/material';
import IssueConnectForm from 'components/Issue/IssueConnectForm';
import IssueDetailsForm from 'components/Issue/IssueDetailsForm';
import ManageIssueHeader from 'components/Issue/ManageIssueHeader';
import { useTranslation } from 'react-i18next';
import { useGetIssues } from 'store/issues/hooks/useGetIssuesById';
import { ManageIssue } from 'store/issues/issues.model';
import { Sizes } from 'style/materialStylings';
import TextFieldComponent from '../textfield/TextField.component';
import React from 'react';

interface IssueDetailsDrawerProps {
  issueId: number;
  handleClose: () => void;
}

const IssueDetailsDrawer = (props: IssueDetailsDrawerProps) => {
  const { issueId, handleClose } = props;
  const { t } = useTranslation();

  const { issues, updateIssue, client, clientUnits, supplier, supplierUnits } = useGetIssues({
    issueId,
  });

  const styled: SxProps = {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none !important',
    },
  };

  return (
    <Drawer
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          padding: '17px 0 0 0',
        },
      }}
      open
      onClose={handleClose}
    >
      <Stack className="scrollable" px="24px" sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
        <ManageIssueHeader
          handleClose={handleClose}
          issue={issues as ManageIssue}
          status={issues?.status}
        />
        <Stack pb="24px" pr="10px" spacing={2}>
          <IssueConnectForm updateIssue={updateIssue('issue')} issue={issues as ManageIssue} />
          <Stack spacing={6}>
            <Stack spacing={2}>
              <TextFieldComponent
                sx={styled}
                labelWidth={Sizes.drawerInputLabelWidth}
                label={t('fe.operations.executions.manage.client')}
                value={client?.label}
                readOnly
              />
              <TextFieldComponent
                sx={styled}
                labelWidth={Sizes.drawerInputLabelWidth}
                label={t('fe.operations.executions.manage.clientSite')}
                value={clientUnits?.label}
                readOnly
              />
            </Stack>
            <Stack spacing={2}>
              <TextFieldComponent
                sx={styled}
                labelWidth={Sizes.drawerInputLabelWidth}
                label={t('fe.operations.executions.manage.supplier')}
                value={supplier?.label}
                readOnly
              />
              <TextFieldComponent
                sx={styled}
                labelWidth={Sizes.drawerInputLabelWidth}
                label={t('fe.operations.executions.manage.supplierSite')}
                value={supplierUnits?.label}
                readOnly
              />
            </Stack>
            <TextFieldComponent
              sx={styled}
              labelWidth={Sizes.drawerInputLabelWidth}
              label={t('fe.issueModal.issueDetails.phoneNumberLabel')}
              value={issues?.phoneNumber ?? ''}
              readOnly
            />
          </Stack>
        </Stack>
        <Divider variant="fullWidth" orientation="horizontal" />
        <Stack py="20px" spacing={4}>
          <Typography fontSize={16} fontWeight="700">
            {t('fe.operations.executions.manage.details')}
          </Typography>
          <IssueDetailsForm updateIssue={updateIssue('issue')} issue={issues as ManageIssue} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default IssueDetailsDrawer;
