import React, { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import StatisticTrendData from '../TrendData/StatisticTrendData';
import style from './CardColored.style';
import { InfoOutlined } from '@mui/icons-material';

interface CardColoredProps extends WithStyles<typeof style> {
  data?: any;
  dataStatistic: number;
  background?:
    | 'error.light'
    | 'secondary.light'
    | 'blueColored.main'
    | 'greenColored.main'
    | 'white';
  devise?: string;
  title?: string;
  subTitle?: string;
  noPourcentage?: boolean;
  isLoading?: boolean;
  tooltip?: string;
  tooltipIconColor?: string;
}

const CardColored: FC<CardColoredProps> = (props) => {
  const {
    classes,
    data,
    dataStatistic,
    background,
    devise,
    title,
    subTitle,
    noPourcentage,
    isLoading,
    tooltip,
    tooltipIconColor,
  } = props;

  if (isLoading) {
    return (
      <Card sx={{ backgroundColor: background }} className={classes.CardContainer}>
        <CardContent>
          <Typography variant="h3">{title}</Typography>
          <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ backgroundColor: background }} className={classes.CardContainer}>
      {dataStatistic != null && !noPourcentage && (
        <StatisticTrendData
          stat={dataStatistic}
          backgroundCard={background}
          icon={true}
          nameOfCard="colored"
        />
      )}
      {tooltip && (
        <Box sx={{ position: 'absolute', bottom: 10, right: 10 }}>
          <Tooltip title={<Typography>{tooltip}</Typography>} placement="top">
            <IconButton>
              <InfoOutlined sx={{ color: tooltipIconColor }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography
            className={classes.title}
            color={background === 'blueColored.main' ? 'white' : 'black'}
          >
            {title}
          </Typography>
        </Stack>
        {background === 'blueColored.main' && (
          <Typography color="text.secondary" className={classes.subtitle}>
            {subTitle}
          </Typography>
        )}
        <div />

        <Card
          sx={{ backgroundColor: background === 'white' ? 'secondary.light' : '#ffffff3f' }}
          className={classes.CardDataContainer}
        >
          <CardContent className={classes.CardDataContent}>
            <div className={classes.dataPosition}>
              <Typography
                color={background === 'blueColored.main' ? 'white' : 'black'}
                className={classes.dataTitle}
              >
                {data}
              </Typography>
              <Typography
                color={background === 'blueColored.main' ? '#ffffff3f' : 'secondary.dark'}
                className={classes.dataSubtitle}
              >
                {devise}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(CardColored);
