import { IconButton, Stack, Typography } from '@mui/material';
import { GridColDef, GridColumns } from '@mui/x-data-grid';
import { Colors } from 'constants/Colors';
import { ContractDto, ContractFilterSelectItems } from 'store/contracts/contracts.model';
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg';
import { t } from 'i18next';

interface ColumnTypes {
  [key: string]: GridColDef;
}

function getColumnTypes<T extends { [keyName: string]: GridColDef }>(types: T) {
  return types;
}

interface GetColumnsParams {
  // intl: IntlShape;
  // currentStatus: number;
  // userRole: UserTypes;
  isDesktop: boolean;
  openModal?: () => void;
  contract?: ContractDto;
  onClick?: () => void;
}

export const contractsColumns = (params: {
  isDesktop: boolean;
  onClick: (value: any) => void;
}): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    contractReference: {
      field: 'contractReference',
      headerName: t('fe.contractsTable.reference'),
      flex: 0.7,
    },
    contractName: {
      field: 'contractName',
      headerName: t('fe.contractsTable.contractName'),
      flex: 1.3,
    },
    clientOrganisationUnitName: {
      field: 'clientOrganisationUnitName',
      headerName: t('fe.contractsTable.clientUnit'),
      flex: 1.3,
    },
    waste: {
      field: 'waste',
      headerName: t('fe.contractsTable.waste'),
      flex: 1,
    },
    container: {
      field: 'container',
      headerName: t('fe.contractsTable.container'),
      flex: 0.7,
    },
    servicesCount: {
      field: 'servicesCount',
      headerName: t('fe.contractsTable.servicesCount'),
      flex: 0.7,
      renderCell: (renderParams) => (
        <Stack
          sx={{
            borderRadius: 50,
            border: '1px solid',
            borderColor: Colors.primary,
            padding: '10px',
            gap: '10px',
          }}
        >
          <Typography fontSize="11px" fontWeight={450}>
            {renderParams.value?.toString()}
          </Typography>
        </Stack>
      ),
    },
    status: {
      field: 'status',
      headerName: t('fe.contractsTable.status'),
      flex: 0.7,
      renderCell: (renderParams) => (
        <Stack
          sx={{
            background: '#F4F4F4',
            padding: '8px',
            borderRadius: '4px',
            width: '120px',
            textAlign: 'center',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Typography fontSize="11px" fontWeight={450}>
            {ContractFilterSelectItems().find((item) => item.value === renderParams.value)?.label}
          </Typography>
        </Stack>
      ),
    },
    actions: {
      field: 'actions',
      headerClassName: 'hiddenHeader',
      cellClassName: 'noPaddingRight',
      sortable: false,
      filterable: false,
      align: 'center',
      flex: 1,
      renderCell: (renderParams) => (
        <div>
          <IconButton onClick={() => params.onClick(renderParams?.row?.id)}>
            <ArrowRight height="18px" width="11px" />
          </IconButton>
        </div>
      ),
    },
  });

  return [
    COLUMN_TYPES.contractReference,
    COLUMN_TYPES.contractName,
    COLUMN_TYPES.clientOrganisationUnitName,
    COLUMN_TYPES.waste,
    COLUMN_TYPES.container,
    COLUMN_TYPES.servicesCount,
    COLUMN_TYPES.status,
    COLUMN_TYPES.actions,
  ];
};

export const supplierContractsColumns = (params: { isDesktop: boolean }): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    name: {
      field: 'name',
      headerName: 'Prestataire',
      flex: 1,
    },
    phone: {
      field: 'phone',
      headerName: 'Téléphone',
      flex: 1,
    },
    email: {
      field: 'email',
      headerName: 'email',
      flex: 1,
    },
    address: {
      field: 'street',
      headerName: 'Adresse',
      flex: 1,
    },
  });

  return [COLUMN_TYPES.name, COLUMN_TYPES.phone, COLUMN_TYPES.email, COLUMN_TYPES.address];
};

export const dataContractsColumns = (params: { isDesktop: boolean }): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    data: {
      field: 'data',
      headerName: 'Donnée',
      flex: 1,
    },
    quantity: {
      field: 'quantity',
      headerName: 'Quantité',
      flex: 1,
    },
  });

  return [COLUMN_TYPES.data, COLUMN_TYPES.quantity];
};
