import React, { FC, useEffect } from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/icons/addDocument.svg';
import NonConformityItem from 'components/NonConformity/NonConformityItem/NonConformityItem';
import IssueModalScreen from 'screens/auth/modals/issueModal/IssueModal.screen';
import {
  executionIssueQuery,
  executionIssueService,
  IssueDetailsUi,
} from 'store/operations/executionIssue';
import { formatUltraShort } from 'utils/dates/format';
import { useIssueForExecution } from 'store/operations/executionIssue/hooks/useIssueForExecution';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils/hooks/useQuery';
import { useObservable } from '@ngneat/react-rxjs';

interface ExecutionIssueFormProps {
  execution: ExecutionDetailsUi;
}

const ExecutionIssueForm: FC<ExecutionIssueFormProps> = (props) => {
  const { execution } = props;
  const [isIssueModalOpen] = useObservable(executionIssueQuery.isModalOpen$);
  const { issues } = useIssueForExecution(execution.id);
  const { t } = useTranslation();

  const query = useQuery();
  const issueId = query.get('issueId');

  useEffect(() => {
    const issue = issues.find((it) => it.id.toString() === issueId);
    if (issue) {
      executionIssueService.openModal(issue);
    }
  }, [issueId, issues]);

  const handleOpenModal = (issue?: IssueDetailsUi) => {
    executionIssueService.openModal(issue);
  };

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">{t('fe.executionModal.executionIssue.issueTitle')}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="upload file"
            component="span"
            style={{ padding: '0px' }}
            onClick={() => handleOpenModal()}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      {issues.map((issue) => (
        <Grid item key={issue.id} style={{ paddingRight: '15px' }}>
          <NonConformityItem
            title={issue.dto?.issueSignature}
            subtitle={t('fe.executionModal.executionIssue.createdBy', {
              firstName: issue?.dto?.author?.firstName,
              lastName: issue?.dto?.author?.lastName?.slice(0, 1),
              date: formatUltraShort(issue.dto?.reportedAt),
            })}
            handleClick={() => handleOpenModal(issue)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      {isIssueModalOpen && <IssueModalScreen execution={execution} />}
    </Grid>
  );
};

export default React.memo(ExecutionIssueForm);
