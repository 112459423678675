import { Button, Stack } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ActivityRowListComponent from 'components/ActivityRow/ActivityRowListComponent';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { Colors } from 'constants/Colors';
import React from 'react';
import { Link } from 'react-router-dom';

interface OperationActivitiesProps {
  isOnbording?: boolean;
}

export const OperationActivities = (props: OperationActivitiesProps) => {
  const { t } = useTranslation();
  const { isOnbording } = props;

  return (
    <Stack>
      <Carousel
        autoPlay={false}
        animation="slide"
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            borderRadius: 0,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            color: Colors.primary,
            opacity: 0.5,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            opacity: 1,
          },
        }}
        navButtonsAlwaysVisible={true}
        PrevIcon={<NavigateBefore color="primary" />}
        NextIcon={<NavigateNext color="primary" />}
      >
        <ActivityRowListComponent isOnbording={isOnbording} tabType={MetawasteTab.LAST_CREATED} />
        <ActivityRowListComponent isOnbording={isOnbording} tabType={MetawasteTab.LAST_ACCEPTED} />
        <ActivityRowListComponent isOnbording={isOnbording} tabType={MetawasteTab.LAST_COMPLETED} />
      </Carousel>
      <Stack direction="row" justifyContent="flex-end" mt={-4}>
        {isOnbording ? (
          <Button variant="outlined">
            {t('fe.dashboard.morgan.stats.operations.activities.buttonSeeMore')}
          </Button>
        ) : (
          <Link to="/operations" style={{ textDecoration: 'none' }}>
            <Button variant="outlined">
              {t('fe.dashboard.morgan.stats.operations.activities.buttonSeeMore')}
            </Button>
          </Link>
        )}
      </Stack>
    </Stack>
  );
};
