import { ExecutionDetailsUi } from 'store/operations/createExecution';

export interface ExecutionCompleteDto {
  executedAt?: string;
  numberOfContainers?: number;
  wasteAmount?: number;
}

export function formatToExecutionCompleteDto(execution: ExecutionDetailsUi): ExecutionCompleteDto {
  const { executedAt, nbContainers, wasteAmount } = execution;
  return {
    executedAt: executedAt?.toISOString(),
    numberOfContainers: nbContainers,
    wasteAmount: Number(wasteAmount ?? 0),
  };
}
