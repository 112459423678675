import { Grid } from '@mui/material';
import CardStatistic from 'components/Card/CardStatistic';
import { OpUnitType } from 'dayjs';
import { useState } from 'react';
import { useDataOperationStatsStyle } from 'screens/auth/dashboard/morgan/components/DataCards/DataCards.component.style';
import { getObCardStatisticData } from 'utils/data/dataCard.utils';
import { isMobile } from 'utils/isMobile';
import ObEcologic from './ObSubScrens/ObEcologic';
import ObFinance from './ObSubScrens/ObFinance';
import ObOperations from './ObSubScrens/ObOperations';

interface ObDataCardsProps {
  from: Date;
  to: Date;
  wasteIds: string[];
  dropwDownDashboard: OpUnitType;
}

const ObDataCards = (props: ObDataCardsProps) => {
  const { from, to, wasteIds, dropwDownDashboard } = props;
  const isMobiles = isMobile();
  const classes = useDataOperationStatsStyle();
  const [currentCard, setCurrentCard] = useState(0);
  const [cardStatisticData, setCardStatisticData] = useState(getObCardStatisticData);

  const renderPageOfCard = (currentCard: number) => {
    switch (currentCard) {
      case 0:
        return (
          <ObOperations
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
      case 1:
        return (
          <ObEcologic
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
      case 2:
        return (
          <ObFinance
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
    }
  };
  return (
    <>
      <Grid
        container
        item
        spacing={1}
        className={classes.responsiveGridCard}
        style={{ justifyContent: isMobiles ? undefined : 'center' }}
      >
        {cardStatisticData?.map((card, idx) => {
          const steper = `step${idx + 5}`;
          return (
            <Grid
              item
              key={card.cardNumber}
              xs={4}
              className={`${classes.responsiveWidthCard} ${steper}`}
            >
              <CardStatistic
                title={card.title}
                data={card.data}
                isLoading={card.isLoading}
                devise={card.devise}
                description={card.description}
                dataStatistic={card.dataStatistic}
                handleClick={card.canClick ? () => setCurrentCard(card.cardNumber) : undefined}
                value={currentCard}
                selected={card.cardNumber}
              />
            </Grid>
          );
        })}
      </Grid>
      {renderPageOfCard(currentCard)}
    </>
  );
};

export default ObDataCards;
