import { Grid, Typography } from '@mui/material';
import React from 'react';
import {
  formatDayOfWeekCalendar,
  formatDayOfWeekCalendarMobileLetter,
  formatDayOfWeekCalendarMobileNumber,
} from 'utils/dates/format';
import { isMobile } from 'utils/isMobile';

const isMobiles = isMobile();

const renderDayHeader = (obj) => {
  return (
    <Typography
      color={obj.isToday ? 'primary' : 'secondary'}
      fontSize={12}
      variant={obj.isToday ? 'h5' : 'body1'}
    >
      {isMobiles ? (
        <Grid container>
          <Grid container item xs={12} justifyContent="flex-start">
            {formatDayOfWeekCalendarMobileLetter(obj.date)}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-start">
            {formatDayOfWeekCalendarMobileNumber(obj.date)}
          </Grid>
        </Grid>
      ) : (
        formatDayOfWeekCalendar(obj.date)
      )}
    </Typography>
  );
};

export default renderDayHeader;
