import { alpha } from '@mui/material';

export const Colors = {
  primary: '#37DC58',
  error: '#E6433C',
  success: '#37DC58',
  warning: '#FF8B21',
  info: '#51A2ED',

  background: '#F4F6F8',
  blur: alpha('#172134', 0.4),
  text: '#172134',
  textWithOpacity: '#848F9D',

  white: '#FFFFFF',
  black: '#000000',

  chartBorder: '#848F9D',
  pink: '#ED51AF',
  blue: '#48547E',
  yellow: '#F8C43F',
  brown: '#9C130E',

  paid: '#5BAF77',
  progress: '#F4F6F8',
  late: 'rgba(219, 95, 104, 0.3)',
  sent: 'rgba(55, 220, 88, 0.7)',
  validated: 'rgba(55, 220, 88, 0.3)',
  waiting: '#D7EBFF',
  toValidate: 'rgb(215, 235, 255)',

  green: '#6FD969',
  grey: '#EDEEF0',
  lightGrey: '#A3A6AD',
};
