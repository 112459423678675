import { InputLabel } from '@mui/material';
import React, { FC } from 'react';
import style from './MaterialTimeSingleTextfield.style';
import { WithStyles, withStyles } from '@mui/styles';
import MaterialTimeTextfieldBase from '../MaterialTimeTextfieldBase';

interface MaterialTextfieldProps extends WithStyles<typeof style> {
  label?: string;
  name?: string;
  required?: boolean;
  readOnly?: boolean;
  value?: Date | null;
  handleChange?: (value: Date | null) => void;
  error?: string;
  color?: 'primary' | 'secondary';
}

const MaterialTimeSingleTextfield: FC<MaterialTextfieldProps> = (props: MaterialTextfieldProps) => {
  const { classes, error, color, label, readOnly, value, handleChange, required } = props;

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <div className={classes.textfields}>
        <MaterialTimeTextfieldBase
          value={value}
          handleChange={handleChange}
          color={color}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default withStyles(style)(MaterialTimeSingleTextfield);
