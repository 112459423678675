import { SnackbarStore, snackbarStore } from './snackbar.store';
import { OptionsObject } from 'notistack';
import { guid, ID } from '@datorama/akita';

export class SnackbarService {
  constructor(private store: SnackbarStore) {}

  showMessage = (message: string, options: OptionsObject) => {
    this.store.upsertMany([{ id: guid(), message, options }]);
  };

  removeMessage = (id: ID) => {
    this.store.remove(id);
  };
}

export const snackbarService = new SnackbarService(snackbarStore);
