import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      width: '100%',
      height: '230px',
      backgroundColor: theme.palette.secondary.light + ' !important',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    divPosition: {
      margin: 'auto !important',
      height: 'auto',
    },
    iconButtonContainer: {
      backgroundColor: theme.palette.neutral.main + ' !important',
      display: 'flex !important',
      margin: 'auto !important',
      borderRadius: '1px !important',
      width: '90px !important',
    },
    icon: {
      color: 'black',
      width: '73px !important',
      height: '70px !important',
    },
    typography: {
      marginTop: '20px !important',
      textAlign: 'center',
      fontWeight: 700 + ' !important',
    },
    input: {
      display: 'none',
    },
    hideOrShow: {
      display: 'none',
      '& > img:hover': {
        display: 'block',
      },
    },
    image: {
      margin: 'auto !important',
      '&:hover': {
        display: 'none',
      },
    },
  });

export default classes;
