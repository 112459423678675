import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { auditProposalSupplierService } from 'store/audit/audit.service';
import { metawasteApi } from 'api/Axios';
import { useTranslation } from 'react-i18next';
import {
  AuditOperationScheduleDto,
  getNumeralDayOfMonthTranslation,
  getWeekDayListFromString,
  getWeekDayTranslation,
  NumeralDayOfMonth,
  WeekDay,
} from 'store/common/days/schedule.model';
import { getTypeOf } from 'utils/data/getTypeOf';
import FileCard from '../FileCard/FileCard';

const ModalContainer = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '80%',
  },
});

export enum AuditOwnedByEnum {
  SUPPLIER = 'SUPPLIER',
  CLIENT = 'CLIENT',
  UNKNOWN = 'UNKNOWN',
}

interface ContainerItem {
  id: number;
  name: string;
  type: string;
  quantity: number;
  property: AuditOwnedByEnum;
  localisation: string;
  wasteName: string;
  typology: string;
  volume: number;
  unit: string;
  frequency: AuditOperationScheduleDto;
  days: string;
}

export interface AiScheduleObj {
  frequencyType: string;
  frequencyItem: AiScheduleItem[];
}
export interface AiScheduleItem {
  title: string;
  value?: string | number;
  oldValue?: string | number;
}

export interface ContainerDetailsProps {
  onClose: () => void;
  auditOperationId: number;
}

const ContainerDetails = (props: ContainerDetailsProps) => {
  const { onClose, auditOperationId } = props;
  const [auditOperation, setAuditOperation] = useState<any>({});
  const [container, setContainer] = useState<Partial<ContainerItem>>({});
  const [documents, setDocuments] = useState<any[]>([]);
  const [frequency, setFrequency] = useState<AiScheduleObj>();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await auditProposalSupplierService.getContainerDetailsForAuditOffer(
          auditOperationId
        );
        setAuditOperation(data);
      } catch (error) {
        console.error('Error fetching audit proposals:', error);
      }
    };

    fetchData();
  }, [auditOperationId]);

  useEffect(() => {
    if (auditOperation.type == 'CLASSIC') {
      setContainer({
        id: auditOperation.id,
        name: auditOperation?.container?.name || '',
        type: auditOperation?.type || '',
        quantity: auditOperation?.quantity || 0,
        property: auditOperation?.ownedBy || '',
        localisation: auditOperation?.description || '',
        wasteName: auditOperation?.waste?.name || '',
        typology: auditOperation.waste?.family?.name || 'Typologie',
        volume: auditOperation.wasteQuantity || 0,
        unit: auditOperation.wasteUnitName || '',
        frequency: auditOperation.schedule ? JSON.parse(auditOperation.schedule) : {},
        days: '',
      });
    } else {
      setContainer({
        id: auditOperation.id,
        name: auditOperation.containerCustom || '',
        type: auditOperation.type || '',
        quantity: auditOperation.quantity || 0,
        property: auditOperation.ownedBy || '',
        localisation: auditOperation.description || '',
        wasteName: auditOperation.wasteCustom || '',
        typology: auditOperation.waste?.family?.name || 'Typologie',
        volume: auditOperation.wasteQuantity || 0,
        unit: auditOperation.wasteUnitName || '',
        frequency: auditOperation.schedule ? JSON.parse(auditOperation.schedule) : {},
        days: '',
      });
    }

    if (auditOperation.documents) {
      auditOperation.documents.forEach((doc) => {
        setDocuments((docs) => [...docs, metawasteApi.getDocumentUrl(doc)]);
      });
    }
  }, [auditOperation]);

  useEffect(() => {
    if (auditOperation?.schedule)
      handleDisplayFrequency(getTypeOf<AuditOperationScheduleDto>(auditOperation.schedule));
  }, [auditOperation?.schedule]);

  const handleDisplayFrequency = (value: Partial<AuditOperationScheduleDto>) => {
    const frequency: AiScheduleObj = {
      frequencyType: value.frequency as string,
      frequencyItem: [
        {
          title: 'fréquence',
        },
        {
          title: 'jours',
        },
      ],
    };

    if (value) {
      switch (value.frequency) {
        case 'DAILY':
          frequency.frequencyItem[0].value = t('fe.audits.preco.period-week', {
            value: value.dailyFrequencyNumber,
          }) as string;
          frequency.frequencyItem[1].value = '-';
          break;
        case 'WEEKLY':
          frequency.frequencyItem[0].value = t('fe.audits.preco.period-hebdo', {
            value: value.weeklyFrequencyNumber,
          }) as string;
          frequency.frequencyItem[1].value = getWeekDayListFromString(value.daysOfWeek ?? '');
          break;
        case 'MONTHLY':
          frequency.frequencyItem[0].value = t('fe.audits.preco.period-monthly-month', {
            value: value.monthlyFrequencyNumber,
          }) as string;
          frequency.frequencyItem[1].value = value.dayOfMonth
            ? (t('fe.audits.preco.period-monthly-day', { value: value.dayOfMonth }) as string)
            : (`${getNumeralDayOfMonthTranslation(
                value.numeralDayOfMonth ?? NumeralDayOfMonth.FIRST
              )} ${getWeekDayTranslation(value.weekDayForMonth ?? WeekDay.MONDAY)}` as string);
          break;
      }
    }
    setFrequency(frequency);
  };

  return (
    <ModalContainer open={true} onClose={onClose}>
      <DialogContent>
        <Stack px="20px" mb="40px">
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack
                sx={{
                  backgroundColor: '#4F3FF5',
                  borderRadius: '3px',
                  alignItems: 'center',
                  padding: '4px',
                  justifyContent: 'center',
                  width: '20px',
                  height: '20px',
                }}
              >
                <InventoryIcon sx={{ color: 'white', width: '15px' }} />
              </Stack>
              <Typography fontSize={18} fontWeight="700" mb="25px">
                {container.name}
              </Typography>
            </Stack>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" flexWrap="wrap" justifyContent="space-between" mt="45px" mb="16px">
            {[
              { title: 'type', value: container.name },
              { title: 'quantité', value: container.quantity },
              { title: 'propriété', value: container.property },
            ].map((item) => (
              <Stack direction="row" alignItems="center" spacing={1} key={item.title}>
                <Typography fontSize="14px" fontWeight={400} color="#868F9C">
                  {item.title}
                </Typography>
                <Stack p="7px 11px" bgcolor="#E9EBEE">
                  <Typography fontSize="12px" fontWeight={400} color="#192133">
                    {item.value}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} mb="8px">
            <Typography fontSize="14px" fontWeight={400} color="#868F9C">
              localisation
            </Typography>
            <Stack p="7px 11px" bgcolor="#E9EBEE">
              <Typography fontSize="12px" fontWeight={400} color="#192133">
                {container.localisation || '-'}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ height: '30px', borderColor: '#868F9C' }} />
          <Stack mt="16px" mb="16px" spacing={2}>
            <Typography fontSize="11px" fontWeight={500} color="#192133">
              Déchet
            </Typography>
            <Stack direction="row" flexWrap="wrap" justifyContent="space-between" mb="16px">
              {[
                { title: 'déchet', value: container.wasteName },
                { title: 'typologie', value: container.typology },
                { title: 'volume', value: container.volume },
              ].map((item) => (
                <Stack direction="row" alignItems="center" spacing={1} key={item.title}>
                  <Typography fontSize="14px" fontWeight={400} color="#868F9C">
                    {item.title}
                  </Typography>
                  <Stack p="7px 11px" bgcolor="#E9EBEE">
                    <Typography fontSize="12px" fontWeight={400} color="#192133">
                      {item.value || '-'}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} mt="20px">
              <Typography fontSize="14px" fontWeight={400} color="#868F9C">
                unité
              </Typography>
              <Stack p="7px 11px" bgcolor="#E9EBEE">
                <Typography fontSize="12px" fontWeight={400} color="#192133">
                  {container.unit || '-'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {auditOperation?.schedule && (
            <>
              <Divider sx={{ height: '30px', borderColor: '#868F9C' }} />
              <Stack spacing={2} pb="10px" pt="10px">
                <Typography fontSize="11px" fontWeight={500} color="#192133">
                  {t('fe.audits.collect')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={5}>
                  {auditOperation?.schedule &&
                    frequency?.frequencyItem.map((el, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Stack key={idx} direction="row" alignItems="center" spacing={1.5}>
                        <Typography fontSize="14px" fontWeight={400} color="#868F9C">
                          {el.title}
                        </Typography>
                        <Stack
                          sx={{
                            backgroundColor: '#E9EBEE',
                            borderRadius: '1px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px 10px',
                            position: 'relative',
                          }}
                        >
                          <Typography fontSize="12px" fontWeight={400} color="#192133">
                            {el.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
              </Stack>
            </>
          )}
          <Divider sx={{ height: '30px', borderColor: '#868F9C' }} />
          <Stack direction="row" pt="25px" spacing={2}>
            {documents.map((doc) => (
              <FileCard document={doc} key={doc} />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </ModalContainer>
  );
};

export default ContainerDetails;
