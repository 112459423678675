export const craftUrlParams = (
  executionId?: string | null,
  issueId?: string | null
): URLSearchParams => {
  let params: any = {};
  if (executionId) {
    params = { ...params, executionId };
  }
  if (issueId) {
    params = { ...params, issueId };
  }
  return new URLSearchParams(params);
};
