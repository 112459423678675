import { Query } from '@datorama/akita';
import {
  OperationsStats,
  OperationsStatsStore,
  operationsStatsStore,
} from './operations-stats.store';

export class OperationsStatsQuery extends Query<OperationsStats> {
  constructor(protected store: OperationsStatsStore) {
    super(store);
  }

  getOperationsStats$ = this.select((stats) => stats.operationTickets.stats);
  isOperationLoading$ = this.select((stats) => stats.operationTickets.isLoading);
}

export const operationsStatsQuery = new OperationsStatsQuery(operationsStatsStore);
