import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    cardContainer: {
      width: 'auto',
      height: '170px',
      position: 'relative',
      display: 'flex',
      alignItems: 'baseline !important',
      [theme.breakpoints.only('xs')]: {
        padding: '16px !important',
        height: '100% !important',
      },
    },
    data: {
      fontSize: '36px !important',
      fontWeight: 700 + '!important',
      color: theme.palette.text.primary,
    },
    ecoDataContainer: {
      display: 'flex',
    },
    TypographyEco: {
      fontSize: '12px !important',
    },
    icon: {
      marginRight: '5px',
    },
    iconSpace: {
      marginLeft: '18px',
    },
    subTitle: {
      fontSize: '12px !important',
      fontWeight: 500 + '!important',
      marginLeft: '5px !important',
      color: theme.palette.text.secondary,
    },
    percentage: {
      marginTop: '10px !important',
      fontSize: '14px !important',
      color: theme.palette.text.primary,
      fontWeight: 700 + '!important',
    },
  });
export default classes;
