import { QueryEntity } from '@datorama/akita';
import { FiltersState, filtersStore, FiltersStore } from 'store/common/filters/filters.store';

export class FiltersQuery extends QueryEntity<FiltersState> {
  constructor(protected store: FiltersStore) {
    super(store);
  }

  currentFilters = this.getValue().currentFilters;
  currentFilters$ = this.select((state) => state.currentFilters);
}

export const filtersQuery = new FiltersQuery(filtersStore);
