import React, { useState } from 'react';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import {
  createExecutionQuery,
  createExecutionService,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { LoadingButton } from '@mui/lab';

interface Props {
  executions: ExecutionDetailsUi[];
}

const MorganNewTimeButtonComponent = (props: Props) => {
  const { executions } = props;
  const { t } = useTranslation();
  const popConfirm = usePopup();
  const classes = useCreateStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [disableMorganNewTimeButton] = useObservable(
    createExecutionQuery.disableMorganNewTimeButton$()
  );

  const proposeNewTime = () => {
    popConfirm({
      title: t('fe.executionModal.confirm.morganNewTime.title'),
      description: t('fe.executionModal.confirm.morganNewTime.description'),
      buttons: [
        {
          label: t('fe.cancel'),
          color: 'error',
          variant: 'outlined',
          onClick: () => undefined,
        },
        {
          label: t('fe.executionModal.confirm.morganNewTime.button'),
          color: 'primary',
          onClick: async () => {
            setIsLoading(true);
            try {
              await createExecutionService.patchExecutionMorganNewTime(executions[0]);
            } catch (e: any) {
              enqueueSnackbar(e.text, e.options);
            }
            setIsLoading(false);
          },
        },
      ],
    });
  };

  return (
    <LoadingButton
      variant="contained"
      size="large"
      onClick={proposeNewTime}
      loading={isLoading}
      disabled={disableMorganNewTimeButton}
    >
      {t('fe.executionModal.buttons.morganNewTime')}
    </LoadingButton>
  );
};

export default MorganNewTimeButtonComponent;
