import { Store, StoreConfig } from '@datorama/akita';
import {
  ExpenseValoStatsUi,
  ServiceExpenseUi,
  WasteExpenseValoUi,
} from './finance-screen-stats.model';

export interface FinanceScreenStats {
  expenseValoEvolution: {
    stats: ExpenseValoStatsUi;
    isLoading: boolean;
  };
  serviceExpense: {
    stats: ServiceExpenseUi[];
    isLoading: boolean;
  };
  wasteExpenseValo: {
    stats: WasteExpenseValoUi[];
    isLoading: boolean;
  };
}

export function createInitialState(): FinanceScreenStats {
  return {
    expenseValoEvolution: {
      stats: {
        currentPeriod: [],
        oldPeriod: [],
      },
      isLoading: false,
    },
    serviceExpense: {
      stats: [],
      isLoading: false,
    },
    wasteExpenseValo: {
      stats: [],
      isLoading: false,
    },
  };
}

@StoreConfig({ name: 'financeScreenStats' })
export class FinanceScreenStatsStore extends Store<FinanceScreenStats> {
  constructor() {
    super(createInitialState());
  }

  setExpenseValoEvolutionLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      expenseValoEvolution: {
        ...state.expenseValoEvolution,
        isLoading: loader,
      },
    }));

  updateExpenseValoEvolutionStats = (expenseValoEvolStats: ExpenseValoStatsUi) =>
    this.update((state) => ({
      ...state,
      expenseValoEvolution: {
        stats: expenseValoEvolStats,
        isLoading: false,
      },
    }));

  setServiceExpenseLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      serviceExpense: {
        ...state.serviceExpense,
        isLoading: loader,
      },
    }));

  updateServiceExpenseStats = (serviceExpenseStats: ServiceExpenseUi[]) =>
    this.update((state) => ({
      ...state,
      serviceExpense: {
        stats: serviceExpenseStats,
        isLoading: false,
      },
    }));

  setWasteExpenseValoLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      wasteExpenseValo: {
        ...state.wasteExpenseValo,
        isLoading: loader,
      },
    }));

  updateWasteExpenseValoStats = (wasteExpenseValoStats: WasteExpenseValoUi[]) =>
    this.update((state) => ({
      ...state,
      wasteExpenseValo: {
        stats: wasteExpenseValoStats,
        isLoading: false,
      },
    }));
}

export const financeScreenStatsStore = new FinanceScreenStatsStore();
