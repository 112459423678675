import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    cardContainer: {
      width: '100% !important',
      height: '100% !important',
      backgroundColor: 'white !important',
      [theme.breakpoints.only('xs')]: {
        padding: '16px !important',
      },
    },
    title: {
      marginBottom: '9px !important',
    },
    description: {
      textAlign: 'center',
    },
    icon: {
      width: '70px',
    },
  });

export default classes;
