import { Typography } from '@mui/material';
import { Colors } from 'style/materialStylings';
import React from 'react';
import { isMobile } from 'utils/isMobile';

const isMobiles = isMobile();

const renderSlotMobile = (obj) => {
  return obj.text.replace(':00', '');
};

const renderSlotLabel = (obj) => {
  if ('7:00' === obj.text) {
    return '';
  }
  return isMobiles ? (
    <Typography color={Colors.darkGray} fontSize={12} variant="h5">
      {renderSlotMobile(obj)}
    </Typography>
  ) : (
    <Typography color={Colors.darkGray} fontSize={12} variant="h5">
      {obj.text}
    </Typography>
  );
};

export default renderSlotLabel;
