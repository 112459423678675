import { QueryEntity } from '@datorama/akita';
import {
  OperationExecutionsState,
  OperationExecutionsStore,
  operationExecutionsStore,
} from './operation-executions.store';
import { executionStatusTabs, translateExecutionStatus } from 'utils/enums/ExecutionStatusEnum';

export class OperationExecutionsQuery extends QueryEntity<OperationExecutionsState> {
  constructor(protected store: OperationExecutionsStore) {
    super(store);
  }

  tabs$ = this.select((state) => {
    return executionStatusTabs.map((status) => {
      if (status === 'ALL') {
        const count = state.status.reduce((accumulator, curr) => accumulator + curr.statusCount, 0);
        return `${translateExecutionStatus(status)} (${count})`.toLocaleUpperCase();
      }
      const findStatus = state.status.find((countStatus) => countStatus.status === status);
      if (findStatus) {
        return `${translateExecutionStatus(status)} (${
          findStatus.statusCount
        })`.toLocaleUpperCase();
      }
      return `${translateExecutionStatus(status)} (0)`.toLocaleUpperCase();
    });
  });

  executions$ = this.selectAll();
  total$ = this.select((state) => state.total);
}

export const operationExecutionsQuery = new OperationExecutionsQuery(operationExecutionsStore);
