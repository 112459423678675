import React, { FC } from 'react';
import { createExecutionQuery, ExecutionDetailsUi } from 'store/operations/createExecution';
import { Grid } from '@mui/material';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield/MaterialBasicTextfield';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';

interface ExecutionDestinationFieldProps {
  execution: ExecutionDetailsUi;
}

const ExecutionDestinationField: FC<ExecutionDestinationFieldProps> = (props) => {
  const { execution } = props;

  const { t } = useTranslation();

  const [canSeeDestination] = useObservable(createExecutionQuery.canSeeDestination$());
  const [canSeeTransporter] = useObservable(createExecutionQuery.canSeeTransporter$());

  return (
    <>
      {canSeeTransporter && (
        <Grid item xs={12}>
          <MaterialBasicTextfield
            label={t('fe.executionModal.executionInfosForm.transporterLabel')}
            placeholder={t('fe.executionModal.executionInfosForm.transporterPlaceholder')}
            color="secondary"
            value={execution.haulingOrganisationUnitName}
            disabled
          />
        </Grid>
      )}
      {canSeeDestination && (
        <Grid item xs={12}>
          <MaterialBasicTextfield
            label={t('fe.executionModal.executionInfosForm.destinationLabel')}
            placeholder={t('fe.executionModal.executionInfosForm.destinationPlaceholder')}
            color="secondary"
            value={execution.destinationOrganisationUnitName}
            disabled
          />
        </Grid>
      )}
    </>
  );
};

export default ExecutionDestinationField;
