import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface ContainerProps {
  container?: string;
  wasteName?: string;
  containerName?: string;
}

const Container: FC<ContainerProps> = (props) => {
  const { container, wasteName, containerName } = props;
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h5">{container}</Typography>
      <Typography
        variant="body1"
        style={{ fontSize: 12, fontWeight: 400, marginTop: 2 }}
        sx={{
          overflow: 'hidden !important',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis !important',
          width: '90% !important',
        }}
      >
        {wasteName}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ fontSize: 12, fontWeight: 400 }}
        sx={{
          overflow: 'hidden !important',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis !important',
          width: '90% !important',
        }}
      >
        {containerName}
      </Typography>
    </div>
  );
};

export default Container;
