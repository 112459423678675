import { ID, QueryEntity } from '@datorama/akita';
import {
  IssueDocumentsState,
  IssueDocumentsStore,
  issueDocumentsStore,
} from './issue-documents.store';

export class IssueDocumentsQuery extends QueryEntity<IssueDocumentsState> {
  constructor(protected store: IssueDocumentsStore) {
    super(store);
  }

  documentsFromIssue$(issueId?: ID) {
    return this.selectAll({ filterBy: (doc) => doc.issueId === issueId });
  }

  documentsFromIssue(issueId: ID) {
    return this.getAll({ filterBy: (doc) => doc.issueId === issueId });
  }

  documentIdsFromIssue(issueId: ID) {
    return this.documentsFromIssue(issueId).map((doc) => Number(doc.id));
  }

  documentsUpdateFromIssue(issueId: ID) {
    const docs = this.documentsFromIssue(issueId);
    const originalDocs = this.getValue().originalDocuments.filter((doc) => doc.issueId === issueId);
    const documentsToAdd = docs
      .filter((doc) => !originalDocs.some((originalDoc) => originalDoc.id === doc.id))
      .map((doc) => Number(doc.id));
    const documentsToDelete = originalDocs
      .filter((originalDoc) => !docs.some((doc) => doc.id === originalDoc.id))
      .map((doc) => Number(doc.id));
    return { documentsToAdd, documentsToDelete };
  }
}

export const issueDocumentsQuery = new IssueDocumentsQuery(issueDocumentsStore);
