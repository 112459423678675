import React, { FC } from 'react';
import { useSelectLangStyles } from './SelectLang.style';
import { Button, Typography } from '@mui/material';

interface SelectLangProps {
  languages: {
    label: string;
    value: any;
  }[];
  value?: any;
  handleClick?: (value: any) => void;
}

const SelectLang: FC<SelectLangProps> = (props) => {
  const { languages = [], value, handleClick } = props;
  const classes = useSelectLangStyles();

  return (
    <div>
      {languages.map((language) => (
        <Button
          key={0}
          className={value === language.value ? classes.buttonSelected : classes.buttonContainer}
          onClick={() => handleClick?.(language.value)}
        >
          <Typography
            color="black"
            className={value === language.value ? classes.typographySelected : classes.typography}
          >
            {language.label}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default SelectLang;
