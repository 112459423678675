import i18n from 'utils/data/i18n.utils';

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const translateIssueKind = (name: string, capitalize = true) => {
  const translated = i18n.t(`database.issueKinds.${name}`);
  return capitalize ? capitalizeFirstLetter(translated) : translated;
};
