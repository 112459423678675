import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';
import { ID } from '@datorama/akita';
import { createExecutionService } from '../create-execution.service';
import { ExecutionDetailsUi } from '../create-execution.model';

export const useSetServiceId = (execution: ExecutionDetailsUi) => {
  const { enqueueSnackbar } = useSnackbar();

  const wasteId$ = useRef(new Subject<ID | undefined>()).current;
  const containerId$ = useRef(new Subject<ID | undefined>()).current;

  useEffect(() => {
    const _queryParams = combineLatest([wasteId$, containerId$])
      .pipe(debounce(() => timer(200)))
      .subscribe(([wasteId, containerId]) => {
        if (wasteId || containerId) {
          createExecutionService
            .setServiceId(execution.id, wasteId, containerId, undefined)
            .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
        }
      });

    return () => {
      _queryParams.unsubscribe();
    };
  }, []);

  useEffect(() => {
    wasteId$.next(execution.wasteId);
  }, [execution.wasteId]);

  useEffect(() => {
    containerId$.next(execution.containerId);
  }, [execution.containerId]);
};
