import { EventCalendar } from 'components/FullCalendar/TestData';
import React from 'react';
import { isMobile } from 'utils/isMobile';
import MobileCard from '../mobileCard';
import WebCard from '../webCard';
import getStyleFromEvent from '../getStyleFromEvent';

const isMobiles = isMobile();

const renderEventContent = (eventInfo: EventCalendar, classes) => {
  const styleEvent = getStyleFromEvent(eventInfo);
  return (
    <div
      className={`${classes.eventDayContainer} ${classes.eventDayContainerGlass}`}
      style={{
        background: styleEvent.background,
        borderLeft: styleEvent.color,
      }}
    >
      {isMobiles ? <MobileCard eventInfo={eventInfo} /> : <WebCard eventInfo={eventInfo} />}
    </div>
  );
};

export default renderEventContent;
