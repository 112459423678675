import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import {
  AvailableClientUnit,
  CreateExecutionUIState,
  ExecutionDetailsUi,
  IdenticalExecution,
  Service,
  ServiceKindLight,
} from './create-execution.model';

export interface CreateExecutionState extends EntityState<ExecutionDetailsUi> {
  parentExecutionId: ID;
  serviceKinds?: ServiceKindLight[];
  services?: Service[];
  ui: CreateExecutionUIState;
  // So other views can subscribe on it and fetch executions again (list and planning)
  reloadExecution: boolean;
  matchingServices?: Service[];
  haulingDestinationLoading: boolean;
  identicalExecutions?: IdenticalExecution[];
  availableClientsUnits?: AvailableClientUnit[];
  selectedClientUnit?: number;
}

const initialState: CreateExecutionState = {
  parentExecutionId: 0,
  serviceKinds: undefined,
  services: undefined,
  ui: {
    isSaving: false,
    isModalOpen: false,
    isEditModalOpen: false,
    isAccepting: false,
    isDenying: false,
    isSavingMarkAsComplete: false,
    highlightDates: false,
  },
  matchingServices: undefined,
  reloadExecution: false,
  haulingDestinationLoading: false,
};

@StoreConfig({ name: 'createExecution', resettable: true })
export class CreateExecutionStore extends EntityStore<CreateExecutionState> {
  constructor() {
    super(initialState);
  }

  setHighlightDates = (value: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        highlightDates: value,
      },
    }));
  };

  setIsSaving = (isSaving: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isSaving,
      },
    }));
  };

  setClientUnits = (availableClientsUnits: any[]) => {
    this.update((state) => ({
      ...state,
      availableClientsUnits,
    }));
  };

  setSelectedClientUnit = (selectedClientUnit: number) => {
    this.update((state) => ({
      ...state,
      selectedClientUnit,
    }));
  };

  getSelectedClientUnit = () => {
    return this.getValue().selectedClientUnit;
  };

  setIsAccepting = (isAccepting: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isAccepting,
      },
    }));
  };

  setIsDenying = (isDenying: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isDenying,
      },
    }));
  };

  setIsSavingMarkAsComplete = (isSavingMarkAsComplete: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isSavingMarkAsComplete,
      },
    }));
  };

  setIsModalOpen = (isModalOpen: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isModalOpen,
      },
    }));
  };

  isEditModalOpen = (isEditModalOpen: boolean) => {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isEditModalOpen,
      },
    }));
  };

  reloadExecutions = () => {
    this.update((state) => ({
      ...state,
      reloadExecution: true,
    }));
  };

  setMainExecution = (mainExecution: ExecutionDetailsUi) => {
    this.update((state) => ({
      ...state,
      parentExecutionId: mainExecution.id,
    }));
    this.upsertMany([mainExecution]);
    this.setActive(mainExecution.id);
  };

  setIdenticalExecutions(identicalExecutions: IdenticalExecution[]) {
    this.update((state) => ({
      ...state,
      identicalExecutions,
    }));
  }
}

export const createExecutionStore = new CreateExecutionStore();
