import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { createExecutionQuery } from 'store/operations/createExecution';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { operationExecutionsService } from '../operation-executions.service';
import SnackError from 'utils/models/snackError';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { operationExecutionsQuery } from '../operation-executions.query';
import { filtersQuery } from 'store/common/filters/filters.query';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';

export interface OperationFilters {
  from?: Date;
  to?: Date;
  status?: ExecutionStatusEnum;
  searchString?: string;
  toPlan?: boolean;
  onlyWithSchedule?: boolean;
}

export const useFetchExecutionSearch = (page?: number, filters?: OperationFilters) => {
  const { enqueueSnackbar } = useSnackbar();

  const page$ = useRef(new Subject<number>()).current;
  const filters$ = useRef(new Subject<OperationFilters>()).current;

  const [executions, setExecutions] = useState<ExecutionDto[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const _queryParams = combineLatest([
      combineLatest([page$, filters$]),
      combineLatest([
        createExecutionQuery.needToReloadExecutions$,
        organisationsQuery.activeOrganisationId$,
        organisationUnitsQuery.activeOrgUnitIds$,
        filtersQuery.currentFilters$,
      ]),
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([[page, filters], [_, activeOrgId, activeOrgUnitIds, currentFilters]]) => {
        operationExecutionsService
          .fetchExecutionSearch(
            page,
            'execution.plannedFrom',
            'DESC',
            activeOrgId,
            activeOrgUnitIds,
            filters,
            currentFilters
          )
          .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
      });
    const _total = operationExecutionsQuery.total$.subscribe(setTotal);
    const _executions = operationExecutionsQuery.executions$.subscribe(setExecutions);
    return () => {
      _queryParams.unsubscribe();
      _total.unsubscribe();
      _executions.unsubscribe();
    };
  }, []);

  useEffect(() => page$.next(page), [page]);
  useEffect(() => filters$.next(filters), [filters]);

  return { executions, total };
};
