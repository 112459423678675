import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useMaterialCheckBoxStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputLabel: {
    paddingTop: '10px !important',
  },
}));
