import { alpha } from '@mui/material';

export const Colors = {
  /** Primary */
  primary: '#37DC58',
  primaryClear: alpha('#37DC58', 0.1),
  darkGreen: '#29BD52',
  /** Secondary */
  lightGray: '#EDF1F7',
  secondary: '#03053D',
  darkGray: '#848F9D',
  /** Neutral */
  lightNeutral: '#FAFAFB',
  white: '#FFFFFF',
  /** Error */
  lightRed: '#e6433c11',
  mainRed: '#F6CAC8',
  darkRed: '#E6433C',
  /** Warning */
  mainOrange: '#FF8B21',
  /** Info */
  mainBlue: '#51A2ED',
  /** Success */
  lightGreen: '#C5E4D9',
  mainGreen: '#29BD52',
  /** Text */
  darkBlue: '#172134',
  grey: '#9DA8B6',
  /** Background */
  background: '#F4F6F8',
  steel: '#008B21',

  /** Other */
  greenCardEco: '#29bd5211',
  neutralText: '#131415',
  primaryText: '#232323',
  buttonShadow: 'rgba(0, 0, 0, 0.04)',
  defaultInputBackground: '#EDF1F7',
  whiteInputBackground: '#FFFFFF',
  red: '#DB4343',
  gray: '#9DA8B6',
  greenRanking: '#A9DDBF',
  grayRanking: '#17213440',
  blueColored: '#48547E',
  greenColored: '#37DC58',
  hoverButton: '#31AE4B',
  hoverFields: 'rgba(0, 0, 0, 0.09)',
  greenStatus: '#51C972',
  blueStatus: '#71B3F0',
  redStatus: '#E9665A',
  greyStatus: '#E6E6E6',
};

export const Sizes = {
  drawerWidth: 62,
  leftBar: 70,
  topBar: 77,
  drawerInputLabelWidth: 140,
  unitDetailsInputLabelWidth: 120,
  datePickerWidth: 160,
  labelMatchWithWidth: 150,
};

export const Transitions = {
  cubic: '0.3s cubic-bezier(.47,1.64,.41,.8)',
};
