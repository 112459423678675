import { globalWeekDays } from 'utils/dayjs.utils';
import i18n from 'utils/data/i18n.utils';

export enum WeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum NumeralDayOfMonth {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
  LAST = 'LAST',
}

export interface AuditOperationScheduleDto {
  frequency?: AiScheduleEnum;
  dayOfMonth?: number; // MENSUEL
  isDayOfMonth?: boolean;
  monthlyFrequencyNumber?: number; // MENSUEL
  weeklyFrequencyNumber?: number; // HEBDOMADAIRE
  daysOfWeek?: string; // HEBDOMADAIRE
  dailyFrequencyNumber?: number; // QUOTIDIEN  -- IF -1 -> every working days
  dailyEveryWeekDay?: boolean;
  numeralDayOfMonth?: NumeralDayOfMonth;
  weekDayForMonth?: WeekDay;
}

export enum AiScheduleEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export function getWeekDayListFromString(days: string) {
  const array = days.split(',');
  const filteredArray = array.filter((item) => item !== '');
  const formatted = filteredArray.map((day) => getWeekDayTranslationFromString(day));
  return formatted.join(', ');
}

export function getWeekDayTranslationFromString(value: string) {
  switch (value) {
    case 'lun.':
      return globalWeekDays[0];
    case 'mar.':
      return globalWeekDays[1];
    case 'mer.':
      return globalWeekDays[2];
    case 'jeu.':
      return globalWeekDays[3];
    case 'ven.':
      return globalWeekDays[4];
    case 'sam.':
      return globalWeekDays[5];
    case 'dim.':
      return globalWeekDays[6];
    default:
      return globalWeekDays[0];
  }
}

export function getNumeralDayOfMonthTranslation(value: NumeralDayOfMonth) {
  switch (value) {
    case NumeralDayOfMonth.FIRST:
      return i18n.t('global.numeralDayOfMonth.first');
    case NumeralDayOfMonth.SECOND:
      return i18n.t('global.numeralDayOfMonth.second');
    case NumeralDayOfMonth.THIRD:
      return i18n.t('global.numeralDayOfMonth.third');
    case NumeralDayOfMonth.FOURTH:
      return i18n.t('global.numeralDayOfMonth.fourth');
    case NumeralDayOfMonth.LAST:
      return i18n.t('global.numeralDayOfMonth.last');
    default:
      return i18n.t('global.numeralDayOfMonth.last');
  }
}

export function getWeekDayTranslation(value: WeekDay) {
  switch (value) {
    case WeekDay.MONDAY:
      return globalWeekDays[0];
    case WeekDay.TUESDAY:
      return globalWeekDays[1];
    case WeekDay.WEDNESDAY:
      return globalWeekDays[2];
    case WeekDay.THURSDAY:
      return globalWeekDays[3];
    case WeekDay.FRIDAY:
      return globalWeekDays[4];
    case WeekDay.SATURDAY:
      return globalWeekDays[5];
    case WeekDay.SUNDAY:
      return globalWeekDays[6];
    default:
      return globalWeekDays[0];
  }
}
