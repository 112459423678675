import { Store, StoreConfig } from '@datorama/akita';

import { Session } from './session.model';

const initSession = (): Session => {
  return {
    user: undefined,
    // user: {
    // 	id: 1,
    // 	company: "EDF",
    // 	email: "cchaillan@dev-id.fr",
    // 	// role: Role.BUSINESS_UNIT,
    // 	role: Role.ADMIN,
    // 	// role: Role.COMPANY,
    // },
  };
};

@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<Session> {
  constructor() {
    super(initSession());
  }

  // login = (loginResponse: LoginResponseDto) => {
  //   APIAxios.defaults.headers.common['Authorization'] = `Bearer ${loginResponse.accessToken}`;
  //
  //   localStorage.setItem('token', btoa(loginResponse.accessToken));
  //
  //   const role = loginResponse.user.role;
  //
  //   this.update({
  //     token: loginResponse.accessToken,
  //     currentRole: role,
  //     currentCompany: loginResponse.user.company,
  //     currentBusinessUnit: loginResponse.user.businessUnit,
  //     user: loginResponse.user,
  //   });
  //
  //   localStorage.setItem('selectedRole', role);
  // };
}

export const sessionStore = new SessionStore();
