import React from 'react';
import GridRowDate from 'components/MaterialDatagrid/Rows/GridRowDate';
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import i18n, { formatTranslatedName } from 'utils/data/i18n.utils';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import Pickup from 'screens/auth/dashboard/patrick/cell/pickup';
import { formatAddress } from 'api/dto/client/AddressDto';
import Container from 'screens/auth/dashboard/patrick/cell/container';
import ContainerMobile from 'screens/auth/dashboard/patrick/cell/container/mobile';
import MaterialTag from 'components/MaterialDatagrid/Rows/MaterialTag';
import { translateExecutionStatus } from 'utils/enums/ExecutionStatusEnum';
import IconWaste from './cell/IconWaste';
import IconType from './cell/iconType/iconType';
import { Group } from 'screens/auth/dashboard/patrick/cell/group/group';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
  formatWeight,
  formatWeightUnit,
} from 'store/dashboard/operationScreenStats/utils/formatWeight';

interface ColumnTypes {
  [key: string]: GridColDef;
}

function getColumnTypes<T extends { [keyName: string]: GridColDef }>(types: T) {
  return types;
}

interface GetColumnsParams {
  isDesktop: boolean;
  openModal?: () => void;
  isMorgan?: boolean;
  isCompleted?: boolean;
}

export const executionColumns = (params: GetColumnsParams): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    pickup: {
      field: 'pickup',
      headerName: i18n.t('fe.operations.table.document'),
      flex: 2,
      cellClassName: !params.isDesktop ? 'column-mobile' : '',
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) => (
        <Pickup
          params={params.isDesktop}
          ticketSignature={renderParams.row.name}
          name={
            params.isMorgan
              ? renderParams.row.supplierOrganisationUnit.name ?? ''
              : renderParams.row.organisationUnit?.name ?? ''
          }
          address={
            renderParams.row.organisationUnit?.address
              ? formatAddress(renderParams.row.organisationUnit.address)
              : ''
          }
        />
      ),
    },
    date: {
      field: 'plannedFrom',
      headerName: i18n.t('fe.operations.table.update'),
      flex: 1,
      renderCell: (params) => (
        <GridRowDate date={params.value as Date} endTime={params.row.plannedTo} />
      ),
    },
    type: {
      field: 'type',
      headerName: i18n.t('fe.operations.table.type'),
      cellClassName: 'value-14-400',
      width: 130,
      valueGetter: (renderParams: GridValueGetterParams<any, ExecutionDto>) =>
        renderParams.row.generatedBySchedule
          ? i18n.t('fe.operations.table.recurrent')
          : i18n.t('fe.operations.table.onDemand'),
    },
    containers: {
      field: 'container.name',
      headerName: i18n.t('fe.operations.table.container'),
      flex: 1,
      // valueGetter: (params) => params.row.container.name,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) =>
        renderParams.row.executionGroup ? (
          <Group executionGroup={renderParams.row.executionGroup} />
        ) : (
          <Container
            container={i18n.t('fe.dashboard.patrick.table.quantity', {
              quantity: renderParams.row.quantity1,
            })}
            wasteName={formatTranslatedName(
              renderParams.row.service.waste?.nameTranslateKey,
              renderParams.row.service.waste?.name
            )}
            containerName={formatTranslatedName(
              renderParams.row.service.container?.nameTranslateKey,
              renderParams.row.service.container?.name
            )}
          />
        ),
    },
    containersMobile: {
      field: 'containers',
      headerName: i18n.t('fe.operations.table.container'),
      flex: 1,
      sortable: false,
      // cellClassName: 'title-mobile',
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) => (
        <ContainerMobile
          isOperation
          execution={renderParams.row}
          params={renderParams}
          onClick={(isAccepted) => params.openModal?.()}
        />
      ),
    },
    status: {
      field: 'status',
      headerName: i18n.t('fe.operations.table.status'),
      // width: 130,
      flex: 1,
      cellClassName: !params.isDesktop ? 'column-mobile' : '',
      renderCell: (params) => (
        <MaterialTag noWidthLimit title={translateExecutionStatus(params.value)} />
      ),
    },
    icon: {
      field: 'iconType',
      headerName: i18n.t('fe.operations.table.icon'),
      cellClassName: 'value-14-700',
      width: 130,
      renderCell: (renderParams) => <IconType params={renderParams} />,
    },
    waste: {
      field: 'iconWaste',
      headerName: i18n.t('fe.operations.table.waste'),
      cellClassName: 'value-14-700',
      width: 130,
      renderCell: (renderParams) => <IconWaste params={renderParams} />,
    },
    executionDetails: {
      field: 'executionDetails',
      headerName: i18n.t('fe.operations.table.executionDetails'),
      flex: 1,
      cellClassName: !params.isDesktop ? 'column-mobile' : '',
      renderCell: (params: GridRenderCellParams<any, ExecutionDto, any>) => (
        <Stack direction="column">
          <Typography style={{ fontSize: 12, fontWeight: 400 }}>
            {[
              i18n.t('fe.operations.table.executedAt'),
              dayjs(params.row.executedAt).format('DD.MM.YYYY'),
            ].join(' : ')}
          </Typography>
          <Typography style={{ fontSize: 12, fontWeight: 400 }}>
            {[
              [i18n.t('fe.operations.table.weight'), formatWeight(params.row.quantity2 || 0)].join(
                ' : '
              ),
              formatWeightUnit(params.row.quantity2 || 0),
            ].join(' ')}
          </Typography>
        </Stack>
      ),
    },
  });
  if (params.isDesktop) {
    if (params.isCompleted) {
      return [
        COLUMN_TYPES.waste,
        COLUMN_TYPES.pickup,
        COLUMN_TYPES.type,
        COLUMN_TYPES.date,
        COLUMN_TYPES.containers,
        COLUMN_TYPES.executionDetails,
        COLUMN_TYPES.status,
        COLUMN_TYPES.icon,
      ];
    }
    return [
      COLUMN_TYPES.waste,
      COLUMN_TYPES.pickup,
      COLUMN_TYPES.type,
      COLUMN_TYPES.date,
      COLUMN_TYPES.containers,
      COLUMN_TYPES.status,
      COLUMN_TYPES.icon,
    ];
  }

  return [
    COLUMN_TYPES.containersMobile,
    //COLUMN_TYPES.status,
    // COLUMN_TYPES.pickup,
    // COLUMN_TYPES.actions,
  ];
};
