import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Sizes } from 'style/materialStylings';

export const useAppBarHeaderStyles = makeStyles((theme: Theme) => ({
  appBarShift: {
    width: `calc(100% - ${Sizes.drawerWidth}px) !important`,
    marginLeft: `${Sizes.drawerWidth}px !important`,
    borderRadius: '0px !important',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
      marginLeft: `0px !important`,
    },
  },
  divider: {
    padding: '8px 20px',
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
  hideForMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
  hideForDesktop: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  buttonContainer: {
    textDecoration: 'none !important',
    textTransform: 'none',
    fontSize: '12px !important',
    lineHeight: 'inherit !important',
    letterSpacing: 'inherit !important',
    marginLeft: '27px !important',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0px !important',
    },
  },
  iconContainer: {
    marginLeft: '20px !important',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0px !important',
    },
  },
  reponsiveWidth: {
    marginLeft: '0px !important',
    marginRight: '0px !important',
    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
    },
  },
  iconResponsive: {
    [theme.breakpoints.down('lg')]: {
      margin: '0px !important',
    },
  },
}));
