import { QueryEntity } from '@datorama/akita';
import { OrganisationsState, organisationsStore, OrganisationsStore } from './organisations.store';
import { map } from 'rxjs/internal/operators';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { formatAddress } from 'api/dto/client/AddressDto';

export class OrganisationsQuery extends QueryEntity<OrganisationsState> {
  constructor(protected store: OrganisationsStore) {
    super(store);
  }

  organisations$ = this.selectAll();
  organisationsAsAutocomplete$ = this.selectAll().pipe(
    map((organisations) =>
      organisations.map(
        (organisation): AutocompleteData => ({
          id: organisation.id,
          title: organisation.name,
          description: formatAddress(organisation.address),
        })
      )
    )
  );
  activeOrganisation$ = this.select((state) => state.activeOrganisation);
  activeOrganisationAsAutocomplete$ = this.activeOrganisation$.pipe(
    map((organisation): AutocompleteData | undefined =>
      organisation
        ? {
            id: organisation.id,
            title: organisation.name,
            description: formatAddress(organisation.address),
          }
        : undefined
    )
  );
  activeOrganisationId$ = this.select((state) => state.activeOrganisationId);

  count$ = this.select((state) => state.count);
  total$ = this.select((state) => state.total);

  get activeOrganisationId() {
    return this.getValue().activeOrganisationId;
  }
}

export const organisationsQuery = new OrganisationsQuery(organisationsStore);
