import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { createEffectFn } from '@ngneat/effects';
import { Array } from 'utils/extensions/array.extensions';
import { ReactNode } from 'react';

export interface SelectItem {
  label: string;
  value: any;
  data?: any;
  backgroundColor?: string;
  color?: string;
  icon?: ReactNode;
}

export enum TableFilterType {
  SUPPLIER_ORGANISATION = 'SUPPLIER_ORGANISATION',
  SUPPLIER_ORGANISATION_UNIT = 'SUPPLIER_ORGANISATION_UNIT',
  CLIENT_ORGANISATION = 'CLIENT_ORGANISATION',
  CLIENT_ORGANISATION_UNIT = 'CLIENT_ORGANISATION_UNIT',
  SERVICE_KIND = 'SERVICE_KIND',
  WASTE = 'WASTE',
  WASTE_FAMILY = 'WASTE_FAMILY',
  CONTAINER = 'CONTAINER',
  ORGANISATION_TYPOLOGY = 'ORGANISATION_TYPOLOGY',
  ORGANISATION_DOMAIN = 'ORGANISATION_DOMAIN',
  CONTRACT = 'CONTRACT',
}

export const initTableFilters = (types: TableFilterType[]): TableFilter[] =>
  types.map((type) => ({
    type,
    values: [],
  }));

export interface TableFilter {
  type: TableFilterType;
  values: SelectItem[];
  hide?: boolean;
  disabled?: boolean;
}

interface SearchParams {
  getOptions: (search?: string) => Observable<SelectItem[]>;
  search?: string;
  setLoading: (loading: boolean) => void;
  setOptions: (options: any[]) => void;
}

export const searchEffect = createEffectFn((search$: Observable<SearchParams>) => {
  return search$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(({ setLoading }) => setLoading(true)),
    switchMap(({ getOptions, search, setLoading, setOptions }) =>
      getOptions(search).pipe(
        tap((options) => {
          setOptions(options);
          setLoading(false);
        })
      )
    )
  );
});

export const getRequestField = (type: TableFilterType) => {
  switch (type) {
    case TableFilterType.SUPPLIER_ORGANISATION:
      return 'supplierOrganisationIds';
    case TableFilterType.SUPPLIER_ORGANISATION_UNIT:
      return 'supplierOrganisationUnitIds';
    case TableFilterType.CLIENT_ORGANISATION:
      return 'clientOrganisationIds';
    case TableFilterType.CLIENT_ORGANISATION_UNIT:
      return 'clientOrganisationUnitIds';
    case TableFilterType.SERVICE_KIND:
      return 'serviceKindIds';
    case TableFilterType.WASTE:
      return 'wasteIds';
    case TableFilterType.WASTE_FAMILY:
      return 'wasteFamilyIds';
    case TableFilterType.CONTAINER:
      return 'containerIds';
    case TableFilterType.ORGANISATION_TYPOLOGY:
      return 'typologiesFilter';
    case TableFilterType.ORGANISATION_DOMAIN:
      return 'domainsFilter';
    case TableFilterType.CONTRACT:
      return 'contractIds';
    default:
      return '';
  }
};

export const toRequestFilters = (filters: TableFilter[]): { [key: string]: string | undefined } =>
  filters.reduce((filters, filter) => {
    return {
      ...filters,
      [getRequestField(filter.type)]: Array.formatJson(
        Array.mapNotNull(filter.values?.map((v) => v.value))
      ),
    };
  }, {} as { [key: string]: string | undefined });
