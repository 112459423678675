import { Query } from '@datorama/akita';
import { PatrickStatsState, PatrickStatsStore, patrickStatsStore } from './patrick-stats.store';

export class PatrickStatsQuery extends Query<PatrickStatsState> {
  constructor(protected store: PatrickStatsStore) {
    super(store);
  }

  isIssueCountStatusLoading$ = this.select((stats) => stats.issueStatus.isLoading);
  getIssueCountStatus$ = this.select((stats) => stats.issueStatus.stats);
}

export const patrickStatsQuery = new PatrickStatsQuery(patrickStatsStore);
