import { Typography } from '@mui/material';
import React from 'react';

interface TextColumnPros {
  value: string;
}

export default function COLUMN_TYPE_TEXT(props: TextColumnPros) {
  const { value } = props;
  return <Typography fontSize="14px">{value}</Typography>;
}
