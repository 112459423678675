import { Grid, Typography } from '@mui/material';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield/MaterialBasicTextfield';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ExecutionIssueCollectFormProps {
  executionTicketSignature: string;
}

const IssueCollectForm = (props: ExecutionIssueCollectFormProps) => {
  const { executionTicketSignature } = props;
  const { t } = useTranslation();
  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          {t('fe.issueModal.issueInfos.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          label={t('fe.issueModal.issueInfos.ticketNumberLabel')}
          type="text"
          placeholder={t('fe.issueModal.issueInfos.ticketNumberPlaceholder')}
          color="primary"
          value={executionTicketSignature}
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default IssueCollectForm;
