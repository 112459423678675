export const DATE_FORMAT_LONG = 'dd.MM.yyyy HH:mm'; // 12.12.2021 04:12
export const DATE_FORMAT_LONG_LOOSE = 'dd.MM.yyyy H:mm';
export const DATE_FORMAT_SHORT = 'dd.MM.yyyy'; // 12.12.2021
export const DATE_FORMAT_ULTA_SHORT = 'DD/MM/YY'; // 12/12/21
export const DATE_FORMAT_SHORT_DAY = 'D'; // 12
export const DATE_FORMAT_MONTH_ABREVIATION = 'MMM'; // JAN
export const DATE_FORMAT_SHORT_YEAR = 'YYYY'; // 2022
export const DATE_FORMAT_SHORT_MOBILE = 'DD/MM'; // 10/02
export const DATE_FORMAT_LONG_SEPARATED = "dd.MM.yyyy' • 'HH:mm"; // 12.12.2021 • 04:12
export const DATE_FORMAT_ISO = 'yyyy-MM-dd';
export const DATE_FORMAT_ISO_DATETIME = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DAY_OF_WEEK = "EEEE', 'H:mm";
export const DATE_FORMAT_DAY_OF_WEEK_CALENDAR = 'EEEE dd MMM';
export const DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_LETTER = 'EE';
export const DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_NUMBER = 'dd';

// date formats for parsing

export const DATE_FORMAT_HOUR = 'dd.MM.yyyy H'; // 12.12.2021 21
export const DATE_FORMAT_HOUR_12 = 'dd.MM.yyyy h a'; // 12.12.2021 6 AM'
export const TIME_FORMAT = 'HH:mm'; // '12:04 AND 04:15
export const TIME_FORMAT_LOOSE = 'H:mm'; // 4:15
export const TIME_FORMAT_HOUR_12 = 'hh:mm a'; // 04:12 AM
export const TIME_FORMAT_HOUR_12_LOOSE = 'h:mm a'; // 4:12 AM

// formats grouped by type

export const PARSING_DATETIME = [
  DATE_FORMAT_LONG,
  DATE_FORMAT_HOUR,
  DATE_FORMAT_HOUR_12,
  DATE_FORMAT_LONG_SEPARATED,
  DATE_FORMAT_ISO,
];
export const PARSING_DATE = [DATE_FORMAT_SHORT];
