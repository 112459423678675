import { Drawer, IconButton, Typography, Box } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import { useBottomModalStyles } from './BottomModal.style';

interface BottomModalProps {
  handleClickCloseModal?: () => void;
  // eslint-disable-next-line no-undef
  children?: JSX.Element | JSX.Element[];
  title?: string;
  rightIcon?: boolean;
}

const BottomModal: FC<BottomModalProps> = (props) => {
  const { handleClickCloseModal, children, title, rightIcon = false } = props;
  const classes = useBottomModalStyles();

  return (
    <Drawer anchor="bottom" open={true} onClose={handleClickCloseModal}>
      <div className={classes.iconPosition}>
        {rightIcon ? (
          <>
            <Box className={classes.boxSize} />
            <Typography variant="h3">{title}</Typography>
            <IconButton onClick={handleClickCloseModal}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleClickCloseModal}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h3">{title}</Typography>
            <Box className={classes.boxSize} />
          </>
        )}
      </div>
      <div className={classes.content}>{children}</div>
    </Drawer>
  );
};

export default BottomModal;
