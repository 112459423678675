import { ID, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/internal/operators';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import {
  ExecutionWeekPlanningState,
  ExecutionWeekPlanningStore,
  executionWeekPlanningStore,
} from './execution-week-planning.store';
import { removeDuplicateCallback } from 'utils/data/array.utils';
import { combineLatest } from 'rxjs';
import {
  ExecutionBetweenDatesDto,
  formatMultipleToEventCalendar,
  formatToEventCalendar,
} from 'api/dto/execution/ExecutionBetweenDatesDto';
import { formatTranslatedName } from 'utils/data/i18n.utils';
import dayjs from 'utils/data/dayjs.utils';

export class ExecutionWeekPlanningQuery extends QueryEntity<ExecutionWeekPlanningState> {
  constructor(protected store: ExecutionWeekPlanningStore) {
    super(store);
  }

  filteredWasteIds$ = this.select((state) => state.filteredWasteIds);

  planning$ = (isMobile: boolean) =>
    combineLatest([this.selectAll(), this.filteredWasteIds$]).pipe(
      map(([executions, filteredWasteIds]) => {
        const filteredExecutions = executions.filter(
          (execution) =>
            this.isMainExecution(execution) &&
            this.isExecutionWithSelectedWastes(execution, filteredWasteIds)
        );

        const execsGroups: {
          from: Date;
          to: Date;
          execs: ExecutionBetweenDatesDto[];
        }[] = filteredExecutions.reduce(
          (
            acc: {
              from: Date;
              to: Date;
              execs: ExecutionBetweenDatesDto[];
            }[],
            val
          ) => {
            const groupIndex = acc.findIndex(
              (g) =>
                dayjs(g.from).format('DD/MM/YYYY HH:mm') ===
                  dayjs(val.plannedFrom).format('DD/MM/YYYY HH:mm') &&
                dayjs(g.to).format('DD/MM/YYYY HH:mm') ===
                  dayjs(val.plannedTo).format('DD/MM/YYYY HH:mm')
            );

            if (groupIndex > -1) {
              acc[groupIndex].execs.push(val);
            } else {
              acc.push({ from: val.plannedFrom, to: val.plannedTo, execs: [val] });
            }

            return acc;
          },
          []
        );

        return execsGroups.map((g) =>
          g.execs.length < 2
            ? formatToEventCalendar(g.execs[0])
            : formatMultipleToEventCalendar(g.execs)
        );

        // let execsToGroup: ExecutionBetweenDatesDto[][] = [];
        // filteredExecutions.forEach((exec) => {
        //   if (execsToGroup.some((group) => group.some((it) => it.id === exec.id))) {
        //     return;
        //   }
        //   const execsBetweenTime = filteredExecutions.filter((it) => {
        //     if (it.id === exec.id) {
        //       return false;
        //     }
        //     if (execsToGroup.some((group) => group.some((it2) => it.id === it2.id))) {
        //       return false;
        //     }
        //     return (
        //       dayjs(it.plannedFrom).format('DD/MM/YYYY HH:mm') ===
        //         dayjs(exec.plannedFrom).format('DD/MM/YYYY HH:mm') &&
        //       dayjs(it.plannedTo).format('DD/MM/YYYY HH:mm') ===
        //         dayjs(exec.plannedTo).format('DD/MM/YYYY HH:mm')
        //     );
        //   });
        //   if (execsBetweenTime.length >= 2) {
        //     execsToGroup = [...execsToGroup, [...execsBetweenTime, exec]];
        //   }
        // });
        // const singleExecs = filteredExecutions.filter(
        //   (exec) =>
        //     this.isMainExecution(exec) &&
        //     !execsToGroup.some((group) => group.some((it) => it.id === exec.id))
        // );
        //
        // console.log('SINGLE EXECS', singleExecs);
        // console.log(
        //   'EXECS GROUPS',
        //   execsToGroup.reduce((acc, val) => acc + val.length, 0)
        // );
        //
        // return [
        //   ...singleExecs.map(formatToEventCalendar),
        //   ...execsToGroup.map(formatMultipleToEventCalendar),
        // ];
      })
    );

  wastes$ = this.selectAll().pipe(
    map((executions) => {
      const allWastes =
        executions.map(
          (execution): MaterialDropdownItem => ({
            label: formatTranslatedName(
              execution.service.waste?.nameTranslateKey,
              execution.service.waste?.name
            ),
            value: execution.service.waste?.id,
          })
        ) ?? [];
      return removeDuplicateCallback(allWastes, (a, b) => a.value === b.value);
    })
  );

  isMainExecution(execution: ExecutionBetweenDatesDto): boolean {
    return execution.executionGroup
      ? execution.id === execution.executionGroup.mainExecutionId
      : execution.executionGroup === null;
  }

  isExecutionWithSelectedWastes(
    execution: ExecutionBetweenDatesDto,
    filteredWasteIds: ID[]
  ): boolean {
    return (
      filteredWasteIds.length === 0 ||
      (filteredWasteIds.length > 0 && filteredWasteIds.includes(execution.service.waste?.id))
    );
  }
}

export const executionWeekPlanningQuery = new ExecutionWeekPlanningQuery(
  executionWeekPlanningStore
);
