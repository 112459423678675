import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    datePicker: {
      width: '80% !important',
      maxWidth: '607px !important',
      '& .MuiIconButton-root': {
        color: theme.palette.text.primary + ' !important',
      },
    },
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    textfield: {
      width: '80% !important',
      maxWidth: '607px !important',
      [theme.breakpoints.only('xs')]: {
        marginTop: '10px !important',
        width: '100% !important',
      },
    },
  });
export default classes;
