import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'style/materialStylings';
import { isMobile } from 'utils/isMobile';

interface MaterialDatePickerButtonProps {
  handleChange: (value: any | null) => void;
  readOnly?: boolean;
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  isPlaceholder?: boolean;
}

const DateColorIcon = (isMobiles: boolean, value: Date | null) => {
  if (isMobiles) {
    return Colors.secondary;
  } else if (value) {
    return Colors.secondary;
  } else {
    return Colors.darkGray;
  }
};

const MaterialDatePickerButton = (props: MaterialDatePickerButtonProps) => {
  const { handleChange, readOnly, minDate, maxDate, value = null, isPlaceholder } = props;
  const { t } = useTranslation();
  const [openPicker, setOpenPicker] = useState(false);
  const isMobiles = isMobile();

  return (
    <DatePicker
      open={openPicker}
      onOpen={() => setOpenPicker(true)}
      onClose={() => setOpenPicker(false)}
      onChange={handleChange}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      readOnly={readOnly}
      // cancelText={
      //   <Button variant="outlined" size="medium">
      //     {t('fe.cancel')}
      //   </Button>
      // }
      // okText={
      //   <Button variant="contained" size="large">
      //     {t('fe.ok')}
      //   </Button>
      // }
      renderInput={(params) => (
        <Button
          ref={params.inputRef}
          variant="basic"
          size="small"
          startIcon={<DateRangeIcon fontSize="small" htmlColor={DateColorIcon(isMobiles, value)} />}
          onClick={() => setOpenPicker(true)}
        >
          {isPlaceholder
            ? (params.inputProps?.value as any | null)?.toString() || (
                <Typography color={Colors.darkGray} fontSize="small">
                  DD.MM.YY
                </Typography>
              )
            : (params.inputProps?.value as any | null)?.toString()}
        </Button>
      )}
      inputFormat="dd MMM yyyy"
      mask=""
    />
  );
};

export default MaterialDatePickerButton;
