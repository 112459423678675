import React, { FC, useMemo, useState } from 'react';
import { Avatar, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useDashboardPatrickStyles } from './DashboardPatrick.style';
import CardBadges from 'components/Card/CardBadges';
import { ReactComponent as TrophyActive } from 'assets/icons/TrophyActive.svg';
import CardOperations from 'components/Card/CardOperations';
import { isMobile } from 'utils/isMobile';
import ActivitiesModalPanel from '../common/sidemodal/ActivitiesModal.panel';
import { useTranslation } from 'react-i18next';
import ExecutionsWaitingComponents from './components/ExecutionsWaiting.components';
import MaterialDropdownDashboard from 'components/MaterialDropdown/MaterialDropdownDashboard/MaterialDropdownDashboard';
import MaterialDatePickerButton from 'components/MaterialDatePicker/MaterialDatePickerButton';
import { OpUnitType } from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import { Colors } from 'style/materialStylings';
import { usePatrickStats } from 'store/dashboard/patrickStats/hooks/usePatrickStats';
import { getEndDate, getStartDate } from 'utils/dates/parse';
import { operationsStatsService } from 'store/dashboard/operationStats';
import { dashboardExecutionsQuery } from 'store/dashboard/dashboardExecutions';
import { useObservable } from '@ngneat/react-rxjs';
import BasicTab from 'components/Tabs/BasicTabs/BasicTabs';
import { MetawasteTab, patrickTabs } from 'utils/enums/MetawasteTab';
import CreateExecutionButtonComponent from '../morgan/components/createExecutionButton/CreateExecutionButton.component';
import { ID } from '@datorama/akita';
import { BookmarkServiceDto, bookmarkStore } from 'store/operations/bookmark';
import { createExecutionService } from 'store/operations/createExecution';
import { isBefore } from 'utils/dates/format';

interface DashboardPatrickProps {
  dateCalendarSelected: Date;
  setDate: (newDate: Date) => void;
}

const DashboardPatrickScreen: FC<DashboardPatrickProps> = (props) => {
  const { dateCalendarSelected, setDate } = props;
  const classes = useDashboardPatrickStyles();
  const isMobiles = isMobile();
  const { t } = useTranslation();
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [startDateButton, setStartDateButton] = useState<Date>(new Date());
  const [currentTab, setCurrentTab] = useState<{ tabType: MetawasteTab; label: string }>(
    patrickTabs()[0]
  );
  const [dropdownDashboard, setDropdownDashboard] = useState<OpUnitType>('month');
  const startDate = useMemo(() => getStartDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);
  const endDate = useMemo(() => getEndDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);
  const [isLoading] = useObservable(dashboardExecutionsQuery.selectLoading());
  const [total] = useObservable(dashboardExecutionsQuery.total$);
  const [totalToConfirm] = useObservable(dashboardExecutionsQuery.totalToConfirm$);
  const { isIssueCountStatusLoading, issueCountStatus } = usePatrickStats(startDate, endDate);
  const getdataStatistics = (issueCountStatus) => {
    const result = operationsStatsService.calculatePercentageEvolution(
      operationsStatsService.calculatePercentage(
        issueCountStatus?.previousIssueCount ?? 0,
        issueCountStatus?.oldExecutionCount ?? 0
      ),
      operationsStatsService.calculatePercentage(
        issueCountStatus?.issueCount ?? 0,
        issueCountStatus?.executionCount ?? 0
      )
    );
    if (result === 0) {
      return undefined;
    }
    return result;
  };

  const handleOpenModal = (
    executionId?: ID,
    dateSelected?: Date,
    bookmarkServices?: BookmarkServiceDto[]
  ) => {
    createExecutionService.openModal(
      executionId,
      isBefore(dateSelected) ? undefined : { dateSelected },
      bookmarkServices
    );

    bookmarkServices && bookmarkStore.isEditModalOpen(true);
  };

  const dataStatistics = useMemo(() => getdataStatistics(issueCountStatus), [issueCountStatus]);

  return (
    <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
      <Stack spacing={2} className={classes.stackContainer}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.container}
        >
          <Grid item>
            <Typography variant="h2" fontSize={isMobiles ? 18 : 20}>
              {t('fe.dashboard.title')}
            </Typography>
          </Grid>
          <Grid item className={classes.hideForDesktop}>
            <IconButton onClick={() => setIsSideModalOpen(true)}>
              <Avatar
                style={{
                  backgroundColor: 'white',
                }}
              >
                <EventIcon
                  style={{
                    color: `${Colors.secondary}`,
                  }}
                />
              </Avatar>
            </IconButton>
          </Grid>
          <Grid item>
            <CreateExecutionButtonComponent
              canOpen={true}
              onClick={() => handleOpenModal(undefined, dateCalendarSelected, undefined)}
              styleButton={classes.createButtonContainer}
            />
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'center' }} spacing={1}>
          <Grid item>
            <Button variant="basic" size="small" onClick={() => setStartDateButton(new Date())}>
              {t('fe.misc.today')}
            </Button>
          </Grid>
          <Grid item>
            <MaterialDropdownDashboard
              required
              // error="Field required"
              color="secondary"
              handleChange={(value) => setDropdownDashboard(value)}
              value={dropdownDashboard}
              items={[
                { label: t('fe.misc.week'), value: 'week' },
                { label: t('fe.misc.month'), value: 'month' },
                { label: t('fe.misc.year'), value: 'year' },
              ]}
            />
          </Grid>
          <Grid item>
            <MaterialDatePickerButton
              value={startDateButton ? new Date(startDateButton) : undefined}
              handleChange={setStartDateButton}
            />
          </Grid>
        </Grid>
        <div>
          <Grid
            container
            item
            spacing={1}
            justifyContent="space-between"
            className={classes.responsiveGridCard}
          >
            <Grid
              item
              xs={isMobiles ? 12 : 4}
              className={classes.responsiveWidthCard}
              style={{ filter: 'blur(1.5px)', opacity: 0.5 }}
            >
              <CardBadges
                title={t(`fe.trophy.title`)}
                data={[
                  { icon: <TrophyActive />, description: 'Ponctualité' },
                  { icon: <TrophyActive />, description: 'Réactivité' },
                  { icon: <TrophyActive />, description: 'Qualité' },
                ]}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4} className={classes.responsiveWidthCard}>
              <CardOperations
                title={t(`fe.dashboard.patrick.issueStatus.title`)}
                isLoading={isIssueCountStatusLoading}
                data={issueCountStatus.issueCount.toString()}
                dataStatistic={dataStatistics}
                background="white"
                percentage={t(`fe.dashboard.patrick.issueStatus.amountConformity`)}
                percentageSubTitle={operationsStatsService
                  .calculatePercentage(
                    issueCountStatus?.issueCount ?? 0,
                    issueCountStatus?.executionCount ?? 0
                  )
                  .toString()}
                description={t(`fe.dashboard.patrick.issueStatus.percentageConformity`)}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4} className={classes.responsiveWidthCard}>
              <CardOperations
                title={t(`fe.dashboard.patrick.executionWithIssue.title`)}
                isLoading={isLoading && !totalToConfirm}
                data={totalToConfirm.toString()}
                background="white"
                description={t(`fe.dashboard.patrick.executionWithIssue.description`)}
              />
            </Grid>
          </Grid>
        </div>
        <Grid item>
          <BasicTab tabs={patrickTabs()} value={currentTab} handleChange={setCurrentTab} />
        </Grid>
        <ExecutionsWaitingComponents currentTab={currentTab} />
      </Stack>
      {isSideModalOpen && isMobiles && (
        <ActivitiesModalPanel
          dateCalendarSelected={dateCalendarSelected}
          setDate={setDate}
          handleClickCloseSideBar={() => setIsSideModalOpen(false)}
        />
      )}
    </Grid>
  );
};

export default DashboardPatrickScreen;
