import { ID, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { CommentExecutionStore, CommentState, executionStore } from './execution-comment.store';

export class CommentExecutionQuery extends QueryEntity<CommentState> {
  constructor(protected store: CommentExecutionStore) {
    super(store);
  }

  getSpecificComment$(executionId: ID) {
    return this.selectAll().pipe(
      map((comments) => comments.filter((comment) => comment.executionId === executionId))
    );
  }
}

export const commentExecutionQuery = new CommentExecutionQuery(executionStore);
