import React, { useState } from 'react';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { createExecutionService, ExecutionDetailsUi } from 'store/operations/createExecution';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { LoadingButton } from '@mui/lab';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';

interface Props {
  executions: ExecutionDetailsUi[];
}

const CancelExecutionButtonComponent = (props: Props) => {
  const { executions } = props;
  const { t } = useTranslation();
  const popConfirm = usePopup();
  const classes = useCreateStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const cancelExecution = () => {
    if (executions?.[0].dto?.status != ExecutionStatusEnum.CREATED) {
      popConfirm({
        title: t('fe.executionModal.cancel.cannotCancelAfterAccepted.title'),
        description: t('fe.executionModal.cancel.cannotCancelAfterAccepted.description'),
        buttons: [
          {
            label: t('fe.executionModal.cancel.cannotCancelAfterAccepted.button'),
            color: 'primary',
            onClick: () => undefined,
          },
        ],
      });
    } else {
      popConfirm({
        title: t('fe.executionModal.confirm.cancel.title'),
        description: t('fe.executionModal.confirm.cancel.description'),
        buttons: [
          {
            label: t('fe.executionModal.confirm.cancel.button'),
            color: 'error',
            onClick: async () => {
              setIsLoading(true);
              try {
                await createExecutionService.cancelExecution(executions[0]);
              } catch (e: any) {
                enqueueSnackbar(e.text, e.options);
              }
              setIsLoading(false);
            },
          },
        ],
      });
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      size="large"
      color="error"
      onClick={cancelExecution}
      loading={isLoading}
    >
      {t('fe.executionModal.buttons.cancel')}
    </LoadingButton>
  );
};

export default CancelExecutionButtonComponent;
