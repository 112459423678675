import React, { useState } from 'react';
import { Button, Chip, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableFilter, TableFilterType } from 'components/Select/SelectItem.interface';
import { filtersQuery } from 'store/common/filters/filters.query';
import TableFiltersDrawer from 'components/Filters/Filters.drawer';
import { Cross } from 'assets/icons';
import { useObservable } from '@ngneat/react-rxjs';
import { filtersService } from 'store/common/filters/filters.service';

interface TableFiltersComponentProps {
  filtersTypes: TableFilterType[];
}

const TableFiltersComponent = (props: TableFiltersComponentProps) => {
  const { filtersTypes } = props;

  const { t } = useTranslation();
  const [filters] = useObservable(filtersQuery.currentFilters$);

  const [openFilters, setOpenFilters] = useState(false);

  const filteredFilters = filters.filter((f) => !f.hide && !!f.values.length);

  const handleSave = (newFilters: TableFilter[]) => filtersService.setCurrentFilters(newFilters);
  const handleReset = () => filtersService.reset();

  return (
    <>
      <Stack alignItems="flex-end" spacing={1}>
        <Stack direction="row" spacing={1.5} height="40px" alignItems="center">
          <Button
            onClick={handleReset}
            variant="contained"
            color="primary"
            startIcon={<Cross />}
            size="small"
          >
            {t('global.resetFilters')}
          </Button>
          <Divider variant="fullWidth" orientation="vertical" sx={{ height: '30px' }} />
          <Button
            onClick={() => setOpenFilters(true)}
            variant="outlined"
            color="primary"
            size="small"
          >
            {t('global.filters')}
          </Button>
        </Stack>
        {!!filteredFilters.length && (
          <Stack direction="row-reverse" alignItems="center" spacing={1}>
            {filteredFilters.map((f, index) => (
              <Chip
                key={`Active filter ${f.type}`}
                disabled={f.disabled}
                onDelete={
                  !f.disabled
                    ? () =>
                        handleSave(
                          filters.map((of) => (of.type === f.type ? { ...of, values: [] } : of))
                        )
                    : undefined
                }
                label={`${t(`global.filterFieldType.${f.type}`)} (${f.values.length})`}
                size="small"
              />
            ))}
          </Stack>
        )}
      </Stack>
      {openFilters && (
        <TableFiltersDrawer
          filters={filters.filter((f) => filtersTypes.includes(f.type))}
          handleSave={(newFilters) => {
            handleSave(newFilters);
            setOpenFilters(false);
          }}
          handleClose={() => setOpenFilters(false)}
        />
      )}
    </>
  );
};

export default TableFiltersComponent;
