import { useObservable } from '@ngneat/react-rxjs';
import Sidebar from 'components/Sidebar';
import { obSidebarPages, sidebarPages } from 'constants/Routes';
import { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { onbordingStyle } from 'store/onbording/onbording.data';
import { onbordingQuery } from 'store/onbording/onbording.query';
import { onbordingService } from 'store/onbording/onbording.service';
import { isMobile } from 'utils/isMobile';
import CustomTooltip from './CustomTooltip';
import ObAppBarHeader from './ObAppBarHeader';
import ObAuthedPage from './ObAuthedPage';

const getCurrentRouteIndex = () => {
  const currentRouteIndex = sidebarPages().findIndex((page) =>
    window.location.pathname.includes(page.route)
  );
  return currentRouteIndex !== -1 ? currentRouteIndex : 0;
};

const ObAuthed = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(getCurrentRouteIndex());
  const isMobiles = isMobile();

  const [run] = useObservable(onbordingQuery.run$);
  const [steps] = useObservable(onbordingQuery.steps$);
  const [stepIndex] = useObservable(onbordingQuery.stepIndex$);

  useEffect(() => {
    history.listen(() => updateCurrentPage(getCurrentRouteIndex()));
  }, []);

  const updateCurrentPage = (newPage: number) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Joyride
        callback={onbordingService.handleJoyrideCallback}
        continuous
        run={run}
        disableScrolling
        steps={steps}
        stepIndex={stepIndex}
        tooltipComponent={(props) => (
          <CustomTooltip {...props} isLastStep={stepIndex === steps.length - 2} />
        )}
        styles={{
          ...onbordingStyle,
          overlay: {
            ...onbordingStyle.overlay,
            position: 'fixed',
          },
        }}
      />
      <ObAppBarHeader handleClick={() => undefined} />
      {!isMobiles && (
        <Sidebar
          value={currentPage}
          handleChange={(newPage) => {
            updateCurrentPage(newPage);
            history.push(obSidebarPages()[newPage].route);
          }}
          tabs={obSidebarPages().map((page) => ({
            title: page.titleKey,
            icon: page.icon,
            iconSelected: page.iconSelected,
          }))}
        />
      )}
      <ObAuthedPage />
    </>
  );
};

export default ObAuthed;
