import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

export const useOperationsScreenStyles = makeStyles((theme: Theme) => ({
  collectesCardContainer: {
    padding: '20px 15px !important',
    [theme.breakpoints.down('lg')]: {
      padding: '0px !important',
      marginTop: '15px !important',
    },
  },
  gridLegendBarChartContainer: {
    backgroundColor: Colors.lightGray,
    width: '90% !important',
  },
  boxLegendBarChartCollect: {
    backgroundColor: Colors.darkBlue,
    width: '13px',
    height: '13px',
    borderRadius: 3,
    marginRight: 10,
  },
  boxLegendBarChartNonConformity: {
    backgroundColor: Colors.darkRed,
    width: '13px',
    height: '13px',
    borderRadius: 3,
    marginRight: 10,
  },
  gridLegendPosition: {
    padding: '5px 0px !important',
  },
  gridLabelPosition: {
    marginLeft: '10px !important',
  },
}));
