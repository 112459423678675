import React, { FC, useState } from 'react';
import { useDashboardScreenStyles } from './Dashboard.screen.style';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIsMobile } from 'utils/isMobile';
import { sessionQuery } from 'store/session';
import ActivitiesPanel from './common/activitiesPanel/Activities.panel';
import DashboardUserContainer from './common/dashboardUser/DashboardUser.container';
import { useObservable } from '@ngneat/react-rxjs';
import { useHasQueryParamsInStorage } from 'store/dashboard/notifications/hooks/useHasQueryParamsInStorage';

const DashboardScreen: FC = () => {
  const [isMorgan] = useObservable(sessionQuery.isMorgan$);
  const isMobiles = useIsMobile();
  const classes = useDashboardScreenStyles();
  const [dateCalendarSelected, setDateCalendarSelected] = useState<Date>(new Date());
  const { enqueueSnackbar } = useSnackbar();
  useHasQueryParamsInStorage();

  return (
    <Grid container spacing={2} className={classes.container}>
      {isMobiles ? (
        <Grid item xs={12} style={{ paddingTop: '30px' }}>
          <DashboardUserContainer isMorgan={isMorgan} />
        </Grid>
      ) : (
        <div className={classes.dashboardPosition}>
          <Grid item className={classes.dashboardSize}>
            <DashboardUserContainer isMorgan={isMorgan} />
          </Grid>
          <Grid item className={classes.activitiesSize}>
            <ActivitiesPanel
              buttonShow={isMorgan}
              setDate={setDateCalendarSelected}
              dateCalendarSelected={dateCalendarSelected}
            />
          </Grid>
        </div>
      )}
    </Grid>
  );
};

export default DashboardScreen;
