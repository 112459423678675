import { addDays, format, isSameDay, isSameWeek } from 'date-fns';
import { removeLocalTime } from '../prepareTime';
import {
  DATE_FORMAT_DAY_OF_WEEK,
  DATE_FORMAT_DAY_OF_WEEK_CALENDAR,
  DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_LETTER,
  DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_NUMBER,
  DATE_FORMAT_ISO,
  DATE_FORMAT_ISO_DATETIME,
  DATE_FORMAT_LONG,
  DATE_FORMAT_LONG_LOOSE,
  DATE_FORMAT_LONG_SEPARATED,
  DATE_FORMAT_MONTH_ABREVIATION,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_DAY,
  DATE_FORMAT_SHORT_MOBILE,
  DATE_FORMAT_SHORT_YEAR,
  DATE_FORMAT_ULTA_SHORT,
  TIME_FORMAT,
  TIME_FORMAT_LOOSE,
} from 'constants/Dates';
import dayjs from 'utils/data/dayjs.utils';
import { getLocaleObject } from '../locales';
import { getCurrentLanguage } from '../data/i18n.utils';

/**
 * Format date by dd.MM.yyyy HH:mm
 * @param dateString date string passed to date-fns
 */
export function formatLong(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_LONG);
}

export function getTimeSinceNow(date?: string | Date | null, removeLocal = true) {
  if (!date) return '';
  return dayjs(date).fromNow();
}

export function formatShortMobile(date?: string | Date | null) {
  return dayjs(date).format(DATE_FORMAT_SHORT_MOBILE);
}

export function formatLongSeparated(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_LONG_SEPARATED);
}

export function formatDayOfWeekCalendar(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_DAY_OF_WEEK_CALENDAR, {
    locale: getLocaleObject(getCurrentLanguage()),
  });
}

export function formatDayOfWeekCalendarMobileLetter(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_LETTER);
}

export function formatDayOfWeekCalendarMobileNumber(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_DAY_OF_WEEK_CALENDAR_MOBILE_NUMBER);
}

export function formatShort(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = new Date(Date.parse(date));
  }

  return format(date, DATE_FORMAT_SHORT);
}

export function formatDay(date?: string | Date | null) {
  return dayjs(date).format(DATE_FORMAT_SHORT_DAY);
}

export function addWeek(date?: string | Date | null): Date {
  return dayjs(date).add(1, 'week').toDate();
}

export function removeWeek(date?: string | Date | null): Date {
  return dayjs(date).subtract(1, 'week').toDate();
}

export function formatMonthAbv(date?: string | Date | null) {
  return dayjs(date).format(DATE_FORMAT_MONTH_ABREVIATION);
}

export function formatYear(date?: string | Date | null) {
  return dayjs(date).format(DATE_FORMAT_SHORT_YEAR);
}

export function formatMonth(date?: string | Date | null) {
  return dayjs(date).format('MMMM, YYYY');
}

export function formatCalendar(date?: string | Date | null) {
  return formatDay(date) + ' ' + formatMonthAbv(date) + ' ' + formatYear(date);
}

export function isBefore(date?: string | Date | null) {
  return dayjs(date).isBefore(dayjs());
}

export function formatUltraShort(date?: string | Date | null) {
  return dayjs(date).format(DATE_FORMAT_ULTA_SHORT);
}

export function formatShortNoLocale(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_SHORT);
}

export function formatTime(date?: string | Date | null, removeLocal = true) {
  return dayjs(date).format(TIME_FORMAT);
}

export function formatDateISO(date?: string | Date | null) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = removeLocalTime(new Date(Date.parse(date)));
  }
  return format(date, DATE_FORMAT_ISO);
}

export function formatDateTimeISO(date?: string | Date | null, removeLocal = true) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = new Date(Date.parse(date));
  }
  if (removeLocal) date = removeLocalTime(date);

  return format(date, DATE_FORMAT_ISO_DATETIME);
}

export function formatHumanReadable(date?: string | Date | null, removeLocal = true) {
  if (!date) return '';
  if (typeof date === 'string') {
    date = new Date(Date.parse(date));
    if (removeLocal) date = removeLocalTime(date);
  }

  const today = new Date();
  // short distance = today, tommorow
  if (isSameDay(today, date)) {
    return `Today, ${format(date, TIME_FORMAT_LOOSE)}`;
  }
  if (isSameDay(today, addDays(date, 1))) {
    return `Tommorow, ${format(date, TIME_FORMAT_LOOSE)}`;
  }
  // this week = Monday, Tuesday, etc.
  if (isSameWeek(today, date)) {
    return format(date, DATE_FORMAT_DAY_OF_WEEK);
  }
  // rest = plain date
  return format(date, DATE_FORMAT_LONG_LOOSE);
}

export function transformDateToDayMonthYear(date: Date | string | null) {
  if (!date) return '';
  const dateTransformed = new Date(date);
  const day = dateTransformed.getDate();
  const month = dateTransformed.getMonth() + 1;
  const year = dateTransformed.getFullYear();
  return `${day}/${month}/${year}`;
}
