import { ecologyScreenStatsStore, EcologyScreenStatsStore } from './ecology-screen-stats.store';
import { ID } from '@datorama/akita';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'i18next';

export class EcologyScreenStatsService {
  constructor(private store: EcologyScreenStatsStore) {}

  fetchStats = async (
    from: Date,
    to: Date,
    wasteIDs: string[],
    organisationId?: ID,
    orgUnitIds?: ID[],
    currentFilters?: TableFilter[]
  ) => {
    this.store.setEvolutionStatsLoading(true);

    const [stats, e] = await resolve(
      metawasteApi.getCo2Evolution(
        from,
        to,
        wasteIDs,
        orgUnitIds,
        organisationId,
        toRequestFilters(currentFilters || [])
      )
    );
    if (!stats || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.ecologyStats'));
    }
    this.store.updateEcologyStats(stats);
    return stats;
  };
}

export const ecologyScreenStatsService = new EcologyScreenStatsService(ecologyScreenStatsStore);
