import * as React from 'react';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { IconButton, Stack, Typography } from '@mui/material';
import { RadioChecked, RadioLess, RadioUnchecked } from 'assets/icons';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import TableCell from '@mui/material/TableCell';
import { CustomColumnsType } from './ColumnsTypes/CustomColumns';
import COLUMN_TYPE_TEXT from './ColumnsTypes/TextColumnsType';
import { SelectItem } from '../Select/SelectItem.interface';
import { GenericColumnType } from './ColumnsTypes/generic-column-type';

interface CustomColumns<T extends GenericColumnType> {
  type: CustomColumnsType;
  field: keyof T;
  header: string;
  width?: number;
  handleRender?: (item: T) => any;
  handleChange?: () => void;
  options?: SelectItem[];
}

interface DataTable<T extends GenericColumnType> {
  key: string;
  labelSubGroup: string;
  items: T[];
}

interface AuditTableProps<T extends GenericColumnType> {
  data: T[];
  groupBy: keyof T;
  labelSubGroup: keyof T;
  columns: CustomColumns<T>[];
  selectedRows: number[];
  handleSelectRows?: (selectedRows: number[]) => void;
  handleOpenSubGroup?: (items: T[]) => any;
  disableSelection?: boolean;
}

const handleRenderRow = (type: CustomColumnsType, value: any) => {
  switch (type) {
    case CustomColumnsType.TEXT:
      return COLUMN_TYPE_TEXT({ value });
    default:
      return COLUMN_TYPE_TEXT({ value });
  }
};

function groupData<T extends GenericColumnType>(
  data: T[],
  groupBy: keyof T,
  labelSubGroup: keyof T
): DataTable<T>[] {
  const groupedMap = new Map<string, Map<string, T[]>>();

  data.forEach((item) => {
    const groupByKey = String(item[groupBy]);
    const subGroupKey = String(item[labelSubGroup]);

    let groupMap = groupedMap.get(groupByKey);
    if (!groupMap) {
      groupMap = new Map<string, T[]>();
      groupedMap.set(groupByKey, groupMap);
    }

    let subGroupItems = groupMap.get(subGroupKey);
    if (!subGroupItems) {
      subGroupItems = [];
      groupMap.set(subGroupKey, subGroupItems);
    }

    subGroupItems.push(item);
  });

  const groupedData: DataTable<T>[] = [];
  groupedMap.forEach((subGroupMap, groupKey) => {
    subGroupMap.forEach((items, subGroupKey) => {
      groupedData.push({
        key: groupKey,
        labelSubGroup: subGroupKey,
        items,
      });
    });
  });

  return groupedData;
}

export default function AuditTable<T extends GenericColumnType>(props: AuditTableProps<T>) {
  const {
    data,
    groupBy,
    labelSubGroup,
    columns,
    selectedRows,
    handleSelectRows,
    handleOpenSubGroup,
    disableSelection,
  } = props;
  const [newData, setNewData] = useState<DataTable<T>[]>([]);

  const allSelected = selectedRows?.length === data.length;

  const selectAllRows = () => {
    const rows = !allSelected ? data.map((it) => it.id) : [];
    handleSelectRows?.(rows);
  };

  const selectGroupedRows = (groupedRows: T[]) => {
    const groupedRowIds = groupedRows.map((it) => it.id);
    const selectedGroupedRowIds = groupedRowIds.filter((id) => selectedRows?.includes(id));

    let rows: any[];
    if (selectedGroupedRowIds.length === groupedRowIds.length) {
      rows = selectedRows?.filter((id) => !groupedRowIds.includes(id));
    } else {
      const missingRowIds = groupedRowIds.filter((id) => !selectedRows?.includes(id));
      rows = [...(selectedRows ?? []), ...missingRowIds];
    }
    handleSelectRows?.(rows);
  };

  const selectRows = (row: number) => {
    const rows = selectedRows?.includes(row)
      ? selectedRows.filter((it) => it !== row)
      : [...(selectedRows ?? []), row];
    handleSelectRows?.(rows);
  };

  useEffect(() => {
    setNewData(groupData(data, groupBy, labelSubGroup));
  }, [data, groupBy, labelSubGroup]);

  const renderSubHeader = (key: string, labelSubGroup: string) => {
    const groupItems = newData?.find((it) => it.key === key)?.items ?? [];
    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: '1px solid #848F9D' }}
        height="50px"
        spacing={1}
        paddingLeft={disableSelection ? 1 : 0}
      >
        {!disableSelection ? (
          <Checkbox
            checkedIcon={<RadioChecked />}
            indeterminateIcon={<RadioLess />}
            icon={<RadioUnchecked />}
            checked={newData
              .find((it) => it.key === key)
              ?.items.every((it) => selectedRows?.includes(it.id))}
            onChange={() => selectGroupedRows(newData.find((it) => it.key === key)?.items ?? [])}
            size="small"
          />
        ) : null}

        <Stack direction="row" spacing={1}>
          <Typography my="10px" ml="8px" fontSize="14px" variant="h5" color="textPrimary">
            {labelSubGroup}
          </Typography>
          <IconButton onClick={() => (handleOpenSubGroup ? handleOpenSubGroup(groupItems) : null)}>
            <InfoIcon htmlColor="#192133" sx={{ height: '15px', width: '15px' }} />
          </IconButton>
        </Stack>
      </Stack>
    );
  };

  const renderRow = (item: T) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        height="50px"
        width="100%"
        sx={{ borderBottom: '1px solid #f0f0f0' }}
        spacing={2}
        paddingLeft={disableSelection ? 2 : 0}
      >
        {!disableSelection ? (
          <Checkbox
            checkedIcon={<RadioChecked />}
            indeterminateIcon={<RadioLess />}
            icon={<RadioUnchecked />}
            checked={selectedRows?.some((it) => it === item.id)}
            onChange={() => selectRows(item.id as number)}
            size="small"
          />
        ) : null}

        {columns.map((column, index) => {
          return (
            <TableCell
              sx={{ padding: '0px', margin: '0px', borderBottom: '0px' }}
              key={column.header}
              width={`${column.width}px`}
            >
              {column.handleRender
                ? column.handleRender(item)
                : handleRenderRow(column.type, item[column.field])}
            </TableCell>
          );
        })}
      </Stack>
    );
  };

  return (
    <Paper>
      <TableContainer>
        <Table size="small" aria-label="table">
          <TableHead>
            <TableRow>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ borderBottom: '1px solid #848F9D' }}
                spacing={2}
              >
                <TableCell sx={{ padding: '0px', margin: '0px' }}>
                  {!disableSelection ? (
                    <Checkbox
                      checked={allSelected}
                      checkedIcon={<RadioChecked />}
                      indeterminateIcon={<RadioLess />}
                      icon={<RadioUnchecked />}
                      onChange={() => selectAllRows()}
                      size="small"
                    />
                  ) : null}
                </TableCell>

                {columns.map((column, index) => (
                  <TableCell
                    sx={{ padding: '0px', margin: '0px', borderBottom: '0px' }}
                    key={column.header}
                    width={`${column.width}px`}
                  >
                    <Typography>{column.header}</Typography>
                  </TableCell>
                ))}
              </Stack>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData?.map((group) => (
              <TableRow key={group.key}>
                {renderSubHeader(group.key, group.labelSubGroup)}
                <TableRow>{/*TODO: render group label*/}</TableRow>
                {group.items.map((item, index) => (
                  <TableRow key={item.id}>{renderRow(item)}</TableRow>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
