import { Colors } from 'constants/Colors';
import { Components } from '@mui/material';

export const inputs: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        color: Colors.textWithOpacity,
        display: 'flex',
        fontSize: 14,
        fontWeight: 400,
        height: 40,
        lineHeight: 1.25,
        overflow: 'visible',
        minWidth: 'fit-content',
        whiteSpace: 'pre',
      },
    },
  },
  MuiTextField: {
    variants: [
      {
        props: { multiline: true },
        style: {
          '& .MuiFilledInput-root': {
            height: 'fit-content !important',
            minHeight: 40,
          },
        },
      },
      {
        props: { 'aria-label': 'search' },
        style: {
          '& .MuiFilledInput-root': {
            height: 40,
          },
          '& .MuiInputAdornment-positionEnd': {
            marginRight: -5,
          },
          '& .MuiFilledInput-input': {
            color: Colors.text,
            fontSize: 16,
            fontWeight: 400,
            '&::placeholder': {
              color: Colors.textWithOpacity,
              opacity: 1,
            },
          },
        },
      },
    ],
    defaultProps: {
      hiddenLabel: true,
      margin: 'none',
      variant: 'filled',
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'filled',
      fullWidth: true,
      displayEmpty: true,
    },
    styleOverrides: {
      select: (props) => ({
        backgroundColor: 'transparent',
        color: !props.ownerState?.value ? Colors.textWithOpacity : undefined,
        height: 'fit-content',
        padding: props.ownerState?.MenuProps?.['aria-label'] === 'small' ? '4px 10px' : '9px 14px',
        whiteSpace: 'normal',
      }),
      icon: {
        bottom: 0,
        margin: 'auto',
        top: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: (props) => ({
        marginBottom: props.ownerState.error ? -10 : undefined,
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        height: 40,
        marginLeft: 0,
      },
      label: {
        color: Colors.textWithOpacity,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.25,
        minWidth: 'fit-content',
        whiteSpace: 'pre',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: Colors.textWithOpacity,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.25,
        minWidth: 'fit-content',
        whiteSpace: 'pre',
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: (props) => ({
        cursor: props.ownerState['aria-label'] === 'clickable' ? 'pointer' : undefined,
        flex: 1,
        ...(props.ownerState.multiple
          ? {
              '& .MuiFilledInput-root': {
                height: 'fit-content !important',
                minHeight: 40,
                padding: '2.5px 5px',
              },
              '& .MuiFilledInput-input': {
                marginLeft: 5,
              },
              '& .MuiAutocomplete-tag': {
                margin: '7.5px 5px',
                maxWidth: 'fit-content',
                '& .MuiChip-label': {
                  marginRight: 4,
                },
              },
            }
          : {}),
      }),
      popper: {
        zIndex: 1301,
      },
      listbox: {
        padding: 0,
        width: '100%',
        overflowX: 'scroll',
      },
      popupIndicator: (props) => ({
        display: props.ownerState.readOnly ? 'none' : undefined,
      }),
      loading: {
        alignItems: 'center',
        display: 'flex',
        height: 38,
        padding: '0 16px',
      },
      noOptions: {
        alignItems: 'center',
        display: 'flex',
        height: 38,
        padding: '0 16px',
      },
      inputRoot: {
        padding: '0 10px',
        '& .MuiFilledInput-input': {
          padding: 0,
          fontSize: '14px',
        },
      },
    },
  },
};
