import { Store, StoreConfig } from '@datorama/akita';
import { Issue } from './issues.model';

export interface IssueState {
  total: number;
  count: number;
  data: Issue[];
  isLoading: boolean;
}

const initStore = (): IssueState => {
  return {
    total: 0,
    count: 0,
    data: [],
    isLoading: false,
  };
};

@StoreConfig({
  name: 'issues',
  resettable: true,
})
export class IssueStore extends Store<IssueState> {
  constructor() {
    super(initStore());
  }

  setIssueLoading = (isLoading: boolean) => {
    this.update((state) => ({
      ...state,
      isLoading: isLoading,
    }));
  };
}

export const issueStore = new IssueStore();
