import { organisationsStore, OrganisationsStore } from './organisations.store';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { resolve } from 'utils/data/promise-utils';
import { ID } from '@datorama/akita';
import { organisationUnitsService } from '../organisationUnits';
import { formatClientDtoToOrganisation } from 'api/dto/client/ClientDto';
import { formatSupplierDtoToOrganisation } from 'api/dto/supplier/SupplierDto';
import { PaginationResponseDto } from 'api/dto/common/PaginationResponseDto';
import { Organisation } from './organisation.model';
import i18n from 'utils/data/i18n.utils';
import { from } from 'rxjs';

export class OrganisationsService {
  constructor(private store: OrganisationsStore) {}

  fetchOrganisations = async (isMorgan: boolean, organisationName: string, offset: number) => {
    if (isMorgan) {
      await this.fetchClients(organisationName, offset);
    } else {
      await this.fetchSuppliers(organisationName, offset);
    }
  };

  fetchOrganisation = async (isMorgan: boolean, organisationId: ID) => {
    if (isMorgan) {
      await this.fetchClient(organisationId);
    } else {
      await this.fetchSupplier(organisationId);
    }
  };

  handleSelectOrganisation = (organisationId: ID) => {
    if (organisationId !== this.store.getValue().activeOrganisationId) {
      organisationUnitsService.removeActiveOrgUnit();
    }
    const active = this.store.getValue().entities?.[organisationId];
    this.store.update((state) => ({
      ...state,
      activeOrganisation: active,
    }));
    this.setOrganisationAsActive();
  };

  setOrganisationAsActive = () => {
    const activeId = this.store.getValue().activeOrganisation?.id;
    this.store.setActiveOrganisationId(activeId);
    this.populateOrgUnits();
  };

  removeActiveOrganisation = () => {
    this.store.removeActiveOrganisation();
  };

  private fetchClient = async (clientId: ID) => {
    const [client, e] = await resolve(metawasteApi.getClient(clientId));
    if (e || !client) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
    this.store.update((state) => ({
      ...state,
      activeOrganisation: formatClientDtoToOrganisation(client),
    }));
    this.setOrganisationAsActive();
  };

  private fetchSupplier = async (supplierId: ID) => {
    const [supplier, e] = await resolve(metawasteApi.getSupplier(supplierId));
    if (e || !supplier) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.supplier'));
    }
    this.store.update((state) => ({
      ...state,
      activeOrganisation: formatSupplierDtoToOrganisation(supplier),
    }));
    this.setOrganisationAsActive();
  };

  private fetchClients = async (organisationName: string, offset: number) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(
      metawasteApi.getClients(organisationName.length ? organisationName : undefined, offset, 10)
    );
    this.store.setLoading(false);
    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
    const organisations = resp.data.map(formatClientDtoToOrganisation);
    this.storeOrganisations(offset, resp, organisations);
  };

  searchClients = (searchString: string, offset: number) => {
    return from(metawasteApi.searchClients(searchString, offset, 30));
  };

  private fetchSuppliers = async (organisationName: string, offset: number) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(
      metawasteApi.getSuppliers(organisationName.length ? organisationName : undefined, offset, 10)
    );
    this.store.setLoading(false);
    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.supplier'));
    }
    const organisations = resp.data.map(formatSupplierDtoToOrganisation);
    this.storeOrganisations(offset, resp, organisations);
  };

  private storeOrganisations = (
    offset: number,
    resp: PaginationResponseDto<any>,
    organisations: Organisation[]
  ) => {
    if (offset === 0) {
      this.store.set(organisations);
    } else {
      this.store.upsertMany(organisations);
    }
    this.store.update((state) => ({
      ...state,
      count: resp.count,
      total: resp.total,
    }));
    if (resp.total === 1 && organisations.length > 0) {
      this.handleSelectOrganisation(organisations[0].id);
    }
  };

  private populateOrgUnits = () => {
    const organisation = this.store.getValue().activeOrganisation;
    organisationUnitsService.populateDataFromOrganisation(organisation?.organisationUnits ?? []);
  };
}

export const organisationsService = new OrganisationsService(organisationsStore);
