import { Store, StoreConfig } from '@datorama/akita';
import { IssueCountStatsUi } from '../operationScreenStats/operation-screen-stats.model';

export interface PatrickStatsState {
  issueStatus: {
    stats: IssueCountStatsUi;
    isLoading: boolean;
  };
}

export function createInitialState(): PatrickStatsState {
  return {
    issueStatus: {
      stats: {
        issueCount: 0,
        oldExecutionCount: 0,
        previousIssueCount: 0,
        executionCount: 0,
        higherKind: {
          issueCount: 0,
          issueKindName: '',
        },
        oldHigherKind: {
          issueCount: 0,
          issueKindName: '',
        },
      },
      isLoading: false,
    },
  };
}

@StoreConfig({ name: 'PatrickStats' })
export class PatrickStatsStore extends Store<PatrickStatsState> {
  constructor() {
    super(createInitialState());
  }

  setIssueStatusLoading = (loader: boolean) =>
    this.update((state) => ({
      ...state,
      issueStatus: {
        ...state.issueStatus,
        isLoading: loader,
      },
    }));
}

export const patrickStatsStore = new PatrickStatsStore();
