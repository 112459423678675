import { TypeNotifications } from 'utils/enums/TypeNotifications';
import { PayloadDto } from 'api/dto/notifications/PayloadDto';

export const getTicketSignatureFromId = (type: TypeNotifications, payload: PayloadDto) => {
  if (!payload.executionId) {
    return;
  }
  switch (type) {
    case TypeNotifications.EXECUTION_TO_BE_TREATED:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_ACCEPTED:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_DENIED:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_ENDED:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_NEW_COMMENT:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_NEW_ISSUE:
      return payload.issueSignature;
    case TypeNotifications.EXECUTION_ISSUE_CLOSED:
      return payload.issueSignature;
    case TypeNotifications.EXECUTION_UPDATED:
      return payload.executionSignature;
    case TypeNotifications.EXECUTION_ISSUE_UPDATED:
      return payload.issueSignature;
    case TypeNotifications.EXECUTION_DELETED:
      return payload.executionSignature;
  }
};
