import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { debounce } from 'rxjs/operators';
import { organisationsService } from '../organisations.service';
import SnackError from 'utils/models/snackError';
import { organisationsQuery } from '../organisations.query';
import { sessionQuery } from 'store/session';

export const useSearchOrganisations = (searchString: string, offset: number) => {
  const { enqueueSnackbar } = useSnackbar();

  const searchString$ = useRef(new Subject<string>()).current;
  const offset$ = useRef(new Subject<number>()).current;

  const [organisations, setOrganisations] = useState<AutocompleteData[]>([]);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const _queryParams = combineLatest([searchString$, offset$, sessionQuery.isMorgan$])
      .pipe(debounce(() => timer(600)))
      .subscribe(([searchString, offset, isMorgan]) => {
        organisationsService
          .fetchOrganisations(isMorgan === true, searchString, offset)
          .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
      });

    const _organisations = organisationsQuery.organisationsAsAutocomplete$.subscribe(
      setOrganisations
    );
    const _count = organisationsQuery.count$.subscribe(setCount);
    const _total = organisationsQuery.total$.subscribe(setTotal);
    return () => {
      _queryParams.unsubscribe();
      _organisations.unsubscribe();
      _count.unsubscribe();
      _total.unsubscribe();
    };
  }, []);

  useEffect(() => {
    searchString$.next(searchString);
  }, [searchString]);

  useEffect(() => {
    offset$.next(offset);
  }, [offset]);

  return { organisations, count, total };
};
