import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExecutionDocument } from './execution-document.model';

export interface ExecutionDocumentsState extends EntityState<ExecutionDocument> {
  originalDocuments: ExecutionDocument[];
}

const initialState: ExecutionDocumentsState = {
  originalDocuments: [],
};

@StoreConfig({
  name: 'executionDocuments',
  resettable: true,
})
export class ExecutionDocumentsStore extends EntityStore<ExecutionDocumentsState> {
  constructor() {
    super(initialState);
  }
}

export const executionDocumentsStore = new ExecutionDocumentsStore();
