import React, { FC, useEffect } from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import SideModal from 'components/Modal/SideModal';
import { useSnackbar } from 'notistack';
import {
  executionIssueQuery,
  executionIssueService,
  IssueDetailsUi,
} from 'store/operations/executionIssue';
import IssueDetailsForm from './details/forms/IssueDetailsForm';
import IssueCollectForm from './details/forms/IssueCollectForm';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import { useTranslation } from 'react-i18next';
import SnackError from 'utils/models/snackError';
import IssueDocumentsForm from './details/forms/IssueDocumentsForm';
import { useQuery } from 'utils/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { translateIssuesStatus } from 'utils/enums/IssueStatusEnum';

interface IssueModalScreenProps {
  execution?: ExecutionDetailsUi;
}

const IssueModalScreen: FC<IssueModalScreenProps> = (props) => {
  const { execution } = props;
  const classes = useCreateStyles();
  const [currentIssue] = useObservable(executionIssueQuery.selectedIssue$);
  const [isBodyEmpty] = useObservable(executionIssueQuery.isButtonDisabled$);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    return () => {
      query.delete('issueId');
      history.replace({
        search: query.toString(),
      });
    };
  }, []);

  const handleSaveButton = () => {
    if (!currentIssue || !execution) {
      return;
    }
    currentIssue?.dto
      ? executionIssueService
          .updateIssue(currentIssue)
          .then((value) =>
            enqueueSnackbar(t('fe.issueModal.messages.successUpdate'), { variant: 'success' })
          )
          .catch((e: SnackError) => enqueueSnackbar(e.text, e.options))
      : executionIssueService
          .createIssue(currentIssue, execution.id)
          .then(() =>
            enqueueSnackbar(t('fe.issueModal.messages.successCreate'), { variant: 'success' })
          )
          .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  const updateIssue = (newValue: IssueDetailsUi) => {
    executionIssueService.setIssueDetail(newValue);
  };

  const updateIssueField = (name: string) => (value: any) => {
    if (currentIssue) {
      updateIssue({ ...currentIssue, [name]: value });
    }
  };

  const handleClose = () => {
    executionIssueService.closeModal(currentIssue);
  };

  if (!currentIssue) {
    return <div />;
  }
  return (
    <SideModal
      status={currentIssue?.status ? translateIssuesStatus(currentIssue.status) : undefined}
      handleClickCloseModal={handleClose}
    >
      <Grid container rowSpacing={3}>
        {currentIssue?.dto && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t('fe.issueModal.createdBy', {
                firstName: currentIssue?.dto?.author?.firstName,
                lastName: currentIssue?.dto?.author?.lastName?.slice(0, 1),
              })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h2" style={{ fontSize: '24px' }}>
            {t('fe.issueModal.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <IssueDocumentsForm issue={currentIssue} />
        <IssueCollectForm executionTicketSignature={execution?.dto?.ticketSignature ?? ''} />
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <IssueDetailsForm issue={currentIssue} updateIssueField={updateIssueField} />
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            onClick={handleSaveButton}
            className={classes.buttonPosition}
            disabled={isBodyEmpty}
          >
            {t('fe.issueModal.saveButton')}
          </Button>
        </Grid>
      </Grid>
    </SideModal>
  );
};

export default React.memo(IssueModalScreen);
