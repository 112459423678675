import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: '14px !important',
      color: '#172134 !important',
      fontWeight: '400 !important',
    },
    subtitle: {
      fontSize: '10px !important',
      color: 'black !important',
      fontWeight: '400 !important',
      opacity: '0.5 !important',
    },
    divider: {
      opacity: 0.3,
    },
    leftContainer: {
      width: '10%',
    },
  });

export default classes;
