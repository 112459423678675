import { Card, CardContent, Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExecutionCommentsForm from 'screens/auth/modals/executionModal/details/forms/ExecutionComments.form';
import ExecutionDocumentsForm from 'screens/auth/modals/executionModal/details/forms/ExecutionDocuments.form';
import ExecutionIssueForm from 'screens/auth/modals/executionModal/details/forms/ExecutionIssue.form';
import ExecutionHeaderForm from 'screens/auth/modals/executionModal/details/header/ExecutionHeader.form';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import ObExecutionInfoForm from './ObExecutionInfoForm';

const mockExecutionDetails: ExecutionDetailsUi = {
  id: '12345',
  createdAt: new Date('2024-07-15T08:00:00Z'),
  serviceKindId: 'serviceKind123',
  wasteId: 'waste123',
  containerId: 'container123',
  serviceId: 'service123',
  selectedDate: new Date('2024-08-01T09:00:00Z'),
  plannedFrom: new Date('2024-08-02T10:00:00Z'),
  plannedTo: new Date('2024-08-02T12:00:00Z'),
  nbContainers: 10,
  comments: ['Initial plan', 'Adjusted schedule'],
  haulingOrganisationUnitName: 'Hauling Co.',
  destinationOrganisationUnitName: 'Waste Processing Unit',
  haulingOrganisationId: 'haulOrg123',
  destinationOrganisationId: 'destOrg123',
  haulingUnitId: 'haulUnit123',
  destinationUnitId: 'destUnit123',
  availableServicesForHaulier: ['service1', 'service2'],
  parentExecutionId: 'parentExec123',
  justification: 'Urgent request',
  authorName: 'John Doe',
  executedAt: null,
  wasteAmount: 500,
  asap: true,
};

const ObExecutionModalScreen = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { t } = useTranslation();

  const updateExecutionField = (name: string) => (value: any) => {
    if (mockExecutionDetails) {
      // updateExecution({ ...mockExecutionDetails, [name]: value });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
        padding: '20px 10px',
      }}
    >
      <CardContent sx={{ padding: '10px' }}>
        <Grid container item xs={12} rowSpacing={3}>
          <ExecutionHeaderForm
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            executionId={mockExecutionDetails.id}
          />
          <Collapse in={isExpanded} style={{ width: '100%', paddingTop: 6 }}>
            <Grid container item xs={12} rowSpacing={3}>
              <ObExecutionInfoForm
                executionId={mockExecutionDetails.id}
                updateExecutionField={updateExecutionField}
                isOnbording
              />
              {/*<ExecutionTransportForm />*/}
              <ExecutionCommentsForm
                execution={mockExecutionDetails}
                updateExecutionField={updateExecutionField}
              />
              <ExecutionDocumentsForm execution={mockExecutionDetails} />

              {mockExecutionDetails?.dto?.status && (
                <ExecutionIssueForm execution={mockExecutionDetails} />
              )}
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObExecutionModalScreen;
