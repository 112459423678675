import i18n from '../data/i18n.utils';

export enum MetawasteTab {
  TO_CONFIRM,
  TO_TREAT,
  TO_COMPLETE,
  WAITING_FOR_CONFIRMATION,
  PLANNED,
  COMPLETED,
  STATS,
  LAST_CREATED,
  LAST_ACCEPTED,
  LAST_COMPLETED,
  TABLE,
  SUMMARY,
  DETAILS,
}

export const patrickTabs = (): { tabType: MetawasteTab; label: any }[] =>
  [MetawasteTab.TO_CONFIRM, MetawasteTab.TO_TREAT, MetawasteTab.TO_COMPLETE].map(
    (it: MetawasteTab) => {
      return { label: i18n.t(`fe.dashboard.patrick.tabs.${it}`), tabType: it };
    }
  );

export const morganTabs = (): { tabType: any; label: any }[] =>
  [
    MetawasteTab.WAITING_FOR_CONFIRMATION,
    MetawasteTab.PLANNED,
    MetawasteTab.COMPLETED,
    MetawasteTab.TABLE,
  ].map((it: MetawasteTab) => {
    return { label: i18n.t(`fe.dashboard.morgan.tabs.${it}`), tabType: it };
  });

export const contractsTabs = (): { tabType: MetawasteTab; label: any }[] =>
  [MetawasteTab.SUMMARY].map((it: MetawasteTab) => {
    return { label: i18n.t(`fe.contracts.tabs.${it}`), tabType: it };
  });
