import React, { FC } from 'react';
import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { ReactComponent as Icon } from 'assets/icons/file.svg';
import { ReactComponent as IconClose } from 'assets/icons/closeX.svg';
import { withStyles, WithStyles } from '@mui/styles';
import style from './FileItem.style';

interface FileProps extends WithStyles<typeof style> {
  handleClose: () => void;
  title?: string;
  size?: string;
  handleClick?: () => void;
  image?: string;
}

const FileItem: FC<FileProps> = (props) => {
  const { classes, handleClose, title, size, handleClick, image } = props;
  return (
    <Badge
      badgeContent={
        <IconButton onClick={() => handleClose()}>
          <IconClose width={20} height={20} className={classes.cursor} />
        </IconButton>
      }
    >
      <Card
        className={classes.boxContainer}
        component={handleClick ? CardActionArea : Card}
        onClick={handleClick}
      >
        {image ? (
          <CardContent>
            <CardMedia component="img" image={image} />
          </CardContent>
        ) : (
          <>
            <Icon width={40} height={30} className={classes.icon} />
            <Typography className={classes.title}>{title}</Typography>
          </>
        )}
        {/*<Typography color="secondary.dark" className={classes.size}>*/}
        {/*  {size}*/}
        {/*</Typography>*/}
      </Card>
    </Badge>
  );
};

export default withStyles(style)(FileItem);
