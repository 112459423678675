import { OperationsStatsStore, operationsStatsStore } from './operations-stats.store';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { operationStatsDtoToModel } from './operations-stats.model';
import { ID } from '@datorama/akita';
import i18n from 'utils/data/i18n.utils';

export class OperationsStatsService {
  constructor(private store: OperationsStatsStore) {}

  calculatePercentageEvolution = (depart: number, end: number) => {
    let result = depart !== 0 ? ((end - depart) / depart) * 100 : end * 100;
    result = ~~result;
    return result;
  };

  calculatePercentage = (part: number, total: number) => {
    let result = total !== 0 ? (part / total) * 100 : 0;
    result = ~~result;
    return result;
  };

  fetchOperationStats = async (
    from: Date,
    to: Date,
    wasteIds: string[],
    orgUnitIds?: ID[],
    organisationId?: ID
  ) => {
    this.store.setOperationLoading(true);
    const [stats, e] = await resolve(
      metawasteApi.getOperationsStats(from, to, wasteIds, organisationId, orgUnitIds)
    );
    this.store.setOperationLoading(false);
    if (e || !stats) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.operationStats'));
    }
    const stat = operationStatsDtoToModel(stats);
    this.store.update((state) => ({
      ...state,
      operationTickets: {
        stats: stat,
        isLoading: false,
      },
    }));
  };
}

export const operationsStatsService = new OperationsStatsService(operationsStatsStore);
