import { Grid, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatAddress } from 'api/dto/client/AddressDto';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { ReactComponent as Group } from 'assets/icons/rework/file_group.svg';
import MaterialTag from 'components/MaterialDatagrid/Rows/MaterialTag';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Pickup from 'screens/auth/dashboard/patrick/cell/pickup';
import IconWaste from 'screens/auth/operations/list/cell/IconWaste';
import { formatTime, formatUltraShort } from 'utils/dates/format';
import { translateExecutionStatus } from 'utils/enums/ExecutionStatusEnum';
import { formatTranslatedName } from 'utils/data/i18n.utils';
import Actions from 'screens/auth/dashboard/patrick/cell/actions';

interface ContainerMobileProps {
  execution: ExecutionDto;
  onClick: (isAccepted: boolean) => void;
  isOperation?: boolean;
  params?: GridRenderCellParams;
}

const ContainerMobile: FC<ContainerMobileProps> = (props) => {
  const { execution, onClick, params, isOperation } = props;
  const { t } = useTranslation();
  return (
    <Grid item style={{ width: '100%', padding: '12px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'top',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex' }}>
          {execution.executionGroup && <Group width={15} height={15} style={{ marginRight: 4 }} />}
          <Typography variant="subtitle2" style={{ fontSize: 12, fontWeight: 400 }}>
            {formatTranslatedName(
              execution.service.waste?.nameTranslateKey,
              execution.service.waste?.name
            )}
          </Typography>
          <div style={{ marginLeft: 10 }}>
            <Typography variant="subtitle2" style={{ fontSize: 12, fontWeight: 400 }}>
              {formatTranslatedName(
                execution.service.container?.nameTranslateKey,
                execution.service.container?.name
              )}
            </Typography>
          </div>
        </div>
        {execution.plannedFrom ? (
          <Typography variant="subtitle2">{formatUltraShort(execution.plannedFrom)}</Typography>
        ) : (
          <Typography variant="subtitle2">{t('fe.asap')}</Typography>
        )}
      </div>
      <Grid container justifyContent="space-between">
        <Typography variant="subtitle2" style={{ fontSize: 12, fontWeight: 400 }}>
          {t('fe.dashboard.patrick.table.quantity', {
            quantity: execution.quantity1,
          })}
        </Typography>
        {execution.plannedFrom && (
          <Typography variant="subtitle2">{formatTime(execution.plannedFrom)}</Typography>
        )}
      </Grid>
      <Grid container justifyContent="space-between" alignItems="start">
        <Pickup
          params={true}
          ticketSignature={execution.ticketSignature}
          name={execution.organisationUnit?.name ?? ''}
          address={
            execution.organisationUnit?.address
              ? formatAddress(execution.organisationUnit.address)
              : ''
          }
        />
        {isOperation && <IconWaste params={params} />}
      </Grid>
      {isOperation ? (
        <div style={{ display: 'flex' }}>
          <MaterialTag
            noWidthLimit
            wrap
            maxHeight={35}
            maxWidth={200}
            title={translateExecutionStatus(execution.status)}
          />
        </div>
      ) : (
        <Actions
          onClick={(isAccepted) => onClick?.(isAccepted)}
          asapMode={execution.plannedFrom === null}
        />
        // <div style={{ marginTop: 6, marginBottom: 10 }}>
        //   <Button
        //     variant="outlined"
        //     size="medium"
        //     style={{ marginRight: 20 }}
        //     onClick={(e) => (e.stopPropagation(), onClick(false))}
        //   >
        //     {i18n.t('fe.dashboard.patrick.table.refuse')}
        //   </Button>
        //   <Button
        //     variant="contained"
        //     size="medium"
        //     onClick={(e) => (e.stopPropagation(), onClick(true))}
        //   >
        //     {i18n.t('fe.dashboard.patrick.table.accept')}
        //   </Button>
        // </div>
      )}
    </Grid>
  );
};

export default ContainerMobile;
