import dayjs from 'dayjs';
import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en';
import frLocale from 'dayjs/locale/fr';
import itLocale from 'dayjs/locale/it';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { getCurrentLanguage } from './i18n.utils';

dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

enLocale.weekStart = 1;
frLocale.weekStart = 1;
deLocale.weekStart = 1;
itLocale.weekStart = 1;

export const combineDateAndHours = (
  selectedDate: Date | null | undefined,
  selectedHours: Date | null
) => {
  const formatDateSelected = dayjs(selectedDate).format('YYYY-MM-DD');
  const formatTimeFrom = dayjs(selectedHours).format('HH:mm:ss');
  const dateFrom = formatDateSelected + formatTimeFrom;
  return dayjs(dateFrom).toISOString();
};

export const combineDateAndHoursToDate = (
  selectedDate: Date | null | undefined,
  selectedHours: Date | null
) => {
  const formatDateSelected = dayjs(selectedDate).format('YYYY-MM-DD');
  const formatTimeFrom = dayjs(selectedHours).format('HH:mm:ss');
  const dateFrom = formatDateSelected + formatTimeFrom;
  return dayjs(dateFrom).toDate();
};

export const updateDayjsLanguage = () => {
  switch (getCurrentLanguage()) {
    case 'fr':
      dayjs.locale(frLocale);
      break;
    case 'de':
      dayjs.locale(deLocale);
      break;
    case 'it':
      dayjs.locale(itLocale);
      break;
    default:
      dayjs.locale(enLocale);
      break;
  }
};

export const weekdaysMin = () => dayjs.localeData().weekdaysMin();

export const checkValidDate = (date?: Date | null) => date && dayjs(date).isValid();

export default dayjs;
