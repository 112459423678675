import React, { Fragment } from 'react';
import { Stack, Typography, IconButton, Button, Divider, Popover } from '@mui/material';
import { IconBin, IconInfo } from 'assets/icons';
import { BookmarkServiceDto } from 'store/operations/bookmark';
import { useTranslation } from 'react-i18next';

interface BookmarkCardsProps {
  bookmarkServices: BookmarkServiceDto[];
  handleDeleteBookmark: () => void;
  onOpenModal: (bookmarkServices: BookmarkServiceDto[]) => void;
}

const BookmarkCards = (props: BookmarkCardsProps) => {
  const { bookmarkServices, handleDeleteBookmark, onOpenModal } = props;
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [element, setElement] = React.useState<BookmarkServiceDto | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    bookmark: BookmarkServiceDto
  ) => {
    setAnchorEl(event.currentTarget);
    setElement(bookmark);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setElement(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack p="15px" spacing={2} bgcolor="#F4F6F8" maxWidth="420px" borderRadius="4px">
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Typography>
          {bookmarkServices.length === 1
            ? bookmarkServices[0].service.clientWasteName
            : t(`fe.bookmark.title.service`, { nbrServices: bookmarkServices.length })}
        </Typography>
        <IconButton onClick={handleDeleteBookmark}>
          <IconBin />
        </IconButton>
      </Stack>

      {bookmarkServices.length === 1 ? (
        <Stack direction="row" width="100%" p="10px 12px" bgcolor="#FFFFFF" minHeight="75px">
          {[
            { name: 'Contenant', value: bookmarkServices[0].service.container.name },
            { name: 'Quantité', value: bookmarkServices[0].quantity },
            { name: 'Déchet', value: bookmarkServices[0].service.waste.name },
          ].map((it, idx) => (
            <Fragment key={it.name}>
              <Stack flex={1} spacing={0.2}>
                <Typography fontSize="12px" fontWeight={450} color="#848F9D">
                  {it.name}
                </Typography>
                <Typography fontSize="12px" fontWeight={500}>
                  {it.value}
                </Typography>
              </Stack>
              {idx < 2 ? (
                <Divider orientation="vertical" flexItem sx={{ mx: 1, width: '0.5px' }} />
              ) : null}
            </Fragment>
          ))}
        </Stack>
      ) : null}

      {bookmarkServices.length === 2 ? (
        <Stack spacing={1} width="320px" minHeight="75px">
          {bookmarkServices.map((it) => (
            <Stack
              key={it.id}
              flex={1}
              direction="row"
              bgcolor="#FFFFFF"
              borderRadius="4px"
              p="7px 8px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="12px" fontWeight={500}>
                {it.service.clientWasteName}
              </Typography>
              <Stack
                onMouseEnter={(e) => handlePopoverOpen(e, it)}
                onMouseLeave={handlePopoverClose}
              >
                <IconInfo width="15px" height="15px" />
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : null}

      {bookmarkServices.length > 2 ? (
        <Stack
          rowGap={1}
          columnGap={1}
          direction="row"
          width="334px"
          flexWrap="wrap"
          height="75px"
          overflow="auto"
        >
          {bookmarkServices.map((it) => (
            <Stack
              key={it.id}
              direction="row"
              width="48%"
              bgcolor="#FFFFFF"
              borderRadius="4px"
              p="7px 8px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontSize="12px"
                fontWeight={500}
                maxWidth="120px"
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {it.service.clientWasteName}
              </Typography>
              <Stack
                onMouseEnter={(e) => handlePopoverOpen(e, it)}
                onMouseLeave={handlePopoverClose}
              >
                <IconInfo width="15px" height="15px" />
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : null}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        open={open}
      >
        <Stack p="10px">
          <Typography fontSize="14px" fontWeight={700} color="#172134">
            {t(`fe.bookmark.quantity`, { quantity: element?.quantity })}
          </Typography>
          <Typography fontSize="12px" fontWeight={450} color="#172134">
            {element?.service.container.name}
          </Typography>
          <Typography fontSize="10x" fontWeight={300} color="#848F9D">
            {element?.service.waste.name}
          </Typography>
        </Stack>
      </Popover>

      <Stack width="fit-content" alignSelf="flex-end">
        <Button
          variant="contained"
          size="small"
          sx={{ p: '14px 20px !important' }}
          onClick={() => onOpenModal(bookmarkServices)}
        >
          <span>Utiliser</span>
        </Button>
      </Stack>
    </Stack>
  );
};

export default BookmarkCards;
