import { ID } from '@datorama/akita';
import { WasteLightDto } from './WasteLightDto';
import { ContainerLightDto } from './ContainerLightDto';
import { ServiceKindLightDto } from './ServiceKindLightDto';

export interface ServiceWasteContainerDto {
  id: ID;
  clientWasteName: string;
  waste: WasteLightDto;
  container: ContainerLightDto;
  kind: ServiceKindLightDto;
  supplierUnitName: string;
  supplierOrganisationName: string;
}

export function formatToServiceWasteContainer({
  id,
  clientWasteName,
  waste,
  container,
  kind,
  supplierUnitName,
  supplierOrganisationName,
}: ServiceWasteContainerDto): any {
  return {
    id,
    clientWasteName,
    waste,
    container,
    kind,
    supplierUnitName,
    supplierOrganisationName,
  };
}
