import { createContext, useContext, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const WindowSizeContext = createContext({
  height: 0,
  width: 0,
});

const useWindowSize = () => {
  return useContext(WindowSizeContext);
};

export const WindowSizeContextProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    height: 0,
    width: 0,
  });

  useLayoutEffect(() => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
  }, []);

  useResizeObserver(window.document.body, (entry) => {
    const { width, height } = entry.contentRect;
    setWindowSize({ width, height });
  });

  return <WindowSizeContext.Provider value={windowSize}>{children}</WindowSizeContext.Provider>;
};

export default useWindowSize;
