import { Box, Dialog } from '@mui/material';

interface ImageModalProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageModal = (props: ImageModalProps) => {
  const { imageUrl, onClose } = props;

  return (
    <Dialog
      aria-label="over-drawer"
      open={Boolean(imageUrl)}
      maxWidth="lg"
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      {imageUrl && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <img
            src={imageUrl}
            alt="Large"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              cursor: 'pointer',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Box>
      )}
    </Dialog>
  );
};

export default ImageModal;
