import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    container: {
      width: '250px !important',
      height: '68px !important',
      padding: '0 !important',
    },
    position: {
      display: 'flex',
      margin: 'auto',
    },
    icon: {
      height: '100%',
      width: '64px',
      padding: '3px',
      marginLeft: '3px',
      marginRight: '3px',
    },
    typography: {
      fontSize: '12px !important',
      padding: '16px 15px 16px 0 !important',
    },
  });

export default classes;
