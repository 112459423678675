import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

export const useSelectLangStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: '15px !important',
    width: '100%',
    height: '65px',
    backgroundColor: theme.palette.neutral.light + '!important',
    justifyContent: 'start !important',
    textDecoration: 'none !important',
    opacity: 1 + '!important',
    '&:hover': {
      backgroundColor: Colors.hoverFields + '!important',
    },
  },
  buttonSelected: {
    marginTop: '15px !important',
    width: '100%',
    height: '65px',
    backgroundColor: theme.palette.secondary.light + '!important',
    justifyContent: 'start !important',
    textDecoration: 'none !important',
    opacity: 1 + '!important',
  },
  typography: {
    marginLeft: '22px !important',
  },
  typographySelected: {
    marginLeft: '22px !important',
    fontWeight: 700 + '!important',
  },
}));
