import { Typography, Tooltip } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import React, { FC } from 'react';
import style from './RankingTooltipInfo.style';
interface RankingTooltipInfoProps extends WithStyles<typeof style> {
  icon?: React.ReactNode;
}

const RankingTooltipInfo: FC<RankingTooltipInfoProps> = (props) => {
  const { classes, icon } = props;

  return (
    <Tooltip
      title={
        <div className={classes.container}>
          <Typography variant="h6" className={classes.title}>
            Détails du calcul des scores
          </Typography>
          <Typography className={classes.marginTypography}>
            <span className={classes.subTitle}>Taux de recyclage :</span> 100 x valeur partiel /
            valeur totale
          </Typography>
          <Typography className={classes.marginTypography}>
            <span className={classes.subTitle}>Empreinte carbone:</span> 100 x valeur partiel /
            valeur totale
          </Typography>
        </div>
      }
      placement="top-end"
    >
      <span>{icon}</span>
    </Tooltip>
  );
};

export default withStyles(style)(RankingTooltipInfo);
