import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import i18n from 'utils/data/i18n.utils';

interface ActionsProps {
  onClick: (isAccepted: boolean) => void;
  asapMode: boolean;
}

const Actions: FC<ActionsProps> = (props) => {
  const { onClick, asapMode } = props;
  return (
    <div>
      {asapMode ? (
        <Button
          variant="contained"
          size="small"
          onClick={(e) => (e.stopPropagation(), onClick(false))}
        >
          {i18n.t('fe.dashboard.patrick.table.proposition')}
        </Button>
      ) : (
        <Stack
          direction={{ xs: 'row', sm: 'row', lg: 'column' }}
          spacing={1}
          alignItems={{ sm: 'flex-start', lg: 'flex-end' }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => (e.stopPropagation(), onClick(false))}
          >
            {i18n.t('fe.dashboard.patrick.table.newProposition')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={(e) => (e.stopPropagation(), onClick(true))}
          >
            {i18n.t('fe.dashboard.patrick.table.accept')}
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default Actions;
