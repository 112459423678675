import { ID } from '@datorama/akita';
import { DocumentDto } from 'api/dto/documents/DocumentDto';

export interface ExecutionDocument {
  id: ID;
  executionId: ID;
  tmpFile?: File;
  dto?: DocumentDto;
}

export const createExecutionDocument = (
  executionId: ID,
  dto: DocumentDto,
  tmpFile?: File
): ExecutionDocument => ({
  id: dto.id,
  executionId,
  dto,
  tmpFile,
});
