import { PARSING_DATE, PARSING_DATETIME } from 'constants/Dates';
import { parse } from 'date-fns';
import dayjs, { OpUnitType } from 'dayjs';

export interface ParsedDate {
  /** Parsed date, if null is returned then the date couldn't be parsed*/
  date: Date | null;
  /** Wether parsed string had time information (hours)
   * Examples
   * 12:21 => has time information
   * 12.12.2021 12:21 => has time information
   * 12.12.2021 => doesn't have time information
   */
  hasTime?: boolean;
  /** Wether parsed string had date information
   * Examples
   * 12:21 => doesn't have date information
   * 12.12.2021 12:21 => has date information
   * 12.12.2021 => has date information
   */
  hasDate?: boolean;
}

export function getStartDate(date: Date, unit: OpUnitType): Date {
  return new Date(dayjs(date).startOf(unit).format());
}

export function getEndDate(date: Date, unit: OpUnitType): Date {
  return new Date(dayjs(date).endOf(unit).format());
}

/** Tries to parse string date provided by user by using different formats */
export function parseDate(query: string): ParsedDate {
  const currentDate = new Date();

  const checkFormats = (
    formats: string[],
    returnArgs: Omit<ParsedDate, 'date'>
  ): ParsedDate | undefined => {
    for (const dateFormat of formats) {
      const result = parse(query, dateFormat, currentDate);
      if (!Number.isNaN(result.getDate())) {
        return {
          date: result,
          ...returnArgs,
        };
      }
    }
  };

  const results = [
    checkFormats(PARSING_DATETIME, { hasDate: true, hasTime: true }),
    checkFormats(PARSING_DATE, { hasDate: true, hasTime: false }),
    // todo: fix parsing for time only
    // checkFormats(PARSING_TIME, { hasDate: false, hasTime: true }),
  ];

  const anyPassedResult = results.find((result) => result !== undefined);
  if (anyPassedResult) return anyPassedResult;

  return {
    date: null,
  };
}
