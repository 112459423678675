import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    textfields: {
      width: '60% !important',
      // alignItems: 'center',
      // display: 'flex',
      // justifyContent: 'space-between',
      [theme.breakpoints.only('xs')]: {
        width: '100% !important',
      },
      '& > .MuiTextField-root': {
        // width: 102,
        '& inputAdornedStart': {
          marginLeft: '0 !important',
        },
        '& .MuiInputAdornment-root': {
          marginLeft: '0 !important',
          marginRight: '0 !important',
        },
        [theme.breakpoints.only('xs')]: {
          marginTop: '10px !important',
        },
      },
    },
  });

export default classes;
