import { Grid } from '@mui/material';
import { useState } from 'react';
import { useDashboardScreenStyles } from 'screens/auth/dashboard/Dashboard.screen.style';
import ObActivitesPanel from './ObActivitesPanel';
import ObDashboardMorgan from './ObDashboardMorgan';

const OnBordingDashBoard = () => {
  const classes = useDashboardScreenStyles();
  const [dateCalendarSelected, setDateCalendarSelected] = useState<Date>(new Date());

  return (
    <Grid container spacing={2} className={classes.container}>
      <div className={classes.dashboardPosition}>
        <Grid item className={classes.dashboardSize}>
          <ObDashboardMorgan />
        </Grid>
        <Grid item className={classes.activitiesSize}>
          <ObActivitesPanel
            buttonShow={true}
            setDate={setDateCalendarSelected}
            dateCalendarSelected={dateCalendarSelected}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default OnBordingDashBoard;
