import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '18vw',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        width: '45vw !important',
        padding: '5px !important',
        backgroundColor: theme.palette.secondary.light,
      },
    },
    leftContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 !important',
    },
    textfield: {
      width: '60% !important',
    },
    placeholder: {
      color: theme.palette.text.primary,
      fontFamily: 'Marianne-Regular',
      fontWeight: 400,
      opacity: 1,
    },
    multiSelect: {
      width: '100%',
    },
    filledInput: {
      fontSize: 16,
      fontWeight: 400,
      minWidth: 170,
      '& > :first-of-type': {
        textOverflow: 'ellipsis',
      },
      '& > :last-child': {
        position: 'absolute',
        right: 7,
      },
    },
    searchField: {
      padding: '10px 16px 0px 16px !important',
    },
    hideForMobile: {
      // maxWidth: '98%',
      flex: '1 !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    typography: {
      flex: '1 !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      [theme.breakpoints.down('lg')]: {
        flex: '0 !important',
        with: '100%',
        maxWidth: '98%',
      },
    },
    appBarContainer: {
      marginLeft: 16,
      display: 'inline-grid',
      width: 'fit-content',
    },
  });

export default classes;
