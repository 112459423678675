import { Store, StoreConfig } from '@datorama/akita';
import { OperationStat } from './operations-stats.model';

export interface OperationsStats {
  operationTickets: {
    stats?: OperationStat;
    isLoading: boolean;
  };
}

const initStore = (): OperationsStats => {
  return {
    operationTickets: {
      stats: undefined,
      isLoading: false,
    },
  };
};

@StoreConfig({
  name: 'operations-stats',
  resettable: true,
})
export class OperationsStatsStore extends Store<OperationsStats> {
  constructor() {
    super(initStore());
  }

  setOperationLoading = (isLoading: boolean) => {
    this.update((state) => ({
      ...state,
      operationTickets: {
        ...state.operationTickets,
        isLoading: isLoading,
      },
    }));
  };
}

export const operationsStatsStore = new OperationsStatsStore();
