import React, { FC, useEffect, useState } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScatterController,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { isMobile } from 'utils/isMobile';
import { useTranslation } from 'react-i18next';
import { OpUnitType } from 'dayjs';
import { getPeriodLabels } from 'utils/data/barchart.utils';
import { EcologyStats } from 'store/dashboard/ecologyScreenStats/ecology-screen-stats.model';
import { Colors } from 'constants/Colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ScatterController,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface EcologicEvolutionProps {
  evolutionStats: EcologyStats[];
  unit?: string;
  dropDownDashboard: OpUnitType;
  currentDate: Date;
}

const EcologicEvolution: FC<EcologicEvolutionProps> = (props) => {
  const { dropDownDashboard, evolutionStats, currentDate } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();

  const [labels, setLabels] = useState(getPeriodLabels(dropDownDashboard, currentDate));
  useEffect(() => {
    setLabels(getPeriodLabels(dropDownDashboard, currentDate));
  }, [currentDate, dropDownDashboard]);
  const getMaxValue = () => {
    return Math.max(
      ...evolutionStats.map((data) => Math.max(data.processingCo2, data.processingBurnCo2))
    );
  };

  return (
    <div style={{ height: '100%', width: '100% !important', margin: '30px 0px' }}>
      <Chart
        height="100%"
        type="bar"
        options={{
          elements: {
            point: {
              radius: 0,
              hoverRadius: 5,
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            yAxis: {
              min: 0,
              max: getMaxValue() + 0.5,
              beginAtZero: true,
              ticks: {
                color: 'black',
              },
              stacked: true,
            },
            xAxis: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 10,
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            filler: {
              propagate: true,
            },
            tooltip: {
              // callbacks: {
              //   beforeFooter: function (context) {
              //     return context.toString() + 'CHF';
              //   },
              // },
              callbacks: {
                title: function (context: TooltipItem<'bar' | 'scatter'>[]) {
                  return '';
                },
                label: function (context: TooltipItem<'bar' | 'scatter'>) {
                  return `${context.dataset.label} : ${Math.round(context.parsed.y * 100) / 100}`;
                },
              },
              padding: 50,
              displayColors: false,
              backgroundColor: 'transparent',
              bodyColor: 'black',
              mode: 'nearest',
              bodyAlign: 'center',
              titleColor: 'transparent',
              bodyFont: {
                size: 14,
                weight: 'bold',
                lineHeight: 20,
              },
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.burnCo2'),
              data: evolutionStats
                .map((data) => data.processingBurnCo2)
                .map((v) => (v === 0 ? null : v)),
              backgroundColor: Colors.warning,
              type: 'scatter',
              pointRadius: 5,
              pointHoverBorderColor: Colors.warning,
              pointHoverBackgroundColor: Colors.white,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 4,
              borderWidth: 0,
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.processingCo2'),
              data: evolutionStats.map((data) => data.processingCo2),
              backgroundColor: Colors.blue,
              borderWidth: 0,
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
            {
              label: t('fe.dashboard.morgan.stats.ecologic.evolution.pickupCo2'),
              data: evolutionStats.map((data) => data.pickupCo2),
              backgroundColor: Colors.primary,
              borderWidth: 0,
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
          ],
        }}
      />
    </div>
  );
};

export default EcologicEvolution;
