import { ReactComponent as Download } from 'assets/icons/rework/downloadinline.svg';
import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDashboardMorganStyles } from 'screens/auth/dashboard/morgan/DashboardMorgan.style';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { formatLong } from 'utils/dates/format';
import { useObservable } from '@ngneat/react-rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface DownloadButtonProps {
  startDate: Date;
  endDate: Date;
  multiDropdown: string[];
  startDateButton: Date;
}

export const DownloadButton = (props: DownloadButtonProps) => {
  const { startDate, endDate, multiDropdown, startDateButton } = props;
  const classes = useDashboardMorganStyles();
  const [organisationId] = useObservable(organisationsQuery.activeOrganisationId$);
  const [orgUnitIds] = useObservable(organisationUnitsQuery.activeOrgUnitIds$);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const getCsv = async () => {
    setIsLoading(true);
    const [csv, e] = await resolve(
      metawasteApi.getExpensesCSV(startDate, endDate, multiDropdown, orgUnitIds, organisationId)
    );

    if (e || !csv) {
      setIsLoading(false);
      enqueueSnackbar(t(e?.message || ''), { variant: 'error' });
      return;
    }
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `RAPPORT_${formatLong(startDateButton)}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading(false);
  };

  return (
    <Button
      disabled={isLoading}
      onClick={getCsv}
      variant="contained"
      color="primary"
      endIcon={
        isLoading ? <CircularProgress style={{ width: 20, height: 20, zIndex: 1 }} /> : <Download />
      }
    >
      {t('fe.operations.button.downloadReport')}
    </Button>
  );
};
