import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { useTranslation } from 'react-i18next';
import { createExecutionService } from 'store/operations/createExecution';

const AddExecutionButton = () => {
  const classes = useCreateStyles();
  const { t } = useTranslation();
  const handleNewExecution = () => {
    createExecutionService.addNewExecution();
  };

  return (
    <Button variant="outlined" size="large" startIcon={<AddIcon />} onClick={handleNewExecution}>
      {t('fe.executionModal.addTicket')}
    </Button>
  );
};

export default AddExecutionButton;
