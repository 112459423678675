import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { ExecutionGroupDto } from 'api/dto/execution/ExecutionGroupDto';
import { useTranslation } from 'react-i18next';

interface PickupProps {
  params: any;
  ticketSignature: string;
  name: string;
  address: string;
  executionGroup?: ExecutionGroupDto;
}

const Pickup: FC<PickupProps> = (props) => {
  const { t } = useTranslation();

  const { params, ticketSignature, executionGroup, name, address } = props;
  return (
    <div style={{ width: '100%' }}>
      <Typography
        variant="h5"
        sx={{
          overflow: 'hidden !important',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis !important',
          width: '90% !important',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: 12,
          fontWeight: 400,
          marginTop: 4,
          textTransform: params ? 'none' : 'uppercase',
        }}
        sx={{
          overflow: 'hidden !important',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis !important',
          width: '90% !important',
        }}
      >
        {address}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          variant="subtitle2"
          style={{ fontSize: 12, fontWeight: 400, marginTop: 2 }}
          sx={{
            overflow: 'hidden !important',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis !important',
          }}
        >
          {ticketSignature}
        </Typography>
      </Stack>
    </div>
  );
};

export default Pickup;
