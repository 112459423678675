import { AddressDto } from 'api/dto/client/AddressDto';
import { ServiceLightDto } from 'api/dto/services/ServiceLightDto';
import { SelectItem } from 'components/Select/SelectItem.interface';
import i18n from 'utils/data/i18n.utils';

export interface ClientInvoiceAddressDto extends AddressDto {
  clientInvoiceAddressId: number;
  name: string;
  addressCode: string;
  internalName: string;
  emails: string[];
  ccEmails: string[];
}

export interface DatahubSpecDto {
  idDatahub: string;
  additionalInformation: string;
}

export interface ContractDto {
  id: number;
  contractReference: string;
  contractName: string;
  startDate: Date;
  endDate: Date;
  clientOrganisationUnitName: string;
  clientOrganisationName: string;
  servicesCount: number;
  waste: string;
  container: string;
  services: ServiceLightDto[];
  clientOrganisationId: number;
  clientOrganisationUnitId: number;
  invoiceAddress: ClientInvoiceAddressDto;
  pickupSupplierUnitName: string;
  datahubSpec: DatahubSpecDto;
  status: ContractFiltersEnum;
}

export enum ContractFiltersEnum {
  ALL = 'ALL',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export const ContractFilterSelectItems = (): SelectItem[] => [
  { label: i18n.t('global.contractFilter.all'), value: ContractFiltersEnum.ALL },
  { label: i18n.t('global.contractFilter.pending'), value: ContractFiltersEnum.PENDING },
  { label: i18n.t('global.contractFilter.expired'), value: ContractFiltersEnum.EXPIRED },
];
