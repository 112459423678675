import React from 'react';
import { GridColDef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import GridRowDate from 'components/MaterialDatagrid/Rows/GridRowDate';
import { Typography } from '@mui/material';
import { formatTime, formatUltraShort } from 'utils/dates/format';
import Pickup from '../cell/pickup';
import Container from '../cell/container';
import ContainerMobile from '../cell/container/mobile';
import Actions from '../cell/actions';
import i18n, { formatTranslatedName } from 'utils/data/i18n.utils';
import { ID } from '@datorama/akita';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import { formatAddress } from 'api/dto/client/AddressDto';
import { Group } from '../cell/group/group';

interface ColumnTypes {
  [key: string]: GridColDef;
}

function getColumnTypes<T extends { [keyName: string]: GridColDef }>(types: T) {
  return types;
}

interface GetColumnsParams {
  isDesktop: boolean;
  handleClick?: (executionId: ID, isAccepted: boolean) => void;
}

export const patrickPickupColumns = (params: GetColumnsParams): GridColumns => {
  const COLUMN_TYPES: ColumnTypes = getColumnTypes({
    pickup: {
      field: 'pickup',
      headerName: 'Documents',
      flex: 2,
      cellClassName: !params.isDesktop ? 'column-mobile' : '',
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) => (
        <Pickup
          params={params.isDesktop}
          executionGroup={renderParams.row.executionGroup}
          ticketSignature={renderParams.row.ticketSignature}
          name={renderParams.row.organisationUnit?.name ?? ''}
          address={
            renderParams.row.organisationUnit?.address
              ? formatAddress(renderParams.row.organisationUnit.address)
              : ''
          }
        />
      ),
    },
    date: {
      field: 'plannedFrom',
      headerName: 'Last update',
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date | null, ExecutionDto, any>) => (
        <GridRowDate date={params.value} endTime={params.row.plannedTo} />
      ),
    },
    dateMobile: {
      field: 'plannedFrom',
      headerName: 'Last update',
      flex: 1,
      cellClassName: 'date-mobile',
      renderCell: (params: GridRenderCellParams<Date | null, any, any>) => (
        <div>
          {!params.value ? (
            <Typography variant="subtitle2">{i18n.t('fe.asap')}</Typography>
          ) : (
            <>
              <Typography variant="subtitle2">{formatUltraShort(params.value)}</Typography>
              <Typography variant="subtitle2">{formatTime(params.value)}</Typography>
            </>
          )}
        </div>
      ),
    },
    containers: {
      field: 'container.name',
      headerName: 'Containers',
      flex: 2,
      // valueGetter: (params) => params.row.container.name,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) =>
        renderParams.row.executionGroup ? (
          <Group executionGroup={renderParams.row.executionGroup} />
        ) : (
          <Container
            container={i18n.t('fe.dashboard.patrick.table.quantity', {
              quantity: renderParams.row.quantity1,
            })}
            wasteName={formatTranslatedName(
              renderParams.row.service.waste?.nameTranslateKey,
              renderParams.row.service.waste?.name
            )}
            containerName={formatTranslatedName(
              renderParams.row.service.container?.nameTranslateKey,
              renderParams.row.service.container?.name
            )}
          />
        ),
    },
    containersMobile: {
      field: 'containers',
      headerName: 'Containers',
      flex: 1,
      sortable: false,
      // cellClassName: 'title-mobile',
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) => (
        <ContainerMobile
          execution={renderParams.row}
          onClick={(isAccepted) => params.handleClick?.(renderParams.id, isAccepted)}
        />
      ),
    },
    actions: {
      field: 'actions',
      headerClassName: 'hiddenHeader',
      cellClassName: !params.isDesktop ? 'button-mobile' : 'noPaddingRight',
      sortable: false,
      filterable: false,
      align: 'right',
      flex: 1.5,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (renderParams: GridRenderCellParams<any, ExecutionDto, any>) => (
        <Actions
          onClick={(isAccepted) => params.handleClick?.(renderParams.id, isAccepted)}
          asapMode={renderParams.row.plannedFrom === null}
        />
      ),
    },
  });
  if (params.isDesktop) {
    return [COLUMN_TYPES.pickup, COLUMN_TYPES.date, COLUMN_TYPES.containers, COLUMN_TYPES.actions];
  }

  return [
    COLUMN_TYPES.containersMobile,
    // COLUMN_TYPES.dateMobile,
    // COLUMN_TYPES.pickup,
    // COLUMN_TYPES.actions,
  ];
};
