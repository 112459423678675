import { CircularProgress } from '@mui/material';
import ReasonsForNonConformities from 'components/Graph/ReasonsForNonConformities';
import { translateIssueKindName } from 'utils/data/issueKinds.utils';
import React from 'react';
import { IssueKindUi } from 'store/dashboard/operationScreenStats/operation-screen-stats.model';
import { useTranslation } from 'react-i18next';
import { NoResultComponent } from './NoResult';

interface OperationsIssueReasonsProps {
  isLoading?: boolean;
  issueKinds?: IssueKindUi[];
}

export const OperationsIssueReasons = (props: OperationsIssueReasonsProps) => {
  const { isLoading, issueKinds } = props;
  const colorForNonConformities = ['#920000', '#FB5D5D', '#3F0D0D', '#FFD3CF'];
  const { t } = useTranslation();

  if (isLoading) {
    return <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />;
  }

  if (!issueKinds || issueKinds?.length === 0) {
    return <NoResultComponent />;
  }

  return (
    <ReasonsForNonConformities
      datasets={[
        {
          data: issueKinds ? issueKinds?.map((issue) => issue.issueCount) : [],
          backgroundColor: colorForNonConformities,
          borderColor: colorForNonConformities,
        },
      ]}
      label={issueKinds?.map((issue) => translateIssueKindName(issue.issueKindName)) ?? []}
    />
  );
};
