import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {
  initTableFilters,
  TableFilter,
  TableFilterType,
} from 'components/Select/SelectItem.interface';
import { SupplierOrganisationUnitDto } from 'api/dto/supplier/SupplierOrganisationUnitDto';
import { SupplierDto } from 'api/dto/supplier/SupplierDto';
import { ClientDto } from 'api/dto/client/ClientDto';
import { ClientOrganisationUnitDto } from 'api/dto/client/ClientOrganisationUnitDto';
import { WasteLightDto } from 'api/dto/services/WasteLightDto';
import { ContainerLightDto } from 'api/dto/services/ContainerLightDto';

export interface FilterResult {
  filterType: TableFilterType;
  entity:
    | SupplierOrganisationUnitDto
    | SupplierDto
    | ClientDto
    | ClientOrganisationUnitDto
    | WasteLightDto
    | ContainerLightDto;
}

export interface FiltersState extends EntityState<FilterResult> {
  currentFilters: TableFilter[];
}

const initialState: FiltersState = {
  currentFilters: initTableFilters([
    TableFilterType.CLIENT_ORGANISATION,
    TableFilterType.CLIENT_ORGANISATION_UNIT,
    TableFilterType.SUPPLIER_ORGANISATION,
    TableFilterType.SUPPLIER_ORGANISATION_UNIT,
    TableFilterType.SERVICE_KIND,
    TableFilterType.WASTE,
    TableFilterType.CONTAINER,
  ]),
};

@StoreConfig({
  name: 'filters',
  resettable: true,
})
export class FiltersStore extends EntityStore<FiltersState> {
  constructor() {
    super(initialState);
  }
}

export const filtersStore = new FiltersStore();
