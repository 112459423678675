import React from 'react';

import { useMaterialTagStyles } from './MaterialTag.style';
import { Typography } from '@mui/material';
import { isMobile } from 'utils/isMobile';
import { useTranslation } from 'react-i18next';

interface MaterialTagProps {
  title: string;
  size?: 'small' | 'big';
  wrap?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  noWidthLimit?: boolean;
}

const MaterialTag = (props: MaterialTagProps) => {
  const { title, wrap, maxHeight, maxWidth, size = 'small', noWidthLimit } = props;
  const classes = useMaterialTagStyles();
  const isMobiles = isMobile();
  const { t } = useTranslation();

  const colorForDifferentStatus = (title?: string) => {
    switch (title) {
      case t(`database.executionStatus.EXECUTED`):
        return '#A8EEAF';
      case t(`database.executionStatus.PLANNED_OR_ACCEPTED`):
        return '#F9C096';
      default:
        return '#f4f6f8';
    }
  };

  return (
    <Typography
      className={classes.container}
      sx={{ background: colorForDifferentStatus(title) }}
      textAlign="center"
      style={{
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        fontSize: size === 'small' ? 10 : 13,
        width: isMobiles && !noWidthLimit ? 55 : 'auto',
      }}
      noWrap={wrap}
    >
      {title}
    </Typography>
  );
};

export default MaterialTag;
