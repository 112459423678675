import { FC, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Grid, IconButton, Typography } from '@mui/material';
import { formatMonth } from 'utils/dates/format';

interface IPickupDatePickerNavbarProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  className?: string;
  month: Date;
  setMonth?: (arg0: Date) => void;
}

const PickupDatePickerNavbar: FC<IPickupDatePickerNavbarProps> = ({
  onPreviousClick,
  onNextClick,
  month,
  setMonth,
}) => {
  useEffect(() => {
    setMonth && setMonth(month);
  }, [month, setMonth]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <IconButton onClick={() => onPreviousClick()}>
        <ArrowBackIosNewIcon style={{ color: 'black' }} />
      </IconButton>
      <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
        {formatMonth(month)}
      </Typography>
      <IconButton onClick={() => onNextClick()}>
        <ArrowForwardIosIcon style={{ color: 'black' }} />
      </IconButton>
    </Grid>
  );
};

export default PickupDatePickerNavbar;
