import React from 'react';
import { useSnackbar } from 'notistack';
import {
  createExecutionQuery,
  createExecutionService,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import SnackError from 'utils/models/snackError';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useObservable } from '@ngneat/react-rxjs';

export interface SaveExecutionButtonComponentProps {
  executions: ExecutionDetailsUi[];
}

const SaveExecutionButtonComponent = (props: SaveExecutionButtonComponentProps) => {
  const { executions } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useCreateStyles();
  const { t } = useTranslation();

  const [isSaving] = useObservable(createExecutionQuery.isSaving$);

  const handleSave = () => {
    createExecutionService
      .save(executions)
      .then((message) => {
        enqueueSnackbar(message, { variant: 'success' });
      })
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  return (
    <LoadingButton variant="contained" size="large" onClick={handleSave} loading={isSaving}>
      {t('fe.executionModal.saveButton')}
    </LoadingButton>
  );
};

export default SaveExecutionButtonComponent;
