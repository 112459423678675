import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { Colors } from 'style/materialStylings';
import { ServiceLightDto } from 'api/dto/services/ServiceLightDto';
import { ContractDto } from 'store/contracts/contracts.model';
import { useState } from 'react';
import HistoryModalScreen from '../modal/HistoryModal.screen';
import { ReactComponent as IconInfo } from 'assets/icons/icon_info_black.svg';
import { t } from 'i18next';
import ScheduleModalScreen from '../modal/ScheduleModal.screen';
import { ServiceKindTypeEnum } from 'utils/enums/ServiceKindTypeEnum';

interface ContractServiceCardProps {
  service: ServiceLightDto;
  contract: ContractDto;
}

function ContractServiceCard(props: ContractServiceCardProps) {
  const { service, contract } = props;
  const pricePeriod = service.pricePeriods?.find((period) => period.toDate === null);
  const sellPrice = pricePeriod?.sellPrices?.find((price) => price.validToDate === null);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const typographyStyles = {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  };

  return (
    <Stack
      spacing={2}
      direction="column"
      bgcolor={Colors.background}
      width="100%"
      height="100%"
      pl={2}
      pr={8}
      py={2}
    >
      <Stack spacing={1} direction="row" justifyContent="space-between" my={2}>
        <Typography fontSize="16px" fontWeight="700">
          {service?.clientWasteName ?? '-'}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" color={Colors.darkBlue} fontSize="14px">
            {t('fe.contracts.details.servicesCard.frequency')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="body2"
              color={Colors.darkBlue}
              fontSize="14px"
              fontWeight="700"
              sx={typographyStyles}
            >
              {service.activeSchedule
                ? t('fe.operations.table.recurrent')
                : t('fe.operations.table.onDemand')}
            </Typography>
            {service.activeSchedule && (
              <IconButton onClick={() => setIsScheduleModalOpen(true)}>
                <IconInfo />
              </IconButton>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color={Colors.darkBlue} fontSize="14px">
            {t('fe.contracts.details.servicesCard.serviceKind')}
          </Typography>
          <Typography
            variant="body2"
            color={Colors.darkBlue}
            fontSize="14px"
            fontWeight="700"
            sx={typographyStyles}
          >
            {service?.kind?.kindTranslateKey ? t(service?.kind?.kindTranslateKey) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color={Colors.darkBlue} fontSize="14px">
            {t('fe.contracts.details.servicesCard.supplier')}
          </Typography>
          <Typography
            variant="body2"
            color={Colors.darkBlue}
            fontSize="14px"
            fontWeight="700"
            sx={typographyStyles}
          >
            {service?.supplierOrganisationUnitName ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color={Colors.darkBlue} fontSize="14px">
            {t('fe.contracts.details.servicesCard.currentPrice')}
          </Typography>
          <Typography
            variant="body2"
            color={Colors.darkBlue}
            fontSize="14px"
            fontWeight="700"
            sx={typographyStyles}
          >
            {new Intl.NumberFormat('de-CH', {
              style: 'currency',
              currency: sellPrice?.currency || 'CHF',
            }).format((sellPrice?.unitPriceInCents || 0) / 100)}
          </Typography>
        </Grid>
      </Grid>
      <Stack style={{ marginTop: '10px' }} />
      <Stack spacing={1} direction="row" justifyContent="space-between" px={2}>
        {service.kind.type !== ServiceKindTypeEnum.PROCESSING &&
          service.kind.type !== ServiceKindTypeEnum.VALORISATION && (
            <Stack spacing={1} direction="column" justifyContent="space-between">
              <Typography variant="body2" color={Colors.darkBlue} fontSize="14px">
                {t('fe.contracts.details.servicesCard.executionCount')}
              </Typography>
              <Typography variant="body2" color={Colors.darkBlue} fontSize="14px" fontWeight="700">
                {service?.executionCount ?? '0'}
              </Typography>
            </Stack>
          )}
        {/*<Button variant="outlined" color="primary" onClick={() => setIsHistoryModalOpen(true)}>*/}
        {/*  {t('fe.contracts.details.servicesCard.priceHistory')}*/}
        {/*</Button>*/}
      </Stack>
      {isHistoryModalOpen && (
        <HistoryModalScreen
          handleClickCloseModal={() => setIsHistoryModalOpen(false)}
          service={service}
        />
      )}
      {isScheduleModalOpen && (
        <ScheduleModalScreen
          handleClickCloseModal={() => setIsScheduleModalOpen(false)}
          state={isScheduleModalOpen}
          activeSchedule={service.activeSchedule}
          service={service}
        />
      )}
    </Stack>
  );
}

export default ContractServiceCard;
