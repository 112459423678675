import { Divider, IconButton, InputLabel, Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ManageIssue } from 'store/issues/issues.model';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Sizes } from 'style/materialStylings';
import TextFieldComponent from 'components/textfield/TextField.component';
import SelectFileCardComponent from 'components/selectFileCard/SelectFileCard.component';

interface IssueConnectFormProps {
  isCreation?: boolean;
  issue: ManageIssue;
  updateIssue: (data: ManageIssue) => void;
}

const IssueConnectForm = (props: IssueConnectFormProps) => {
  const { issue, updateIssue } = props;

  const { t } = useTranslation();

  const styled: SxProps = {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none !important',
    },
  };

  return (
    <>
      <Stack py="20px" spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize={16} fontWeight="700">
            {t('fe.operations.nonCompliance.manage.connect')}
          </Typography>
          {issue?.documents?.length ? (
            <IconButton target="_blank" href="">
              <OpenInNewIcon color="primary" />
            </IconButton>
          ) : null}
        </Stack>
        <Stack spacing={2}>
          <Stack>
            <SelectFileCardComponent
              readonly
              accept=".png, .svg, .jpg, .jpeg"
              document={issue?.documents?.length ? issue.documents[0] : undefined}
              handleUpdateDocument={() => undefined}
            />
          </Stack>
          {issue?.executionId && (
            <>
              <TextFieldComponent
                sx={styled}
                labelWidth={Sizes.drawerInputLabelWidth}
                label={t('fe.operations.nonCompliance.manage.ticket')}
                value={issue.executionSignature}
                readOnly
              />
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <InputLabel sx={{ width: Sizes.drawerInputLabelWidth }} required={false}>
                  {t('fe.operations.nonCompliance.manage.ticketDetails')}
                </InputLabel>
                <IconButton onClick={() => undefined}>
                  <OpenInNewIcon color="primary" />
                </IconButton>
              </Stack>
            </>
          )}
          <TextFieldComponent
            sx={styled}
            labelWidth={Sizes.drawerInputLabelWidth}
            label={t('global.createByFullName')}
            value={`${issue?.author?.firstName} ${issue?.author?.lastName}`}
            readOnly
          />
        </Stack>
      </Stack>
      <Divider variant="fullWidth" orientation="horizontal" />
    </>
  );
};

export default IssueConnectForm;
