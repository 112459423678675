import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent } from '@mui/material';
import { metawasteApi } from 'api/Axios';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import React, { useEffect, useState } from 'react';
import { ExecutionDto } from 'api/dto/execution/ExecutionDto';
import env from 'utils/env';
import { executionColumns } from 'screens/auth/operations/list/table.utils';
import MaterialDatagrid from 'components/MaterialDatagrid';
import { ID } from '@datorama/akita';
import { createExecutionService } from 'store/operations/createExecution';
import { isMobile } from 'utils/isMobile';
import ActionOperationsScreen from 'screens/auth/operations/bottomModal/ActionOperations.screen';
import dayjs from 'utils/data/dayjs.utils';
import { resolve } from 'utils/data/promise-utils';
import { useObservable } from '@ngneat/react-rxjs';
import { filtersQuery } from 'store/common/filters/filters.query';

interface Props {
  handleClose?: () => void;
  from: Date;
  to: Date;
}

const PlanningWeekModalList = (props: Props) => {
  const { handleClose, from, to } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = isMobile();

  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const [executions, setExecutions] = useState<ExecutionDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [filters] = useObservable(filtersQuery.currentFilters$);

  useEffect(() => {
    const searchExecutions = async () => {
      const [search] = await resolve(
        metawasteApi.getSearchExecutionForMultipleModal(
          page + 1,
          dayjs(from).toDate(),
          dayjs(to).toDate(),
          organisationsQuery.activeOrganisationId,
          organisationUnitsQuery.activeOrgUnitId,
          filters
        )
      );
      setExecutions(search?.data ?? []);
      setLoading(false);
      setTotal(search?.total || 0);
    };

    setExecutions([]);
    setLoading(true);
    searchExecutions().catch((err) => console.log(err));
  }, [from, to, page, filters]);

  const handleOpenModal = (ids: ID[]) => {
    const execution = executions.find((execution) => ids.includes(execution.id));
    if (execution) {
      createExecutionService.openModal(execution.id);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="xl">
      <DialogContent>
        <MaterialDatagrid
          loading={loading}
          autoHeight
          onRowSelected={handleOpenModal}
          dataByPage={env.PAGINATION}
          page={page}
          totalDataPage={total}
          onPageChange={setPage}
          data={executions}
          rowHeight={isMobiles ? 170 : 75}
          defaultSortField={isMobiles ? 'pickupDateMobile' : 'pickupDate'}
          columns={executionColumns({
            isDesktop: !isMobiles,
            openModal: () => setIsBottomModalOpen(true),
          })}
        />
      </DialogContent>
      {isBottomModalOpen && isMobiles && (
        <ActionOperationsScreen handleClickCloseModal={() => setIsBottomModalOpen(false)} />
      )}
    </Dialog>
  );
};

export default PlanningWeekModalList;
