import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import { PricePeriodDto, SellPriceDto, ServiceLightDto } from 'api/dto/services/ServiceLightDto';
import { t } from 'i18next';
import dayjs from 'dayjs';

function dateFormat(date: string) {
  return date
    ? date
        .split('T')[0]
        .split('-')
        .reverse()
        .map((d) => d.slice(-2))
        .join('.')
    : 'N/A';
}

interface HistoryModalScreenProps {
  handleClickCloseModal: () => void;
  state?: boolean;
  service: ServiceLightDto;
}

const CustomStepIcon = () => (
  <div
    style={{
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'black',
    }}
  />
);

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  '.MuiStepConnector-line': {
    borderColor: 'black',
    borderWidth: 1,
    marginLeft: -10,
  },
}));

const HistoryModalScreen = (props: HistoryModalScreenProps) => {
  const { handleClickCloseModal, state, service } = props;

  const sortedPeriods = service.pricePeriods.sort((a: PricePeriodDto, b: PricePeriodDto) => {
    return (new Date(b.fromDate).getTime() || 0) - (new Date(a.fromDate).getTime() || 0);
  });

  const steps = sortedPeriods.map((pricePeriod: PricePeriodDto) => {
    const price = pricePeriod.sellPrices?.[0];

    return {
      price: new Intl.NumberFormat('de-CH', {
        currency: price.currency,
        style: 'currency',
      }).format(price.unitPriceInCents / 100),
      date: pricePeriod.fromDate,
    };
  });

  const currentPeriod = sortedPeriods.filter(
    (period: PricePeriodDto) => period.toDate == null
  )?.[0];

  const currentPrice: SellPriceDto | undefined = currentPeriod?.sellPrices?.[0];

  return (
    <Dialog open={true} fullWidth>
      <Box sx={{ position: 'relative' }}>
        <DialogTitle fontSize="12px" fontWeight="400">
          {t('fe.contracts.details.servicesCard.priceHistoryModal.title')}
        </DialogTitle>
        <IconButton
          onClick={handleClickCloseModal}
          sx={{ position: 'absolute', top: 8, left: 380 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Stack spacing={3} direction="column" px="20px">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontSize="14px" fontWeight="450px" color="gray">
              {t('fe.contracts.details.servicesCard.currentPrice')}
            </Typography>
            <Typography fontSize="14px" fontWeight="450px">
              {new Intl.NumberFormat('de-CH', {
                currency: currentPrice?.currency,
                style: 'currency',
              }).format((currentPrice?.unitPriceInCents || 0) / 100)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontSize="14px" fontWeight="450px" color="gray">
              {t('fe.contracts.details.servicesCard.priceHistoryModal.fromDate')}
            </Typography>
            <Typography fontSize="14px" fontWeight="450px">
              {currentPeriod ? dayjs(currentPeriod.fromDate).format('DD.MM.YYYY') : 'N/A'}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="column" spacing={1}>
            <Stepper orientation="vertical" connector={<CustomStepConnector />}>
              {steps.map((step, index) => (
                <Step key={index} active completed>
                  <StepLabel StepIconComponent={CustomStepIcon}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>{step.price}</Typography>
                      <Typography>•</Typography>
                      <Typography variant="subtitle2">
                        {dayjs(step.date).format('DD.MM.YYYY')}
                      </Typography>
                    </Stack>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryModalScreen;
