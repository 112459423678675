import React from 'react';

import MaterialDropdownBase, { MaterialDropdownBaseProps } from '../MaterialDropdownBase';
import { useMaterialDropdownDashboardStyles } from './MaterialDropdownDashboard.style';

interface MaterialDropdownProps extends MaterialDropdownBaseProps {
  withBorder?: boolean;
}

const MaterialDropdownDashboard = (props: MaterialDropdownProps) => {
  const { withBorder } = props;
  const classes = useMaterialDropdownDashboardStyles();

  const className = classes.textfield + ' ' + (withBorder && classes.withBorder);

  return <MaterialDropdownBase {...props} className={className} />;
};

export default MaterialDropdownDashboard;
