import { ID } from '@datorama/akita';
import { SelectItem } from 'components/Select/SelectItem.interface';
import i18n from 'utils/data/i18n.utils';

export enum ClientInvoiceTypeEnum {
  'DEFAULT' = 'DEFAULT',
  'CANCEL' = 'CANCEL',
}

export enum InvoiceStatusEnum {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  SENT = 'SENT',
  PAID = 'PAID',
  LATE = 'LATE',
}

export enum InvoiceFiltersEnum {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  SENT = 'SENT',
  PAID = 'PAID',
  LATE = 'LATE',
}

export const InvoiceFilterSelectItems = (): SelectItem[] => [
  {
    label: i18n.t('global.invoiceFilter.all'),
    value: InvoiceFiltersEnum.ALL,
    backgroundColor: '#FAFAFA',
  },
  {
    label: i18n.t('global.invoiceFilter.validated'),
    value: InvoiceFiltersEnum.VALIDATED,
    backgroundColor: 'rgba(55, 220, 88, 0.3)',
  },
  {
    label: i18n.t('global.invoiceFilter.sent'),
    value: InvoiceFiltersEnum.SENT,
    backgroundColor: 'rgba(55, 220, 88, 0.7)',
  },
  {
    label: i18n.t('global.invoiceFilter.paid'),
    value: InvoiceFiltersEnum.PAID,
    backgroundColor: '#5BAF77',
  },
  {
    label: i18n.t('global.invoiceFilter.late'),
    value: InvoiceFiltersEnum.LATE,
    backgroundColor: 'rgba(219, 95, 104, 0.3)',
  },
];

export interface Invoice {
  id: ID;
  clientCount: number;
  unitCount: number;
  clientName: string;
  unitName: string;
  invoiceRef: string;
  dueDate: Date;
  createdAt: Date;
  comment: string;
  totalAmountNoVatInCents: number;
  totalAmountVatIncludedInCents: number;
  currency: string;
  status: InvoiceStatusEnum;
  reminderDate: Date;
  documentDate: Date;
  accountingTransferDate: Date;
  organisationKind?: string;
  type: ClientInvoiceTypeEnum;
  deletedAt: Date;
  cancelledAmountNoVatInCents: number;
  cancelledCount: number;
}
