import { InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CardColored from 'components/Card/CardColored';
import BarchartFinances from 'components/Graph/BarchartFinance';
import ExpensesByService from 'components/Graph/ExpensesByService';
import TotalExpenses from 'components/Graph/TotalExpenses';
import WeightByWasteFamily from 'components/Graph/WeightByWasteFamily';
import MaterialDropdownDashboard from 'components/MaterialDropdown/MaterialDropdownDashboard';
import { WasteTypeIconNames } from 'constants/WasteTypesIcon';
import { OpUnitType } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFinancesScreenStyles } from 'screens/auth/dashboard/morgan/finances/Finances.screen.style';
import { ServiceKindTypeEnum } from 'utils/enums/ServiceKindTypeEnum';
import { isMobile } from 'utils/isMobile';

const colors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

interface ObFinanceProps {
  from: Date;
  to: Date;
  wasteIds: string[];
  dropDownDashboard: OpUnitType;
}

const ObFinance = (props: ObFinanceProps) => {
  const { from, to, wasteIds, dropDownDashboard } = props;
  const isMobiles = isMobile();
  const classes = useFinancesScreenStyles();
  const { t } = useTranslation();
  const [dateFilterKind, setDateFilterKind] = useState<'OPERATIONS' | 'INVOICING'>('OPERATIONS');

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ height: '400px' }}>
            <CardContent>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h3" className={classes.cardTypography}>
                    {t('fe.dashboard.morgan.stats.finance.totalExpense')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={1} className={classes.titleTotalExpensesPosition}>
                    <Tooltip
                      title={
                        <Typography>
                          {t('fe.dashboard.morgan.stats.finance.tooltip.expenseValoEvolution')}
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
              <TotalExpenses
                isLoading={false}
                dropDownDashboard={dropDownDashboard}
                currentData={[]}
                oldData={[]}
                currentDate={from}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Stack spacing={2} alignItems="center" direction="row">
              <Typography variant="h5">
                {t('fe.dashboard.morgan.stats.finance.select.title')}
              </Typography>
              <Tooltip
                title={
                  <Typography>
                    {t('fe.dashboard.morgan.stats.finance.tooltip.selectDateFilter')}
                  </Typography>
                }
                placement="right"
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Stack>

            <MaterialDropdownDashboard
              sx={{ height: '50px' }}
              color="secondary"
              items={[
                {
                  value: 'OPERATIONS',
                  label: t('fe.dashboard.morgan.stats.finance.select.operations'),
                },
                {
                  value: 'INVOICING',
                  label: t('fe.dashboard.morgan.stats.finance.select.invoicing'),
                },
              ]}
              value={dateFilterKind}
              handleChange={(value) => setDateFilterKind(value)}
            />
          </Stack>
        </Grid>
        <Grid item container xs={12} direction="row" spacing={2}>
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.expenseOnPeriod')}
                isLoading={false}
                data={0}
                devise="CHF"
                background="white"
                dataStatistic={0}
                title={t('fe.dashboard.morgan.stats.finance.expenseOnPeriod')}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.expenseValo')}
                tooltipIconColor="white"
                isLoading={false}
                data={0}
                noPourcentage={false}
                devise="CHF"
                dataStatistic={0}
                background="blueColored.main"
                title={t('fe.dashboard.morgan.stats.finance.retrocession')}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.averagePickupExpense')}
                isLoading={false}
                data={0}
                devise="CHF"
                noPourcentage
                dataStatistic={-37}
                background="white"
                title={t('fe.dashboard.morgan.stats.finance.averageExpense')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Card sx={{ height: '600px' }}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.expensesByServiceType')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t('fe.dashboard.morgan.stats.finance.tooltip.expensesByServiceType')}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
              <ExpensesByService
                isLoading={false}
                label={Object.values(ServiceKindTypeEnum).filter((_, index) => index < 3)}
                datasets={[
                  {
                    data: Object.values(ServiceKindTypeEnum)
                      .filter((_, index) => index < 3)
                      .map(() => 0),
                    backgroundColor: colors,
                  },
                ]}
                unit="CHF"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: '600px' }}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.weightByWasteFamily')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t('fe.dashboard.morgan.stats.finance.tooltip.weightByWasteFamily')}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
              <WeightByWasteFamily
                isLoading={false}
                labels={Object.values(WasteTypeIconNames).filter((_, index) => index < 3)}
                datasets={[
                  {
                    data: Object.values(WasteTypeIconNames)
                      .filter((_, index) => index < 3)
                      .map(() => 0),
                    backgroundColor: colors,
                  },
                ]}
                unit="T"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.wasteExpense')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t(
                        'fe.dashboard.morgan.stats.finance.tooltip.expensesValorisationByWasteFamily'
                      )}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '30px' }}>
              <BarchartFinances isLoading={false} data={[]} labels={['test']} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default ObFinance;
