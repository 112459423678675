import { alpha, createTheme } from '@mui/material';
import { Colors } from './materialStylings';
import shadows from '@mui/material/styles/shadows';
import { grey } from '@mui/material/colors';
import fonts from '../assets/fonts';
import { drawer } from 'style/drawer.theme';
import { inputs } from 'style/inputs.theme';

const defaultTheme = createTheme();

const theme = (colors: typeof Colors) =>
  createTheme({
    palette: {
      primary: {
        main: colors.primary,
        dark: colors.darkGreen,
      },
      secondary: {
        light: colors.lightGray,
        main: colors.secondary,
        dark: colors.darkGray,
      },
      neutral: {
        light: colors.lightNeutral,
        main: colors.white,
        contrastText: colors.neutralText,
      },
      error: {
        light: colors.lightRed,
        main: colors.darkRed,
        dark: colors.mainRed,
      },
      blueColored: {
        main: colors.blueColored,
      },
      greenColored: {
        main: colors.greenColored,
      },
      hoverButton: {
        main: colors.hoverButton,
      },
      warning: {
        main: colors.mainOrange,
      },
      info: {
        light: colors.greenRanking,
        main: colors.mainBlue,
        dark: colors.grayRanking,
      },
      success: {
        light: colors.lightGreen,
        main: colors.mainGreen,
      },
      text: {
        primary: colors.darkBlue,
        secondary: colors.darkGray,
      },
      background: {
        paper: colors.white,
        default: colors.background,
      },
      ecoGreen: {
        main: colors.greenCardEco,
      },
      ecoGray: {
        main: colors.lightGray,
      },
    },
    typography: {
      fontFamily: 'CircularStd, sans-serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `${fonts}
          html {
            -webkit-font-smoothing: auto;
          }
					body {
						scrollbar-color: ${colors.primary} ${colors.primaryClear};
						scrollbar-width: thin;
					}
					input[type="search"]::-webkit-search-decoration,
          input[type="search"]::-webkit-search-cancel-button,
          input[type="search"]::-webkit-search-results-button,
          input[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance:none;
          }
					*::-webkit-scrollbar {
						height: 8px;
						width: 8px;
					}
					*::-webkit-scrollbar-track {
						background-color: ${colors.primaryClear};
						border-radius: 4px;
						margin: 10px;
					}
					*::-webkit-scrollbar-thumb {
						background-color: ${colors.primary};
						border-radius: 4px;
					}`,
      },
      // MuiTooltip: {
      //   styleOverrides: {
      //     tooltip: {
      //       position: 'static',
      //       backgroundColor: colors.white,
      //       boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2) !important',
      //       maxWidth: 550,
      //     },
      //   },
      // },
      MuiTypography: {
        styleOverrides: {
          root: {
            whiteSpace: 'pre-line',
            '& > a': {
              color: colors.primary,
            },
          },
          h1: {
            fontSize: 30,
            fontWeight: 800,
          },
          h2: {
            fontSize: 24,
            fontWeight: 700,
          },
          h3: {
            fontSize: 18,
            fontWeight: 700,
          },
          h4: {
            fontSize: 16,
            fontWeight: 700,
          },
          h5: {
            fontSize: 14,
            fontWeight: 700,
          },
          h6: {
            fontSize: 10,
            fontWeight: 700,
          },
          subtitle1: {
            fontSize: 16,
            fontWeight: 500,
            color: Colors.darkGray,
          },
          subtitle2: {
            fontSize: 12,
            fontWeight: 500,
            color: Colors.darkGray,
          },
        },
      },
      MuiIcon: {
        variants: [
          {
            props: { color: 'error' },
            style: {
              color: colors.darkRed,
            },
          },
        ],
      },
      MuiBadge: {
        variants: [
          {
            props: { variant: 'dot' },
            style: {
              '& .MuiBadge-badge': {
                minWidth: '5px',
                height: '5px',
              },
            },
          },
          {
            props: { variant: 'standard', color: 'warning' },
            style: {
              '& .MuiBadge-badge': {
                backgroundColor: colors.red,
                color: colors.white,
              },
            },
          },
          {
            props: { variant: 'standard' },
            style: {
              '& .MuiBadge-badge': {
                color: colors.white,
              },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'basic', size: 'small' },
            style: {
              borderRadius: 4,
              color: colors.secondary,
              backgroundColor: colors.whiteInputBackground,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 400,
              height: 28,
              padding: '8px 10px 8px 10px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
                marginRight: 8,
                [defaultTheme.breakpoints.down('lg')]: {
                  margin: '8px',
                },
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
            },
          },
          {
            props: { variant: 'basic', size: 'medium' },
            style: {
              borderRadius: 4,
              color: colors.whiteInputBackground,
              backgroundColor: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 400,
              height: 28,
              padding: '8px 10px 8px 10px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
                marginRight: 8,
                [defaultTheme.breakpoints.down('lg')]: {
                  margin: '8px',
                },
              },
              '&:hover': {
                color: colors.primary,
                backgroundColor: colors.secondary,
              },
              '&:disabled': {
                color: colors.primary,
              },
            },
          },
          {
            props: { variant: 'basic', size: 'large' },
            style: {
              borderRadius: 4,
              color: colors.darkGray,
              borderColor: colors.darkGray,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 400,
              height: 28,
              padding: '8px 10px 8px 10px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
                marginRight: 8,
                [defaultTheme.breakpoints.down('lg')]: {
                  margin: '8px',
                },
              },
              '&:hover': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              backgroundColor: colors.primary,
              borderRadius: 23,
              boxShadow: `0 6px 16px 0 ${colors.primary}`,
              color: colors.white,
              fontSize: 15,
              fontWeight: 700,
              height: 46,
              padding: '0 45px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: colors.darkGreen,
              },
            },
          },
          {
            props: { variant: 'contained', size: 'large' },
            style: {
              borderRadius: 4,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 700,
              height: 38,
              padding: '11px 16px 11px 16px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
              [defaultTheme.breakpoints.down('lg')]: {
                padding: '11px 16px',
              },
            },
          },
          {
            props: { variant: 'contained', size: 'medium' },
            style: {
              borderRadius: 4,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 700,
              height: 32,
              padding: '8px 12px 8px 12px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
              [defaultTheme.breakpoints.down('lg')]: {
                padding: '11px 16px',
              },
            },
          },
          {
            props: { variant: 'contained', size: 'medium', color: 'error' },
            style: {
              backgroundColor: colors.darkRed,
              borderRadius: 4,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 700,
              height: 32,
              padding: '8px 12px 8px 12px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
              [defaultTheme.breakpoints.down('lg')]: {
                padding: '11px 16px',
              },
              '&:hover': {
                backgroundColor: alpha(colors.darkRed, 0.8),
              },
            },
          },
          {
            props: { variant: 'contained', size: 'small' },
            style: {
              borderRadius: 4,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 12,
              fontWeight: 400,
              height: 22,
              padding: '4px 8px 4px 8px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
            },
          },
          {
            props: { variant: 'outlined', size: 'large' },
            style: {
              borderRadius: 4,
              // borderColor: colors.primary,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 700,
              height: 38,
              padding: '11px 16px 11px 16px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:hover': {
                // borderColor: colors.hoverButton,
              },
              '&:disabled': {
                // borderColor: colors.primary,
                // color: colors.primary,
                opacity: '0.3',
              },
              '&:disabled .MuiButton-startIcon': {
                // color: colors.primary,
              },
              [defaultTheme.breakpoints.down('lg')]: {
                padding: '11px 16px',
              },
            },
          },
          {
            props: { variant: 'outlined', size: 'medium' },
            style: {
              borderRadius: 4,
              borderColor: colors.primary,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 700,
              height: 32,
              padding: '8px 12px 8px 12px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:hover': {
                borderColor: colors.hoverButton,
              },
              '&:disabled': {
                borderColor: colors.primary,
                color: colors.primary,
                opacity: '0.3',
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.primary,
              },
              [defaultTheme.breakpoints.down('lg')]: {
                padding: '11px 16px',
              },
            },
          },
          {
            props: { variant: 'outlined', size: 'medium', color: 'secondary' },
            style: {
              borderRadius: 4,
              color: colors.darkGray,
              borderColor: colors.darkGray,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 400,
              height: 28,
              padding: '8px 10px 8px 10px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
                marginRight: 8,
                [defaultTheme.breakpoints.down('lg')]: {
                  margin: '8px',
                },
              },
              '&:hover': {
                color: colors.secondary,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.white,
              },
            },
          },
          {
            props: { variant: 'outlined', size: 'medium', color: 'error' },
            style: {
              borderRadius: 4,
              color: colors.primaryText,
              borderColor: colors.mainRed,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 14,
              fontWeight: 400,
              height: 32,
              padding: '8px 10px 8px 10px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
                marginRight: 8,
                [defaultTheme.breakpoints.down('lg')]: {
                  margin: '8px',
                },
              },
              '&:hover': {
                color: colors.secondary,
                borderColor: colors.darkRed,
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.mainRed,
              },
            },
          },
          {
            props: { variant: 'outlined', size: 'small' },
            style: {
              borderRadius: 4,
              borderColor: colors.primary,
              color: colors.secondary,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              fontSize: 12,
              fontWeight: 700,
              height: 22,
              padding: '4px 8px 4px 8px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
              '& .MuiButton-startIcon': {
                color: colors.secondary,
              },
              '&:hover': {
                borderColor: colors.hoverButton,
              },
              '&:disabled': {
                borderColor: colors.primary,
                color: colors.primary,
                opacity: '0.3',
              },
              '&:disabled .MuiButton-startIcon': {
                color: colors.primary,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down('lg')]: {
              minWidth: '26px',
              // height: '26px',
              padding: '4px',
            },
          },
          contained: {
            borderRadius: 4,
            boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
            fontSize: 16,
            fontWeight: 700,
            height: 48,
            padding: '11px 16px 11px 16px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
          },
          containedPrimary: {
            color: colors.primaryText,
            '&:hover': {
              backgroundColor: colors.hoverButton,
            },
            '& .MuiButton-startIcon': {
              borderRadius: '4px',
            },
            '&:disabled': {
              backgroundColor: colors.primary,
              color: colors.white,
              opacity: '0.3',
            },
          },
          containedSecondary: {
            backgroundColor: colors.lightGray,
            color: colors.primaryText,
            '&:hover': {
              backgroundColor: colors.hoverFields,
            },
            '& .MuiButton-startIcon': {
              borderRadius: '4px',
            },
            '&:disabled': {
              backgroundColor: colors.primary,
              color: colors.white,
              opacity: '0.3',
            },
          },
          startIcon: {
            padding: 0,
            [defaultTheme.breakpoints.down('lg')]: {
              margin: '0px',
            },
          },
          textPrimary: {
            color: colors.primaryText,
            fontSize: 12,
            fontWeight: 400,
            // opacity: 0.5,
            padding: '0px 0px',
            // textDecoration: 'underline',
            textTransform: 'none',
          },
        },
      },
      MuiFab: {
        variants: [
          {
            props: { color: 'neutral' },
            style: {
              backgroundColor: colors.white,
            },
          },
        ],
        styleOverrides: {
          root: {
            boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
            height: 48,
            width: 48,
            backgroundColor: colors.white,
            '&:hover': {
              backgroundColor: colors.white,
              boxShadow: shadows[5],
            },
          },
        },
      },
      MuiCard: {
        variants: [
          {
            props: { variant: 'big-data' },
            style: {
              '& .MuiCardHeader-root': {
                marginBottom: 20,
              },
              '& .MuiCardContent-root': {
                fontFamily: 'CircularStd-Black',
                fontSize: 70,
                fontWeight: 800,
                marginRight: 15,
              },
            },
          },
          {
            props: { color: 'primary' },
            style: {
              backgroundColor: colors.primary,
              color: colors.white,
              '& .MuiCardHeader-title': {
                color: `${colors.white} !important`,
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              backgroundColor: colors.secondary,
              color: colors.primary,
              '& .MuiCardHeader-title': {
                color: colors.primary,
              },
            },
          },
          {
            props: { variant: 'big-data', color: 'warning' },
            style: {
              color: colors.red,
              '& .MuiCardHeader-title': {
                color: colors.primaryText,
              },
            },
          },
          {
            props: { variant: 'small-data' },
            style: {
              boxShadow: 'none',
              padding: '10px 20px 10px 10px',
              '& .MuiCardContent-root': {
                fontFamily: 'CircularStd-Black',
                fontWeight: 800,
                fontSize: 22,
              },
              '& .MuiCardHeader-title': {
                fontFamily: 'CircularStd-Medium',
                fontWeight: 500,
                fontSize: 12,
                whiteSpace: 'nowrap',
              },
              '& .MuiCardHeader-avatar': {
                marginRight: 6,
              },
            },
          },
          {
            props: { variant: 'custom-with-description' },
            style: {
              '& .MuiCardHeader-root': {
                marginBottom: 20,
                alignSelf: 'normal',
                alignItems: 'center',
                position: 'relative',
              },
              '& .MuiCardHeader-title': {
                fontFamily: 'CircularStd-Black',
                fontSize: 24,
                fontWeight: 800,
                marginBottom: 8,
              },
              '& .MuiCardHeader-subheader': {
                color: colors.primaryText,
                fontFamily: 'CircularStd-Medium',
                fontSize: 14,
                fontWeight: 500,
                opacity: 0.3,
              },
              '& .MuiCardHeader-action': {
                backgroundColor: colors.secondary,
                borderRadius: 7,
                position: 'absolute',
                right: 8,
                top: 4,
                '& > .MuiIconButton-root': {
                  borderRadius: 7,
                },
              },
              '& .MuiCardContent-root': {
                fontFamily: 'CircularStd-Medium',
                fontSize: 14,
                fontWeight: 500,
                alignSelf: 'flex-start',
              },
              '& .MuiCardAction-root': {
                backgroundColor: colors.secondary,
                color: colors.primary,
              },
            },
          },
          {
            props: { variant: 'row-data' },
            style: {
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'row',
              padding: '10px 20px',
              '& .MuiCardContent-root': {
                fontFamily: 'CircularStd-Black',
                fontWeight: 800,
                fontSize: 22,
              },
              '& .MuiCardHeader-root': {
                margin: 'auto',
                height: '100%',
              },
              '& .MuiCardHeader-title': {
                fontFamily: 'CircularStd-Medium',
                fontWeight: 500,
                fontSize: 14,
              },
            },
          },
          {
            props: { variant: 'custom' },
            style: {
              color: colors.primaryText,
              '& .MuiCardHeader-title': {
                color: colors.primaryText,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: 4,
            height: '100%',
            padding: '20px 25px',
            '& > :last-child': {
              paddingBottom: '0px !important',
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            alignSelf: 'flex-start',
            padding: 0,
          },
          avatar: {
            marginRight: 10,
          },
          title: {
            color: colors.primaryText,
            fontSize: 13,
            fontWeight: 500,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: 0,
            width: '100%',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            width: '100%',
            alignItems: 'center',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'row',
            boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: colors.whiteInputBackground,
            borderRadius: '4px',
            fontSize: 12,
            fontWeight: 400,
            color: colors.darkGray,
            height: 22,
          },
          deleteIcon: {
            width: 14,
            height: 14,
            color: 'grey',
          },
          colorPrimary: {
            backgroundColor: colors.white,
            boxShadow: 'none !important',
            color: colors.darkBlue,
            fontSize: 16,
            fontWeight: 400,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 36,
            height: 20,
            padding: 0,
          },
          switchBase: {
            padding: 0,
            margin: 1.5,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(15.5px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: colors.primary,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: grey[100],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 1,
            },
          },
          thumb: {
            boxSizing: 'border-box',
            height: 17.23,
            width: 17.23,
          },
          track: {
            borderRadius: 20 / 2,
            backgroundColor: colors.red,
            opacity: 1,
            transition: defaultTheme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginNormal: {
            marginTop: '0px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down('lg')]: {
              width: '100% !important',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.darkGray,
            fontSize: 14,
            fontWeight: 400,
            [defaultTheme.breakpoints.down('lg')]: {
              textOverflow: 'inherit',
            },
          },
        },
      },
      MuiTextField: {
        variants: [
          {
            props: { multiline: true },
            style: {
              '.MuiFilledInput-root': {
                height: 'fit-content',
              },
              '.MuiFilledInput-input': {
                padding: 10,
              },
            },
          },
          {
            props: { variant: 'filled', color: 'edit-table' },
            style: {
              '.MuiFilledInput-root': {
                backgroundColor: colors.secondary,
                height: 50,
                '&:hover': {
                  backgroundColor: colors.secondary,
                },
                '&:focused': {
                  backgroundColor: colors.secondary,
                },
              },
              '.MuiFilledInput-input': {
                textAlign: 'center',
              },
            },
          },
          {
            props: { type: 'search', color: 'primary' },
            style: {
              '.MuiFilledInput-root': {
                backgroundColor: colors.whiteInputBackground,
                borderRadius: 4,
                height: 40,
              },
            },
          },
          {
            props: { type: 'search', color: 'secondary' },
            style: {
              '.MuiFilledInput-root': {
                backgroundColor: colors.defaultInputBackground,
                borderRadius: 4,
                height: 40,
              },
              '::-webkit-search-decoration': {
                WebkitAppearance: 'none',
              },
              '::-webkit-search-cancel-button': {
                WebkitAppearance: 'none',
              },
              '::-webkit-search-results-button': {
                WebkitAppearance: 'none',
              },
              '::-webkit-search-results-decoration': {
                WebkitAppearance: 'none',
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            marginBottom: 0,
          },
        },
      },
      MuiFilledInput: {
        defaultProps: { hiddenLabel: true },
        styleOverrides: {
          root: {
            backgroundColor: colors.defaultInputBackground,
            borderRadius: 4,
            height: 40,
            '&[aria-label=clickable]': {
              cursor: 'pointer !important',
              '& .MuiFilledInput-input': {
                cursor: 'pointer !important',
              },
            },
          },
          colorSecondary: {
            backgroundColor: colors.whiteInputBackground,
            borderRadius: 4,
            height: 40,
          },
          sizeSmall: {
            borderRadius: 6,
            height: 32,
            '& input': {
              padding: 5,
              textAlign: 'center',
            },
            '& .MuiInputAdornment-root': {
              marginLeft: 0,
              marginRight: -5,
            },
          },
          adornedStart: {
            paddingLeft: 12,
          },
          adornedEnd: {
            paddingRight: 10,
          },
          inputAdornedStart: {
            marginLeft: 10,
          },
          inputAdornedEnd: {
            marginLeft: 0,
          },
          input: {
            color: colors.primaryText,
            fontSize: 14,
            fontWeight: 500,
            '&::placeholder': {
              color: colors.primaryText,
              fontWeight: 400,
              opacity: 0.5,
            },
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              WebkitBoxShadow: `0 0 0px 1000px ${colors.defaultInputBackground} inset`,
            },
            '&:-webkit-autofill:first-line': {
              color: colors.primaryText,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 14,
            marginRight: 14,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiTypography-root': {
              color: colors.primaryText,
              fontSize: 14,
              fontWeight: 500,
            },
          },
          positionEnd: {
            '&[aria-label=color-picker]': {
              backgroundColor: alpha(Colors.primary, 0.1),
              borderRadius: 4,
              height: 24,
              marginRight: -4,
              maxWidth: 24,
              minWidth: 24,
              width: 24,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: 'transparent !important',
            paddingBottom: 4,
            paddingTop: 4,
            '&.Mui-focused': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            padding: 0,
            width: '100%',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: colors.primaryText,
            fontSize: 14,
            fontWeight: 400,
            height: 40,
            '&.Mui-selected': {
              backgroundColor: alpha(colors.primary, 0.2),
              fontWeight: 500,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: '#848F9D',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            margin: '0 5px',
          },
          paper: {
            borderRadius: 4,
            padding: '0',
          },
          paperWidthXl: {
            height: '100%',
            padding: 0,
            '& > .MuiDialogContent-root': {
              height: '100%',
              marginBottom: 0,
              padding: 25,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            color: colors.primaryText,
            display: 'flex',
            fontSize: 24,
            fontWeight: 800,
            marginBottom: 30,
            width: '100%',
            '&[aria-label=centered]': {
              justifyContent: 'center',
            },
            '& .MuiIconButton-root:first-of-type': {
              backgroundColor: colors.white,
              borderRadius: 7,
              boxShadow: `0 6px 12px 0 ${colors.buttonShadow}`,
              marginRight: 18,
            },
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: colors.neutralText,
            fontSize: 14,
            fontWeight: 500,
            margin: '30px 0',
            padding: '0 45px',
            textAlign: 'center',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            marginBottom: 0,
            padding: '30px 30px',
            width: '100%',
            '&[aria-label=no-margin-bottom]': {
              marginBottom: 0,
            },
            [defaultTheme.breakpoints.down('lg')]: {
              padding: '10px',
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30,
            marginTop: 30,
            '& > :nth-of-type(n)': {
              minWidth: 54,
              height: 32,
              '&:not(:first-of-type)': {
                margin: '20px 0 0',
              },
            },
          },
          spacing: {
            padding: '0 0 10px',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 0,
            '& .MuiTabs-indicator': {
              height: 4,
              colors: colors.primary,
            },
            '& .MuiTabs-scroller': {
              [defaultTheme.breakpoints.only('xs')]: {
                overflow: 'auto !important',
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          textColorPrimary: {
            textTransform: 'none',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: colors.white,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '& > :first-of-type': {
              alignItems: 'center',
              display: 'flex',
              '& > :first-of-type': {
                marginRight: 15,
                [defaultTheme.breakpoints.down('lg')]: {
                  marginRight: '0px',
                  paddingBottom: '7px',
                },
              },
            },
            '& > :last-child': {
              alignItems: 'center',
              display: 'flex',
              '& [aria-label=account]': {
                color: colors.white,
                fontSize: 18,
                fontWeight: 700,
                marginTop: 5,
                textTransform: 'none',
                whiteSpace: 'nowrap',
                '& .MuiAvatar-root': {
                  marginBottom: 5,
                },
              },
              '& > :nth-of-type(n)': {
                marginLeft: 20,
                [defaultTheme.breakpoints.down('lg')]: {
                  marginLeft: 0,
                },
              },
            },
            '& .MuiSvgIcon-root': {
              color: colors.secondary,
            },
          },
        },
      },
      ...drawer,
      ...inputs,
    },
  });

export default theme;
