import React, { FC } from 'react';
import BottomModal from 'components/Modal/BottomModal';
import { ReactComponent as FileJoin } from 'assets/icons/rework/icon_file_join.svg';
import { ReactComponent as PinMap } from 'assets/icons/rework/icon_pin_map.svg';
import { ReactComponent as Warning } from 'assets/icons/rework/icon_warning.svg';
import { useCreateMobileStyles } from 'components/Modal/BottomModal/CreateMobile.style';
import { Box } from '@mui/material';
import ListItems from 'components/ListItems/ListItems';

interface ActionOperationsProps {
  handleClickCloseModal?: () => void;
  state?: boolean;
}

const ActionOperationsScreen: FC<ActionOperationsProps> = (props) => {
  const { handleClickCloseModal, state } = props;
  const classes = useCreateMobileStyles();
  return (
    <BottomModal handleClickCloseModal={handleClickCloseModal} rightIcon={true}>
      <Box sx={{ width: '100%' }}>
        <ListItems
          data={[
            { title: 'Suivi de collecte', icon: <PinMap className={classes.iconContainer} /> },
            { title: 'Document', icon: <FileJoin className={classes.iconContainer} /> },
            { title: 'Non-conformité', icon: <Warning className={classes.iconContainer} /> },
          ]}
        />
      </Box>
    </BottomModal>
  );
};

export default ActionOperationsScreen;
