import { Typography } from '@mui/material';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import React from 'react';
import { EventCalendar } from 'components/FullCalendar/TestData';
import { WithStyles, withStyles } from '@mui/styles';
import { style } from 'components/FullCalendar/FullCalendar.style';

interface MobileCardProps extends WithStyles<typeof style> {
  eventInfo: EventCalendar;
}

const MobileCard = (props: MobileCardProps) => {
  const { classes, eventInfo } = props;
  return (
    <div style={{ padding: '0px' }}>
      <div className={classes.eventDayLayout}>
        {eventInfo.extendedProps?.hasIssue && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <WarningAmberRoundedIcon
              color="error"
              fontSize="small"
              style={{ width: '10px', height: '10px' }}
            />
          </div>
        )}
        <div>
          <Typography
            color="secondary"
            variant="body1"
            fontSize={12}
            style={{
              overflowWrap: 'anywhere',
              textDecoration:
                eventInfo.extendedProps?.status === ExecutionStatusEnum.REJECTED
                  ? 'line-through'
                  : 'none',
            }}
          >
            {eventInfo.title}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(style)(MobileCard);
