/** User with different scopes
 * Permissions from highest to lowest
 * Described here: https://netguru.atlassian.net/browse/RTHK-184
 */
export enum UserTypes {
  /** Read/Write on all users */
  Admin = 'ADMIN',
  AccountManager = 'KEY_ACCOUNT_MANAGER',
  WasteDirector = 'WASTE_DIRECTOR',
  InternalSiteOperator = 'INTERNAL_SITE_OPERATOR',
  ExternalSiteOperator = 'EXTERNAL_SITE_OPERATOR',
  Worksite = 'WORKSITE',
  HaulingCompanyOwner = 'HAULING_COMPANY_OWNER',
  /** TODO: not currently used */
  Driver = 'DRIVER',
}

/** Users managing pickups (can create pickup) */
export const MANAGING_USER_TYPES = new Set([
  UserTypes.Admin,
  UserTypes.AccountManager,
  UserTypes.ExternalSiteOperator,
  UserTypes.InternalSiteOperator,
  UserTypes.WasteDirector,
  UserTypes.Worksite,
]);

/** User roles that can see all company data */
export const ADMIN_USER_TYPES = new Set([UserTypes.Admin, UserTypes.AccountManager]);

export const PATRICK_USER_TYPES = new Set([UserTypes.HaulingCompanyOwner, UserTypes.Driver]);
