import { Grid } from '@mui/material';
import CardStatistic from 'components/Card/CardStatistic/CardStatistic';
import React, { useState } from 'react';
import { isMobile } from 'utils/isMobile';
import { useDataOperationStatsStyle } from './DataCards.component.style';
import OperationsScreen from 'screens/auth/dashboard/morgan/operations/Operations.screen';
import EcologicScreen from 'screens/auth/dashboard/morgan/ecologic/Ecologic.screen';
import FinancesScreen from 'screens/auth/dashboard/morgan/finances/Finances.screen';
import { getCardStatisticData } from 'utils/data/dataCard.utils';
import { OpUnitType } from 'dayjs';
import { useObservable } from '@ngneat/react-rxjs';
import { sessionQuery } from 'store/session';
import { UserTypes } from 'constants/UserTypes';
import { operationScreenStatsQuery } from 'store/dashboard/operationScreenStats';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { operationsStatsService } from 'store/dashboard/operationStats';
import { ecologyScreenStatsQuery } from 'store/dashboard/ecologyScreenStats';
import { useEcologyScreenStats } from 'store/dashboard/ecologyScreenStats/hooks/useEcologyScreenStats';
import { organisationsQuery } from 'store/organisation/organisations';
import {
  financeScreenStatsQuery,
  financeScreenStatsService,
} from 'store/dashboard/financeScreenStats';
import { useFinanceScreenStats } from 'store/dashboard/financeScreenStats/hooks/useFinanceScreenStats';

// import { useFinanceScreenStats } from 'store/dashboard/financeScreenStats/hooks/useFinanceScreenStats';

interface DataCardOperationStatProps {
  from: Date;
  to: Date;
  wasteIds: string[];
  dropwDownDashboard: OpUnitType;
}

const DataCardsComponent = (props: DataCardOperationStatProps) => {
  const { from, to, wasteIds, dropwDownDashboard } = props;
  const isMobiles = isMobile();
  const classes = useDataOperationStatsStyle();
  const [currentCard, setCurrentCard] = useState(0);
  const [cardStatisticData, setCardStatisticData] = useState(getCardStatisticData);
  const [userRoles] = useObservable(sessionQuery.roles$);
  const [hasStatsAccess] = useObservable(sessionQuery.hasStatsAccess$);
  const [clientActive] = useObservable(organisationsQuery.activeOrganisation$);

  const { ecologyEvolutionsStats } = useEcologyScreenStats(from, to, wasteIds);
  const { expenseValoEvolStats } = useFinanceScreenStats(from, to, wasteIds, 'OPERATIONS');

  const isUserRoleAllowed = () =>
    [UserTypes.Admin, UserTypes.AccountManager, UserTypes.WasteDirector].some(
      (it) => userRoles?.includes(it) || hasStatsAccess
    );

  const updateOperation = (
    ticketsCount: number,
    oldTicketsCount: number,
    loading: boolean,
    co2Count: number,
    ecologyLoading: boolean,
    financeCount: number,
    oldFinancesCount: number,
    financeLoading: boolean
  ) => {
    const numberFormatter = new Intl.NumberFormat('de-CH');
    const newOperationStat = {
      ...cardStatisticData[0],
      data: numberFormatter.format(ticketsCount) ?? '...',
      dataStatistic:
        operationsStatsService.calculatePercentageEvolution(oldTicketsCount, ticketsCount) ?? 0,
      isLoading: loading,
    };
    const newEcologyStat = {
      ...cardStatisticData[1],
      data: numberFormatter.format(co2Count) ?? '...',
      isLoading: ecologyLoading,
    };
    if (isUserRoleAllowed()) {
      const newFinanceStat = {
        ...cardStatisticData[2],
        data: numberFormatter.format(financeCount) ?? '...',
        dataStatistic:
          financeScreenStatsService.calculatePercentageEvolution(oldFinancesCount, financeCount) ??
          0,
        isLoading: financeLoading,
      };
      return [newOperationStat, newEcologyStat, newFinanceStat];
    }
    return [newOperationStat, newEcologyStat];
  };

  const [_] = useObservable(
    combineLatest([
      operationScreenStatsQuery.getEvolutionOperationStats$,
      operationScreenStatsQuery.isOperationLoading$,
      ecologyScreenStatsQuery.getEvolutionStats$,
      financeScreenStatsQuery.getExpenseValoEvolutionStats$,
      financeScreenStatsQuery.isExpenseValoEvolutionLoading$,
    ]).pipe(
      tap(([evolution, loading, ecologyStats, financeStats, loadingFinance]) => {
        return setCardStatisticData(
          updateOperation(
            evolution.execCreatedCount.current,
            evolution.execCreatedCount.old,
            loading,
            ecologyStats.value.reduce((acc, cur) => acc + cur.processingCo2 + cur.pickupCo2, 0),
            ecologyStats.loading,
            Math.round(
              financeStats.currentPeriod
                .filter((it) => it.totalExpensesInCents != undefined)
                .reduce(
                  (acc, cur) => acc + (cur.totalExpensesInCents - cur.totalValorisationInCents),
                  0
                ) / 100
            ),
            Math.round(
              financeStats.oldPeriod
                .filter((it) => it.totalExpensesInCents != undefined)
                .reduce(
                  (acc, cur) => acc + (cur.totalExpensesInCents - cur.totalValorisationInCents),
                  0
                ) / 100
            ),
            loadingFinance
          )
        );
      })
    )
  );

  const renderPageOfCard = (currentCard: number) => {
    switch (currentCard) {
      case 0:
        return (
          <OperationsScreen
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
      case 1:
        return (
          <EcologicScreen
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
      case 2:
        return (
          <FinancesScreen
            from={from}
            to={to}
            wasteIds={wasteIds}
            dropDownDashboard={dropwDownDashboard}
          />
        );
    }
  };
  return (
    <>
      <Grid
        container
        item
        spacing={1}
        className={classes.responsiveGridCard}
        style={{ justifyContent: isMobiles ? undefined : 'center' }}
      >
        {cardStatisticData?.map((card) => {
          return clientActive?.invoicingSource === 'MOS' ? (
            <Grid
              item
              key={card.cardNumber}
              xs={isMobiles || isUserRoleAllowed() ? 4 : 6}
              className={classes.responsiveWidthCard}
            >
              <CardStatistic
                title={card.title}
                data={card.data}
                isLoading={card.isLoading}
                devise={card.devise}
                description={card.description}
                dataStatistic={card.dataStatistic}
                handleClick={card.canClick ? () => setCurrentCard(card.cardNumber) : undefined}
                value={currentCard}
                selected={card.cardNumber}
              />
            </Grid>
          ) : (
            <Grid
              item
              key={card.cardNumber}
              xs={isMobiles || isUserRoleAllowed() ? 4 : 6}
              className={classes.responsiveWidthCard}
            >
              <CardStatistic
                title={card.title}
                data={card.data}
                isLoading={card.isLoading}
                devise={card.devise}
                description={card.description}
                dataStatistic={card.dataStatistic}
                handleClick={card.canClick ? () => setCurrentCard(card.cardNumber) : undefined}
                value={currentCard}
                selected={card.cardNumber}
              />
            </Grid>
          );
        })}
      </Grid>
      {renderPageOfCard(currentCard)}
    </>
  );
};

export default React.memo(DataCardsComponent);
