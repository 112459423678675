import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    CardContainer: {
      width: '100%',
      minHeight: 163,
      padding: '14px !important',
      position: 'relative',
      [theme.breakpoints.only('xs')]: {
        padding: '16px !important',
      },
    },
    title: {
      fontWeight: 700 + '!important',
      fontSize: '18px !important',
    },
    subtitle: {
      fontSize: '12px !important',
      fontWeight: 400 + '!important',
    },
    CardDataContainer: {
      maxHeight: '65px',
      maxWidth: '70%',
      marginTop: 'auto !important',
      marginBottom: 'auto !important',
      padding: '20px !important',
      justifyContent: 'center',
    },
    CardDataContent: {
      minWidth: 'max-content',
    },
    dataPosition: {
      display: 'flex',
      justifyContent: 'center',
    },
    dataTitle: {
      fontSize: '36px !important',
      fontWeight: 700 + '!important',
    },
    dataSubtitle: {
      marginLeft: '20px !important',
      fontSize: '10px !important',
      alignSelf: 'center',
      marginTop: '15px !important',
    },
  });

export default classes;
