import { QueryEntity } from '@datorama/akita';
import { InvoiceState, InvoiceStore, invoiceStore } from './invoice.store';

export class InvoiceQuery extends QueryEntity<InvoiceState> {
  constructor(protected store: InvoiceStore) {
    super(store);
  }

  invoices$ = this.select((state) => state.invoices);
  search$ = this.select((state) => state.search);
  total$ = this.select((state) => state.total);
}

export const invoiceQuery = new InvoiceQuery(invoiceStore);
