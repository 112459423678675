import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const usePopupStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0 + '!important',
    marginTop: '25px !important',
  },
  contained: {
    margin: 15,
  },
  close: {
    position: 'absolute',
    right: 10,
  },
  content: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    padding: '0 24px 24px 24px !important',
    marginBottom: 0 + '!important',
  },
}));
