/* eslint-disable react/no-multi-comp */
/* eslint-disable import/export */
import {
  Archive,
  CancelOutlined,
  ContentCopy,
  Delete,
  Download,
  EditOutlined,
  FolderZipOutlined,
  PictureAsPdfOutlined,
  SaveOutlined,
  Share,
} from '@mui/icons-material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColumnHeaderParams,
  GridRowModes,
  GridRowParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { ColumnProps } from '../columnTypes.table';
import { ReactElement } from 'react';
import { Colors } from 'constants/Colors';
import I18nUtils from 'utils/data/i18n.utils';

export interface ConfirmActionProps {
  type: OtherActionType;
  row: any;
}

interface OtherActionProps {
  handleClick?: (row: any) => void;
  hidden?: (row: any) => boolean;
  type: OtherActionType;
}

interface ActionsColumnProps extends ColumnProps {
  handleCancel?: () => void;
  handleShowRow?: (row: any) => void;
  handleDownload?: (row: any) => void;
  disabledShowRow?: (row: any) => boolean;
  disableDownload?: (row: any) => boolean;
  otherActions?: OtherActionProps[];
  renderHeader?: (params: GridColumnHeaderParams) => ReactElement;
  actions?: OtherActionProps[];
}

export enum OtherActionType {
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  SAVE = 'SAVE',
  CANCEL = 'CANCEL',
  PDF = 'PDF',
  CSV = 'CSV',
  ZIP = 'ZIP',
  SHARE = 'SHARE',
  TEMPLATE = 'TEMPLATE',
  FUSION = 'FUSION',
  DUPLICATE = 'DUPLICATE',
}

export namespace OtherActionType {
  export const icon = (action: OtherActionType) => {
    switch (action) {
      case OtherActionType.ARCHIVE:
        return <Archive />;
      case OtherActionType.DELETE:
        return <Delete />;
      case OtherActionType.EDIT:
        return <EditOutlined htmlColor={Colors.text} sx={{ width: '20px' }} />;
      case OtherActionType.SAVE:
        return <SaveOutlined color="success" sx={{ width: '20px' }} />;
      case OtherActionType.CANCEL:
        return <CancelOutlined color="error" sx={{ width: '20px' }} />;
      case OtherActionType.PDF:
        return <PictureAsPdfOutlined htmlColor={Colors.text} style={{ width: '20px' }} />;
      case OtherActionType.ZIP:
        return <FolderZipOutlined htmlColor={Colors.text} style={{ width: '20px' }} />;
      case OtherActionType.CSV:
        return <Download style={{ width: '20px' }} />;
      case OtherActionType.SHARE:
        return <Share />;
      case OtherActionType.TEMPLATE:
        return <Share />;
      case OtherActionType.FUSION:
        return <Share />;
      case OtherActionType.DUPLICATE:
        return <ContentCopy htmlColor={Colors.text} style={{ width: '20px' }} />;
    }
  };

  export const label = (action: OtherActionType) => {
    switch (action) {
      case OtherActionType.ARCHIVE:
        return I18nUtils.t('global.archive');
      case OtherActionType.DELETE:
        return I18nUtils.t('global.delete');
      case OtherActionType.EDIT:
        return I18nUtils.t('global.modify');
      case OtherActionType.PDF:
        return I18nUtils.t('global.downloadPDF');
      case OtherActionType.ZIP:
        return I18nUtils.t('global.downloadZIP');
      case OtherActionType.CSV:
        return I18nUtils.t('global.exportCSV');
      case OtherActionType.SHARE:
        return I18nUtils.t('global.share');
      case OtherActionType.DUPLICATE:
        return I18nUtils.t('global.duplicate');
      case OtherActionType.TEMPLATE:
        return I18nUtils.t('global.template');
      case OtherActionType.FUSION:
        return I18nUtils.t('global.fusion');
      default:
        return '';
    }
  };

  export const translationKey = (action: OtherActionType) => {
    switch (action) {
      case OtherActionType.ARCHIVE:
        return 'archive';
      case OtherActionType.DELETE:
        return 'delete';
      default:
        return '';
    }
  };
}

interface EditActionComponentProps {
  rowId: string | number;
  showInMenu?: boolean;
}

const EditActionComponent = (props: EditActionComponentProps) => {
  const apiRef = useGridApiContext();

  return (
    <GridActionsCellItem
      icon={OtherActionType.icon(OtherActionType.EDIT)}
      onClick={() => {
        apiRef.current.startRowEditMode({ id: props.rowId });
      }}
      label={OtherActionType.label(OtherActionType.EDIT)}
      showInMenu={props.showInMenu}
    />
  );
};

interface CancelAndSaveActionsComponentProps {
  handleCancel?: () => void;
  rowId: string | number;
}

const CancelAndSaveActionsComponent = (props: CancelAndSaveActionsComponentProps) => {
  const apiRef = useGridApiContext();

  const isEditMode = apiRef.current.getRowMode(props.rowId) === GridRowModes.Edit;

  if (!isEditMode) return null;

  return [
    <GridActionsCellItem
      key={OtherActionType.SAVE}
      icon={OtherActionType.icon(OtherActionType.SAVE)}
      onClick={() => apiRef.current.stopRowEditMode({ id: props.rowId })}
      label={OtherActionType.label(OtherActionType.SAVE)}
      showInMenu={false}
    />,
    <GridActionsCellItem
      key={OtherActionType.CANCEL}
      icon={OtherActionType.icon(OtherActionType.CANCEL)}
      onClick={() => {
        apiRef.current.stopRowEditMode({ id: props.rowId, ignoreModifications: true });
        props.handleCancel?.();
      }}
      label={OtherActionType.label(OtherActionType.CANCEL)}
      showInMenu={false}
    />,
  ];
};

export const COLUMN_TYPE_ACTIONS = (props: ActionsColumnProps): GridActionsColDef => ({
  field: 'actions',
  type: 'actions',
  headerClassName: 'actions',
  resizable: false,
  minWidth:
    props.minWidth ??
    ((!!props.handleShowRow || !!props.handleDownload) && !!props.otherActions?.length ? 70 : 40),
  width:
    props.width ??
    ((!!props.handleShowRow || !!props.handleDownload) && !!props.otherActions?.length ? 70 : 40),
  renderHeader: props.renderHeader,
  getActions: (params: GridRowParams) => {
    const actions: ReactElement[] = [];

    if (props.otherActions?.some((a) => a.type === OtherActionType.EDIT)) {
      const CancelAndSave = CancelAndSaveActionsComponent({
        rowId: params.id,
        handleCancel: props.handleCancel,
      });
      if (CancelAndSave) return CancelAndSave;
    }

    if (props.handleShowRow) {
      actions.push(
        <GridActionsCellItem
          icon={<ArrowCircleRightOutlinedIcon color="primary" />}
          sx={{ opacity: props.disabledShowRow?.(params.row) ? 0 : undefined }}
          disabled={!!props.disabledShowRow?.(params.row)}
          onClick={() => props.handleShowRow!(params.row)}
          label=""
          showInMenu={false}
        />
      );
    }

    if (props.handleDownload) {
      actions.push(
        <GridActionsCellItem
          icon={<Download color="primary" />}
          sx={{ opacity: props.disabledShowRow?.(params.row) ? 0 : undefined }}
          onClick={() => props.handleDownload!(params)}
          label=""
          showInMenu={false}
        />
      );
    }

    props.actions?.forEach((action) => {
      switch (action.type) {
        default:
          actions.push(
            <GridActionsCellItem
              icon={OtherActionType.icon(action.type)}
              onClick={() => action.handleClick?.(params.row)}
              label={OtherActionType.label(action.type)}
              showInMenu={false}
            />
          );
          break;
      }
    });

    props.otherActions?.forEach((otherAction) => {
      switch (otherAction.type) {
        case OtherActionType.EDIT:
          actions.push(<EditActionComponent rowId={params.id} showInMenu={true} />);
          break;
        default:
          if (!otherAction.hidden?.(params.row)) {
            actions.push(
              <GridActionsCellItem
                icon={OtherActionType.icon(otherAction.type)}
                onClick={() => otherAction.handleClick?.(params.row)}
                label={OtherActionType.label(otherAction.type)}
                showInMenu={true}
              />
            );
          }
          break;
      }
    });

    return actions;
  },
});
