import React, { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import style from './RowActivities.style';
import Badge from '@mui/material/Badge';
import MaterialTag from '../MaterialDatagrid/Rows/MaterialTag';
import { translateExecutionStatus } from 'utils/enums/ExecutionStatusEnum';
import { t } from 'i18next';

interface RowActivitiesProps extends WithStyles<typeof style> {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  issue?: boolean;
  status?: string;
  isRecurent?: boolean;
}

const RowActivities: FC<RowActivitiesProps> = (props) => {
  const { classes, title, status, description, icon, issue, isRecurent } = props;
  return (
    <Box className={classes.boxContainer}>
      {issue ? (
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              background: 'red',
            },
          }}
          badgeContent=" "
          variant="dot"
          className={classes.badgePosition}
        >
          <div className={classes.cardContainer}>{icon}</div>
        </Badge>
      ) : (
        <div className={classes.cardContainer}>{icon}</div>
      )}
      <div className={classes.typographyPosition}>
        <Stack direction="row" justifyContent="space-between">
          <Stack maxWidth={230}>
            <Typography
              variant="body1"
              color="text.primary"
              style={{
                fontSize: 14,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                lineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {title}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              fontSize="10px"
              fontWeight={400}
              sx={{
                border: '1px solid grey',
                borderRadius: '4px',
                padding: '3px',
                textAlign: 'center',
                marginBottom: 1,
              }}
            >
              {isRecurent ? t('fe.operations.table.recurrent') : t('fe.operations.table.onDemand')}
            </Typography>
          </Stack>
        </Stack>
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Typography
            variant="subtitle2"
            style={{ fontSize: 12, fontWeight: 400 }}
            color="text.secondary"
          >
            {description}
          </Typography>
          <Stack spacing={0.5}>
            {status && (
              <MaterialTag
                wrap={true}
                maxHeight={35}
                noWidthLimit
                maxWidth={180}
                title={translateExecutionStatus(status)}
              />
            )}
          </Stack>
        </Grid>
      </div>
    </Box>
  );
};

export default withStyles(style)(RowActivities);
