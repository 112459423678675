import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useFinancesScreenStyles = makeStyles((theme: Theme) => ({
  cardTypography: {
    [theme.breakpoints.only('xs')]: {
      fontWeight: 700 + '!important',
    },
  },
  titleTotalExpensesPosition: {
    display: 'flex',
    alignItems: 'center !important',
  },
  line: {
    backgroundColor: 'black',
    width: '20px',
    height: '3px',
  },
  redLine: {
    backgroundColor: 'rgba(230, 67, 60)',
    width: '20px',
    height: '3px',
  },
  lightLine: {
    backgroundColor: '#DDDEE1',
    width: '20px',
    height: '3px',
  },
  lightRedLine: {
    backgroundColor: '#F9E5E3',
    width: '20px',
    height: '3px',
  },
}));
