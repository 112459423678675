import { metawasteApi } from 'api/Axios';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import { IssueStatus, toManageIssue } from './issues.model';
import { issueStore, IssueStore } from './issues.store';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { ID } from '@datorama/akita';

export class IssueService {
  constructor(private store: IssueStore) {}

  async getIssue(
    page: number,
    limit: number,
    sort: { field: string; sort: GridSortDirection },
    organisationUnitIds: ID[] | undefined,
    organisationId?: ID,
    issueStatus?: IssueStatus,
    from?: Date,
    to?: Date,
    search?: string,
    currentFilters?: TableFilter[]
  ) {
    const [issue, e] = await resolve(
      metawasteApi.getIssue(
        page,
        limit,
        sort.field,
        sort.sort,
        toRequestFilters(currentFilters || []),
        organisationUnitIds,
        organisationId,
        issueStatus,
        from,
        to,
        search
      )
    );

    if (!issue || e) {
      throw handleBasicSnackError('error fetching issue');
    }

    return issue;
  }

  async getIssueById(issueId: number) {
    const [issue, e] = await resolve(metawasteApi.getIssueById(issueId));

    if (!issue || e) {
      throw handleBasicSnackError('error fetching issue');
    }

    return toManageIssue(issue);
  }
}

export const issueService = new IssueService(issueStore);
