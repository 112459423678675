import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Sidebar from 'components/Sidebar';
import AppBarHeader from 'components/AppBar/AppBarHeader/AppBarHeader';
import SidebarMobile from 'components/Sidebar/mobile/SidebarMobile';
import { isMobile } from 'utils/isMobile';
import { sidebarPages } from 'constants/Routes';
import AuthedPage from './AuthedPage';
import { createExecutionQuery, createExecutionService } from 'store/operations/createExecution';
import ExecutionModalScreen from './modals/executionModal/ExecutionModal.screen';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils/hooks/useQuery';
import { useObservable } from '@ngneat/react-rxjs';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { useAppBarHeaderStyles } from 'components/AppBar/AppBarHeader/AppBarHeader.style';
import { sessionQuery } from 'store/session';
import { UserTypes } from 'constants/UserTypes';

const Authed = () => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [isSideModalOpen] = useObservable(createExecutionQuery.isModalOpen$);
  const isMobiles = isMobile();
  const { t } = useTranslation();
  const query = useQuery();
  const executionId = query.get('executionId');
  const classes = useAppBarHeaderStyles();
  const [isMorgan] = useObservable(sessionQuery.isMorgan$);
  const [isAdmin] = useObservable(sessionQuery.isAdmin$);
  const [hasStatsAccess] = useObservable(sessionQuery.hasStatsAccess$);
  const [roles] = useObservable(sessionQuery.roles$);

  const hasAccessToFinance =
    isAdmin ||
    (isMorgan &&
      [UserTypes.Admin, UserTypes.AccountManager, UserTypes.WasteDirector].some(
        (it) => roles?.includes(it) || hasStatsAccess
      ));

  const getCurrentRouteIndex = () => {
    const currentRouteIndex = sidebarPages(hasAccessToFinance).findIndex((page) =>
      window.location.pathname.includes(page.route)
    );
    return currentRouteIndex !== -1 ? currentRouteIndex : 0;
  };

  const [currentPage, setCurrentPage] = useState(getCurrentRouteIndex());

  useEffect(() => {
    history.listen(() => updateCurrentPage(getCurrentRouteIndex()));
  }, []);

  // useEffect(() => {
  //   const readKey = storageService.getItem(LocalStorageKey.AlertMessageKeyRead);
  //   if (!readKey || readKey !== env.ALERT_MSG_KEY) {
  //     enqueueSnackbar(t(env.ALERT_MSG_KEY), {
  //       persist: true,
  //       variant: 'info',
  //       action: (snackbarId) => {
  //         return (
  //           <Stack direction="row">
  //             <Button
  //               size="small"
  //               onClick={() => {
  //                 storageService.setItem(LocalStorageKey.AlertMessageKeyRead, env.ALERT_MSG_KEY);
  //                 closeSnackbar(snackbarId);
  //               }}
  //               sx={{ color: 'white', opacity: 0.5, padding: '0.5rem' }}
  //             >
  //               {t('global.doNotDisplayAgain')}
  //             </Button>
  //             <Button
  //               size="small"
  //               onClick={() => {
  //                 closeSnackbar(snackbarId);
  //               }}
  //               sx={{ color: 'white', padding: '0.5rem' }}
  //             >
  //               {t('global.close')}
  //             </Button>
  //           </Stack>
  //         );
  //       },
  //     });
  //   }
  // }, [t]);

  useEffect(() => {
    if (executionId) {
      createExecutionService
        .openModal(executionId)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  }, [executionId]);

  const updateCurrentPage = (newPage: number) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <AppBarHeader handleClick={() => setIsOpenSideBar(true)} />
      {isOpenSideBar && isMobiles && (
        <SidebarMobile
          value={currentPage}
          tabs={sidebarPages(hasAccessToFinance).map((page) => ({
            title: page.titleKey,
            icon: page.icon,
            iconSelected: page.iconSelected,
          }))}
          handleChange={(newPage) => {
            updateCurrentPage(newPage);
            history.push(sidebarPages(hasAccessToFinance)[newPage].route);
            setIsOpenSideBar(false);
          }}
          handleClickCloseSideBar={() => setIsOpenSideBar(false)}
        />
      )}
      {!isMobiles && (
        <Sidebar
          value={currentPage}
          handleChange={(newPage) => {
            updateCurrentPage(newPage);
            history.push(sidebarPages(hasAccessToFinance)[newPage].route);
          }}
          tabs={sidebarPages(hasAccessToFinance).map((page) => ({
            title: page.titleKey,
            icon: page.icon,
            iconSelected: page.iconSelected,
          }))}
        />
      )}
      <AuthedPage hasAccessToFinance={hasAccessToFinance} />
      {isSideModalOpen && <ExecutionModalScreen />}
    </>
  );
};

export default Authed;
