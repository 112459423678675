import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NotificationUi } from './notification.model';

export type NotificationsState = EntityState<NotificationUi>;

@StoreConfig({
  name: 'notifications',
})
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}

export const notificationsStore = new NotificationsStore();
