import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';
import deLocale from 'date-fns/locale/de';

export const filterHourAndMinute = (date: Date) => {
  const hours = dayjs(date).format('HH');
  const minutes = dayjs(date).format('mm');
  return `${hours}:${minutes}`;
};

export const isSameDate = (firstDate: Date, secondDate: Date) => {
  return new Date(firstDate).getDate() === new Date(secondDate).getDate();
};

export const localeMap = {
  en: enLocale,
  fr: frLocale,
  it: itLocale,
  de: deLocale,
};
