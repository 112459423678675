import { ID } from '@datorama/akita';
import { UserTypes } from 'constants/UserTypes';
import { User } from 'store/session';
import { OrganisationUserDto } from './OrganisationUserDto';
import { UserPreferencesDto } from './UserPreferencesDto';

export interface UserDto {
  userId: ID;
  userRoles: UserTypes[];
  firstName: string;
  lastName: string;
  email: string;
  organisations: OrganisationUserDto[];
  userPreferences?: UserPreferencesDto;
  hasStatsAccess?: boolean;
}

export function formatToUser({
  userId,
  userRoles,
  firstName,
  lastName,
  email,
  organisations,
  userPreferences,
  hasStatsAccess,
}: UserDto): User {
  return {
    id: userId,
    userRoles,
    firstName,
    lastName,
    email,
    organisations,
    userPreferences,
    hasStatsAccess,
  };
}
