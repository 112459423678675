import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      width: '100%',
      display: 'flex',
      padding: '4px',
    },
    cardContainer: {
      position: 'relative',
      backgroundColor: Colors.lightGray,
      padding: '4px 6px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
    typographyPosition: {
      marginLeft: '6px !important',
      width: '100%',
    },
    badgePosition: {
      position: 'relative',
    },
  });

export default classes;
