import { Query } from '@datorama/akita';
import {
  ecologyScreenStatsStore,
  EcologyScreenStatsStore,
  EcologyStatsState,
} from './ecology-screen-stats.store';

export class EcologyScreenStatsQuery extends Query<EcologyStatsState> {
  constructor(protected store: EcologyScreenStatsStore) {
    super(store);
  }

  getEvolutionStats$ = this.select((state) => state.evolutionStats);
}

export const ecologyScreenStatsQuery = new EcologyScreenStatsQuery(ecologyScreenStatsStore);
