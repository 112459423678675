import { organisationUnitsStore, OrganisationUnitsStore } from './organisation-units.store';
import { OrganisationUnit } from './organisation-unit.model';
import { ID } from '@datorama/akita';

export class OrganisationUnitsService {
  constructor(private store: OrganisationUnitsStore) {}

  populateDataFromOrganisation = (organisationUnits: OrganisationUnit[]) => {
    this.store.set(organisationUnits);
    if (organisationUnits.length === 1) {
      this.store.setActiveOrgUnitIds([organisationUnits[0].id]);
    } else {
      this.store.setActiveOrgUnitIds([], true);
    }
  };

  setOrgUnitsAsActive = (orgUnitIds: ID[]) => {
    this.store.setActiveOrgUnitIds(orgUnitIds);
  };

  removeActiveOrgUnit = () => {
    this.store.removeActiveOrgUnit();
  };
}

export const organisationUnitsService = new OrganisationUnitsService(organisationUnitsStore);
