import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { Organisation } from './organisation.model';
import { storageService } from 'utils/StorageService/StorageService';
import { LocalStorageKeyJson } from 'constants/LocalStorageKey';

export interface OrganisationsState extends EntityState<Organisation> {
  activeOrganisationId?: ID;
  activeOrganisation?: Organisation;
  count: number;
  total: number;
}

const initialState: OrganisationsState = {
  count: 0,
  total: 0,
};

@StoreConfig({
  name: 'clients',
  resettable: true,
})
export class OrganisationsStore extends EntityStore<OrganisationsState> {
  constructor() {
    super(initialState);
    this.setActiveOrganisationId(undefined);
  }

  setActiveOrganisationId = (organisationId?: ID) => {
    const cacheOrganisationId = storageService.getItemJson(
      LocalStorageKeyJson.OrganisationId,
      undefined
    )?.organisationId;
    const vOrganisationId = organisationId ?? cacheOrganisationId;
    this.update((state) => ({
      ...state,
      activeOrganisationId: vOrganisationId,
    }));
    storageService.setItemJson(LocalStorageKeyJson.OrganisationId, {
      organisationId: vOrganisationId,
    });
  };

  removeActiveOrganisation = () => {
    this.update((state) => ({
      ...state,
      activeOrganisationId: undefined,
      activeOrganisation: undefined,
    }));
    storageService.removeItem(LocalStorageKeyJson.OrganisationId);
    storageService.removeItem(LocalStorageKeyJson.OrganisationUnitId);
  };
}

export const organisationsStore = new OrganisationsStore();
