import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNotificationModalStyles } from './NotificationModalStyle';
import NotificationCard from './components/NotificationCard';
import { useEffect } from 'react';
import {
  notificationsQuery,
  notificationsService,
  NotificationUi,
} from 'store/dashboard/notifications';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { craftUrlParams } from 'utils/data/url.utils';

const NotificationModal = () => {
  const { t } = useTranslation();
  const classes = useNotificationModalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [notificationsData] = useObservable(notificationsQuery.getNotifUser$);

  const notifClick = (notification: NotificationUi) => {
    const queryParams = craftUrlParams(
      notification.payload.executionId?.toString(),
      notification.payload.issueId?.toString()
    );
    history.replace({ pathname: location.pathname, search: queryParams.toString() });
    notificationsService.updateSeenNotification(notification.id);
  };

  useEffect(() => {
    return () => {
      notificationsService
        .updateSeenNotifications()
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h2">
          {t('fe.notifications.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
      {notificationsData.map((notification) => (
        <Grid container key={notification.id}>
          <NotificationCard
            notification={notification}
            hasSeen={notification.hasSeen}
            type={notification.type}
            signature={notification.ticketSignature}
            notifClick={notifClick}
            receivedAt={notification.receivedSince}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default NotificationModal;
