import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      width: '90px !important',
      height: '95px !important',
      padding: '2px !important',
      backgroundColor: 'white',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    cursor: {
      cursor: 'pointer',
    },
    icon: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '3px',
    },
    title: {
      fontWeight: 700 + '!important',
      fontSize: '13px !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box !important',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'normal',
      textAlign: 'center',
      lineHeight: '1.0',
    },
    size: {
      fontSize: '12px !important',
    },
  });

export default classes;
