import React, { FC } from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { isMobile } from 'utils/isMobile';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

interface ReasonsForNonConformitiesProps {
  label: string[];
  datasets: [
    {
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
    }
  ];
  unit?: string;
}

const ReasonsForNonConformities: FC<ReasonsForNonConformitiesProps> = (props) => {
  const { label, datasets = [], unit } = props;
  const isMobiles = isMobile();
  return (
    <div style={{ height: '100%' }}>
      <Doughnut
        height="100%"
        options={{
          interaction: {
            mode: 'index',
            intersect: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              formatter: (value, ctx) => {
                const datasets = ctx.chart.data.datasets;
                const sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  const percentage = Math.round((value / sum) * 100) + '%';
                  return percentage;
                } else {
                  return Math.round((value / sum) * 100) + '%';
                }
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
              },
            },
            tooltip: {
              backgroundColor: '#EBEFF7',
              bodyColor: 'black',
              cornerRadius: 0,
              caretSize: 0,
              titleColor: 'black',
              bodyAlign: 'right',
              bodySpacing: 5,
              displayColors: false,
              callbacks: {
                label: (el) =>
                  el.label.replace(el.label.toString(), el.dataset.data[el.dataIndex].toString()),
              },
              bodyFont: {
                weight: 'bold',
                size: 14,
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                usePointStyle: true,
                pointStyle: 'rectRounded',
              },
            },
          },
          // cutout: 60,
          layout: {
            padding: isMobiles ? 20 : 40,
          },
        }}
        data={{
          labels: label,
          datasets: datasets.map((dataset) => ({
            ...dataset,
            offset: 0,
          })),
        }}
      />
    </div>
  );
};

export default ReasonsForNonConformities;
