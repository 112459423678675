import React, { FC } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import style from './SplitBarStat.style';
import { Box, Typography } from '@mui/material';

interface SplitBarStatProps extends WithStyles<typeof style> {
  data: {
    name?: string;
    value?: number;
    color?: string;
  }[];
}

const SplitBarStat: FC<SplitBarStatProps> = (props) => {
  const { classes, data = [] } = props;

  const value =
    data.length &&
    data.map((item) => {
      if (item.value! > 0) {
        return (
          <div
            style={{
              backgroundColor: item.color,
              color: 'white',
              width: item.value + '%',
            }}
            key={item.name}
          >
            <Typography className={classes.typographyValue}>{item.value}%</Typography>
          </div>
        );
      }
    });

  const label =
    data.length &&
    data.map((item) => {
      if (item.value! > 0) {
        return (
          <div
            style={{
              width: item.value + '%',
            }}
            key={item.name}
          >
            <Typography className={classes.police}>{item.name}</Typography>
          </div>
        );
      }
    });

  return (
    <Box className={classes.boxContainer}>
      <div className={classes.value}>{value === 0 ? undefined : value}</div>
      <div className={classes.description}>{value === 0 ? undefined : label}</div>
    </Box>
  );
};

export default withStyles(style)(SplitBarStat);
