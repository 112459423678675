import React, { FC } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { WasteExpenseValoUi } from 'store/dashboard/financeScreenStats/finance-screen-stats.model';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Colors } from 'style/materialStylings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface BarchartFinancesProps {
  labels: string[];
  data: WasteExpenseValoUi[];
  isLoading?: boolean;
}

const BarchartFinances: FC<BarchartFinancesProps> = (props) => {
  const { data, labels, isLoading } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Stack height="250px" minWidth="100% !important" m="30px 0px" pt="0px">
        <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
      </Stack>
    );
  }

  return (
    <Stack height="250px" minWidth="100% !important" m="30px 0px" pt="0px">
      <Bar
        height="100%"
        options={{
          interaction: {
            mode: 'index',
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          scales: {
            xAxis: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                color: 'black',
                font: {
                  size: 9,
                },
              },
            },
            yAxis: {
              min: 0,
              beginAtZero: true,
              ticks: {
                color: 'black',
              },
              stacked: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              data: data.map((data) =>
                data.totalExpensesInCents ? data.totalExpensesInCents / 100 : 0
              ),
              barPercentage: 0.7,
              borderRadius: 0,
              categoryPercentage: 0.4,
              backgroundColor: '#172134',
              stack: 'Stack 0',
              yAxisID: 'yAxis',
              xAxisID: 'xAxis',
            },
            {
              data: data.map((data) =>
                data.totalValorisationInCents ? data.totalValorisationInCents / 100 : 0
              ),
              barPercentage: 0.7,
              borderRadius: 0,
              categoryPercentage: 0.4,
              backgroundColor: 'rgba(230, 67, 60)',
              stack: 'Stack 1',
              yAxisID: 'yAxis',
              xAxisID: 'xAxis',
            },
          ],
        }}
      />
      <Stack width="100%" alignItems="center" mt="15px">
        <Stack
          direction="row"
          bgcolor={Colors.lightGray}
          width="fit-content"
          spacing={2}
          p="10px 20px"
        >
          {[
            {
              id: 1,
              label: t('fe.dashboard.morgan.stats.finance.totalValo'),
              bgcolor: 'rgba(230, 67, 60)',
            },
            {
              id: 2,
              label: t('fe.dashboard.morgan.stats.finance.totalExpense'),
              bgcolor: '#172134',
            },
          ].map((el) => (
            <Stack
              key={el.id}
              direction="row"
              justifyContent="center"
              alignItems="center"
              p="5px 0px"
            >
              <Box
                width="13px"
                height="13px"
                borderRadius="3px"
                marginRight="10px"
                bgcolor={el.bgcolor}
              />
              <Typography variant="body1" fontSize={12}>
                {el.label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BarchartFinances;
