import { contractsStore, ContractsStore } from './contracts.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';
import { ContractFiltersEnum } from './contracts.model';
import { TableFilter, toRequestFilters } from 'components/Select/SelectItem.interface';

export class ContractsService {
  constructor(private store: ContractsStore) {}

  setSearch(value: string) {
    this.store.update((state) => ({
      ...state,
      search: value,
    }));
  }

  setStatus(value: ContractFiltersEnum) {
    this.store.update((state) => ({
      ...state,
      status: value,
    }));
  }

  resetContracts = () => {
    this.store.update((state) => ({
      ...state,
      contracts: [],
    }));
  };

  getContracts = async (
    organisationId: ID,
    organisationUnitIDs: ID[] | undefined,
    page: number,
    rowPerPage: number,
    currentFilters?: TableFilter[]
  ) => {
    this.store.setLoading(true);

    const search = this.store.getValue().search;
    const status = this.store.getValue().status;

    const [resp, e] = await resolve(
      metawasteApi.getContracts(
        organisationId,
        organisationUnitIDs,
        search,
        page + 1,
        rowPerPage,
        status,
        toRequestFilters(currentFilters || [])
      )
    );

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.contracts'));
    }

    this.store.update((state) => ({
      ...state,
      contracts: resp.data,
      total: resp.total,
    }));
    return resp.data;
  };

  getContractById = async (contractId: ID) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getContract(contractId));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.contract'));
    }

    return resp;
  };

  getSupplierByContract = async (contractId: ID) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getSupplierByContract(contractId));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.contract'));
    }

    return resp;
  };
}

export const contractsService = new ContractsService(contractsStore);
