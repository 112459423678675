import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useActivitiesPanelStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: '14px !important',
    height: '100% !important',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      boxShadow: 'none !important',
    },
    width: '401px',
  },
  gridButton: {
    flex: 0 + '!important',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '40px !important',
    },
  },
}));
