import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import SnackError, { handleBasicSnackError } from 'utils/models/snackError';
import { IssueDocumentsStore, issueDocumentsStore } from './issue-documents.store';
import { ID } from '@datorama/akita';
import { createIssueDocument, IssueDocument } from './issue-document.model';
import { DocumentKindEnum } from 'utils/enums/DocumentKindEnum';
import { issueDocumentsQuery, IssueDocumentsQuery } from './issue-documents.query';
import { isAxiosError } from 'api/axios.utils';
import { IssueDetailsUi } from '../executionIssue';
import i18n from 'utils/data/i18n.utils';

export class IssueDocumentsService {
  constructor(private store: IssueDocumentsStore, private query: IssueDocumentsQuery) {}

  populateDocumentsFromIssue = (issue: IssueDetailsUi) => {
    const docs = issue.dto?.documents?.map((doc) => createIssueDocument(issue.id, doc)) ?? [];
    this.store.upsertMany(docs);
    this.store.update((state) => ({
      ...state,
      originalDocuments: [...state.originalDocuments, ...docs],
    }));
  };

  uploadDocument = async (file: File, issueId: ID) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('name', file.name);
    formData.append('description', '');
    formData.append('documentKind', DocumentKindEnum.ISSUE);

    const [document, e] = await resolve(metawasteApi.createDocument(formData));
    if (e || !document) {
      if (isAxiosError(e)) {
        if (e.response?.data.statusCode === 413) {
          throw new SnackError('errors.fileTooLarge', 'error');
        }
      }
      throw handleBasicSnackError(i18n.t('fe.errors.uploadDocument'));
    }
    this.store.upsert(document.id, createIssueDocument(issueId, document, file));
  };

  updateDocumentsForIssue = async (issueId: ID) => {
    const { documentsToAdd, documentsToDelete } = this.query.documentsUpdateFromIssue(issueId);
    if (!documentsToAdd.length && !documentsToDelete.length) {
      return;
    }
    const [, e] = await resolve(
      metawasteApi.editIssueDocuments(issueId, { documentsToAdd, documentsToDelete })
    );
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.uploadDocument'));
    }
  };

  getDocumentUrl = (document: IssueDocument) => {
    if (document.tmpFile) {
      return URL.createObjectURL(document.tmpFile);
    }
    return metawasteApi.getDocumentUrl(document.id);
  };

  fetchDocument = (document: IssueDocument) => {
    window.open(this.getDocumentUrl(document), '_blank', '');
  };

  removeDocument = (documentId: ID) => {
    this.store.remove(documentId);
  };

  documentIdsForIssue = (issueId: ID): number[] => this.query.documentIdsFromIssue(issueId);

  resetStore = () => {
    this.store.reset();
  };
}

export const issueDocumentsService = new IssueDocumentsService(
  issueDocumentsStore,
  issueDocumentsQuery
);
