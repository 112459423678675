import { Autocomplete, CircularProgress, InputLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

import { useEffectFn } from '@ngneat/effects-hooks';

import { searchEffect, SelectItem } from './SelectItem.interface';

interface SingleSelectWithSearchComponentProps {
  color?: 'primary' | 'secondary';
  textColor?: string;
  error?: string;
  getOptions: (search?: string) => Observable<SelectItem[]>;
  handleChange?: (value: SelectItem | null) => void;
  label?: string;
  labelWidth?: number;
  labelHeight?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: SelectItem | '';
  disabledUnderline?: boolean;
}

const AsyncSelectWithSearchComponent = (props: SingleSelectWithSearchComponentProps) => {
  const {
    color = 'primary',
    textColor,
    error,
    getOptions,
    handleChange,
    label,
    labelWidth,
    placeholder,
    readOnly,
    required,
    disabledUnderline,
    value,
  } = props;

  const removeUnderline = {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none !important',
    },
  };

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const searchOptions = useEffectFn(searchEffect);
  const [loading, setLoading] = useState(!readOnly && open && options.length === 0);

  useEffect(() => {
    setLoading(!readOnly && open && options.length === 0);
  }, [readOnly, open, options.length]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else if (!readOnly) {
      setLoading(true);
      searchOptions({ getOptions, setLoading, setOptions });
    }
  }, [open, readOnly]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      {!!label && (
        <InputLabel error={!!error} sx={{ width: labelWidth ? `${labelWidth}px` : undefined }}>
          {label + (required ? '*' : '')}
        </InputLabel>
      )}
      <Autocomplete
        disableCloseOnSelect
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option: SelectItem) => option.label}
        isOptionEqualToValue={(option: SelectItem, value: any) => option.value === value?.value}
        options={options}
        loading={loading}
        value={value as SelectItem | null}
        readOnly={readOnly}
        color={color}
        filterOptions={(options) => options}
        onChange={(evt, value) => handleChange?.(value)}
        onInputChange={(evt, inputValue) => {
          if (evt?.type !== 'click') {
            searchOptions({ search: inputValue, getOptions, setLoading, setOptions });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: !value && !readOnly ? placeholder : undefined,
            }}
            sx={{
              color: textColor ?? color,
              '& .MuiInputBase-input::placeholder': {
                color: !value && !readOnly ? textColor : undefined,
              },
              ...(disabledUnderline ? removeUnderline : {}),
            }}
            error={!!error}
            helperText={error}
          />
        )}
      />
    </Stack>
  );
};

export default AsyncSelectWithSearchComponent;
