import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useMaterialDropdownDashboardStyles = makeStyles((theme: Theme) => ({
  textfield: {
    height: '28px',
    '& > .MuiMenuItem-root': {
      height: '28px',
    },
  },
  withBorder: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
