import { ServiceLightWasteDto } from '../services/ServiceLightWasteDto';
import { ExecutionGroupDto } from './ExecutionGroupDto';
import { ID } from '@datorama/akita';
import { EventCalendar } from 'components/FullCalendar/TestData';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import i18n, { formatTranslatedName } from 'utils/data/i18n.utils';
import dayjs from 'utils/data/dayjs.utils';
import { ExecutionCountPerDayDto } from './execution-count-per-day.dto';

export interface ExecutionBetweenDatesDto {
  id: ID;
  name: string;
  status: ExecutionStatusEnum;
  ticketSignature: string;
  plannedFrom: Date;
  plannedTo: Date;
  service: ServiceLightWasteDto;
  executionGroup?: ExecutionGroupDto;
  commentsCount: number;
  issuesCount: number;
  generatedBySchedule?: any;
}

export const isExecutionReccurent = (ticketSignature: string) => ticketSignature.slice(-2) === '-R';

export function formatExecCountPerDayToEventCalendar({
  date,
  execCount,
  issueCount,
}: ExecutionCountPerDayDto): EventCalendar {
  return {
    title: '',
    start: dayjs(date).toDate(),
    end: dayjs(date).toDate(),
    allDay: true,
    extendedProps: {
      execCount,
      hasIssue: issueCount > 0,
    },
  };
}

export function formatToEventCalendar({
  id,
  name,
  status,
  ticketSignature,
  plannedFrom,
  plannedTo,
  service,
  executionGroup,
  issuesCount,
  generatedBySchedule,
}: ExecutionBetweenDatesDto): EventCalendar {
  return {
    title: executionGroup ? i18n.t('fe.operations.planning.groupPickup') : name,
    start: plannedFrom,
    end: plannedTo,
    allDay: dayjs(plannedTo).diff(dayjs(plannedFrom), 'h', true) > 4,
    extendedProps: {
      id: id,
      executionGroupId: executionGroup?.id,
      status: status,
      wasteName: executionGroup
        ? `${executionGroup.executionsRecap.length} ${i18n.t('fe.operations.group.tickets')}`
        : formatTranslatedName(service.waste?.nameTranslateKey, service.waste?.name || ''),
      hasIssue: issuesCount > 0,
      color: `#FF${(service.waste?.id as number) % 10}${(service.waste?.id as number) % 10}00`,
      isRecurrent: !!generatedBySchedule,
      realStart: plannedFrom,
      realEnd: plannedTo,
    },
  };
}

export function formatMultipleToEventCalendar(
  executions: ExecutionBetweenDatesDto[]
): EventCalendar {
  const start = executions.reduce((p, v) => (p.plannedFrom < v.plannedFrom ? p : v)).plannedFrom;
  const end = executions.reduce((p, v) => (p.plannedTo > v.plannedTo ? p : v)).plannedTo;
  return {
    title:
      i18n.t('fe.operations.multiple', { count: executions.length }) +
      `\n${dayjs(start).format('HH:mm')}-${dayjs(end).format('HH:mm')}`,
    start,
    end,
    allDay: dayjs(end).diff(dayjs(start), 'h', true) > 4,
    extendedProps: {
      color: `#FF0000`,
      multiple: true,
      realStart: start,
      realEnd: end,
    },
  };
}
