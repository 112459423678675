import React from 'react';
import { Box, FormControlLabel } from '@mui/material';
import { CustomCheckbox } from 'components/CheckboxComponent/CustomCheckbox.component';

interface CheckboxComponentProps {
  handleChange?: (checked: boolean) => void;
  label?: string;
  labelPlacement?: 'start' | 'end';
  labelWidth?: number;
  readOnly?: boolean;
  value?: boolean;
}

const CheckboxComponent = (props: CheckboxComponentProps) => {
  const { handleChange, label, labelPlacement = 'start', labelWidth, readOnly, value } = props;

  if (!label) {
    return <CustomCheckbox readOnly={readOnly} />;
  }

  return (
    <Box>
      <FormControlLabel
        disabled={readOnly}
        checked={value}
        control={<CustomCheckbox handleChange={(checked) => handleChange?.(checked)} />}
        sx={{
          '& .MuiFormControlLabel-label': { width: labelWidth ? `${labelWidth}px` : undefined },
        }}
        labelPlacement={labelPlacement}
        label={label}
      />
    </Box>
  );
};

export default CheckboxComponent;
