import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { OrganisationUnit } from './organisation-unit.model';
import { storageService } from 'utils/StorageService/StorageService';
import { LocalStorageKeyJson } from 'constants/LocalStorageKey';

export interface OrganisationUnitsState extends EntityState<OrganisationUnit> {
  activeOrgUnitIds?: ID[];
}

@StoreConfig({
  name: 'organisationUnits',
  resettable: true,
})
export class OrganisationUnitsStore extends EntityStore<OrganisationUnitsState> {
  constructor() {
    super();
    this.setActiveOrgUnitIds([], true);
  }

  setActiveOrgUnitId = (orgUnitId?: ID) => {
    // const cacheOrgUnitId = storageService.getItemJson(
    //   LocalStorageKeyJson.OrganisationUnitId,
    //   undefined
    // )?.orgUnitId;
    // const vOrgUnitId = orgUnitId ?? cacheOrgUnitId;
    // if (vOrgUnitId && this.getValue().entities?.[vOrgUnitId]) {
    //   this.setActive(vOrgUnitId);
    // }
    // // We want to update the state only if its different
    // if (this.getValue().activeClientOrgUnitId !== vOrgUnitId) {
    //   this.update((state) => ({
    //     ...state,
    //     activeOrgUnitId: vOrgUnitId,
    //   }));
    //   storageService.setItemJson(LocalStorageKeyJson.OrganisationUnitId, {
    //     orgUnitId: vOrgUnitId,
    //   });
    // }
  };

  setActiveOrgUnitIds = (orgUnitIds?: ID[], init = false) => {
    const cacheOrgUnitId = storageService.getItemJson(
      LocalStorageKeyJson.OrganisationUnitId,
      undefined
    )?.orgUnitIds;
    if (init) {
      const vOrgUnitIds = cacheOrgUnitId;
      if (vOrgUnitIds && vOrgUnitIds.every((id) => this.getValue().entities?.[id])) {
        this.update((state) => ({
          ...state,
          activeOrgUnitIds: vOrgUnitIds,
        }));
      }
    } else {
      this.update((state) => ({
        ...state,
        activeOrgUnitIds: orgUnitIds,
      }));
      storageService.setItemJson(LocalStorageKeyJson.OrganisationUnitId, {
        orgUnitIds: orgUnitIds,
      });
    }
  };

  removeActiveOrgUnit = () => {
    this.setActiveOrgUnitIds([]);
  };
}

export const organisationUnitsStore = new OrganisationUnitsStore();
