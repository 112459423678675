import { NotificationsStore, notificationsStore } from './notifications.store';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { formatNotificationDtoToUi } from './notification.model';
import { ID } from '@datorama/akita';
import i18n from 'utils/data/i18n.utils';

export class NotificationsService {
  constructor(private store: NotificationsStore) {}

  fetchNotifications = async () => {
    const [notifications, e] = await resolve(metawasteApi.getNotifs());
    if (e || !notifications) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.notifications'));
    }

    this.store.upsertMany(notifications.map(formatNotificationDtoToUi));
  };

  updateSeenNotifications = async () => {
    const dateNow = new Date();
    const [, e] = await resolve(metawasteApi.userReadNotifications(dateNow));
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.notificationsSeen'));
    }
    this.store.update(null, { hasSeen: true });
  };

  updateSeenNotification = (notificationId: ID) => {
    this.store.update(notificationId, { hasSeen: true });
  };
}

export const notificationsService = new NotificationsService(notificationsStore);
