import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useAzureRedirectStyles } from './AzureRedirect.style';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Popup from 'components/Modal/Popup';
import { InteractionStatus } from '@azure/msal-browser';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const AzureRedirectScreen = () => {
  const classes = useAzureRedirectStyles();
  const { t } = useTranslation();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [hasAlreadyRedirected, setHasAlreadyRedirected] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !hasAlreadyRedirected) {
      history.push('/');
      setHasAlreadyRedirected(true);
    }
  }, [history, inProgress, isAuthenticated, hasAlreadyRedirected]);

  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.bgImage}>
      <Popup title="Redirection" description="You will be redirected in a moment..." />
    </Grid>
  );
};

export default AzureRedirectScreen;
