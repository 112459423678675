import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Typography } from '@mui/material';
import { ReactComponent as Download } from 'assets/icons/rework/downloadinline.svg';
import MaterialDatePickerButton from 'components/MaterialDatePicker/MaterialDatePickerButton';
import MaterialDropdownDashboard from 'components/MaterialDropdown/MaterialDropdownDashboard';
import BasicTab from 'components/Tabs/BasicTabs';
import dayjs, { OpUnitType } from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardMorganStyles } from 'screens/auth/dashboard/morgan/DashboardMorgan.style';
import { sessionQuery } from 'store/session';
import { getEndDate, getStartDate } from 'utils/dates/parse';
import { MetawasteTab } from 'utils/enums/MetawasteTab';
import ObDataCards from './ObDataCards';
import { useObservable } from 'react-use';
import { onbordingQuery } from 'store/onbording/onbording.query';
import { onbordingService } from 'store/onbording/onbording.service';

const ObDashboardMorgan = () => {
  const [dropdownDashboard, setDropdownDashboard] = useState<OpUnitType>('month');
  const [multiDropdown, setMultiDropdown] = useState<string[]>([]);
  const [startDateButton, setStartDateButton] = useState<Date>(new Date());

  const classes = useDashboardMorganStyles();
  const { t } = useTranslation();

  const openNewDemande = useObservable(onbordingQuery.openNewDemande$);

  const [currentTab, setCurrentTab] = useState<{ tabType: MetawasteTab; label: string }>({
    label: t('fe.dashboard.morgan.statisticButton'),
    tabType: MetawasteTab.STATS,
  });

  const startDate = useMemo(() => getStartDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);
  const endDate = useMemo(() => getEndDate(startDateButton, dropdownDashboard), [
    startDateButton,
    dropdownDashboard,
  ]);

  const getCurrentPeriod = (startDateButton: Date, dropdownDashboard: OpUnitType) => {
    const d = dayjs(startDateButton);

    const startDate = d.startOf(dropdownDashboard).format('LL');
    const endDate = d.endOf(dropdownDashboard).format('LL');

    return `${startDate} - ${endDate}`;
  };

  return (
    <Grid item xs={12} container spacing={2} className={classes.container}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.title}
      >
        <Grid item container justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h1">Dashboard</Typography>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" spacing={2} xs={8}>
            {sessionQuery.isWorkSite() && (
              <Grid item>
                <Button
                  onClick={() => window.open('https://form.jotform.com/222431908487057')}
                  variant="outlined"
                  size="large"
                >
                  {t('fe.operations.button.worksite')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
                className={`${classes.createButtonContainer} step12`}
                onClick={() => onbordingService.handleOpenNewDemande(true)}
              >
                <span>{t('fe.operations.createExecutionButton')}</span>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" size="large" startIcon={<AddIcon />}>
                <span>{t('fe.bookmarks.modal.button')}</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 7 }}>
        <BasicTab
          tabs={[{ label: t('fe.dashboard.morgan.statisticButton'), tabType: MetawasteTab.STATS }]}
          value={currentTab}
          handleChange={setCurrentTab}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <>
          <div style={{ display: 'flex' }}>
            <Grid container className="step3" spacing={1}>
              <Grid item>
                <Button variant="basic" size="small" onClick={() => setStartDateButton(new Date())}>
                  {t('fe.misc.today')}
                </Button>
              </Grid>
              <Grid item>
                <MaterialDropdownDashboard
                  required
                  sx={{ height: '28px !important' }}
                  color="secondary"
                  handleChange={(value) => setDropdownDashboard(value)}
                  value={dropdownDashboard}
                  items={[
                    { label: t('fe.misc.week'), value: 'week' },
                    { label: t('fe.misc.month'), value: 'month' },
                    { label: t('fe.misc.year'), value: 'year' },
                  ]}
                />
              </Grid>
              <Grid item>
                <MaterialDatePickerButton
                  value={startDateButton ? new Date(startDateButton) : undefined}
                  handleChange={setStartDateButton}
                />
              </Grid>
            </Grid>
          </div>
          <Grid item className="step4">
            <Typography variant="h3" noWrap width="10rem" textAlign="center">
              {getCurrentPeriod(startDateButton, dropdownDashboard)}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" endIcon={<Download />}>
              {t('fe.operations.button.downloadReport')}
            </Button>
          </Grid>
        </>
      </Grid>
      <ObDataCards
        from={startDate}
        to={endDate}
        wasteIds={multiDropdown}
        dropwDownDashboard={dropdownDashboard}
      />
    </Grid>
  );
};

export default ObDashboardMorgan;
