import { LocalStorageKey } from 'constants/LocalStorageKey';
import { LocalStorageService } from './StorageService';
import { de, enUS, fr, it } from 'date-fns/locale';
import { Locale } from 'constants/Locales';

const availableLocalesRegex = {
  [Locale.English]: new RegExp(/en.*/),
  [Locale.French]: new RegExp(/fr.*/),
  [Locale.Italian]: new RegExp(/it.*/),
  [Locale.German]: new RegExp(/de.*/),
};

const isValidLocale = (locale: string | null): boolean => {
  if (!locale) return false;
  return Object.values(availableLocalesRegex).some((supportedLang) => supportedLang.test(locale));
};

const parseLocale = (locale: string | null | undefined) => {
  if (!locale) return null;
  const [appLocale] = Object.entries(availableLocalesRegex).find(([appLocale, localeRegex]) =>
    localeRegex.test(locale)
  ) ?? [null];

  return appLocale as Locale | null;
};

export function getLocale(): Locale {
  const savedLocale = parseLocale(LocalStorageService.getItem(LocalStorageKey.Locale));
  if (savedLocale) {
    return savedLocale;
  }

  const browserLanguages = navigator.languages ?? [navigator.language];
  const prefferedUserLanguage = parseLocale(browserLanguages.find((lang) => isValidLocale(lang)));

  if (prefferedUserLanguage) {
    return prefferedUserLanguage;
  }

  return Locale.English;
}

export function saveLocale(locale: Locale) {
  LocalStorageService.setItem(LocalStorageKey.Locale, locale);
}

// export async function getMessages(locale: Locale) {
//   // This needs to be a variable - when used in import directly causes reference error (Webpack issue)
//   if (locale === Locale.English) {
//     return enTranslations;
//   }
//   const localeFileName = LocaleFileName[locale];
//   const importedMessages = await import(`assets/locale/${localeFileName}.json`);
//   return { ...enTranslations, ...importedMessages };
// }

export function getLocaleObject(locale: string) {
  switch (locale) {
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'de':
      return de;
    default:
      return enUS;
  }
}
