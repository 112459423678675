import { CircularProgress, Grid } from '@mui/material';
import CardOperations from 'components/Card/CardOperations/CardOperations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationsScreenStyles } from '../Operations.screen.style';
import { isMobile } from 'utils/isMobile';
import { NoResultComponent } from './NoResult';
import { operationsStatsService } from 'store/dashboard/operationStats';
import { OperationEvolutionDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';

interface OperationPickUpDoneComponentProps {
  evolution: OperationEvolutionDto;
  isLoading: boolean;
}

export const OperationPickUpDoneComponent = (props: OperationPickUpDoneComponentProps) => {
  const { isLoading, evolution } = props;
  const { t } = useTranslation();
  const classes = useOperationsScreenStyles();
  const isMobiles = isMobile();

  if (isLoading) {
    return <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />;
  }
  if (!evolution) {
    return <NoResultComponent />;
  }

  return (
    <Grid container spacing={2} className={classes.collectesCardContainer}>
      <Grid item xs={isMobiles ? 12 : 6}>
        <CardOperations
          data={evolution.execExecutedCount.current.toString()}
          dataStatistic={operationsStatsService.calculatePercentageEvolution(
            evolution.execExecutedCount.old,
            evolution.execExecutedCount.current
          )}
          background="secondary.light"
          description={t('fe.dashboard.morgan.stats.operations.collect.totalCollect')}
        />
      </Grid>
      {/*<Grid item xs={isMobiles ? 12 : 6}>*/}
      {/*  <CardOperations*/}
      {/*    data={pickUpDone.numberExecutionPerDay.toString()}*/}
      {/*    dataStatistic={operationsStatsService.calculatePercentageEvolution(*/}
      {/*      pickUpDone.oldNumberExecutionPerDay,*/}
      {/*      pickUpDone.numberExecutionPerDay*/}
      {/*    )}*/}
      {/*    background="secondary.light"*/}
      {/*    description={t('fe.dashboard.morgan.stats.operations.collect.averageCollect')}*/}
      {/*  />*/}
      {/*</Grid>*/}
    </Grid>
  );
};
