import { PublicClientApplication } from '@azure/msal-browser';
import { LocalStorageKey } from '../constants/LocalStorageKey';
import { metawasteApi } from '../api/Axios';
import env from './env';

export const msalConfig = {
  auth: {
    clientId: env.AZURE_CLIENT_ID ?? '',
    authority: env.AZURE_AUTHORITY,
    knownAuthorities: [env.AZURE_KNOWN_AUTHORITY ?? ''],
    redirectUri: env.AZURE_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const msalLoginRequest = {
  scopes: ['openid offline_access'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const requestAzureAccessToken = async () => {
  const account = msalInstance.getAllAccounts()[0];

  const request = {
    ...msalLoginRequest,
    account,
  };
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    const resp = await msalInstance.acquireTokenSilent(request);
    localStorage.setItem(LocalStorageKey.AccessToken, btoa(resp.idToken));
    metawasteApi.setToken(resp.idToken);
    if (localStorage.getItem('isOnbording') !== 'true') {
      localStorage.setItem('isOnbording', `${false}`);
    }
  } catch (e) {
    await msalInstance.loginRedirect(request).catch((e) => {
      console.error(e);
    });
  }
};
