import { Components } from '@mui/material';

import { Sizes } from 'constants/Sizes';
import { Colors } from 'constants/Colors';

export const drawer: Components = {
  MuiDrawer: {
    styleOverrides: {
      root: (props) => ({
        ...(!props.ownerState.hideBackdrop
          ? {
              backdropFilter: 'blur(4px)',
              backgroundColor: Colors.blur,
            }
          : {}),
        zIndex: props.ownerState.anchor === 'right' ? 1301 : undefined,
      }),
      paperAnchorDockedLeft: {
        borderRight: 'none',
        boxSizing: 'border-box',
        flexShrink: 0,
        width: Sizes.leftBar,
      },
      paperAnchorRight: {
        padding: '17px 12px 0 24px',
        width: 550,
      },
    },
  },
};
