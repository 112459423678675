import { InputLabel, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useRef, useState } from 'react';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import dayjs from 'dayjs';

interface DatePickerComponentProps {
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  handleChange?: (date?: Date) => void;
  label?: string;
  labelWidth?: number;
  inputWidth?: number;
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  preventClick?: boolean;
  readOnly?: boolean;
  required?: boolean;
  size?: 'small';
  error?: string;
  value?: Date;
  hasFocus?: boolean;
  fontStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  calendarStyle?: React.CSSProperties;
  inputBaseStyle?: React.CSSProperties;
  disabledUnderline?: boolean;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {
  const {
    color = 'primary',
    disabled,
    disableFuture,
    disablePast,
    format = 'dd/mm/yyyy',
    handleChange,
    label,
    labelWidth,
    inputWidth,
    maxDate,
    minDate,
    placeholder,
    preventClick,
    readOnly,
    required,
    size,
    error,
    value,
    hasFocus,
    fontStyle,
    inputStyle,
    calendarStyle,
    inputBaseStyle,
    disabledUnderline,
  } = props;

  const removeUnderline = {
    '& .MuiInput-underline:before': { display: 'none' },
    '& .MuiInput-underline:hover:before': { display: 'none' },
    '& .MuiInput-underline:after': { display: 'none' },
  };

  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEnhancedEffect(() => {
    if (hasFocus && ref.current && !readOnly) {
      const input = ref.current.querySelector<HTMLInputElement>('input');
      input?.focus();
    }
  }, [hasFocus, value, readOnly]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      {!!label && (
        <InputLabel
          error={!!error && !disabled}
          sx={{ width: labelWidth ? `${labelWidth}px` : undefined, ...fontStyle }}
        >
          {label + (required ? '*' : '')}
        </InputLabel>
      )}
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        readOnly={readOnly}
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          if (newValue && newValue.isValid() && (!value || !dayjs(value).isSame(newValue))) {
            handleChange?.(newValue.toDate());
          }
        }}
        InputProps={{
          disableUnderline: disabledUnderline,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              width: inputWidth ? `${inputWidth}px` : undefined,
              ...inputStyle,
              '& .MuiInputBase-root': {
                ...inputBaseStyle,
              },
            }}
            error={!!error}
            helperText={error}
            placeholder={placeholder}
            size={size}
            color={color}
          />
        )}
        inputFormat={format}
      />
    </Stack>
  );
};

export default DatePickerComponent;
