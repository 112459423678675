import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// import translationEN from 'assets/rt-translations/translations/en.json';
import translationFR from '../translationFR.json';
import env from '../env';
// import translationDE from 'assets/rt-translations/translations/de.json';
// import translationIT from 'assets/rt-translations/translations/it.json';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

if (env.USE_LOCAL_TRANSLATION === 'true') {
  i18n
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'fr',
      debug: false,
      resources: {
        // en: {
        //   translation: translationEN,
        // },
        fr: {
          translation: translationFR,
        },
        // de: {
        //   translation: translationDE,
        // },
        // it: {
        //   translation: translationIT,
        // },
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
} else {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: {
        loadPath: `${env.AZURE_STORAGE_URL}/translations/genius/{{lng}}.json`,
      },
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}

export const getCurrentLanguage = (): string =>
  i18n.language?.split(/[-_]/)[0] ||
  window.localStorage.i18nextLng?.toString()?.split(/[-_]/)[0] ||
  '';

export const formatTranslatedName = (key?: string, defaultValue?: string) => {
  return (key ? i18n.t(key) : defaultValue) ?? '-';
};

export default i18n;
