import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoResult } from 'assets/img/no_result.svg';

export const NoResultComponent = () => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
      <Grid item alignItems="center">
        <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginLeft: 8 }}>
          <NoResult />
        </div>
        {t(`fe.noResult`)}
      </Grid>
    </Grid>
  );
};
