import React, { useMemo, useState } from 'react';
import { FormHelperText, IconButton, InputLabel, Stack, TextField } from '@mui/material';

import { Calendar } from 'assets/icons/index';

import dayjs from 'utils/data/dayjs.utils';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

interface DateRangePickerComponentProps {
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  handleChangeStartDate?: (date: Date | undefined | null) => void;
  handleChangeEndDate?: (date: Date | undefined | null) => void;
  label?: string;
  labelWidth?: number;
  inputWidth?: number;
  maxDate?: Date;
  minDate?: Date;
  placeholderStart?: string;
  placeholderEnd?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: 'small';
  error?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}

const DateRangePickerComponent = (props: DateRangePickerComponentProps) => {
  const {
    color = 'primary',
    disabled,
    disableFuture,
    disablePast,
    format,
    handleChangeStartDate,
    handleChangeEndDate,
    label,
    labelWidth,
    inputWidth,
    maxDate,
    minDate,
    placeholderStart,
    placeholderEnd,
    readOnly,
    required,
    size,
    error,
    startDate,
    endDate,
  } = props;

  const [open, setOpen] = useState(false);

  const value: DateRange<Date | undefined | null> = useMemo(
    () => [startDate ?? null, endDate ?? null],
    [startDate, endDate]
  );

  return (
    <DateRangePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      shouldDisableDate={(day) => {
        if (minDate) {
          return dayjs(day).isSameOrBefore(minDate, 'd');
        }
        if (maxDate) {
          return dayjs(day).isSameOrAfter(maxDate, 'd');
        }
        return false;
      }}
      disableOpenPicker
      disabled={disabled}
      readOnly={readOnly}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value || null}
      inputFormat={format || 'dd/MM/yyyy'}
      onChange={(dates) => {
        if (!dayjs(dates[0]).isSame(startDate) && dayjs(dates[0]).isValid()) {
          handleChangeStartDate?.(dates[0]);
        }
        if (!dayjs(dates[1]).isSame(endDate) && dayjs(dates[1]).isValid()) {
          handleChangeEndDate?.(dates[1]);
        }
      }}
      renderInput={(startParams, endParams) => {
        return (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            {!!label && (
              <InputLabel
                error={!!error && !disabled}
                sx={{ width: labelWidth ? `${labelWidth}px` : undefined }}
              >
                {label + (required ? '*' : '')}
              </InputLabel>
            )}
            <Stack spacing={1} flex={!inputWidth ? 1 : undefined}>
              <TextField
                {...startParams}
                label={undefined}
                sx={{ flex: 1, width: inputWidth ? `${inputWidth}px` : undefined }}
                color={color}
                size={size}
                disabled={disabled}
                focused={readOnly ? false : undefined}
                InputProps={{
                  ...startParams.InputProps,
                  startAdornment: (
                    <IconButton onClick={() => setOpen(true)} sx={{ mx: '-8px !important' }}>
                      <Calendar />
                    </IconButton>
                  ),
                  endAdornment: null,
                }}
                inputProps={{
                  ...startParams.inputProps,
                  placeholder: placeholderStart,
                }}
              />
              {!!error && !disabled && (
                <FormHelperText error sx={{ mx: '14px', paddingLeft: '20px' }}>
                  {error}
                </FormHelperText>
              )}
            </Stack>
            <Stack spacing={1} flex={!inputWidth ? 1 : undefined}>
              <TextField
                {...endParams}
                label={undefined}
                sx={{ flex: 1, width: inputWidth ? `${inputWidth}px` : undefined }}
                color={color}
                size={size}
                disabled={disabled}
                focused={readOnly ? false : undefined}
                InputProps={{
                  ...endParams.InputProps,
                  startAdornment: (
                    <IconButton onClick={() => setOpen(true)} sx={{ mx: '-8px !important' }}>
                      <Calendar />
                    </IconButton>
                  ),
                  endAdornment: null,
                }}
                inputProps={{
                  ...endParams.inputProps,
                  placeholder: placeholderEnd,
                }}
              />
            </Stack>
          </Stack>
        );
      }}
    />
  );
};

export default DateRangePickerComponent;
