import { Box, Button, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AuditProposalInfoUnprotectedDto } from 'api/dto/Audit/AuditProposalInfoUnprotectedDto';
import { AuditProposalSupplierDto } from 'api/dto/Audit/AuditProposalSupplierDto';
import { formatAddressDetail } from 'api/dto/client/AddressDto';
import ContainerDetails from 'components/Drawer/ContainerDetails.drawer';
import MaterialNumberTextfield from 'components/MaterialTextfield/MaterialNumberTextfield';
import AsyncSelectWithSearchComponent from 'components/Select/AsyncSelectWithSearchComponent';
import AuditTable from 'components/Table/AuditTable';
import { CustomColumnsType } from 'components/Table/ColumnsTypes/CustomColumns';
import { Colors } from 'constants/Colors';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { map } from 'rxjs/operators';
import {
  AuditOfferSupplierStatus,
  AuditOfferSupplierStatusEnum,
} from 'store/audit/audit-offer-supplier/audit-offer-supplier.model';
import { auditProposalSupplierService } from 'store/audit/audit.service';
import { AuditOfferSupplierItemDto } from 'store/audit/auditModel';
import { filtersService } from 'store/common/filters/filters.service';
import { transformDateToDayMonthYear } from 'utils/dates/format';
import { getUnitOfMeasureLabel, UnitOfMeasure } from 'utils/enums/ServiceKindTypeEnum';

interface AuditProposalsScreenParams {
  id: string;
}

const AuditProposalsScreen = () => {
  const [auditProposals, setAuditProposals] = useState<AuditProposalSupplierDto[]>([]);
  const [auditInfo, setAuditInfo] = useState<AuditProposalInfoUnprotectedDto>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [auditOperationId, setAuditOperationId] = useState<number | undefined>(undefined);

  const handleSelectedRows = (params: any) => setSelectedRows([...params]);

  const { id } = useParams<AuditProposalsScreenParams>();

  function updateObjectById(id: number, key: keyof AuditProposalSupplierDto, value: any): void {
    setAuditProposals((prevAuditProposals) =>
      prevAuditProposals.map((item) => (item.id === id ? { ...item, [key]: value } : item))
    );
  }

  const fetchData = async () => {
    try {
      const proposals = await auditProposalSupplierService.getAuditProposalSupplier(id);
      setAuditProposals(proposals);
      const info = await auditProposalSupplierService.getAuditInformationsByAuditProposalSupplierId(
        id
      );
      setAuditInfo(info);
    } catch (e) {
      console.error('Error fetching audit proposals:', e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const updateAuditProposals = (newAuditProposals: AuditProposalSupplierDto[]) => {
    setAuditProposals(newAuditProposals);
  };

  const canAccept = () => {
    return (
      !auditProposals.every((proposal) => proposal.status !== AuditOfferSupplierStatusEnum.NONE) ||
      loading ||
      auditProposals.some(
        (proposal) =>
          proposal.status !== AuditOfferSupplierStatusEnum.DENIED &&
          (proposal.serviceKindId === null || proposal.serviceKindId === undefined)
      )
    );
  };

  const handlePostAuditProposal = async () => {
    try {
      setLoading(true);
      const auditProposalsArray: AuditOfferSupplierItemDto[] = auditProposals.map(
        (proposal: AuditProposalSupplierDto) => {
          return {
            id: proposal.id,
            serviceKindId: proposal.serviceKindId,
            unitCostInCents: proposal.unitCostInCents,
            status: proposal.status,
          };
        }
      );
      await auditProposalSupplierService.patchAuditProposalSupplier(id, {
        auditOfferSuppliers: auditProposalsArray,
      });
      setLoading(false);
      enqueueSnackbar('Votre réponse a bien été envoyée', { variant: 'success' });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting audit proposal:', error);
    }
  };

  const isAnswered = () => {
    return auditInfo?.answeredAt !== null;
  };

  const isExpired = () => {
    const Today = new Date();
    if (auditInfo?.expireAt) {
      const expireDate = new Date(auditInfo.expireAt);
      if (Today > expireDate) {
        return true;
      }
    }
    return false;
  };

  const updateStatusForSelectedItems = (status: AuditOfferSupplierStatusEnum) => {
    const selectedItems = new Set(selectedRows);
    selectedItems.forEach((id) => updateObjectById(id, 'status', status));

    setSelectedRows([]);
  };

  const responseMessage = () => {
    if (isExpired() && !isAnswered()) {
      return (
        <Stack
          border={`2px solid ${Colors.white}`}
          paddingTop="16px"
          paddingLeft="16px"
          bgcolor={Colors.white}
        >
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t('audits.expired')}
          </Typography>
        </Stack>
      );
    }
    if (!isExpired() && !isAnswered()) {
      return '';
    }
    if (!isExpired() && isAnswered()) {
      return (
        <Stack
          border={`2px solid ${Colors.white}`}
          paddingTop="16px"
          paddingLeft="16px"
          bgcolor={Colors.white}
        >
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t('audits.answered')}{' '}
            {auditInfo?.answeredAt && transformDateToDayMonthYear(auditInfo.answeredAt)}
          </Typography>
        </Stack>
      );
    }
    if (isExpired() && isAnswered()) {
      return (
        <Stack
          border={`2px solid ${Colors.white}`}
          paddingTop="16px"
          paddingLeft="16px"
          bgcolor={Colors.white}
        >
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t('audits.answered')}{' '}
            {auditInfo?.answeredAt && transformDateToDayMonthYear(auditInfo.answeredAt)}
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
        bgcolor={Colors.white}
        p={2}
      >
        <img src="/images/metawaste-logo.png" width="50px" height="auto" alt="Metawaste Logo" />
      </Stack>
      <Stack flex={1} width="100%" px="24px" spacing={2} overflow="hidden" py="34px">
        <Typography fontSize="30px">{auditInfo?.offer}</Typography>
        <Stack direction="row" spacing={12}>
          <Stack direction="row" spacing={12} alignItems="baseline" px="20px">
            <Stack>
              <Typography fontSize={14} fontWeight={400} color="textSecondary">
                {t('audits.site')}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="textSecondary">
                {t('audits.address')}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize={14} fontWeight={400}>
                {auditInfo?.site}
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                {auditInfo?.address && formatAddressDetail(auditInfo.address)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={12} alignItems="baseline" px="20px">
            <Stack>
              <Typography fontSize={14} fontWeight={400} color="textSecondary">
                {t('audits.writtenOn')}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="textSecondary">
                {t('audits.limitDate')}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize={14} fontWeight={400}>
                {transformDateToDayMonthYear(auditInfo?.createdAt ?? null)}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={isExpired() ? Colors.error : Colors.green}
              >
                {transformDateToDayMonthYear(
                  auditInfo?.expireAt ? new Date(auditInfo.expireAt) : null
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {responseMessage()}
        <Stack
          border={`2px solid ${Colors.white}`}
          borderRadius="8px"
          padding="16px"
          marginTop="16px"
          marginBottom="16px"
          bgcolor={Colors.white}
        >
          <Typography variant="h5" color="textPrimary" mb={2}>
            {t('fe.audits.salesProposal')}
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t('fe.audits.salesProposalDescription')}
          </Typography>
          {!isAnswered() && !isExpired() ? (
            <Stack direction="row" spacing={1} justifyContent="flex-start" mb={2}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => updateStatusForSelectedItems(AuditOfferSupplierStatusEnum.ACCEPTED)}
              >
                Accepter
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => updateStatusForSelectedItems(AuditOfferSupplierStatusEnum.DENIED)}
              >
                Refuser
              </Button>
            </Stack>
          ) : null}
          <AuditTable
            data={auditProposals}
            groupBy="auditOperationId"
            columns={[
              {
                type: CustomColumnsType.TEXT,
                field: 'container',
                header: 'Container',
                width: 300,
              },
              {
                type: CustomColumnsType.TEXT,
                field: 'waste',
                header: 'Waste',
                width: 200,
              },
              {
                type: CustomColumnsType.TEXT,
                field: 'family',
                header: 'Family',
                width: 200,
              },
              {
                type: CustomColumnsType.CUSTOM,
                field: 'status',
                header: 'Status',
                width: 350,
                handleRender: (item: AuditProposalSupplierDto) => {
                  if (item.status === AuditOfferSupplierStatusEnum.DENIED) {
                    return null;
                  }

                  if (isAnswered() || isExpired()) {
                    return (
                      <Typography fontSize="14px">
                        {item.status != AuditOfferSupplierStatusEnum.DENIED
                          ? item.serviceKind?.kind
                          : ''}
                      </Typography>
                    );
                  }

                  return (
                    <AsyncSelectWithSearchComponent
                      labelWidth={60}
                      textColor={Colors.black}
                      placeholder={item.serviceKind?.kind ?? 'Sélectionner'}
                      handleChange={(value) => {
                        const v = value as { label: string; value: number; unit: string };
                        updateObjectById(item.id, 'serviceKind', {
                          kind: v.label,
                          id: v.value,
                          execUnitName1: v.unit,
                        });
                        updateObjectById(item.id, 'serviceKindId', v.value);
                      }}
                      getOptions={(search) => {
                        return filtersService
                          .fetchServiceKindsUnprotected({
                            searchString: search,
                            family: item.family,
                          })
                          .pipe(
                            map((options) =>
                              options.map((o) => ({
                                label: o.kind || '-',
                                value: o.id,
                                unit: o.execUnitName1,
                              }))
                            )
                          );
                      }}
                    />
                  );
                },
              },
              {
                type: CustomColumnsType.CUSTOM,
                field: 'serviceKind',
                header: 'Unit',
                width: 200,
                handleRender: (item: AuditProposalSupplierDto) => {
                  if (item.status === AuditOfferSupplierStatusEnum.DENIED) {
                    return null;
                  }
                  return (
                    <Stack>
                      <Typography typography="14px">
                        {getUnitOfMeasureLabel(item.serviceKind?.execUnitName1 as UnitOfMeasure)}
                      </Typography>
                    </Stack>
                  );
                },
              },
              {
                type: CustomColumnsType.CUSTOM,
                field: 'unitCostInCents',
                header: 'Price',
                width: 200,
                handleRender: (item: AuditProposalSupplierDto) => {
                  if (item.status === AuditOfferSupplierStatusEnum.DENIED) {
                    return null;
                  }

                  if (isExpired() || isAnswered()) {
                    return (
                      <Typography fontSize="14px">
                        {item.status != AuditOfferSupplierStatusEnum.DENIED
                          ? item.unitCostInCents / 100 + ' CHF'
                          : ''}
                      </Typography>
                    );
                  }

                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ height: '26px', width: '105px' }}
                    >
                      <MaterialNumberTextfield
                        size="small"
                        value={String(item.unitCostInCents / 100)}
                        pattern={new RegExp(/^-?(\d*|\d+)[,.]?\d*$/)}
                        handleChange={(value) => {
                          updateObjectById(item.id, 'unitCostInCents', value * 100);
                        }}
                      />
                      <Typography paddingLeft={1} fontSize="14px">
                        CHF
                      </Typography>
                    </Stack>
                  );
                },
              },
              {
                type: CustomColumnsType.CUSTOM,
                field: 'status',
                header: 'Status',
                width: 200,
                handleRender: (value: AuditProposalSupplierDto) => {
                  if (isAnswered() || isExpired()) {
                    const item = AuditOfferSupplierStatus?.selectItems()?.find(
                      (i) => i.value === value.status
                    );
                    return (
                      <Stack
                        width="fit-content"
                        height="26px"
                        bgcolor={item?.backgroundColor || Colors.grey}
                        justifyContent="center"
                        borderRadius="6px"
                        p="9px 14px"
                      >
                        <Typography fontSize="14px">
                          {item?.value != AuditOfferSupplierStatusEnum.NONE ? item?.label : ''}
                        </Typography>
                      </Stack>
                    );
                  }

                  return (
                    <Select
                      value={value.status}
                      onChange={(e) => updateObjectById(value.id, 'status', e.target.value)}
                      style={{
                        width: '140px',
                        height: '26px',
                        backgroundColor:
                          AuditOfferSupplierStatus.selectItems()?.find(
                            (i) => i.value === value.status
                          )?.backgroundColor || Colors.grey,
                      }}
                      displayEmpty
                    >
                      {AuditOfferSupplierStatus.selectItems()?.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                          <Box alignItems="center" sx={{ display: 'flex', gap: 1 }}>
                            <Typography fontSize={14} fontWeight={400} color={i.color}>
                              {i.label}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  );
                },
              },
            ]}
            labelSubGroup="container"
            selectedRows={selectedRows}
            handleSelectRows={handleSelectedRows}
            handleOpenSubGroup={(groupItems: AuditProposalSupplierDto[]) => {
              setAuditOperationId(groupItems[0].auditOperationId);
            }}
            disableSelection={isAnswered() || isExpired()}
          />
        </Stack>
        {auditInfo?.answeredAt === null && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="white"
            padding={2}
          >
            <Typography variant="body2" color="textSecondary">
              {t('audits.salesProposalDescription2')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostAuditProposal}
              disabled={canAccept()}
            >
              {t('audits.send')}
            </Button>
          </Stack>
        )}
        {auditOperationId ? (
          <ContainerDetails
            onClose={() => setAuditOperationId(undefined)}
            auditOperationId={auditOperationId}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default AuditProposalsScreen;
