export namespace Array {
  export const upsert = <T>(array: T[], element: T, compareFn: (el: T) => boolean): T[] => {
    const i = array.findIndex((_element) => compareFn(_element));
    if (i > -1) array[i] = element;
    else array.push(element);
    return array;
  };

  export const mapNotNull = <T>(arr?: (T | undefined)[]): T[] | undefined =>
    arr?.flatMap((it) => (it ? [it] : []));

  export const formatJson = (arr?: any[]): string | undefined =>
    arr && arr.length > 0 ? arr.join(',') : undefined;
}
