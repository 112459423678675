import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { usePopupStyles } from './Popup.style';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';

export interface PopupProps {
  handleClose?: () => void;
  title: string;
  description: string;
  buttons?: {
    label: string;
    color: 'primary' | 'error';
    variant?: 'contained' | 'outlined';
    onClick: () => void;
    disabled?: boolean;
  }[];
  handleSubmit?: () => void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg';
}

const Popup: FC<PopupProps> = (props) => {
  const { title, description, handleClose, maxWidth = 'sm', handleSubmit, buttons = [] } = props;
  const classes = usePopupStyles();
  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      {!!handleClose && (
        <IconButton
          className={classes.close}
          onClick={handleClose}
          style={{ position: 'absolute' }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          {buttons.map((button) => (
            <Grid item key={`${button.label} - ${button.color}`}>
              <Button
                className={classes.contained}
                key={`${button.label} - ${button.color}`}
                color={button.color}
                variant={button.variant ?? 'contained'}
                onClick={() => {
                  button.onClick();
                  handleSubmit?.();
                }}
                disabled={button.disabled}
              >
                {button.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
