import { Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import style from './BasicTabs.style';
import { WithStyles, withStyles } from '@mui/styles';
import { MetawasteTab } from 'utils/enums/MetawasteTab';

interface BasicTabsProps extends WithStyles<typeof style> {
  handleChange?: (value: { tabType: MetawasteTab; label: string }) => void;
  tabs: { tabType: MetawasteTab; label: string }[];
  value?: { tabType: MetawasteTab; label: string };
  size?: 'medium' | 'small';
}

const BasicTabs: FC<BasicTabsProps> = (props: BasicTabsProps) => {
  const { classes, handleChange, tabs, value, size = 'medium' } = props;

  return (
    <Tabs
      value={value?.tabType || tabs[0].tabType}
      onChange={(evt, value) => handleChange?.(tabs.find((t) => t.tabType === value) || tabs[0])}
      className={classes.tabsContainer + (size === 'small' ? ` ${classes.tabsContainerSmall}` : '')}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={`Tab - ${tab.tabType}`}
          label={tab.label}
          value={tab.tabType}
          disabled={!handleChange}
          className={classes.tab + (size === 'small' ? ` ${classes.tabSmall}` : '')}
        />
      ))}
    </Tabs>
  );
};

export default withStyles(style)(BasicTabs);
