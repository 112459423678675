import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { ecologyScreenStatsQuery } from '../ecology-screen-stats.query';
import { useObservable } from '@ngneat/react-rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { filtersQuery } from 'store/common/filters/filters.query';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';
import { ecologyScreenStatsService } from '../ecology-screen-stats.service';

export const useEcologyScreenStats = (from: Date, to: Date, wasteIds: string[]) => {
  const { enqueueSnackbar } = useSnackbar();
  const from$ = useRef(new Subject<Date>()).current;
  const to$ = useRef(new Subject<Date>()).current;
  const wasteIds$ = useRef(new Subject<string[]>()).current;

  const [ecologyEvolutionsStats] = useObservable(ecologyScreenStatsQuery.getEvolutionStats$);

  useEffect(() => {
    const _queryParams = combineLatest([
      from$,
      to$,
      wasteIds$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitIds$,
      filtersQuery.currentFilters$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([from, to, wasteIds, organisationId, orgUnitIds, currentFilters]) => {
        if (organisationId) {
          ecologyScreenStatsService
            .fetchStats(from, to, wasteIds, organisationId, orgUnitIds, currentFilters)
            .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
        }
      });
    return () => {
      _queryParams.unsubscribe();
    };
  }, []);

  useEffect(() => wasteIds$.next(wasteIds), [wasteIds]);

  useEffect(() => from$.next(from), [from]);

  useEffect(() => to$.next(to), [to]);

  return {
    ecologyEvolutionsStats,
  };
};
