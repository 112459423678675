import React, { FC } from 'react';
import { Avatar } from '@mui/material';

type AvatarProps = {
  initials: string;
  isBig?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const AvatarComponent: FC<AvatarProps> = ({ initials, isBig, onClick }) => (
  <Avatar
    onClick={onClick}
    sx={{ width: isBig ? 32 : 24, height: isBig ? 32 : 24, fontSize: '12px !important' }}
  >
    {initials}
  </Avatar>
);

export default AvatarComponent;
