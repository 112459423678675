import { combineDateAndHours } from 'utils/data/dayjs.utils';
import { ExecutionDetailsUi } from 'store/operations/createExecution';

export interface ExecutionUpdateDto {
  serviceId: number;
  plannedFrom?: string | null;
  plannedTo?: string | null;
  quantity1: number;
  asap?: boolean;
}

export function formatToExecutionUpdateDto(execution: ExecutionDetailsUi): ExecutionUpdateDto {
  const { serviceId, selectedDate, plannedFrom, plannedTo, nbContainers, asap } = execution;
  return {
    serviceId: Number(serviceId ?? ''),
    plannedFrom:
      selectedDate && plannedFrom && !asap ? combineDateAndHours(selectedDate, plannedFrom) : null,
    plannedTo:
      selectedDate && plannedTo && !asap ? combineDateAndHours(selectedDate, plannedTo) : null,
    quantity1: nbContainers,
    asap,
  };
}
