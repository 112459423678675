import React, { FC, useEffect, useState } from 'react';
import { useAppBarHeaderStyles } from './AppBarHeader.style';
import { Alert, AppBar, Grid, IconButton, Link, Stack, Toolbar, Tooltip } from '@mui/material';
import { ReactComponent as IconFaq } from 'assets/icons/rework/icon_faq.svg';
import { ReactComponent as IconNotification } from 'assets/icons/rework/icon_notification.svg';
import { ReactComponent as IconSettings } from 'assets/icons/rework/icon_settings.svg';
import { ReactComponent as IconBurger } from 'assets/icons/rework/menuBurger.svg';
import AvatarComponent from 'components/AvatarComponent';
import { Cypress } from 'constants/Cypress';
import Button from '@mui/material/Button';
import { isMobile } from 'utils/isMobile';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { organisationsQuery } from 'store/organisation/organisations';
import { useSnackbar } from 'notistack';
import AppBarDropdownOrganisation from './AppBarDropdownClient/AppBarDropdownOrganisation';
import AppBarDropdownOrgUnit from './AppBarDropdownClientOrgUnit/AppBarDropdownOrgUnit';
import { iconSrc } from 'constants/Icons';
import { sessionQuery, sessionService } from 'store/session';
import SnackError from 'utils/models/snackError';
import SettingsScreen from 'screens/auth/modals/settings/Settings.screen';
import NotificationModal from 'screens/auth/modals/notifications/NotificationModal';
import SideModal from 'components/Modal/SideModal';
import { useTranslation } from 'react-i18next';
import ScrollDownMenu from 'components/ScrollDownMenu/ScrollDownMenu';
import { getHelpMenu } from 'utils/data/helpMenu.utils';
import Badge from '@mui/material/Badge';
import { notificationsQuery, notificationsService } from 'store/dashboard/notifications';
import { useObservable } from '@ngneat/react-rxjs';

interface AppBarHeaderProps {
  handleClick?: () => void;
}

const AppBarHeader: FC<AppBarHeaderProps> = (props: AppBarHeaderProps) => {
  const classes = useAppBarHeaderStyles();
  const isMobiles = isMobile();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const popConfirm = usePopup();
  const menuDropwDownItems = getHelpMenu();

  const [clientActive] = useObservable(organisationsQuery.activeOrganisation$);
  const [isMorgan] = useObservable(sessionQuery.isMorgan$);
  const [name] = useObservable(sessionQuery.name$);
  const [initials] = useObservable(sessionQuery.initials$);
  const [notificationsUnseen] = useObservable(notificationsQuery.numberNewNotifications$);
  const [isSideModalSettingsOpen, setIsSideModalSettingsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    sessionService.fetchMe().catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    notificationsService
      .fetchNotifications()
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  }, [enqueueSnackbar]);

  const handleLogout = () => {
    popConfirm({
      title: t('fe.appBar.logout.title'),
      description: t('fe.appBar.logout.description'),
      buttons: [
        {
          label: t('fe.appBar.logout.button'),
          color: 'error',
          onClick: sessionService.logout,
        },
      ],
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBarShift}>
      <Toolbar>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          direction={{ xs: 'column-reverse', lg: 'row' }}
          className={classes.reponsiveWidth}
        >
          <Grid item className={classes.reponsiveWidth}>
            {isMorgan !== undefined && (
              <Grid container item justifyContent={{ xs: 'space-between' }} alignItems="center">
                <AppBarDropdownOrganisation />
                {clientActive && (
                  <>
                    <img src={iconSrc.spacer} className={classes.divider} />
                    <AppBarDropdownOrgUnit />
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.reponsiveWidth}>
            <Grid
              container
              item
              justifyContent={{ xs: 'space-between', lg: 'end' }}
              alignItems="center"
              columnSpacing={{ xs: 0, lg: 1 }}
            >
              <Grid item className={classes.hideForDesktop}>
                <IconButton onClick={props.handleClick}>
                  <IconBurger />
                </IconButton>
              </Grid>
              <Stack direction="row" alignItems="center" spacing={2} p={1}>
                <Grid
                  sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}
                  item
                  className={classes.iconContainer}
                >
                  <Alert sx={{ fontSize: '12px' }} variant="outlined" severity="info" icon={false}>
                    {t('fe.appBar.supportMessage')}
                    <br />
                    <Link href="mailto:support@metawaste.com">
                      <strong>support@metawaste.com</strong>
                    </Link>{' '}
                    /{' '}
                    <Link href="tel:0264704561">
                      <strong>026 470 45 61</strong>
                    </Link>
                  </Alert>
                </Grid>
                <Stack direction="row" alignItems="center">
                  <Grid item>
                    <ScrollDownMenu mainIcon={<IconFaq />} items={menuDropwDownItems} />
                  </Grid>
                  <Grid item className={classes.iconContainer}>
                    <Tooltip title={t('fe.appBar.tooltips.settings') ?? ''}>
                      <IconButton onClick={() => setIsSideModalSettingsOpen(true)}>
                        <IconSettings />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.iconContainer}>
                    <Tooltip title={t('fe.appBar.tooltips.notifications') ?? ''}>
                      <IconButton onClick={() => setIsModalOpen(true)}>
                        <Badge
                          badgeContent={notificationsUnseen}
                          variant="standard"
                          color="warning"
                        >
                          <IconNotification />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.buttonContainer}
                      startIcon={<AvatarComponent isBig={!isMobiles} initials={initials} />}
                      size="small"
                      aria-describedby="id"
                      data-cy={Cypress.HeaderAccountDropdown}
                      onClick={handleLogout}
                    >
                      <span className={classes.hideForMobile}>{name}</span>
                    </Button>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {isSideModalSettingsOpen && (
            <SettingsScreen handleClickCloseModal={() => setIsSideModalSettingsOpen(false)} />
          )}
          {isModalOpen && (
            <SideModal handleClickCloseModal={() => setIsModalOpen(false)}>
              <NotificationModal />
            </SideModal>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
