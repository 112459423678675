import React, { FC } from 'react';
import BottomModal from 'components/Modal/BottomModal/BottomModal';
import { Box, Checkbox, MenuItem } from '@mui/material';

interface DataItem {
  label: string;
  value: any;
}

interface FilterOperationsProps {
  handleClickCloseModal?: () => void;
  handleChange?: (itemsSelected: any[]) => void;
  dataFilter?: DataItem[];
  selectedValues?: any[];
  title?: string;
}

const FilterOperationsScreen: FC<FilterOperationsProps> = (props) => {
  const { handleClickCloseModal, handleChange, dataFilter, selectedValues, title } = props;

  const handleChangeIntern = (value: string) => {
    if (value === '') {
      handleChange?.([]);
    } else if (selectedValues?.includes(value)) {
      handleChange?.((selectedValues || []).filter((v) => v !== value));
    } else {
      handleChange?.((selectedValues || []).concat(value));
    }
  };

  return (
    <BottomModal handleClickCloseModal={handleClickCloseModal} title={title}>
      <Box sx={{ width: '100%' }}>
        {dataFilter?.map((item) => (
          <MenuItem
            key={`${item.label} ${item.value}`}
            value={item.value}
            onClick={() => handleChangeIntern(item.value)}
          >
            <Checkbox color="primary" size="small" checked={selectedValues?.includes(item.value)} />
            <div>{item.label}</div>
          </MenuItem>
        ))}
      </Box>
    </BottomModal>
  );
};

export default FilterOperationsScreen;
