import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Colors } from 'style/materialStylings';
import MaterialDatagrid from 'components/MaterialDatagrid';
import { useIsMobile } from 'utils/isMobile';
import { supplierContractsColumns } from './table.utils';
import { useContractStyles } from './Contracts.style';
import { contractsService } from 'store/contracts/contracts.service';
import SnackError from 'utils/models/snackError';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SupplierOrganisationUnitLight } from 'store/organisation/organisationUnits';

interface ContractDetailsScreenParams {
  id: string;
}

function ContractDetailDetails() {
  const { id } = useParams<ContractDetailsScreenParams>();
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = useIsMobile();
  const classes = useContractStyles();
  const [suppliers, setSuppliers] = useState<SupplierOrganisationUnitLight[] | undefined>();

  useEffect(() => {
    contractsService
      .getSupplierByContract(id)
      .then((data) => setSuppliers(data))
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  }, [enqueueSnackbar, id]);

  return (
    <Stack direction="column" spacing={2} height="auto" width="auto">
      <Stack spacing={1} direction="column" bgcolor={Colors.white} py={2}>
        <Typography fontSize="16px" fontWeight="700" ml={4}>
          Prestataire(s)
        </Typography>
        <Grid item xs={12} style={{ height: '40vh' }}>
          <Card className={classes.cardContainer}>
            <CardContent>
              <MaterialDatagrid
                data={suppliers ?? []}
                columns={supplierContractsColumns({
                  isDesktop: !isMobiles,
                })}
                hideFooter
              />
            </CardContent>
          </Card>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ContractDetailDetails;
