import React from 'react';
import { useSnackbar } from 'notistack';
import {
  createExecutionQuery,
  createExecutionService,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import SnackError from 'utils/models/snackError';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

export interface MarkAsCompleteButtonComponentProps {
  executions: ExecutionDetailsUi[];
}

const MarkAsCompleteButtonComponent = (props: MarkAsCompleteButtonComponentProps) => {
  const { executions } = props;
  const [canValidateMarkAsComplete] = useObservable(
    createExecutionQuery.canValidateMarkAsComplete$
  );
  const [hasStartedMarkAsComplete] = useObservable(createExecutionQuery.hasStartedMarkAsComplete$);
  const [isSavingMarkAsComplete] = useObservable(createExecutionQuery.isSavingMarkAsComplete$);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleMarkAsComplete = () => {
    if (hasStartedMarkAsComplete) {
      createExecutionService
        .validateMarkAsComplete(executions)
        .then(() => {
          enqueueSnackbar(t('fe.executionModal.buttons.successCompleted'), { variant: 'success' });
        })
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    } else {
      createExecutionService.startMarkAsComplete();
    }
  };

  return (
    <LoadingButton
      variant="contained"
      size="large"
      onClick={handleMarkAsComplete}
      loading={isSavingMarkAsComplete}
      disabled={!canValidateMarkAsComplete}
    >
      {hasStartedMarkAsComplete
        ? t('fe.executionModal.buttons.complete')
        : t('fe.executionModal.buttons.markAsComplete')}
    </LoadingButton>
  );
};

export default MarkAsCompleteButtonComponent;
