export interface UserPreferencesDto {
  emailOnExecutionDeleted: boolean;
  emailOnExecutionToBeTreated: boolean;
  emailOnExecutionAccepted: boolean;
  emailOnExecutionDenied: boolean;
  emailOnExecutionEnded: boolean;
  emailOnExecutionNewComment: boolean;
  emailOnExecutionNewIssue: boolean;
  emailOnExecutionIssueClosed: boolean;
  emailOnExecutionUpdated: boolean;
  emailOnExecutionIssueUpdated: boolean;
}

export const initUserPreferencesDto = (): UserPreferencesDto => ({
  emailOnExecutionDeleted: true,
  emailOnExecutionToBeTreated: true,
  emailOnExecutionAccepted: true,
  emailOnExecutionDenied: true,
  emailOnExecutionEnded: true,
  emailOnExecutionNewComment: true,
  emailOnExecutionNewIssue: true,
  emailOnExecutionIssueClosed: true,
  emailOnExecutionUpdated: true,
  emailOnExecutionIssueUpdated: true,
});

export const convertUserPreferencesDtoToArray = (
  data: UserPreferencesDto
): { name: string; value: boolean }[] => {
  return [
    {
      name: 'emailOnExecutionToBeTreated',
      value: data.emailOnExecutionToBeTreated,
    },
    {
      name: 'emailOnExecutionAccepted',
      value: data.emailOnExecutionAccepted,
    },
    {
      name: 'emailOnExecutionDenied',
      value: data.emailOnExecutionDenied,
    },
    {
      name: 'emailOnExecutionEnded',
      value: data.emailOnExecutionEnded,
    },
    {
      name: 'emailOnExecutionUpdated',
      value: data.emailOnExecutionUpdated,
    },
    {
      name: 'emailOnExecutionDeleted',
      value: data.emailOnExecutionDeleted,
    },
    {
      name: 'emailOnExecutionNewComment',
      value: data.emailOnExecutionNewComment,
    },
    {
      name: 'emailOnExecutionNewIssue',
      value: data.emailOnExecutionNewIssue,
    },
    {
      name: 'emailOnExecutionIssueClosed',
      value: data.emailOnExecutionIssueClosed,
    },
    {
      name: 'emailOnExecutionIssueUpdated',
      value: data.emailOnExecutionIssueUpdated,
    },
  ];
};
