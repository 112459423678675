import i18n from '../data/i18n.utils';

export enum Language {
  EN = 'en',
  FR = 'fr',
  DE = 'de',
  IT = 'it',
}

export const languageTabs: string[] = [Language.EN, Language.FR, Language.DE, Language.IT];

export const translateLanguage = (lng: string) => i18n.t(`database.language.${lng}`);

export const languageTabsSelect = () =>
  languageTabs.map((it) => ({
    label: translateLanguage(it),
    value: it,
  }));
