import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: '28px !important',
      minHeight: '28px !important',
      width: '200px !important',
      '& > .MuiFilledInput-root': {
        height: '28px !important',
      },
      '& > .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
        borderRadius: '4px !important',
      },
    },
  });
export default classes;
