import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    CardContainer: {
      minWidth: '100% !important',
      minHeight: 160,
      backgroundColor: theme.palette.neutral.main + '!important',
    },
    header: {
      width: '100% !important',
      fontWeight: 500 + '!important',
    },
    data: {
      display: 'flex',
    },
    title: {
      fontSize: '35px !important',
      fontWeight: 700 + '!important',
      marginTop: '10px !important',
    },
    subTitle: {
      fontSize: '13px !important',
      fontWeight: 540 + '!important',
      color: theme.palette.text.secondary,
    },
    devise: {
      marginTop: 'auto',
      marginBottom: 'auto',
      fontSize: '14px !important',
      fontWeight: 540 + '!important',
      color: theme.palette.text.secondary,
      marginLeft: '10px !important',
    },
    selected: {
      width: '100%',
      minHeight: 160,
      backgroundColor: theme.palette.neutral.main + '!important',
      borderBottom: `5px solid ${theme.palette.primary.main} !important`,
    },
  });

export default classes;
