import React, { FC } from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import { useSideModalStyles } from './SideModal.style';
import MaterialTag from 'components/MaterialDatagrid/Rows/MaterialTag';

interface SideModalProps {
  handleClickCloseModal?: () => void;
  // eslint-disable-next-line no-undef
  children?: JSX.Element | JSX.Element[];
  status?: string;
}

const SideModal: FC<SideModalProps> = (props) => {
  const { handleClickCloseModal, status, children } = props;
  const classes = useSideModalStyles();

  return (
    <Dialog
      open={true}
      onClose={handleClickCloseModal}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper, root: classes.root, container: classes.container }}
    >
      <DialogContent sx={{ position: 'relative' }}>
        <Grid container alignItems="center" className={classes.iconPosition}>
          {status ? <MaterialTag size="big" noWidthLimit title={status} /> : <div />}
          <IconButton onClick={handleClickCloseModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default SideModal;
