import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/icons/addDocument.svg';
import CommentRow from 'components/CommentRow';
import { sessionQuery } from 'store/session';
import { commentExecutionQuery } from 'store/operations/executionComment/execution-comment-query';
import { commentExecutionServices } from 'store/operations/executionComment/execution-comment.service';
import { useSnackbar } from 'notistack';
import SnackError from 'utils/models/snackError';
import { useTranslation } from 'react-i18next';
import { formatCalendar, formatTime } from 'utils/dates/format';
import { useObservable } from '@ngneat/react-rxjs';
import { ExecutionDetailsUi } from 'store/operations/createExecution';

interface ExecutionCommentsFormProps {
  execution: ExecutionDetailsUi;
  updateExecutionField: (name: string) => (value: any) => void;
}

const ExecutionCommentsForm: FC<ExecutionCommentsFormProps> = (props) => {
  const { execution, updateExecutionField } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isCreating, setIsCreating] = useState(false);
  const [showAddComment, setShowAddComment] = useState(false);
  const [commentBody, setCommentBody] = useState('');
  const [userId] = useObservable(sessionQuery.userId$);
  const [comsTab] = useObservable(commentExecutionQuery.getSpecificComment$(execution.id));
  const { t } = useTranslation();

  useEffect(() => {
    if (!execution.dto?.status) {
      return;
    }
    commentExecutionServices.fetchComment(execution.id);
  }, []);

  const commentRequest = useCallback(async () => {
    if (commentBody === '') {
      enqueueSnackbar(t('fe.executionModal.executionCommentForm.errorCommentCreateRequest'), {
        variant: 'error',
      });
      return;
    }
    setIsCreating(true);
    try {
      if (!execution.dto?.status) {
        commentExecutionServices.pushComment(commentBody, execution.id, userId);
        updateExecutionField('comments')([...comsTab.map((com) => com.content), commentBody]);
        setIsCreating(false);
        setShowAddComment(false);
        setCommentBody('');
        return;
      }
      await commentExecutionServices.createComment(execution.id, commentBody);
      enqueueSnackbar(t('fe.executionModal.executionCommentForm.successCommentCreateRequest'), {
        variant: 'success',
      });
      setShowAddComment(false);
      setCommentBody('');
    } catch (e) {
      if (e instanceof SnackError) {
        enqueueSnackbar(e.text, e.options);
      }
    }
    setIsCreating(false);
  }, [execution.id, commentBody]);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            {t('fe.executionModal.executionCommentForm.commentsTitle')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="upload file"
            component="span"
            style={{ padding: '0px' }}
            onClick={() => {
              setShowAddComment(!showAddComment);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      {comsTab.map((comment) => (
        <Grid item xs={12} key={comment.id}>
          <CommentRow
            name={
              userId === comment.author?.id
                ? t('fe.executionModal.executionCommentForm.commentsUsername')
                : `${comment.author?.firstName} ${comment.author?.lastName} ${
                    comment.orgUnitAuthor ? `(${comment.orgUnitAuthor})` : ''
                  }`
            }
            comment={comment.content}
            date={formatCalendar(comment.createdAt)}
            dateTime={formatTime(comment.createdAt)}
            onLeft={!(userId === comment.author?.id)}
          />
        </Grid>
      ))}
      {showAddComment && (
        <>
          <Grid item xs={12} container justifyContent="flex-end" style={{ paddingTop: '20px' }}>
            <TextField
              variant="outlined"
              style={{ width: '85%' }}
              fullWidth
              multiline
              onChange={(e) => setCommentBody(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                paddingTop: '15px',
                flexDirection: 'row-reverse',
              }}
            >
              <Button disabled={isCreating} variant="basic" size="medium" onClick={commentRequest}>
                {t('fe.executionModal.executionCommentForm.commentsValidation')}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                style={{
                  marginRight: '12px',
                }}
                onClick={() => {
                  setShowAddComment(false);
                }}
              >
                {t('fe.executionModal.executionCommentForm.commentsCancel')}
              </Button>
            </div>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default ExecutionCommentsForm;
