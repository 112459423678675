import React from 'react';
import { Button, Grid } from '@mui/material';
import { createExecutionQuery, ExecutionDetailsUi } from 'store/operations/createExecution';
import MarkAsCompleteButtonComponent from './MarkAsCompleteButton.component';
import SaveExecutionButtonComponent from './SaveExecutionButton.component';
import AcceptExecutionButtonComponent from './AcceptExecutionButton.component';
import AddExecutionButton from './AddExecutionButton';
import { useObservable } from '@ngneat/react-rxjs';
import MorganNewTimeButtonComponent from './MorganNewTimeButton.component';
import CancelExecutionButtonComponent from './CancelExecutionButton.component';
import { t } from 'i18next';
import { BookmarkServiceDtoLight, bookmarkQuery, bookmarkService } from 'store/operations/bookmark';
import { useSnackbar } from 'notistack';
import AddExectionAsEventBtn from './AddExectionAsEventBtn';

interface ExecutionModalButtonsComponentProps {
  executions: ExecutionDetailsUi[];
}

const ExecutionModalButtonsComponent = (props: ExecutionModalButtonsComponentProps) => {
  const { executions } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [showAcceptButton] = useObservable(createExecutionQuery.showAcceptButton$);
  const [showDenyButton] = useObservable(createExecutionQuery.showDenyButton$);
  const [showAddBookmarkButton] = useObservable(createExecutionQuery.showAddBookmarkButton$);
  const [showMarkAsComplete] = useObservable(createExecutionQuery.showMarkAsComplete$);
  const [showSaveButton] = useObservable(createExecutionQuery.showSaveButton$);
  const [showMorganNewTimeButton] = useObservable(createExecutionQuery.showMorganNewTimeButton$());
  const [showCancelButton] = useObservable(createExecutionQuery.showCancelButton$());
  const [canAddExecution] = useObservable(createExecutionQuery.canAddExecution$);
  const [isBookmarkSideModalOpen] = useObservable(bookmarkQuery.isEditModalOpen$);

  const handleaAddBookmarks = () => {
    if (!executions[0].serviceId || !executions[0].nbContainers) {
      return;
    }

    const bookmark: BookmarkServiceDtoLight = {
      bookmarkServices: executions.map((it) => ({
        serviceId: it.serviceId as number,
        quantity: it.nbContainers,
      })),
    };

    bookmarkService.addBookmark(bookmark).finally(() => {
      enqueueSnackbar(t('fe.bookmarks.add.success'), { variant: 'success' });
    });
  };

  return (
    <>
      {/*{showDenyButton && (*/}
      {/*  <Grid item>*/}
      {/*    <DenyExecutionButtonComponent executions={executions} />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {executions.some((it) => it.plannedFrom) && showCancelButton && (
        <Grid item>
          <AddExectionAsEventBtn executions={executions} />
        </Grid>
      )}
      {canAddExecution && (
        <Grid item>
          <AddExecutionButton />
        </Grid>
      )}
      {showCancelButton && (
        <Grid item>
          <CancelExecutionButtonComponent executions={executions} />
        </Grid>
      )}
      {showAcceptButton && (
        <Grid item>
          <AcceptExecutionButtonComponent executions={executions} />
        </Grid>
      )}
      {showMarkAsComplete && (
        <Grid item>
          <MarkAsCompleteButtonComponent executions={executions} />
        </Grid>
      )}
      {showSaveButton && (
        <Grid item alignItems="center">
          {!isBookmarkSideModalOpen && showAddBookmarkButton && (
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: '15px' }}
              onClick={handleaAddBookmarks}
            >
              {t('fe.bookmarks.addButton')}
            </Button>
          )}
          <SaveExecutionButtonComponent executions={executions} />
        </Grid>
      )}
      {showMorganNewTimeButton && (
        <Grid item>
          <MorganNewTimeButtonComponent executions={executions} />
        </Grid>
      )}
    </>
  );
};

export default ExecutionModalButtonsComponent;
