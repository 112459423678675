import { metawasteApi } from 'api/Axios';
import { BookmarkStore, bookmarkStore } from './bookmark.store';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';
import { BookmarkServiceDtoLight } from './bookmarkModel';
import {
  OrganisationUnitsQuery,
  organisationUnitsQuery,
} from 'store/organisation/organisationUnits';
import { ID } from '@datorama/akita';

export class BookmarkService {
  constructor(private store: BookmarkStore, private orgUnitQuery: OrganisationUnitsQuery) {}

  resetStore = () => {
    this.store.reset();
  };

  closeModal = () => {
    this.store.isEditModalOpen(false);
    this.store.reset();
  };

  addBookmark = async (body: BookmarkServiceDtoLight) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.putBookmark(body));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
    this.store.update((state) => ({
      ...state,
      bookmarks: [...state.bookmarks, resp],
    }));

    return resp;
  };

  getBookmarks = async (organisationUnitIDs: ID[]) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getBookmarks(organisationUnitIDs));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }

    this.store.setBookmarks(resp);
    return resp;
  };

  deleteBookmark = async (bookmarkId: number) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.deleteBookmark(bookmarkId));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }

    await this.getBookmarks(this.orgUnitQuery.activeOrgUnitIds as number[]);

    return resp;
  };
}

export const bookmarkService = new BookmarkService(bookmarkStore, organisationUnitsQuery);
