import React from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { IconList } from 'utils/data/helpMenu.utils';
import { useTranslation } from 'react-i18next';

interface ScrollDownMenuProps {
  mainIcon: React.ReactNode;
  items?: IconList[];
}

const ScrollDownMenu = (props: ScrollDownMenuProps) => {
  const { mainIcon, items } = props;
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: 'popover', popupId: 'options' });
  return (
    <>
      <Tooltip title={t('fe.appBar.tooltips.faq') ?? ''}>
        <IconButton {...bindTrigger(popupState)}>{mainIcon}</IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{ style: { overflow: 'auto' } }}
        PaperProps={{
          style: { width: 300 },
        }}
      >
        {items?.map((item) => (
          <MenuItem key={item.id} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ScrollDownMenu;
