import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import { google, ics, office365, yahoo } from 'calendar-link';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutionDetailsUi } from 'store/operations/createExecution';

interface Props {
  executions: ExecutionDetailsUi[];
}

const typeArray = ['Google', 'Office365', 'Yahoo', 'Apple'] as const;
type Typelink = typeof typeArray[number];

const AddExectionAsEventBtn = (props: Props) => {
  const { executions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const selectCalendarLink = (calendar?: Typelink) => {
    switch (calendar) {
      case 'Google':
        return google;
      case 'Office365':
        return office365;
      case 'Yahoo':
        return yahoo;
      case 'Apple':
        return ics;
      default:
        return undefined;
    }
  };

  const handleAddToCalendar = (type?: Typelink) => {
    const event = {
      title: executions?.[0].dto?.name || '',
      description: executions
        .map((it) => `${it.dto?.service?.kind?.kind} - ${it.dto?.service?.waste?.name}`)
        .join('\n'),
      start: executions[0].plannedFrom,
      end: executions[0].plannedTo,
    };

    const url = selectCalendarLink?.(type)?.(event);

    if (!url) {
      setAnchorEl(null);
      return;
    }

    window.open(url, '_blank');
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        variant="outlined"
        disableElevation
        onClick={handleClick}
        size="large"
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('fe.executionModal.buttons.addToCalendar')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Stack width="200px">
          {typeArray.map((type) => (
            <MenuItem key={type} onClick={() => handleAddToCalendar(type)}>
              {type}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Stack>
  );
};

export default AddExectionAsEventBtn;
