import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDashboardPatrickStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '0px !important',
    padding: '25px 0px',
    [theme.breakpoints.only('xs')]: {
      padding: '0px 15px !important',
    },
  },
  stackContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  createButtonContainer: {
    padding: '11px 16px',
  },
  responsiveGridCard: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexWrap: 'nowrap !important',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0px 15px !important',
    },
  },
  responsiveWidthCard: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '80%',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '10px 15px !important',
    },
  },
  hideForDesktop: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  cardContainer: {
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      padding: '0px 5px !important',
      paddingTop: '5px !important',
    },
  },
  subTitle: {
    margin: '24px 0px 0px 15px !important',
  },
  GridCardContainer: {
    [theme.breakpoints.down('lg')]: {
      minHeight: '100% !important',
    },
  },
}));
