import React from 'react';

import { useMaterialTagStyles } from './GridRowDate.style';
import { Typography } from '@mui/material';
import { formatTime, formatUltraShort } from 'utils/dates/format';
import { useTranslation } from 'react-i18next';

interface GridRowDateProps {
  date?: Date | null;
  endTime?: Date | null;
}

const GridRowDate = (props: GridRowDateProps) => {
  const { date, endTime } = props;
  const classes = useMaterialTagStyles();
  const { t } = useTranslation();

  return (
    <div>
      {!date ? (
        <Typography className={classes.date}>{t('fe.asap')}</Typography>
      ) : (
        <>
          <Typography className={classes.date}>{formatUltraShort(date)}</Typography>
          <Typography className={classes.time}>
            {`${formatTime(date)} ${endTime ? ' - ' + formatTime(endTime) : ''}`}
          </Typography>
        </>
      )}
    </div>
  );
};

export default GridRowDate;
