import { InvoiceStore, invoiceStore } from './invoice.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import i18n from 'utils/data/i18n.utils';

export class InvoiceService {
  constructor(private store: InvoiceStore) {}

  //change value of search in store
  setSearch = (search: string) => {
    this.store.update((state) => ({
      ...state,
      search,
    }));
  };

  setStatus = (status: string) => {
    this.store.update((state) => ({
      ...state,
      status,
    }));
  };

  resetInvoices = () => {
    this.store.update((state) => ({
      ...state,
      invoices: [],
    }));
  };

  getInvoices = async (
    organisationId: ID,
    organisationUnitIDs: ID[] | undefined,
    page: number,
    rowPerPage: number,
    fromDate: Date | undefined,
    toDate: Date | undefined
  ) => {
    this.store.setLoading(true);
    const search = this.store.getValue().search;
    const status = this.store.getValue().status;

    const [resp, e] = await resolve(
      metawasteApi.getInvoices(
        organisationId,
        organisationUnitIDs,
        search,
        page + 1,
        rowPerPage,
        fromDate,
        toDate,
        status
      )
    );

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.invoices'));
    }

    this.store.update((state) => ({
      ...state,
      invoices: resp.data,
      total: resp.total,
    }));
    return resp.data;
  };

  downloadInvoice = async (invoiceId: ID) => {
    const [resp, e] = await resolve(metawasteApi.downloadInvoice(invoiceId));

    console.log('e', e);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.downloadInvoice'));
    }

    const blob = new Blob([resp as Blob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `invoice_${invoiceId}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
}

export const invoiceService = new InvoiceService(invoiceStore);
