import { useEffect } from 'react';
import { storageService } from 'utils/StorageService/StorageService';
import { LocalStorageKeyJson } from 'constants/LocalStorageKey';
import { useHistory, useLocation } from 'react-router-dom';
import { craftUrlParams } from 'utils/data/url.utils';

export const useHasQueryParamsInStorage = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const notifParams = storageService.getItemJson(LocalStorageKeyJson.NotificationQueryParams);
    if (notifParams?.executionId || notifParams?.issueId) {
      const queryParams = craftUrlParams(notifParams?.executionId, notifParams?.issueId);
      history.replace({ pathname: location.pathname, search: queryParams.toString() });
      storageService.removeItem(LocalStorageKeyJson.NotificationQueryParams);
    }
  }, []);
};
