import React, { FC, useMemo, useState } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { EvolutionInfosDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';
import { useTranslation } from 'react-i18next';
import { OpUnitType } from 'dayjs';
import { getPeriodLabels } from 'utils/data/barchart.utils';
import { useDebounce } from 'react-use';
import dayjs from 'utils/data/dayjs.utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface BarchartOperationsProps {
  currentData: EvolutionInfosDto[];
  oldData: EvolutionInfosDto[];
  dropDownDashboard: OpUnitType;
  currentDate: Date;
}

const BarchartOperations: FC<BarchartOperationsProps> = (props) => {
  const { currentData, oldData, dropDownDashboard, currentDate } = props;
  const { t } = useTranslation();

  const [labels, setLabels] = useState(getPeriodLabels(dropDownDashboard, currentDate));
  useDebounce(() => setLabels(getPeriodLabels(dropDownDashboard, currentDate)), 50, [
    t,
    dropDownDashboard,
  ]);
  const getMaxValue = () => {
    return Math.max(Math.max(...currentData.map((data) => data.execCount + data.issueCount))) + 2;
  };
  const { vCurrentData, vOldData } = useMemo(() => {
    if (currentData.length === 0) {
      return { vCurrentData: [], vOldData: [] };
    }
    const halfLength = Math.trunc(currentData.length / 2);
    const start = dayjs(currentData[halfLength].date).startOf(dropDownDashboard);
    const end = dayjs(currentData[halfLength].date).endOf(dropDownDashboard);
    return {
      vCurrentData: currentData.filter((it) => dayjs(it.date).isBetween(start, end, null, '[)')),
      vOldData: [],
    };
  }, [currentData, oldData, dropDownDashboard]);

  return (
    <div style={{ height: '100%', minWidth: '100% !important', margin: '30px 0px' }}>
      <Bar
        height="100%"
        options={{
          interaction: {
            mode: 'index',
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          scales: {
            xAxis: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                color: 'black',
                font: {
                  size: 12,
                },
              },
            },
            yAxis: {
              min: 0,
              max: getMaxValue(),
              beginAtZero: true,
              ticks: {
                color: 'black',
              },
              stacked: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              data: vCurrentData.map((data) => data.issueCount),
              barPercentage: 0.7,
              borderRadius: 100,
              categoryPercentage: 0.4,
              backgroundColor: 'rgba(230, 67, 60)',
              stack: 'Stack 0',
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
            {
              data: vCurrentData.map((data) => data.execCount),
              barPercentage: 0.7,
              borderRadius: 100,
              categoryPercentage: 0.4,
              backgroundColor: '#172134',
              stack: 'Stack 0',
              xAxisID: 'xAxis',
              yAxisID: 'yAxis',
            },
          ],
        }}
      />
    </div>
  );
};

export default BarchartOperations;
