import { useEffect, useState } from 'react';
import { executionIssueService } from '../execution-issue.service';
import { executionIssueQuery } from '../execution-issue.query';
import { IssueDetailsUi } from '../execution-issue.model';
import { ID } from '@datorama/akita';
import { useSnackbar } from 'notistack';
import SnackError from 'utils/models/snackError';

export const useIssueForExecution = (executionId: ID) => {
  const { enqueueSnackbar } = useSnackbar();

  const [issues, setIssues] = useState<IssueDetailsUi[]>([]);

  useEffect(() => {
    executionIssueService
      .fetchIssues(executionId)
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  }, []);

  useEffect(() => {
    const _getIssue = executionIssueQuery.getIssuesFromExecution$(executionId).subscribe(setIssues);
    return () => _getIssue.unsubscribe();
  }, [executionId]);

  return { issues };
};
