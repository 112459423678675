import { TextField } from '@mui/material';
import React, { FC } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimePicker } from '@mui/x-date-pickers-pro';
import { Colors } from 'style/materialStylings';
import dayjs from 'utils/data/dayjs.utils';
import { useTranslation } from 'react-i18next';

interface MaterialTimeTextfieldBaseProps {
  value?: Date | null;
  handleChange?: (value: Date | null) => void;
  color?: 'primary' | 'secondary';
  readOnly?: boolean;
  customStyle?: React.CSSProperties;
}

const MaterialTimeTextfield: FC<MaterialTimeTextfieldBaseProps> = (
  props: MaterialTimeTextfieldBaseProps
) => {
  const { value, handleChange, color, readOnly, customStyle } = props;
  const { t } = useTranslation();

  const accessTimeColor = () => {
    if (value) {
      return dayjs(value).isValid() ? Colors.secondary : Colors.red;
    }
    return Colors.darkGray;
  };

  return (
    <TimePicker
      value={value}
      onChange={(newValue: Date | null) => handleChange?.(newValue)}
      readOnly={readOnly}
      disabled={readOnly}
      ampm={false}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          hiddenLabel
          style={customStyle}
          fullWidth={true}
          color={color}
          InputProps={{
            ...params.InputProps,
            startAdornment: <AccessTimeIcon htmlColor={accessTimeColor()} fontSize="small" />,
            endAdornment: null,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default MaterialTimeTextfield;
