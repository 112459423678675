import React, { FC } from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNonConformityItemStyles } from './NonConformityItem.style';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';

interface NonConformityItemProps {
  title?: string;
  subtitle?: string;
  handleClick?: () => void;
}

const NonConformityItem: FC<NonConformityItemProps> = (props) => {
  const { title, subtitle, handleClick } = props;
  const classes = useNonConformityItemStyles();

  return (
    <Card component={CardActionArea} onClick={handleClick} className={classes.boxContainer}>
      <CardContent style={{ flexDirection: 'row' }}>
        <Warning className={classes.logo} />
        <div className={classes.positionTypography}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography color="secondary.dark" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default NonConformityItem;
