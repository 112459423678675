import i18n from '../data/i18n.utils';

export enum TypeNotifications {
  EXECUTION_TO_BE_TREATED = 'EXECUTION_TO_BE_TREATED',
  EXECUTION_ACCEPTED = 'EXECUTION_ACCEPTED',
  EXECUTION_DENIED = 'EXECUTION_DENIED',
  EXECUTION_ENDED = 'EXECUTION_ENDED',
  EXECUTION_NEW_COMMENT = 'EXECUTION_NEW_COMMENT',
  EXECUTION_NEW_ISSUE = 'EXECUTION_NEW_ISSUE',
  EXECUTION_ISSUE_CLOSED = 'EXECUTION_ISSUE_CLOSED',
  EXECUTION_UPDATED = 'EXECUTION_UPDATED',
  EXECUTION_ISSUE_UPDATED = 'EXECUTION_ISSUE_UPDATED',
  EXECUTION_DELETED = 'EXECUTION_DELETED',
}

export const translateNotificationsStatus = (status: string, signature?: string) =>
  i18n.t(`database.notificationStatus.${status}`, { signature: signature });
