import i18n from 'utils/data/i18n.utils';

export const formatWeight = (weightInTons: number): string => {
  if (weightInTons < 1) {
    return (weightInTons * 1000).toFixed(1);
  }
  return weightInTons.toFixed(1);
};

export const formatWeightUnit = (weightInTons: number): string => {
  if (weightInTons < 1) {
    return i18n.t('database.units.kg');
  }
  return i18n.t('database.units.tons');
};
