import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { combineLatest, Subject, timer } from 'rxjs';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { debounce } from 'rxjs/operators';
import SnackError from 'utils/models/snackError';
import { useObservable } from '@ngneat/react-rxjs';
import { patrickStatsQuery } from '../patrick-stats.query';
import { patrickStatsService } from '../patrick-stats.service';

export const usePatrickStats = (from: Date, to: Date) => {
  const { enqueueSnackbar } = useSnackbar();
  const from$ = useRef(new Subject<Date>()).current;
  const to$ = useRef(new Subject<Date>()).current;
  const [isIssueCountStatusLoading] = useObservable(patrickStatsQuery.isIssueCountStatusLoading$);
  const [issueCountStatus] = useObservable(patrickStatsQuery.getIssueCountStatus$);
  useEffect(() => {
    const _queryParams = combineLatest([
      from$,
      to$,
      organisationsQuery.activeOrganisationId$,
      organisationUnitsQuery.activeOrgUnitIds$,
    ])
      .pipe(debounce(() => timer(500)))
      .subscribe(([from, to, organisationId, orgUnitIds]) => {
        if (organisationId) {
          patrickStatsService
            .fetchstats(from, to, organisationId, orgUnitIds)
            .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
        }
      });
  }, []);

  useEffect(() => from$.next(from), [from]);

  useEffect(() => to$.next(to), [to]);

  return {
    isIssueCountStatusLoading,
    issueCountStatus,
  };
};
