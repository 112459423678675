import React from 'react';
import { Checkbox, MenuItem, Select } from '@mui/material';

import { useMaterialMultiDropdownBaseStyles } from './MaterialMultiDropdownBase.style';
import { ArrowDropDown } from '@mui/icons-material';

interface MaterialDropdownItem {
  label: string;
  value: any;
}

export interface MaterialMultiDropdownBaseProps {
  color?: 'primary' | 'secondary';
  label?: string;
  name?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: any;
  error?: string;
  selectedValues?: any[];
  items?: MaterialDropdownItem[];
  handleChange?: (itemsSelected: any[]) => void;
  renderValue?: (value: any) => React.ReactNode;
  height?: number;
  className?: string;
}

const MaterialMultiDropdownBase = (props: MaterialMultiDropdownBaseProps) => {
  const {
    color = 'primary',
    name,
    fullWidth = true,
    handleChange,
    required,
    disabled,
    placeholder,
    readOnly,
    defaultValue,
    error,
    selectedValues,
    items,
    renderValue,
    height,
    className,
  } = props;
  const classes = useMaterialMultiDropdownBaseStyles();
  return (
    <Select
      className={classes.container + ' ' + className}
      variant="filled"
      color={color}
      style={{
        height,
      }}
      classes={{
        filled: !defaultValue && !selectedValues?.length ? classes.placeholder : undefined,
      }}
      IconComponent={() => (readOnly ? <div /> : <ArrowDropDown />)}
      multiple
      displayEmpty
      onChange={(evt) => handleChange?.(evt.target.value)}
      fullWidth={fullWidth}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      name={name}
      readOnly={readOnly}
      error={!!error}
      value={selectedValues}
      disableUnderline
      renderValue={renderValue}
    >
      {(placeholder || defaultValue) && (
        <MenuItem
          value=""
          disabled
          className={!defaultValue ? classes.placeholder : undefined}
          onClick={() => handleChange?.([])}
        >
          {defaultValue || placeholder || 'None'}
        </MenuItem>
      )}
      {items?.map((item) => (
        <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
          <Checkbox color="primary" size="small" checked={selectedValues?.includes(item.value)} />
          <div>{item.label}</div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default MaterialMultiDropdownBase;
