import { GridColDef } from '@mui/x-data-grid-pro';
import { ColumnProps } from '../columnTypes.table';

import { Array } from 'utils/extensions/array.extensions';

export const COLUMN_TYPE_TEXT_BOLD = (props: ColumnProps): GridColDef => ({
  ...COLUMN_TYPE_TEXT(props),
  cellClassName: `bold ${props.color}`,
});

export const COLUMN_TYPE_TEXT = (props: ColumnProps): GridColDef => ({
  cellClassName: (params) =>
    Array.mapNotNull([props.color, props.cellClassName?.(params)])?.join(' ') ?? '',
  field: props.field ?? '',
  flex: props.fullWidth ? 1 : undefined,
  headerName: props.label,
  editable: props.editable ?? true,
  renderEditCell: (params) => params.formattedValue,
  minWidth: props.minWidth ?? 50,
  sortable: props.sortable ?? false,
  type: 'string',
  valueGetter: props.valueGetter,
  valueFormatter: props.valueFormatter,
  width: props.width ?? 150,
  renderCell: props.renderCell,
});
