import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    operationsContainer: {
      padding: '16px 10px !important',
      maxHeight: 30,
      justifyContent: 'center',
      position: 'absolute',
      right: 10,
      top: 10,
    },
    rankingContainer: {
      padding: '20px 3px !important',
      maxHeight: 10,
      justifyContent: 'center',
      display: 'flex !important',
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
      marginTop: '15px !important',
      marginBottom: '7px !important',
    },
    statisticContainer: {
      padding: '15px 5px !important',
      maxHeight: 10,
      justifyContent: 'center',
      display: 'flex !important',
    },
    icon: {
      marginRight: '5px !important',
    },
    police: {
      fontSize: '14px !important',
      fontWeight: 700 + '!important',
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
  });

export default classes;
