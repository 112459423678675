import { InputLabel, List, ListItemButton, ListItemText, TextField } from '@mui/material';
import React, { FC, useEffect } from 'react';
import style from './MaterialAutocomplete.style';
import { WithStyles, withStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { AutocompleteData } from 'utils/models/autocomplete-data';

interface MaterialAutocompleteProps extends WithStyles<typeof style> {
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  color?: 'primary' | 'secondary';
  error?: string;
  handleChange?: (value: string) => void;
  placeholder?: string;
  value: string;
  data: AutocompleteData[];
}

const MaterialAutocomplete: FC<MaterialAutocompleteProps> = (props: MaterialAutocompleteProps) => {
  const {
    classes,
    label,
    required,
    readOnly,
    color = 'primary',
    fullWidth = true,
    error,
    handleChange,
    placeholder,
    value,
    data,
  } = props;

  const popupState = usePopupState({ variant: 'popover', popupId: 'autocompleteTextfield' });
  const searchInput = React.useRef(null);

  useEffect(() => {
    if (searchInput.current) {
      popupState.setAnchorEl(searchInput.current);
    }
  }, [searchInput.current]);

  return (
    <div className={classes.boxContainer}>
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <TextField
        variant="filled"
        className={classes.textfield}
        color={color}
        fullWidth
        {...bindHover(popupState)}
        hiddenLabel
        focused={readOnly ? false : undefined}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: readOnly,
          disableUnderline: true,
          className: value?.length === 0 ? classes.placeholder : classes.filledInput,
          endAdornment: (
            <ArrowDropDown
              style={{ transform: popupState.isOpen ? 'rotate(180deg)' : undefined }}
            />
          ),
        }}
        inputRef={searchInput}
        placeholder={placeholder}
        error={!!error}
        helperText={error}
        onChange={(evt) => {
          handleChange?.(evt.target.value);
        }}
        value={value}
      />
      {data.length > 0 && (
        <HoverMenu
          {...bindMenu(popupState)}
          MenuListProps={{ style: { overflow: 'auto' } }}
          PaperProps={{
            style: { minWidth: popupState.anchorEl?.offsetWidth, maxHeight: '300px' },
          }}
        >
          <List dense={true}>
            {data.map((item) => (
              <ListItemButton
                key={item.id.toString()}
                onClick={() => {
                  handleChange?.(item.title);
                  popupState.close();
                }}
              >
                <ListItemText primary={item.title} secondary={item.description} />
              </ListItemButton>
            ))}
          </List>
        </HoverMenu>
      )}
    </div>
  );
};

export default withStyles(style)(MaterialAutocomplete);
