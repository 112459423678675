import { ID } from '@datorama/akita';
import { metawasteApi } from 'api/Axios';
import i18n from 'utils/data/i18n.utils';
import { resolve } from 'utils/data/promise-utils';
import { handleBasicSnackError } from 'utils/models/snackError';
import { AuditOfferSupplierDto } from './auditModel';
import { AuditStore, auditStore } from './audit.store';

export class AuditProposalSupplierService {
  constructor(private store: AuditStore) {}

  getAuditProposalSupplier = async (id: ID) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getAuditOfferSupplier(id));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }

    this.store.setAuditProposalSupplier(resp);
    return resp;
  };

  getAuditInformationsByAuditProposalSupplierId = async (id: ID) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getAuditInformationsByAuditProposalSupplierId(id));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
    return resp;
  };

  patchAuditProposalSupplier = async (id: ID, data: AuditOfferSupplierDto) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.patchAuditProposalSupplier(id, data));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
  };

  getContainerDetailsForAuditOffer = async (id: ID) => {
    this.store.setLoading(true);

    const [resp, e] = await resolve(metawasteApi.getContainerDetailsForAuditOffer(id));

    this.store.setLoading(false);

    if (e || !resp) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.client'));
    }
    return resp;
  };
}

export const auditProposalSupplierService = new AuditProposalSupplierService(auditStore);
