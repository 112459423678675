import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: theme.palette.text.primary,
      fontWeight: 400,
      opacity: 0.5,
    },
    imageIcon: {
      height: '100%',
      width: '100%',
    },
    iconRoot: {
      textAlign: 'center',
    },
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    textfield: {
      width: '80% !important',
      maxWidth: '607px !important',
      [theme.breakpoints.only('xs')]: {
        width: '100% !important',
        maxWidth: '85vw !important',
      },
    },
  });
export default classes;
