import { ID } from '@datorama/akita';
import { Box } from '@mui/material';
import { ReactComponent as IconBuilding } from 'assets/icons/rework/icon_building.svg';
import AppBarDropdown from 'components/AppBar/AppBarDropdown/AppBarDropdown';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteData } from 'utils/models/autocomplete-data';

const fakeOrganisations: AutocompleteData[] = Array.from({ length: 5 }, (_, i) => ({
  id: i.toString(),
  title: `Organisation ${i}`,
  description: `description orga ${i}`,
}));

const ObAppBarDropdownOrganisation: FC = () => {
  const [searchString, setSearchString] = useState<string>('');
  const [forganisation, setfOrganisation] = useState<AutocompleteData | undefined>(
    fakeOrganisations[0]
  );
  const { t } = useTranslation();

  const filteredFakeOrganisations = fakeOrganisations.filter((it) =>
    it.title.toLowerCase().includes(searchString.toLowerCase())
  );

  const handleSelectOrganisation = (value?: ID) => {
    const el = filteredFakeOrganisations.find((it) => it.id === value);

    if (el) {
      setfOrganisation(el);
    } else {
      setfOrganisation(undefined);
    }
  };

  return (
    <Box className="step1">
      <AppBarDropdown
        icon={<IconBuilding />}
        handleChange={handleSelectOrganisation}
        value={forganisation}
        data={filteredFakeOrganisations}
        placeholder={t('fe.appBar.clientPlaceholder')}
        handleSearchChange={setSearchString}
      />
    </Box>
  );
};

export default ObAppBarDropdownOrganisation;
