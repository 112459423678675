import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MaterialCheckBox from 'components/MaterialCheckBox/MaterialCheckBox';
import {
  convertUserPreferencesDtoToArray,
  initUserPreferencesDto,
  UserPreferencesDto,
} from 'api/dto/user/UserPreferencesDto';
import { sessionQuery, sessionService } from 'store/session';

const SettingsNotificationsComponent = () => {
  const { t } = useTranslation();

  const [userPreferences, setUserPreferences] = useState<UserPreferencesDto>(
    sessionQuery.userPreferences() ?? initUserPreferencesDto()
  );

  const prefsArray = useMemo(() => convertUserPreferencesDtoToArray(userPreferences), [
    userPreferences,
  ]);

  const updatePrefs = (name: string) => (value: any) => {
    const newPrefs = { ...userPreferences, [name]: value };
    setUserPreferences(newPrefs);
    sessionService.userUpdatePreferences(newPrefs);
  };

  return (
    <Grid item container xs={12} rowSpacing={0}>
      <Grid item>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          {t('fe.settings.subtitle.emailPreferences')}
        </Typography>
      </Grid>
      {prefsArray.map((pref) => (
        <Grid key={pref.name} item xs={12}>
          <MaterialCheckBox
            handleChange={updatePrefs(pref.name)}
            label={t(`fe.settings.emailPreferences.${pref.name}`)}
            value={pref.value}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SettingsNotificationsComponent;
