import React, { FC, useCallback, useEffect, useState } from 'react';
import { Divider, Fade, Grid, IconButton, Modal, Skeleton, Stack, Typography } from '@mui/material';
import {
  ExecutionDocument,
  executionDocumentsQuery,
  executionDocumentsService,
} from 'store/operations/executionDocuments';
import { ReactComponent as AddIcon } from 'assets/icons/addDocument.svg';
import { useSnackbar } from 'notistack';
import FileItem from 'components/FileItem';
import { useTranslation } from 'react-i18next';
import { ExecutionDetailsUi } from 'store/operations/createExecution';
import { ID } from '@datorama/akita';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { makeStyles } from '@mui/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { Close, Download } from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface ExecutionDocumentsFormProps {
  execution: ExecutionDetailsUi;
}

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundcolor: 'red',
    },
  },
  img: {
    outline: 'none',
  },
  pdf: {
    height: '50vh',
    color: 'white',
  },
}));

const ExecutionDocumentsForm: FC<ExecutionDocumentsFormProps> = (props) => {
  const { execution } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<ExecutionDocument[]>([]);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const popConfirm = usePopup();
  const [activeDocument, setActiveDocument] = useState<ExecutionDocument | undefined>(undefined);
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach((layer: any) => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
    setNumPages(numPages);
  }

  const openDocumentModal = Boolean(activeDocument);

  useEffect(() => {
    executionDocumentsService.populateDocumentsFromExecution(execution);
    const _documents = executionDocumentsQuery
      .documentsFromExecution$(execution.id)
      .subscribe(setDocuments);
    return () => {
      _documents.unsubscribe();
    };
  }, [enqueueSnackbar]);

  const uploadNewFile = useCallback(
    async (file?: File) => {
      if (file) {
        setIsUploadingDocument(true);
        try {
          await executionDocumentsService.uploadDocument(file, execution.id);
        } catch (e: any) {
          enqueueSnackbar(e.text, e.options);
        }
        setIsUploadingDocument(false);
      }
    },
    [execution, enqueueSnackbar, setIsUploadingDocument]
  );

  const showDocument = (document: ExecutionDocument) => {
    executionDocumentsService.fetchDocument(document);
  };

  const removeDocument = (documentId: ID) => {
    popConfirm({
      title: t('fe.executionModal.deleteDocument.title'),
      description: t('fe.executionModal.deleteDocument.description'),
      buttons: [
        {
          label: t('fe.cancel'),
          color: 'error',
          variant: 'outlined',
          onClick: () => undefined,
        },
        {
          label: t('fe.executionModal.deleteDocument.action'),
          color: 'error',
          onClick: () => {
            executionDocumentsService.removeDocument(documentId);
            enqueueSnackbar(t('fe.executionModal.deleteDocument.success'), { variant: 'success' });
          },
        },
      ],
    });
  };

  const displayDocument = (document: ExecutionDocument | undefined) => {
    if (document?.dto?.mimetype.includes('image/')) {
      return (
        <img
          src={executionDocumentsService.getDocumentUrl(document)}
          alt={document.dto?.name}
          style={{ maxWidth: '90%', maxHeight: '90%' }}
        />
      );
    } else if (document?.dto?.mimetype.includes('pdf')) {
      return (
        <Stack>
          <Document
            className={classes.pdf}
            renderMode="svg"
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => console.error(e)}
            file={executionDocumentsService.getDocumentUrl(document)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </Stack>
      );
    } else {
      return (
        <Typography sx={{ color: 'white' }}>
          {document?.dto?.filename || document?.tmpFile?.name || 'Failed to load'}
        </Typography>
      );
    }
  };

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            {t('fe.executionModal.executionInfosForm.documentTitle')}
          </Typography>
        </Grid>
        <Grid item>
          <form encType="multipart/form-data" method="post">
            <label htmlFor="icon-button-file">
              <input
                accept="file/*"
                style={{ display: 'none' }}
                id="icon-button-file"
                type="file"
                onChange={(e) => uploadNewFile(e.target.files?.[0])}
              />
              <IconButton aria-label="upload file" component="span" style={{ padding: '0px' }}>
                <AddIcon />
              </IconButton>
            </label>
          </form>
        </Grid>
      </Grid>
      {documents.map((document) => (
        <Grid item key={document.id} style={{ marginRight: 20 }}>
          <FileItem
            handleClose={() => removeDocument(document.id)}
            title={document.dto?.name}
            handleClick={() => setActiveDocument(document)}
            image={
              document.dto?.mimetype?.includes('image/')
                ? executionDocumentsService.getDocumentUrl(document)
                : undefined
            }
          />
        </Grid>
      ))}
      {isUploadingDocument && (
        <Grid item>
          <Skeleton variant="rectangular" width={90} height={95} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      <Modal
        sx={{ overflow: 'scroll' }}
        className={classes.modal}
        open={openDocumentModal}
        onClose={() => setActiveDocument(undefined)}
        closeAfterTransition
      >
        <Fade in={openDocumentModal} timeout={500} className={classes.img}>
          <Stack>
            <Stack
              direction="row"
              sx={{
                position: 'fixed',
                top: 10,
                right: 10,
                background: 'black',
                height: '50px',
                borderRadius: '25px',
              }}
            >
              <IconButton
                onClick={() => {
                  if (activeDocument) {
                    executionDocumentsService.downloadDocument(activeDocument).then();
                  }
                }}
              >
                <Download fontSize="large" sx={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={() => setActiveDocument(undefined)}>
                <Close fontSize="large" sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
            {displayDocument(activeDocument)}
          </Stack>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default ExecutionDocumentsForm;
