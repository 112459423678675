import { Search } from '@mui/icons-material';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import MaterialDatagrid from 'components/MaterialDatagrid';
import MaterialDatePickerButton from 'components/MaterialDatePicker/MaterialDatePickerButton';
import MaterialTextfieldBase from 'components/MaterialTextfield/MaterialTextfieldBase';
import SelectComponent from 'components/Select/Select.component';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import {
  InvoiceFilterSelectItems,
  InvoiceFiltersEnum,
  invoiceQuery,
  invoiceService,
} from 'store/invoice';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { Colors } from 'style/materialStylings';
import env from 'utils/env';
import { isMobile } from 'utils/isMobile';
import SnackError from 'utils/models/snackError';
import ActionsInvoiceScreen from './bottomModal/ActionsInvoice.screen';
import { DownloadInvoiceButton } from './buttons/DownloadInvoiceButton';
import { useInvoicesStyles } from './Invoices.style';
import CreateInvoiceScreen from './sideModal/CreateInvoice.screen';
import { invoicesColumns } from './table.utils';

const InvoicesScreen: FC = () => {
  const [textfieldValue, setTextfieldValue] = useState('');
  const [currentFilterTab, setCurrentFilterTab] = useState<number>(0);
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const classes = useInvoicesStyles();
  const isMobiles = isMobile();
  const [organisationUnitIDs] = useObservable(organisationUnitsQuery.activeOrgUnitIds$);
  const [organisationId] = useObservable(organisationsQuery.activeOrganisationId$);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<InvoiceFiltersEnum>(InvoiceFiltersEnum.ALL);
  const [total] = useObservable(invoiceQuery.total$);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(env.PAGINATION);
  const [isLoading] = useObservable(invoiceQuery.selectLoading());
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const [invoices] = useObservable(invoiceQuery.invoices$);
  const [search] = useObservable(invoiceQuery.search$);

  useEffect(() => {
    if (isBottomModalOpen && isSideModalOpen) {
      setIsSideModalOpen(false);
    }
  }, [isBottomModalOpen, isSideModalOpen]);

  useDebounce(
    () => {
      invoiceService.setSearch(textfieldValue);
    },
    500,
    [textfieldValue]
  );

  useEffect(() => {
    invoiceService.setStatus(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (organisationId) {
      invoiceService
        .getInvoices(organisationId, organisationUnitIDs, page, rowPerPage, fromDate, toDate)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    } else {
      invoiceService.resetInvoices();
    }
  }, [
    enqueueSnackbar,
    organisationId,
    organisationUnitIDs,
    search,
    page,
    rowPerPage,
    filterValue,
    fromDate,
    toDate,
  ]);

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2" fontSize={isMobiles ? 18 : 25} className={classes.title}>
          {t('fe.invoices.title')}
        </Typography>
        <DownloadInvoiceButton
          startDate={fromDate as Date}
          endDate={toDate as Date}
          multiDropdown={[]}
          startDateButton={new Date()}
        />
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" width="100%" spacing={2} alignItems="center">
          <MaterialDatePickerButton
            value={fromDate}
            handleChange={(value) => setFromDate(value)}
            isPlaceholder
          />
          <Typography> - </Typography>
          <MaterialDatePickerButton
            value={toDate}
            handleChange={(value) => setToDate(value)}
            isPlaceholder
          />
        </Stack>
      </Grid>
      <Grid item xs={12} alignItems="center" alignContent="center">
        <Stack direction="row" width="100%" spacing={2} height="40px">
          <SelectComponent
            disableUnderline
            color="secondary"
            inputWidth={300}
            value={filterValue}
            handleChange={(value) => setFilterValue(value)}
            items={InvoiceFilterSelectItems()}
          />
          <Stack flex={1}>
            <MaterialTextfieldBase
              type="search"
              startIcon={<Search htmlColor={Colors.darkGray} />}
              placeholder="Rechercher une facture par référence..."
              color="secondary"
              value={textfieldValue}
              handleChange={setTextfieldValue}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} style={{ height: '75vh' }}>
        <Card className={classes.cardContainer}>
          <CardContent>
            <MaterialDatagrid
              data={invoices ?? []}
              paginationMode="server"
              dataByPage={rowPerPage}
              totalDataPage={total}
              loading={isLoading}
              page={page}
              onPageChange={setPage}
              columns={invoicesColumns({
                isDesktop: !isMobiles,
                onClick: (id) => {
                  invoiceService
                    .downloadInvoice(id)
                    .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
                },
              })}
            />
          </CardContent>
        </Card>
      </Grid>
      {isBottomModalOpen && isMobiles && (
        <ActionsInvoiceScreen handleClickCloseModal={() => setIsBottomModalOpen(false)} />
      )}
      {isSideModalOpen && (
        <CreateInvoiceScreen handleClickCloseModal={() => setIsSideModalOpen(false)} />
      )}
    </Grid>
  );
};

export default InvoicesScreen;
