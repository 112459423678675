import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { ScheduleDto, ScheduleEnum, ServiceLightDto } from 'api/dto/services/ServiceLightDto';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import { t } from 'i18next';

interface ScheduleModalScreenProps {
  handleClickCloseModal: () => void;
  state?: boolean;
  activeSchedule: ScheduleDto;
  service: ServiceLightDto;
}

function getDayOfMonth(activeSchedule: ScheduleDto) {
  return activeSchedule.dayOfMonth;
}

function getWeekDayForMonth(activeSchedule: ScheduleDto) {
  switch (activeSchedule.weekDayForMonth) {
    case 'MONDAY':
      return t('fe.global.days.monday');
    case 'TUESDAY':
      return t('fe.global.days.tuesday');
    case 'WEDNESDAY':
      return t('fe.global.days.wednesday');
    case 'THURSDAY':
      return t('fe.global.days.thursday');
    case 'FRIDAY':
      return t('fe.global.days.friday');
    case 'SATURDAY':
      return t('fe.global.days.saturday');
    case 'SUNDAY':
      return t('fe.global.days.sunday');
    default:
      return '-';
  }
}

function getDaysOfWeek(activeSchedule: ScheduleDto) {
  return activeSchedule.daysOfWeek
    ?.map((day) => {
      switch (day) {
        case 1:
          return t('fe.global.days.monday');
        case 2:
          return t('fe.global.days.tuesday');
        case 3:
          return t('fe.global.days.wednesday');
        case 4:
          return t('fe.global.days.thursday');
        case 5:
          return t('fe.global.days.friday');
        case 6:
          return t('fe.global.days.saturday');
        case 7:
          return t('fe.global.days.sunday');
        default:
          return '-';
      }
    })
    .join(', ');
}

function translateFrequency(frequency: string) {
  switch (frequency) {
    case 'DAILY':
      return t('fe.contracts.details.servicesCard.serviceFrequency.DAILY');
    case 'WEEKLY':
      return t('fe.contracts.details.servicesCard.serviceFrequency.WEEKLY');
    case 'MONTHLY':
      return t('fe.contracts.details.servicesCard.serviceFrequency.MONTHLY');
    default:
      return '-';
  }
}

function getTextForFrequency(activeSchedule: ScheduleDto) {
  switch (activeSchedule.frequency) {
    case ScheduleEnum.DAILY:
      if (activeSchedule.dailyEveryWeekDay) {
        return t('fe.global.frequency.everyWeekDay');
      } else {
        return t('fe.global.frequency.everyXDay', { number: activeSchedule.dailyFrequencyNumber });
      }
    case ScheduleEnum.WEEKLY:
      return t('fe.global.frequency.everyXWeek', { number: activeSchedule.weeklyFrequencyNumber });
    case ScheduleEnum.MONTHLY:
      if (activeSchedule.dayOfMonth) {
        return t('fe.global.frequency.everyXMonthOnDayOfMonth', {
          number: activeSchedule.monthlyFrequencyNumber,
          dayOfMonth: activeSchedule.dayOfMonth,
        });
      } else {
        return '';
      }
  }
}

function ScheduleModalScreen(props: ScheduleModalScreenProps) {
  const { handleClickCloseModal, state, activeSchedule, service } = props;

  return (
    <Dialog open={true} fullWidth>
      <IconButton onClick={handleClickCloseModal} sx={{ position: 'absolute', top: 10, right: 16 }}>
        <CloseIcon />
      </IconButton>
      <Stack spacing={2} sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', padding: '16px' }}>
          <Typography fontSize="16px" fontWeight={700}>
            {service.clientWasteName}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          pb={2}
          px={6}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography fontSize="14px" fontWeight={400} color="#868F9C">
              {t('fe.global.frequency.frequency')}
            </Typography>
            <Stack
              sx={{
                backgroundColor: '#E9EBEE',
                borderRadius: '1px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '6px 10px',
                position: 'relative',
              }}
            >
              <Typography fontSize="12px" fontWeight={400} color="#192133">
                {translateFrequency(activeSchedule.frequency)}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography fontSize="14px" fontWeight={400} color="#868F9C">
              {t('fe.global.frequency.rule')}
            </Typography>
            <Stack
              sx={{
                backgroundColor: '#E9EBEE',
                borderRadius: '1px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '6px 10px',
                position: 'relative',
              }}
            >
              <Typography fontSize="12px" fontWeight={400} color="#192133">
                {getTextForFrequency(activeSchedule)}
              </Typography>
            </Stack>
            {activeSchedule.frequency === ScheduleEnum.WEEKLY && (
              <Stack
                sx={{
                  backgroundColor: '#E9EBEE',
                  borderRadius: '1px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '6px 10px',
                  position: 'relative',
                }}
              >
                <Typography fontSize="12px" fontWeight={400} color="#192133">
                  {getDaysOfWeek(activeSchedule)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default ScheduleModalScreen;
