import { OperationStatsDto } from 'api/dto/dashboard/operations/OperationStatsDto';
import { operationsStatsService } from './operations-stats.service';

export interface OperationStat {
  currentTicketsCount: number;
  evolutionTicketsCount: number;
}

export function operationStatsDtoToModel(statDto: OperationStatsDto): OperationStat {
  return {
    currentTicketsCount: statDto.operationsTicketsCount,
    evolutionTicketsCount: operationsStatsService.calculatePercentageEvolution(
      statDto.previousTicketsCount,
      statDto.operationsTicketsCount
    ),
  };
}
