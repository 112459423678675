import React, { useState } from 'react';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import {
  createExecutionQuery,
  createExecutionService,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { LoadingButton } from '@mui/lab';

export interface AcceptExecutionButtonComponentProps {
  executions: ExecutionDetailsUi[];
}

const AcceptExecutionButtonComponent = (props: AcceptExecutionButtonComponentProps) => {
  const { executions } = props;
  const { t } = useTranslation();
  const classes = useCreateStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [disableAcceptButton] = useObservable(createExecutionQuery.disableAcceptButton$());

  const acceptExecution = async () => {
    setIsLoading(true);
    try {
      const message = await createExecutionService.acceptOrRejectExecution(executions, true);
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e: any) {
      enqueueSnackbar(e.text, e.options);
    }
    setIsLoading(false);
  };

  return (
    <LoadingButton
      variant="contained"
      size="large"
      loading={isLoading}
      onClick={acceptExecution}
      disabled={disableAcceptButton}
    >
      {t('fe.executionModal.buttons.accept')}
    </LoadingButton>
  );
};

export default AcceptExecutionButtonComponent;
