import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCreateMobileStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '25px !important',
    marginBottom: '50px !important',
  },
  iconContainer: {
    color: '#848F9D !important',
    marginRight: '18px !important',
    width: '20px !important',
    height: '20px !important',
  },
}));
