import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as ArrowDown } from 'assets/icons/rework/arrowDown.svg';
import { ReactComponent as Chat } from 'assets/icons/rework/chatCollect.svg';
import { ReactComponent as Shape } from 'assets/icons/rework/shape.svg';
import { ReactComponent as Warning } from 'assets/icons/rework/icon_warning.svg';
import { Transitions } from 'style/materialStylings';
import { isMobile } from 'utils/isMobile';
import {
  createExecutionQuery,
  createExecutionService,
  ExecutionDetailsUi,
} from 'store/operations/createExecution';
import { ID } from '@datorama/akita';
import { formatAddress } from 'api/dto/client/AddressDto';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import dayjs from 'dayjs';

interface ExecutionHeaderFormProps {
  executionId: ID;
  isExpanded: boolean;
  setIsExpanded: (newValue: boolean) => void;
}

const ExecutionHeaderForm: FC<ExecutionHeaderFormProps> = (props) => {
  const { isExpanded, setIsExpanded, executionId } = props;
  const isMobiles = isMobile();
  const { t } = useTranslation();

  const [execution, setExecution] = useState<ExecutionDetailsUi | undefined>(undefined);

  useEffect(() => {
    const _execution = createExecutionQuery.execution$(executionId).subscribe(setExecution);
    return () => {
      _execution.unsubscribe();
    };
  }, []);

  const handleRemoveChildExecution = () => {
    createExecutionService.removeChildExecution(executionId);
  };

  const dto = execution?.dto;
  const isIssue = dto?.issuesCount ?? 0;
  const isComment = dto?.commentsCount ?? 0;

  return (
    <Grid item container rowSpacing={1}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <div>
          <Grid item container direction="column">
            {execution?.authorName && (
              <Typography variant="subtitle2">
                {t('fe.executionModal.createdBy', {
                  authorName: execution.authorName,
                })}
              </Typography>
            )}
            {execution?.createdAt && (
              <Typography variant="subtitle2">
                {dayjs(execution?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            )}
          </Grid>
          {execution?.parentExecutionId && !execution.dto && (
            <IconButton onClick={handleRemoveChildExecution}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          <ArrowDown
            width={isMobiles ? 15 : 20}
            height={isMobiles ? 7 : 20}
            style={{
              transform: isExpanded ? undefined : 'rotate(180deg)',
              transition: Transitions.cubic,
            }}
          />
        </IconButton>
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h2">{dto?.ticketSignature || ''}</Typography>
          {!execution?.parentExecutionId && dto?.organisationUnit && (
            <div style={{ display: 'flex', marginTop: 5 }}>
              <Shape />
              <Typography
                variant="subtitle2"
                style={{ marginLeft: 8, width: isMobiles ? '90%' : '100%' }}
              >
                {dto.organisationUnit.name} - {formatAddress(dto.organisationUnit.address)}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            {isIssue > 0 && (
              <Grid item>
                <IconButton>
                  <Warning width={20} height={20} />
                </IconButton>
              </Grid>
            )}
            {isComment > 0 && (
              <Grid item>
                <IconButton>
                  <Chat width={20} height={20} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default ExecutionHeaderForm;
