import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ContractDto, ContractFiltersEnum } from './contracts.model';

const initialState: ContractState = {
  contracts: [],
  total: 0,
  search: '',
  status: ContractFiltersEnum.ALL,
};

export interface ContractState extends EntityState<ContractDto> {
  contracts: ContractDto[];
  search: string;
  total: number;
  status: ContractFiltersEnum;
}

@StoreConfig({ name: 'contracts', resettable: true })
export class ContractsStore extends EntityStore<ContractState> {
  constructor() {
    super(initialState);
  }
}

export const contractsStore = new ContractsStore();
