import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { createExecutionQuery, ExecutionDetailsUi } from 'store/operations/createExecution';
import StepButtons from 'components/StepButtons/StepButtons';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield/MaterialBasicTextfield';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { sessionQuery } from 'store/session';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';

interface ExecutionNbContainersFieldProps {
  execution: ExecutionDetailsUi;
  updateExecutionField: (name: string) => (value: any) => void;
}

const ExecutionNbContainersField: FC<ExecutionNbContainersFieldProps> = (props) => {
  const { execution, updateExecutionField } = props;
  const [canEditNbContainers] = useObservable(createExecutionQuery.canEditNbContainers$);
  const [canEditWasteAmount] = useObservable(createExecutionQuery.canEditWasteAmount$);
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        {canEditNbContainers ? (
          <StepButtons
            required
            label={t('fe.executionModal.executionInfosForm.containerAmountLabel')}
            value={execution.nbContainers}
            handleChange={(value) => value > 0 && updateExecutionField('nbContainers')(value)}
          />
        ) : (
          <MaterialBasicTextfield
            disabled
            label={t('fe.executionModal.executionInfosForm.containerAmountLabel')}
            color="secondary"
            className="step20"
            value={execution.nbContainers?.toString()}
          />
        )}
      </Grid>
      {(canEditWasteAmount ||
        (execution.dto && execution.dto.status !== ExecutionStatusEnum.CREATED)) && (
        <Grid item xs={12}>
          <MaterialBasicTextfield
            required
            disabled={!canEditNbContainers || sessionQuery.isMorgan}
            label={t('fe.executionModal.executionInfosForm.wasteAmountLabel')}
            color="secondary"
            type="number"
            placeholder={t('fe.executionModal.executionInfosForm.wasteAmountPlaceholder')}
            endIcon={<Typography>{execution.dto?.service.kind.execUnitName2 || ''}</Typography>}
            value={execution.wasteAmount === -1 ? '' : execution.wasteAmount?.toString()}
            handleChange={updateExecutionField('wasteAmount')}
          />
        </Grid>
      )}
    </>
  );
};

export default ExecutionNbContainersField;
