import i18n from 'utils/data/i18n.utils';
import ArticleIcon from '@mui/icons-material/Article';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import QuizIcon from '@mui/icons-material/Quiz';
import env from '../env';
import { onbordingService } from 'store/onbording/onbording.service';
import { Tour } from '@mui/icons-material';

export interface IconList {
  id: number;
  onClick?: () => void;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  name?: string;
}

export const getHelpMenu = (): IconList[] => {
  return [
    {
      id: 0,
      icon: <YouTubeIcon />,
      name: i18n.t('fe.misc.channel'),
      onClick: () => window.open('https://www.youtube.com/channel/UC8BuIsvhpmT30CNQbRRavzg'),
    },
    {
      id: 1,
      icon: <ArticleIcon />,
      name: i18n.t('fe.misc.cgu'),
      onClick: () => window.open('/documents/cgu_metawaste.pdf'),
    },
    {
      id: 2,
      icon: <ArticleIcon />,
      name: i18n.t('fe.misc.politic'),
      onClick: () => window.open('/documents/politiqueconfidentialite_metawaste.pdf'),
    },
    {
      id: 3,
      icon: <QuizIcon />,
      name: i18n.t('fe.misc.faq'),
      onClick: () => window.open(env.AZURE_STORAGE_URL + '/assets/FAQ_metawaste.pdf'),
    },
    {
      id: 4,
      icon: <EmailIcon />,
      name: i18n.t('fe.misc.mailto'),
      onClick: () => window.open('mailto:support@metawaste.com'),
    },
    {
      id: 5,
      icon: <Tour />,
      name: i18n.t('fe.misc.joyride'),
      onClick: () => {
        onbordingService.initSteps();
        onbordingService.handleIsonbording(true);
        onbordingService.handleRun(true);
      },
    },
  ];
};
