import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { IssueDetailsUi } from './execution-issue.model';

export interface ExecutionIssueState extends EntityState<IssueDetailsUi> {
  isModalOpen: boolean;
  isSaving?: ID;
}

const initialState: ExecutionIssueState = {
  isModalOpen: false,
};

@StoreConfig({ name: 'executionIssue', resettable: true })
export class ExecutionIssueStore extends EntityStore<ExecutionIssueState> {
  constructor() {
    super(initialState);
  }

  setIsModalOpen = (isModalOpen: boolean) => {
    this.update((state) => ({
      ...state,
      isModalOpen,
    }));
  };

  setIsSaving = (isSaving?: ID) => {
    this.update((state) => ({
      ...state,
      isSaving,
    }));
  };
}

export const executionIssueStore = new ExecutionIssueStore();
