import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { onbordingStore, OnbordingStore } from './onbording.store';
import { onbordingSteps } from './onbording.data';

export class OnbordingService {
  constructor(private store: OnbordingStore) {}

  handleIsonbording = (isOnbording: boolean) => {
    this.store.setIsonbording(isOnbording);
  };

  handleRun = (run: boolean) => {
    this.store.setRun(run);
  };

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const resetDelay = 400;

    if (finishedStatuses.includes(status)) {
      this.store.setRun(false);
      this.store.setOpenNewDemande(false);
      this.store.setIsonbording(false);
      this.store.setStepIndex(0);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.store.setStepIndex(nextStepIndex);

      if (index === 13 && action === ACTIONS.PREV) {
        this.store.setOpenNewDemande(index === 13 ? false : true);
        this.store.setRun(false);

        setTimeout(() => {
          this.store.setRun(true);
        }, resetDelay);
      }
    }
  };

  handleOpenNewDemande = (value: boolean) => {
    this.store.setOpenNewDemande(value);
    if (this.store.getRun()) {
      this.store.setRun(false);
      setTimeout(() => {
        this.store.setRun(true);
        this.store.setStepIndex(13);
      }, 400);
    }
  };

  initSteps() {
    this.store.update((state) => ({
      ...state,
      steps: onbordingSteps(),
    }));
  }
}

export const onbordingService = new OnbordingService(onbordingStore);
