import React from 'react';
import { FormHelperText, InputLabel, Stack } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

import style from './MaterialDropdownForm.style';
import MaterialDropdownBase, { MaterialDropdownBaseProps } from '../MaterialDropdownBase';
import MaterialAutocompleteBase from 'components/MaterialAutocomplete/MaterialAutocompleteBase';

interface MaterialDropdownProps extends WithStyles<typeof style>, MaterialDropdownBaseProps {
  enableSearch?: boolean;
}

const MaterialDropdownForm = (props: MaterialDropdownProps) => {
  const { classes, label, required, error, enableSearch = false, items, value } = props;

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ sm: 'center', xs: 'flex-start' }}
      justifyContent="space-between"
      className={classes.boxContainer}
    >
      {!!label && (
        <InputLabel error={!!error}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <div className={classes.textfield}>
        {enableSearch ? (
          <MaterialAutocompleteBase {...props} />
        ) : (
          <MaterialDropdownBase {...props} />
        )}
        <FormHelperText error>{error}</FormHelperText>
      </div>
    </Stack>
  );
};

export default withStyles(style)(MaterialDropdownForm);
