import React from 'react';
import { Grid, Typography } from '@mui/material';
import MaterialSingleDatePicker from 'components/MaterialDatePicker/MaterialSingleDatePicker/MaterialSingleDatePicker';
import MaterialTimeSingleTextfield from 'components/MaterialTimeTextfield/MaterialTimeSingleTextfield/MaterialTimeSingleTextfield';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield/MaterialBasicTextfield';
import { IssueDetailsUi } from 'store/operations/executionIssue';
import MaterialDropdownForm from 'components/MaterialDropdown/MaterialDropdownForm/MaterialDropdownForm';
import { useIssueKindsItems } from 'store/operations/executionIssueKinds/hooks/useIssueKindsItems';
import { useTranslation } from 'react-i18next';
import { translateIssueKind } from 'utils/enums/IssueKinds';
import { issueStatusItems } from 'utils/enums/IssueStatusEnum';

export interface IssueDetailsProps {
  issue: IssueDetailsUi;
  updateIssueField: (name: string) => (value: any) => void;
}

const IssueDetailsForm = (props: IssueDetailsProps) => {
  const { issue, updateIssueField } = props;

  const issueStatusData = issueStatusItems();
  const { issueKindsData } = useIssueKindsItems(issue.dto === undefined);
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          {t('fe.issueModal.issueDetails.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {issue.dto !== undefined ? (
          <MaterialBasicTextfield
            label={t('fe.issueModal.issueDetails.typeLabel')}
            placeholder={t('fe.issueModal.issueDetails.typePlaceholder')}
            value={
              issue.dto?.issueKind.name ? translateIssueKind(issue.dto.issueKind.name) : undefined
            }
            disabled
          />
        ) : (
          <MaterialDropdownForm
            label={t('fe.issueModal.issueDetails.typeLabel')}
            placeholder={t('fe.issueModal.issueDetails.typePlaceholder')}
            handleChange={updateIssueField('issueKindId')}
            value={issue.issueKindId}
            items={issueKindsData}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <MaterialSingleDatePicker
          label={t('fe.issueModal.issueDetails.reportingDateLabel')}
          value={issue.reportedAtDate ? new Date(issue?.reportedAtDate) : undefined}
          handleChange={updateIssueField('reportedAtDate')}
          withIcon={true}
          disabled={issue.dto !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTimeSingleTextfield
          color="primary"
          label={t('fe.issueModal.issueDetails.reportingTimeLabel')}
          value={issue.reportedAtTime}
          handleChange={updateIssueField('reportedAtTime')}
          readOnly={issue.dto !== undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          color="primary"
          label={t('fe.issueModal.issueDetails.phoneNumberLabel')}
          value={issue?.phoneNumber}
          handleChange={updateIssueField('phoneNumber')}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialBasicTextfield
          label={t('fe.issueModal.issueDetails.descriptionLabel')}
          multiline
          rows={4}
          placeholder={t('fe.issueModal.issueDetails.descriptionPlaceholder')}
          color="primary"
          value={issue.description}
          handleChange={updateIssueField('description')}
        />
      </Grid>
      {issue?.dto && (
        <Grid item xs={12}>
          <MaterialDropdownForm
            label={t('fe.issueModal.issueDetails.statusLabel')}
            placeholder={t('fe.issueModal.issueDetails.statusPlaceholder')}
            handleChange={updateIssueField('status')}
            value={issue?.status}
            items={issueStatusData}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default IssueDetailsForm;
