import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { getWasteTypeIconOrDefault } from 'constants/WasteTypesIcon';
import { isMobile } from 'utils/isMobile';

interface IconWasteProps {
  params?: GridRenderCellParams;
}

const IconWaste: FC<IconWasteProps> = (props) => {
  const { params } = props;
  const isMobiles = isMobile();

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingTop: isMobiles ? 5 : 0 }}>
      <img
        src={getWasteTypeIconOrDefault(params?.row?.service?.waste?.family?.name)}
        height={25}
        width={25}
      />
    </div>
  );
};

export default IconWaste;
