import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors, Sizes } from 'style/materialStylings';

const classes = (theme: Theme) =>
  createStyles({
    boxContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: Sizes.drawerWidth,
      height: '100%',
      backgroundColor: 'white',
      zIndex: 1300,
    },
    logoContainer: {
      position: 'absolute',
      top: 10,
    },
    logo: {
      width: Sizes.drawerWidth,
      padding: '18px 5px',
      cursor: 'pointer',
    },
    tabsContainer: {
      minWidth: 0,
      maxWidth: Sizes.drawerWidth,
      borderLeft: 1,
      borderColor: 'divider',
      '& .MuiTabs-indicator': {
        height: 0,
        left: 0,
        width: 4,
      },
    },
    tab: {
      minHeight: '0px !important',
      minWidth: '8px !important',
      padding: '8px 34px !important',
      borderRadius: `0px !important`,
      color: `${Colors.secondary} !important`,
      height: '70px !important',
      margin: '10px 0 !important',
      fontWeight: `400 !important`,
      fontSize: '8px !important',
      '& > svg': {
        width: '18px !important',
        height: 'auto !important',
      },
      '&.MuiTab-root.Mui-selected': {
        color: `${Colors.primary} !important`,
        backgroundColor: 'transparent',
        fontWeight: '400 !important',
        fontSize: '8px !important',
      },
    },
  });

export default classes;
