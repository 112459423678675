import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { IssueStatus, IssueStatusSelectItems, ManageIssue } from 'store/issues/issues.model';
import { Close } from 'assets/icons';
import { Colors } from 'constants/Colors';

interface ManageIssueHeaderProps {
  issue: ManageIssue;
  handleClose: () => void;
  status?: IssueStatus;
}

const ManageIssueHeader = (props: ManageIssueHeaderProps) => {
  const { handleClose, status, issue } = props;

  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Stack pr="10px">
        <Stack direction="row" alignItems="center" mb="15px" spacing={1.5}>
          <Typography fontSize={24} fontWeight="700">
            {t(`fe.operations.nonCompliance.manage.title.edit`, {
              issue: issue?.issueSignature,
            })}
          </Typography>
          {!!status && (
            <Box bgcolor={Colors.background} p="8px">
              <Typography textAlign="center" textTransform="uppercase">
                {IssueStatusSelectItems().find((s) => s.value === status)?.label || ''}
              </Typography>
            </Box>
          )}
        </Stack>
        <Divider variant="fullWidth" orientation="horizontal" />
      </Stack>
    </>
  );
};

export default ManageIssueHeader;
