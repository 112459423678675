import { MetawasteTab } from 'utils/enums/MetawasteTab';
import { ButtonBase, CircularProgress, Stack, Typography } from '@mui/material';
import ActivityRowComponent from './ActivityRowComponent';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { activitiesQuery } from 'store/dashboard/activities/activities.query';
import { useActivities } from 'screens/auth/dashboard/morgan/operations/hooks/ActivitiesEffect';
import dayjs from 'dayjs';
import { ActivityDto, ActivityExecutionDto } from 'api/dto/activities/ActivityDto';
import { createExecutionService } from 'store/operations/createExecution';
import { NoResultComponent } from 'screens/auth/dashboard/common/components/NoResult';

interface ActivityRowListProps {
  tabType: MetawasteTab;
  isOnbording?: boolean;
}

const ActivityRowListComponent = (props: ActivityRowListProps) => {
  const { tabType, isOnbording } = props;
  const { t } = useTranslation();
  const [activities] = useObservable(activitiesQuery.executionActivities$);
  const [loading] = useObservable(activitiesQuery.selectLoading());

  useActivities(tabType);

  const Obloading = isOnbording ? true : loading;

  return (
    <Stack sx={{ height: '400px' }} direction="column" pt={2} pl={6} pr={6}>
      <Stack direction="row" justifyContent="flex-end">
        <Typography sx={{ fontStyle: 'italic' }}>
          {t(`fe.dashboard.morgan.tabs.${tabType}`)}
        </Typography>
      </Stack>
      {!isOnbording && Obloading && (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <CircularProgress size={30} />
        </Stack>
      )}
      {(isOnbording || (!Obloading && activities.length === 0)) && (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <NoResultComponent />
        </Stack>
      )}
      {!Obloading &&
        activities.length > 0 &&
        activities.map((a: ActivityDto<ActivityExecutionDto>) => (
          <ButtonBase key={a.entityId} onClick={() => createExecutionService.openModal(a.entityId)}>
            <ActivityRowComponent
              leftInfo={
                a.entity.plannedFrom
                  ? {
                      title: dayjs(a.entity.plannedFrom).format('DD.MM.YYYY'),
                      subtitle: [
                        dayjs(a.entity.plannedFrom).format('HH:mm'),
                        dayjs(a.entity.plannedTo).format('HH:mm'),
                      ].join(' - '),
                    }
                  : { title: t('fe.asap'), subtitle: '' }
              }
              middleInfo={{
                title: a.entity.clientWasteName,
                subtitle: [
                  a.entity.wasteNameTranslateKey
                    ? t(a.entity.wasteNameTranslateKey)
                    : a.entity.wasteName,
                  t(a.entity.containerNameTranslateKey),
                ].join(' - '),
              }}
              time={a.lastActivityAt}
              authorName={
                a.authorFirstname && a.authorLastname
                  ? `${a.authorFirstname} ${a.authorLastname?.substring(0, 1)}.`
                  : t('fe.dashboard.morgan.stats.operations.activities.authorAutomatic')
              }
            />
          </ButtonBase>
        ))}
    </Stack>
  );
};

export default ActivityRowListComponent;
