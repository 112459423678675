import { Badge } from '@mui/material';
import React, { FC, useState } from 'react';
import DayPicker from 'react-day-picker';
import { isSameDay } from 'date-fns';
import { Colors } from 'style/materialStylings';
import 'react-day-picker/lib/style.css';
import { EventCalendar } from '../FullCalendar/TestData';
import PickupDatePickerNavbar from './PickupDatePickerNavbar';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from 'utils/data/dayjs.utils';
import { useDebounce } from 'react-use';

interface MonthCalendarProps {
  selectedDays?: Date;
  handleChange?: (selectedDays: Date) => any;
  activities?: EventCalendar[];
  month?: Date;
  setMonth?: (date: Date) => void;
}

const renderDot = (badges: ('secondary' | 'error')[], modifiers: any) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: '4px',
      }}
    >
      {badges.map((badge) => (
        <Badge key={badge} color={badge} variant="dot" />
      ))}
    </div>
  );
};

const renderDay = (day: Date, modifiers: any, activities?: EventCalendar[]) => {
  const date = day.getDate();
  const filteredActivities = activities?.filter((activity) =>
    isSameDay(new Date(activity.start), day)
  );
  let badges: ('secondary' | 'error')[] = [];
  if (
    filteredActivities?.some(
      (activity) =>
        activity.extendedProps?.hasIssue === false || (activity.extendedProps?.execCount || 0) > 0
    )
  ) {
    badges = ['secondary'];
  }
  if (filteredActivities?.some((activity) => activity.extendedProps?.hasIssue === true)) {
    badges = [...badges, 'error'];
  }
  return (
    <div style={{ position: 'relative', padding: '0px 10px', height: '32px' }}>
      {date}
      {badges && badges.length > 0 && renderDot(badges, modifiers)}
    </div>
  );
};

const modifiersStyles = {
  selected: {
    // '& > :first-child': {
    //   backgroundColor: `${Colors.primary} !important`,
    // },
    backgroundColor: Colors.primary,
    color: 'black',
    outline: 'none',
    fontWeight: 700,
  },
};

const styles = `
  .DayPicker-Weekday {
        font-family: CircularStd-Book, sans-serif;
        font-weight: 700;
        color: black;
      }
  .DayPicker {
    flex-direction: row;
    font-family: CircularStd-Book, sans-serif;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    padding: 0;
}
.DayPicker-Months {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
@media (max-width: 500px) {
  .DayPicker {
    width: auto;
  }
}
      `;

const MonthCalendar: FC<MonthCalendarProps> = (props) => {
  const { selectedDays, handleChange, activities, month, setMonth } = props;
  const { t } = useTranslation();
  const [weekDays, setWeekDays] = useState(weekdaysMin());

  useDebounce(() => setWeekDays(weekdaysMin()), 50, [t]);

  const handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }
    handleChange?.(modifiers.selected ? undefined : day);
  };

  return (
    <>
      <style>{styles}</style>
      {activities && (
        <DayPicker
          weekdaysShort={weekDays}
          selectedDays={selectedDays}
          renderDay={(day, modifiers) => renderDay(day, modifiers, activities)}
          onDayClick={(day, modifiers) => handleDayClick(day, modifiers)}
          month={month}
          modifiersStyles={modifiersStyles}
          captionElement={() => <span />}
          firstDayOfWeek={1}
          navbarElement={({ onPreviousClick, onNextClick, month }) => (
            <PickupDatePickerNavbar
              setMonth={setMonth}
              month={month}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          )}
        />
      )}
    </>
  );
};
export default MonthCalendar;
