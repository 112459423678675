import React, { useEffect, useMemo, useRef } from 'react';
import { Alert, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { useSnackbar } from 'notistack';
import { isMobile } from 'utils/isMobile';
import SideModal from 'components/Modal/SideModal';
import ExecutionDetailsComponent from './details/ExecutionDetails.component';
import { createExecutionQuery, createExecutionService } from 'store/operations/createExecution';
import ExecutionModalButtonsComponent from './components/buttons/ExecutionModalButtons.component';
import { useTranslation } from 'react-i18next';
import { translateExecutionStatus } from 'utils/enums/ExecutionStatusEnum';
import { useQuery } from 'utils/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { IdenticalExecutionsModal } from 'components/IdenticalExecutionsModal/IdenticalExecutions.modal';
import SnackError from 'utils/models/snackError';
import { organisationsQuery } from 'store/organisation/organisations';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { bookmarkService, BookmarkServiceDto } from 'store/operations/bookmark';
import { sessionQuery } from 'store/session';

interface ExecutionModalScreenProps {
  bookmarkServices?: BookmarkServiceDto[];
}

const ExecutionModalScreen = (props: ExecutionModalScreenProps) => {
  const { bookmarkServices } = props;
  const [executions] = useObservable(createExecutionQuery.executions$);
  const [mainExecution] = useObservable(createExecutionQuery.mainExecution$);
  const [isLoading] = useObservable(createExecutionQuery.selectLoading());
  const [identicalExecutions] = useObservable(createExecutionQuery.identicalExecutions$);
  // const [showOptionsButton] = useObservable(createExecutionQuery.showOptionsButton$());
  const { enqueueSnackbar } = useSnackbar();
  const isMobiles = isMobile();
  const classes = useCreateStyles();
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const queryRef = useRef(query);
  const historyRef = useRef(history);
  const title = useMemo(() => {
    if (mainExecution?.dto?.executionGroup) {
      const wastes = executions.map((it) => it.dto?.service?.waste?.name ?? '').join(', ');
      return t(`fe.executionModal.signatureGroup`, {
        name: wastes,
      });
    }
    return mainExecution?.dto?.status ? mainExecution?.dto?.name : t('fe.executionModal.title');
  }, [mainExecution, executions]);

  useEffect(() => {
    queryRef.current = query;
    historyRef.current = history;
  }, [history, query]);

  useEffect(() => {
    return () => {
      queryRef.current.delete('executionId');
      historyRef.current.replace({
        search: queryRef.current.toString(),
      });
    };
  }, []);

  const handleClickCloseModal = () => {
    createExecutionService.closeModal();
    bookmarkService.closeModal();
  };

  if (isLoading) {
    return (
      <SideModal handleClickCloseModal={handleClickCloseModal}>
        <CircularProgress className={classes.loadingCenter} />
      </SideModal>
    );
  }

  const dto = mainExecution?.dto;
  const haulingOrgAlertKey = `fe.executionModal.alerts.hauling.orgs.${mainExecution?.haulingOrganisationId}`;
  const haulingUnitAlertKey = `fe.executionModal.alerts.hauling.units.${mainExecution?.haulingUnitId}`;

  const destinationOrgAlertKey = `fe.executionModal.alerts.destination.orgs.${mainExecution?.destinationOrganisationId}`;
  const destinationUnitAlertKey = `fe.executionModal.alerts.destination.units.${mainExecution?.destinationUnitId}`;

  const clientOrgAlertKey = `fe.executionModal.alerts.client.orgs.${organisationsQuery.activeOrganisationId}`;
  const clientUnitAlertKey = `fe.executionModal.alerts.client.units.${organisationUnitsQuery.activeOrgUnitId}`;

  const haulingOrgAlertMessage = t(haulingOrgAlertKey);
  const haulingUnitAlertMessage = t(haulingUnitAlertKey);

  const destinationOrgAlertMessage = t(destinationOrgAlertKey);
  const destinationUnitAlertMessage = t(destinationUnitAlertKey);

  const clientOrgAlertMessage = t(clientOrgAlertKey);
  const clientUnitAlertMessage = t(clientUnitAlertKey);

  const shouldDisplayAlerts =
    mainExecution?.serviceId &&
    mainExecution?.containerId &&
    mainExecution?.wasteId &&
    mainExecution?.haulingOrganisationUnitName &&
    mainExecution?.destinationOrganisationUnitName;

  const handleSaveForce = () => {
    createExecutionService
      .save(executions, true)
      .then((message) => {
        enqueueSnackbar(message, { variant: 'success' });
      })
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  return (
    <SideModal
      status={dto?.status ? translateExecutionStatus(dto.status) : undefined}
      handleClickCloseModal={handleClickCloseModal}
    >
      <Grid container rowSpacing={4}>
        <Grid
          item
          container
          justifyContent="space-between"
          flexWrap="nowrap"
          alignItems="center"
          style={{ marginBottom: 4 }}
        >
          <Typography variant="h2" style={{ fontSize: '24px' }}>
            {title}
          </Typography>
          {/*{showOptionsButton && (*/}
          {/*  <ExecutionModalOptionsButtonComponent mainExecution={mainExecution} />*/}
          {/*)}*/}
        </Grid>
        {mainExecution?.dto?.ticketSignature && (
          <Typography variant="body1">
            {mainExecution!.dto!.generatedBySchedule!
              ? t('fe.operations.table.recurrent')
              : t('fe.operations.table.onDemand')}
          </Typography>
        )}
        {sessionQuery.isPatrick && mainExecution?.dto == null && (
          <Grid item container style={{ marginBottom: 4 }}>
            <Alert severity="warning">{t('fe.executionModal.alertSupplier')}</Alert>
          </Grid>
        )}
        <Stack
          sx={{
            marginTop: '1rem',
            overflow: 'auto',
            height: 'calc(100vh - 250px)',
          }}
          spacing={2}
        >
          {executions.map((execution) => (
            <Grid item key={execution.id}>
              <ExecutionDetailsComponent execution={execution} />
            </Grid>
          ))}
        </Stack>
        {shouldDisplayAlerts && (
          <Grid item container justifyContent="space-between" flexWrap="nowrap" alignItems="center">
            {haulingOrgAlertMessage && haulingOrgAlertMessage !== haulingOrgAlertKey && (
              <Alert severity="warning">
                <Typography>{haulingOrgAlertMessage}</Typography>
              </Alert>
            )}
            {haulingUnitAlertMessage && haulingUnitAlertMessage !== haulingUnitAlertKey && (
              <Alert severity="warning">
                <Typography>{haulingUnitAlertMessage}</Typography>
              </Alert>
            )}
            {destinationOrgAlertMessage && destinationOrgAlertMessage !== destinationOrgAlertKey && (
              <Alert severity="warning">
                <Typography>{destinationOrgAlertMessage}</Typography>
              </Alert>
            )}
            {destinationUnitAlertMessage &&
              destinationUnitAlertMessage !== destinationUnitAlertKey && (
                <Alert severity="warning">
                  <Typography>{destinationUnitAlertMessage}</Typography>
                </Alert>
              )}
            {clientOrgAlertKey && clientOrgAlertMessage !== clientOrgAlertKey && (
              <Alert severity="warning">
                <Typography>{clientOrgAlertMessage}</Typography>
              </Alert>
            )}
            {clientUnitAlertKey && clientUnitAlertMessage !== clientUnitAlertKey && (
              <Alert severity="warning">
                <Typography>{clientUnitAlertMessage}</Typography>
              </Alert>
            )}
          </Grid>
        )}

        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ position: 'absolute', bottom: 20, right: 20 }}
        >
          <ExecutionModalButtonsComponent executions={executions} />
        </Grid>
        {identicalExecutions && identicalExecutions.length > 0 && (
          <IdenticalExecutionsModal
            identicalExecutions={identicalExecutions}
            handleOnForce={handleSaveForce}
          />
        )}
      </Grid>
    </SideModal>
  );
};

export default ExecutionModalScreen;
