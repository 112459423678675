import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

export const useOperationsStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '1rem',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '2rem',
    },
  },
  title: {},
  hideForMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
  buttonContainer: {
    // [theme.breakpoints.down('lg')]: {
    //   height: '26px !important',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   marginRight: '15px !important',
    // },
  },
  gridContainer: {
    [theme.breakpoints.only('xs')]: {
      margin: '0px 15px !important',
    },
  },
  iconButtonDownload: {
    borderRadius: '4px !important',
    backgroundColor: `${Colors.whiteInputBackground} !important`,
    boxShadow: `0 6px 12px 0 ${Colors.buttonShadow}`,
    height: 28,
    '&:hover': {
      backgroundColor: `${Colors.hoverFields} !important`,
    },
  },
  cardContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: '0px 5px !important',
    },
  },
  cardCalendar: {
    [theme.breakpoints.down('lg')]: {
      padding: '20px 16px !important',
    },
  },
}));
