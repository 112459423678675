export interface AddressDto {
  id: number;
  street: string;
  city: string;
  zip: string;
  country: string;
}

export const formatAddress = (address: AddressDto) => {
  const fields = [[address.street], [address.zip, address.city], [address.country]];
  return fields
    .map((part) => part.filter(Boolean).join(' '))
    .filter((str) => str.length)
    .join(', ');
};

export interface AddressDetailDto {
  id: number;
  street: string;
  street2: string;
  street3: string;
  city: string;
  zip: string;
  country: string;
}

export const formatAddressDetail = (address: AddressDetailDto) => {
  return [
    [
      address?.street || '',
      address?.street2 || '',
      address?.street3 || '',
      address?.zip,
      address?.city,
    ]
      .filter((a) => a !== '')
      .join(', '),
  ].join(' - ');
};
