import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Colors } from 'style/materialStylings';

export const useMaterialTagStyles = makeStyles((theme: Theme) => ({
  date: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: `${Colors.darkGray} !important`,
  },
  time: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: `${Colors.darkGray} !important`,
  },
}));
