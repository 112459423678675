import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CommentExecutionDto } from 'api/dto/execution/CommentExecutionDto';

export type CommentState = EntityState<CommentExecutionDto>;

@StoreConfig({ name: 'executionComment', resettable: true })
export class CommentExecutionStore extends EntityStore<CommentState> {
  constructor() {
    super();
  }
}

export const executionStore = new CommentExecutionStore();
