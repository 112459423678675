import React, { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import style from './RankingTrophyCard.style';

interface RankingTrophyCardProps extends WithStyles<typeof style> {
  description?: string;
  icon?: React.ReactNode;
}

const RankingTrophyCard: FC<RankingTrophyCardProps> = (props) => {
  const { classes, description, icon } = props;
  return (
    <Card sx={{ backgroundColor: 'secondary.light' }} className={classes.container}>
      <CardContent>
        <div className={classes.position}>
          {!!icon && <span className={classes.icon}>{icon}</span>}
          <Typography className={classes.typography}>{description}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(RankingTrophyCard);
