import { ID } from '@datorama/akita';
import AddIcon from '@mui/icons-material/Add';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import ContentPaste from '@mui/icons-material/ContentPaste';
import ListIcon from '@mui/icons-material/List';
import {
  Box,
  Button,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { useObservable } from '@ngneat/react-rxjs';
import BookmarkCards from 'components/Bookmark/bookmarkSolocard/bookmarkCards';
import IssueDetailsDrawer from 'components/Drawer/IssueDetails.drawer';
import TableFiltersComponent from 'components/Filters/Filters.component';
import ModalComponent from 'components/Modal/Modal';
import { TableFilterType } from 'components/Select/SelectItem.interface';
import TableComponent from 'components/TableGrid/NewTable.component';
import BasicTab from 'components/Tabs/BasicTabs/BasicTabs';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tap } from 'rxjs/operators';
import ExecutionsWaitingComponents from 'screens/auth/dashboard/patrick/components/ExecutionsWaiting.components';
import { useHasQueryParamsInStorage } from 'store/dashboard/notifications/hooks/useHasQueryParamsInStorage';
import { useGetIssues } from 'store/issues/hooks/useGetIssues';
import { Issue, IssueStatusSelectItems } from 'store/issues/issues.model';
import {
  bookmarkQuery,
  bookmarkService,
  BookmarkServiceDto,
  bookmarkStore,
} from 'store/operations/bookmark';
import { createExecutionQuery, createExecutionService } from 'store/operations/createExecution';
import { organisationUnitsQuery } from 'store/organisation/organisationUnits';
import { sessionQuery } from 'store/session';
import { MetawasteTab, morganTabs, patrickTabs } from 'utils/enums/MetawasteTab';
import { useIsMobile } from 'utils/isMobile';
import SnackError from 'utils/models/snackError';
import COLUMN_TYPES from 'utils/table/columnTypes.table';
import CreateExecutionButtonComponent from '../dashboard/morgan/components/createExecutionButton/CreateExecutionButton.component';
import ExecutionModalScreen from '../modals/executionModal/ExecutionModal.screen';
import { NoResultComponent } from '../dashboard/common/components/NoResult';
import ActionOperationsScreen from './bottomModal/ActionOperations.screen';
import OperationsListComponent from './list/OperationsList.component';
import { useOperationsStyles } from './Operations.style';
import PlanningWeekComponent from './planningWeek/PlanningWeek.component';
import { organisationsQuery } from 'store/organisation/organisations';

const OperationsScreen: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tab, setTab] = useState<{ tabType: MetawasteTab; label: string }>(morganTabs()[0]);
  const [executions] = useObservable(createExecutionQuery.executions$);
  const [bookmarks] = useObservable(bookmarkQuery.bookmarks$);
  const [organisationUnitIDs] = useObservable(organisationUnitsQuery.activeOrgUnitIds$);
  const [organisationId] = useObservable(organisationsQuery.activeOrganisationId$);
  const [isSideModalOpen] = useObservable(bookmarkQuery.isEditModalOpen$);
  const [openModal, setOpenModal] = useState<{ issueId: number | null }>({ issueId: null });
  const [isMorgan] = useObservable(
    sessionQuery.isMorgan$.pipe(
      tap((isMorgan) => {
        if (isMorgan) {
          setTabIndex(0);
          setTab(morganTabs()[0]);
        } else {
          setTabIndex(1);
          setTab(patrickTabs()[0]);
        }
      })
    )
  );
  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useOperationsStyles();
  const isMobiles = useIsMobile();
  const { enqueueSnackbar } = useSnackbar();
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
  const history = useHistory();
  const {
    issues,
    loading: issueLoading,
    option: {
      page,
      sort,
      total,
      handlechangePage,
      handleSearch,
      handleSort,
      limitPerPage,
      handlePageSizeChange,
    },
  } = useGetIssues({
    organisationUnitIds: organisationUnitIDs,
    organisationId: organisationId,
  });

  useHasQueryParamsInStorage();

  useEffect(() => {
    if (organisationUnitIDs && isBookmarkModalOpen) {
      bookmarkService
        .getBookmarks(organisationUnitIDs)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  }, [organisationUnitIDs, isBookmarkModalOpen]);

  const handleOpenModal = (executionId?: ID, bookmarkServices?: BookmarkServiceDto[]) => {
    createExecutionService
      .openModal(executionId, undefined, bookmarkServices)
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));

    bookmarkServices && bookmarkStore.isEditModalOpen(true);
  };

  const handleDeleteBookmark = (bookmarkId: number) => {
    bookmarkService
      .deleteBookmark(bookmarkId)
      .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
  };

  const handleDetails = (issue: Issue) => setOpenModal({ issueId: issue.id });

  return (
    <Stack direction="column" spacing={3} className={classes.container}>
      <Stack direction="column" flex={1} spacing={2}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Stack direction="column" height="100%" spacing={2} justifyContent="space-between">
            <Typography
              variant="h2"
              fontSize={isMobiles ? 18 : 20}
              ml={0}
              className={classes.title}
            >
              {t('fe.operations.title')}
            </Typography>
            <ToggleButtonGroup
              value={tabIndex}
              exclusive
              onChange={(evt, val) => setTabIndex(val)}
              aria-label="text alignment"
            >
              {isMorgan && (
                <ToggleButton value={0}>
                  <ContentPaste />
                </ToggleButton>
              )}
              <ToggleButton value={1} aria-label="left aligned">
                <CalendarIcon />
              </ToggleButton>
              <ToggleButton value={2} aria-label="centered">
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="column" spacing={2} alignItems="flex-end">
                {sessionQuery.isWorkSite() && (
                  <Button
                    onClick={() => window.open('https://form.jotform.com/222431908487057')}
                    variant="outlined"
                    size="large"
                  >
                    {t('fe.operations.button.worksite')}
                  </Button>
                )}
                {isMorgan && (
                  <>
                    <CreateExecutionButtonComponent
                      onClick={() => handleOpenModal()}
                      styleButton={classes.buttonContainer}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<AddIcon />}
                      disabled={!organisationUnitIDs}
                      onClick={() => setIsBookmarkModalOpen(true)}
                    >
                      <span>{t('fe.bookmarks.modal.button')}</span>
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
            <TableFiltersComponent
              filtersTypes={
                isMorgan
                  ? [
                      TableFilterType.SUPPLIER_ORGANISATION,
                      TableFilterType.SUPPLIER_ORGANISATION_UNIT,
                      TableFilterType.SERVICE_KIND,
                      TableFilterType.WASTE,
                      TableFilterType.CONTAINER,
                    ]
                  : [
                      TableFilterType.CLIENT_ORGANISATION,
                      TableFilterType.CLIENT_ORGANISATION_UNIT,
                      TableFilterType.SERVICE_KIND,
                      TableFilterType.WASTE,
                      TableFilterType.CONTAINER,
                    ]
              }
            />
          </Stack>
        </Stack>

        {tabIndex === 0 && (
          <Stack direction="column" spacing={2}>
            <BasicTab tabs={morganTabs()} value={tab} handleChange={setTab} />
            {tab?.tabType != 10 ? (
              <ExecutionsWaitingComponents currentTab={tab} />
            ) : (
              <Stack>
                <TableComponent
                  columns={[
                    COLUMN_TYPES.TEXT({
                      field: 'issue.reportedAt',
                      color: 'secondary',
                      label: t('global.dateTitle'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        params.row.reportedAt,
                      valueFormatter: (params) =>
                        params.value ? dayjs(params.value).format('DD/MM/YYYY') : '',
                      width: 110,
                    }),
                    COLUMN_TYPES.TEXT({
                      field: 'clientOrganisationUnitName',
                      label: t('fe.operations.nonCompliance.table.clientOrganisationUnitName'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        params.row.clientOrganisationUnitName,
                      width: 250,
                    }),
                    COLUMN_TYPES.TEXT({
                      field: 'supplierOrganisationUnitName',
                      label: t('fe.operations.nonCompliance.table.supplierOrganisationUnitName'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        params.row.supplierOrganisationUnitName,
                      width: 250,
                    }),
                    COLUMN_TYPES.TEXT_BOLD({
                      field: 'issueKind.name',
                      label: t('fe.operations.nonCompliance.table.type'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        t(`${params.row.typeTranslationKey}`),
                      width: 250,
                    }),
                    COLUMN_TYPES.TEXT({
                      field: 'comment',
                      color: 'secondary',
                      label: t('fe.operations.nonCompliance.table.comments'),
                      fullWidth: true,
                      minWidth: 400,
                    }),
                    COLUMN_TYPES.TEXT_WITH_ACTION({
                      field: 'execution.ticketSignature',
                      actionLabel: (issue: Issue) => issue.executionTicketSignature,
                      color: 'success',
                      textButton: true,
                      handleClick: (issue: Issue) => undefined,
                      label: t('fe.operations.nonCompliance.table.ticket'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        params.row.executionTicketSignature,
                      width: 170,
                    }),
                    COLUMN_TYPES.TEXT({
                      field: 'waste.name',
                      color: 'secondary',
                      label: t('fe.operations.nonCompliance.table.waste'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) => params.row.waste,
                      width: 250,
                    }),
                    COLUMN_TYPES.TEXT({
                      field: 'container.name',
                      color: 'secondary',
                      label: t('fe.operations.nonCompliance.table.container'),
                      sortable: true,
                      valueGetter: (params: GridValueGetterParams<any, Issue>) =>
                        params.row.container,
                      width: 200,
                    }),
                    COLUMN_TYPES.SELECT({
                      field: 'status',
                      label: t('global.status'),
                      options: IssueStatusSelectItems(),
                      sortable: true,
                      width: 150,
                    }),
                    COLUMN_TYPES.ACTIONS({
                      handleShowRow: (issue: Issue) => handleDetails(issue),
                    }),
                  ]}
                  autoHeight
                  loading={issueLoading}
                  totalRows={total}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={limitPerPage}
                  handlePageChange={(page) => handlechangePage(page + 1)}
                  page={page - 1}
                  handleSortModelChange={handleSort}
                  sortModel={[sort]}
                  data={issues ?? []}
                  disableEdition
                  disableRowSelection
                />
              </Stack>
            )}
          </Stack>
        )}
        {tabIndex === 1 && (
          <Stack>
            <PlanningWeekComponent handleOpenModal={handleOpenModal} />
          </Stack>
        )}
        {tabIndex === 2 && (
          <Stack pt={2}>
            <OperationsListComponent />
          </Stack>
        )}
      </Stack>

      {isBottomModalOpen && isMobiles && (
        <ActionOperationsScreen handleClickCloseModal={() => setIsBottomModalOpen(false)} />
      )}

      {isBookmarkModalOpen && (
        <ModalComponent
          handleClose={() => setIsBookmarkModalOpen(false)}
          title={t('fe.bookmarks.modal.title')}
          maxWidth="lg"
          maxHeight="85vh"
        >
          <Box>
            <Grid container spacing={4}>
              {bookmarks.length ? (
                <Stack flex={1} alignItems="center" height="400px" justifyContent="center">
                  <NoResultComponent />
                </Stack>
              ) : (
                bookmarks.map((it) => (
                  <Grid item xs={4} key={it.id}>
                    <BookmarkCards
                      bookmarkServices={it.bookmarkServices}
                      handleDeleteBookmark={() => handleDeleteBookmark(it.id)}
                      onOpenModal={(bookmarkServices: BookmarkServiceDto[]) =>
                        handleOpenModal(undefined, bookmarkServices)
                      }
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </ModalComponent>
      )}
      {isSideModalOpen ? <ExecutionModalScreen /> : undefined}
      {!_.isNil(openModal.issueId) && openModal?.issueId >= 0 && (
        <IssueDetailsDrawer
          issueId={openModal.issueId}
          handleClose={() => setOpenModal({ issueId: null })}
        />
      )}
    </Stack>
  );
};

export default OperationsScreen;
