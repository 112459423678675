import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SnackbarModel } from './snackbar.model';

export type SnackbarState = EntityState<SnackbarModel>;

@StoreConfig({
  name: 'snackbar',
})
export class SnackbarStore extends EntityStore<SnackbarState> {
  constructor() {
    super();
  }
}

export const snackbarStore = new SnackbarStore();
