import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

export const useMaterialDatagridStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    width: '100%',

    '&[color=primary] .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& .hiddenHeader': {
      opacity: 0,
      padding: 0,

      '& .MuiDataGrid-columnHeaderTitle': {
        display: 'none',
      },
    },

    '& .value-multi': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },

    '& .title-mobile': {
      gridColumn: 'span 4 !important',
      justifyContent: 'start !important',
      minWidth: '100% !important',
      gridArea: 1,
      minHeight: '12px !important',
    },

    '& .date-mobile': {
      position: 'absolute',
      top: '0 !important',
      right: '0 !important',
      justifyContent: 'end !important',
      minWidth: '100% !important',
      gridArea: 1,
      minHeight: '12px !important',
      marginRight: '15px !important',
    },

    '& .column-mobile': {
      minWidth: '100% !important',
      maxWidth: '100% !important',
      minHeight: '12px !important',
    },

    '& .button-mobile': {
      minWidth: '100% !important',
      maxWidth: '100% !important',
      minHeight: '12px !important',
      gridArea: 3,
      justifyContent: 'flex-start !important',
      marginTop: '8px !important',
    },

    '& .column-ticket-mobile': {
      minWidth: '100% !important',
      maxWidth: '100% !important',
      minHeight: '12px !important',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.3,
      color: Colors.darkGray,
    },

    '& .value-14-700': {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.3,
    },

    '& .value-14-800': {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.3,
      textTransform: 'uppercase !important',
    },

    '& .value-14-400': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.3,
      // opacity: 0.3,
      color: Colors.darkGray,
    },

    '& .rowIcon': {
      minWidth: '20px',
      maxWidth: '20px',
      minHeight: '20px',
      maxHeight: '20px',
    },

    '& .MuiDataGrid[isRowSelectable=true]': {
      backgroundColor: 'red !important',
    },

    '& .MuiDataGrid-root': {
      border: 'none',
    },

    '& .MuiDataGrid-columnsContainer': {
      // backgroundColor: 'transparent',
      // border: 'none !important',
      borderBottom: `4px solid ${theme.palette.secondary.main}`,
      borderRadius: '8px 8px 0 0',
    },

    '& .MuiDataGrid-columnHeaders': {
      outline: 'none !important',
      userSelect: 'none !important',
      borderBottom: (props: any) =>
        props.hideHeader === true ? 'none' : `2px solid ${theme.palette.secondary.main} !important`,

      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },

      '&.hiddenHeader': {
        padding: '0 !important',
      },

      '&.noPaddingLeft': {
        paddingLeft: '0 !important',
      },
      '&.noPaddingRight': {
        paddingRight: '0 !important',
      },
    },

    '& .MuiDataGrid-iconButtonContainer': {
      '& .MuiIconButton-root': {
        color: theme.palette.secondary.main,
      },
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'none',
    },

    '& .MuiDataGrid-row': {
      borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.1)} !important`,
      '&.Mui-selected': {
        backgroundColor: 'transparent !important',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
      // [theme.breakpoints.down('lg')]: {
      //   position: 'relative !important',
      //   display: 'grid !important',
      //   minWidth: 'auto !important',
      //   width: 'auto !important',
      //   padding: '12px !important',
      //   minHeight: '100% !important',
      //   maxHeight: '100% !important',
      // },
    },

    // '& .css-uw2ren-MuiDataGrid-virtualScrollerRenderZone': {
    //   [theme.breakpoints.down('lg')]: {
    //     width: '100% !important',
    //   },
    // },
    //
    // '& .MuiDataGrid-virtualScrollerRenderZone': {
    //   [theme.breakpoints.only('xs')]: {
    //     position: 'inherit !important',
    //   },
    //   width: '100% !important',
    // },

    '& .MuiDataGrid-cell': {
      border: 'none !important',
      outline: 'none !important',
      padding: '0 16px !important',
      userSelect: 'none !important',
      [theme.breakpoints.down('lg')]: {
        padding: '0px !important',
      },

      '&.hiddenCell': {
        display: 'none',
      },

      '&.noPaddingLeft': {
        paddingLeft: '6px !important',
      },
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
    },

    '& .MuiTablePagination-toolbar': {
      height: 40,
      minHeight: 0,
    },

    '& .MuiTablePagination-root': {
      color: alpha(theme.palette.neutral.contrastText, 0.3),
      fontSize: 12,
      fontWeight: 500,
    },

    '& .MuiTablePagination-select': {
      color: alpha(theme.palette.neutral.contrastText, 0.3),
      fontSize: 12,
      fontWeight: 500,
    },

    '& .MuiTablePagination-selectIcon': {
      color: alpha(theme.palette.neutral.contrastText, 0.3),
    },
  },
  hideHeaderContainer: {
    display: 'none !important',
  },
  selectableRow: {
    cursor: 'pointer',

    '&.Mui-selected': {
      cursor: 'default',
    },
  },
}));
