import React, { FC, useEffect, useState } from 'react';
import AppBarDropdown from 'components/AppBar/AppBarDropdown/AppBarDropdown';
import { ReactComponent as IconBuilding } from 'assets/icons/rework/icon_building.svg';
import { organisationsQuery, organisationsService } from 'store/organisation/organisations';
import SnackError from 'utils/models/snackError';
import { useSnackbar } from 'notistack';
import { AutocompleteData } from 'utils/models/autocomplete-data';
import { Button } from '@mui/material';
import { useSearchOrganisations } from 'store/organisation/organisations/hooks/useSearchOrganisations';
import { useTranslation } from 'react-i18next';
import { sessionQuery } from 'store/session';

const AppBarDropdownOrganisation: FC = () => {
  const [searchString, setSearchString] = useState<string>('');
  const [organisation, setOrganisation] = useState<AutocompleteData | undefined>();
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { organisations, count, total } = useSearchOrganisations(searchString, offset);

  useEffect(() => {
    const activeClientId = organisationsQuery.activeOrganisationId;
    if (activeClientId) {
      organisationsService
        .fetchOrganisation(sessionQuery.isMorgan === true, activeClientId)
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
    const _activeClientAsAutocomplete = organisationsQuery.activeOrganisationAsAutocomplete$.subscribe(
      setOrganisation
    );
    const _isLoading = organisationsQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _activeClientAsAutocomplete.unsubscribe();
      _isLoading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setOffset(0);
  }, [searchString]);

  return (
    <AppBarDropdown
      bottomComponent={
        offset + count !== total ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
            <Button variant="text" onClick={() => setOffset(offset + count)}>
              {t('fe.appBar.clientLoadMore')}
            </Button>
          </div>
        ) : undefined
      }
      disableCheckBox
      isLoadingSearch={isLoading}
      icon={<IconBuilding />}
      handleChange={(value) =>
        value
          ? organisationsService.handleSelectOrganisation(value)
          : organisationsService.removeActiveOrganisation()
      }
      values={organisation ? [organisation] : undefined}
      data={organisations}
      placeholder={t('fe.appBar.clientPlaceholder')}
      handleSearchChange={setSearchString}
    />
  );
};

export default AppBarDropdownOrganisation;
