import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as Chat } from 'assets/icons/rework/chatCollect.svg';
import { ReactComponent as Warning } from 'assets/icons/rework/icon_warning.svg';

interface IconTypeProps {
  params?: GridRenderCellParams;
}

const IconType: FC<IconTypeProps> = (props) => {
  const { params } = props;
  const isMobiles = isMobile();

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingTop: isMobiles ? 5 : 0 }}>
      {params?.row.commentsCount > 0 && <Chat style={{ marginRight: 10 }} width={18} height={18} />}
      {params?.row.issuesCount > 0 && <Warning style={{ marginRight: 10 }} />}
      {/*{params?.row.executionGroup && <Group width={18} height={18} />}*/}
    </div>
  );
};

export default IconType;
