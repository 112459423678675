import React, { FC, useCallback, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/icons/addDocument.svg';
import { useSnackbar } from 'notistack';
import FileItem from 'components/FileItem';
import { useTranslation } from 'react-i18next';
import { ID } from '@datorama/akita';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { IssueDetailsUi } from 'store/operations/executionIssue';
import {
  IssueDocument,
  issueDocumentsQuery,
  issueDocumentsService,
} from 'store/operations/issueDocuments';

interface IssueDocumentFormProps {
  issue: IssueDetailsUi;
}

const IssueDocumentForm: FC<IssueDocumentFormProps> = (props) => {
  const { issue } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<IssueDocument[]>([]);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const popConfirm = usePopup();

  useEffect(() => {
    issueDocumentsService.populateDocumentsFromIssue(issue);
    const _documents = issueDocumentsQuery.documentsFromIssue$(issue.id).subscribe(setDocuments);
    return () => {
      _documents.unsubscribe();
    };
  }, [enqueueSnackbar]);

  const uploadIssueFile = useCallback(
    async (file?: File) => {
      if (file) {
        setIsUploadingDocument(true);
        try {
          await issueDocumentsService.uploadDocument(file, issue.id);
        } catch (e: any) {
          enqueueSnackbar(e.text, e.options);
        }
        setIsUploadingDocument(false);
      }
    },
    [issue, enqueueSnackbar, setIsUploadingDocument]
  );

  const showDocument = (document: IssueDocument) => {
    issueDocumentsService.fetchDocument(document);
  };

  const removeDocument = (documentId: ID) => {
    popConfirm({
      title: t('fe.executionModal.deleteDocument.title'),
      description: t('fe.executionModal.deleteDocument.description'),
      buttons: [
        {
          label: t('fe.cancel'),
          color: 'error',
          variant: 'outlined',
          onClick: () => undefined,
        },
        {
          label: t('fe.executionModal.deleteDocument.action'),
          color: 'error',
          onClick: () => {
            issueDocumentsService.removeDocument(documentId);
            enqueueSnackbar(t('fe.executionModal.deleteDocument.success'), { variant: 'success' });
          },
        },
      ],
    });
  };

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">{t('fe.issueModal.issueInfos.documentTitle')}</Typography>
        </Grid>
        <Grid item>
          <form encType="multipart/form-data" method="post">
            <label htmlFor="icon-button-file-issue">
              <input
                accept="file/*"
                style={{ display: 'none' }}
                id="icon-button-file-issue"
                type="file"
                onChange={(e) => uploadIssueFile(e.target.files?.[0])}
              />
              <IconButton aria-label="upload file" component="span" style={{ padding: '0px' }}>
                <AddIcon />
              </IconButton>
            </label>
          </form>
        </Grid>
      </Grid>
      {documents.map((document) => (
        <Grid item key={document.id} style={{ marginRight: 20 }}>
          <FileItem
            handleClose={() => removeDocument(document.id)}
            title={document.dto?.name}
            handleClick={() => showDocument(document)}
            image={
              document.dto?.mimetype?.includes('image/')
                ? issueDocumentsService.getDocumentUrl(document)
                : undefined
            }
          />
        </Grid>
      ))}
      {isUploadingDocument && (
        <Grid item>
          <Skeleton variant="rectangular" width={90} height={95} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default IssueDocumentForm;
