import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useNonConformityItemStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    width: '211px',
    height: '54px',
    padding: '10px 15px !important',
    // display: 'flex',
    backgroundColor: '#fff',
  },
  logo: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  positionTypography: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  title: {
    fontWeight: 700 + '!important',
    fontSize: '14px !important',
  },
  subtitle: {
    fontSize: '10px !important',
  },
}));
