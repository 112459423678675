import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExecutionCountPerDayDto } from 'api/dto/execution/execution-count-per-day.dto';

export type ExecutionMonthPlanningState = EntityState<ExecutionCountPerDayDto>;

@StoreConfig({
  name: 'executionMonthPlanning',
  resettable: true,
  idKey: 'date',
})
export class ExecutionMonthPlanningStore extends EntityStore<ExecutionMonthPlanningState> {
  constructor() {
    super();
  }
}

export const executionMonthPlanningStore = new ExecutionMonthPlanningStore();
