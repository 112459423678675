import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createHistory } from 'utils/createHistory';
import { App } from './components/App';
import { CssBaseline, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import materialTheme from './style/materialTheme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Colors } from './style/materialStylings';
import LoadingScreen from 'screens/azure/loading/Loading.screen';
import ErrorBoundary from './components/ErrorBoundary';
import { storageService } from 'utils/StorageService/StorageService';
import { LocalStorageKeyJson } from 'constants/LocalStorageKey';

// eslint-disable-next-line require-await
const initiateApplication = async () => {
  const history = createHistory();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.executionId || params.issueId) {
    storageService.setItemJson(LocalStorageKeyJson.NotificationQueryParams, {
      executionId: params.executionId,
      issueId: params.issueId,
    });
  }

  return {
    history,
  };
};

initiateApplication().then(({ history }) => {
  ReactDOM.render(
    <MaterialThemeProvider theme={materialTheme(Colors)}>
      <CssBaseline />
      <ErrorBoundary>
        <Suspense fallback={<LoadingScreen />}>
          <App history={history} />
        </Suspense>
      </ErrorBoundary>
    </MaterialThemeProvider>,
    document.getElementById('root')
  );
  serviceWorkerRegistration.register();
});
