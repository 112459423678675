import { PatrickStatsStore, patrickStatsStore } from './patrick-stats.store';
import { ID } from '@datorama/akita';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { IssueCountStatsUi } from '../operationScreenStats/operation-screen-stats.model';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import i18n from 'utils/data/i18n.utils';

export class PatrickStatsService {
  constructor(private store: PatrickStatsStore) {}

  private updateIssueCountStatus = (issuesStatus: IssueCountStatsUi) =>
    this.store.update((state) => ({
      ...state,
      issueStatus: {
        stats: issuesStatus,
        isLoading: false,
      },
    }));

  fetchstats = async (from: Date, to: Date, organisationId?: ID, orgUnitIds?: ID[]) => {
    this.store.setIssueStatusLoading(true);

    const [issueCountStatus, e] = await resolve(
      metawasteApi.getIssueCount(
        from,
        to,
        [],
        orgUnitIds,
        organisationId,
        ExecutionStatusEnum.CREATED,
        true,
        ExecutionStatusEnum.EXECUTED
      )
    );
    if (!issueCountStatus || e) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueCountStatus'));
    }
    this.updateIssueCountStatus(issueCountStatus);
  };
}

export const patrickStatsService = new PatrickStatsService(patrickStatsStore);
