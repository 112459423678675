import React, { FC, useState } from 'react';
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import { ReactComponent as MoreIcon } from 'assets/icons/moreFill.svg';
import MaterialAutocomplete from 'components/MaterialAutocomplete/MaterialAutocomplete';
import SideModal from 'components/Modal/SideModal';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield';
import MaterialDropdownForm from 'components/MaterialDropdown/MaterialDropdownForm';
import MaterialMultipleDatePicker from 'components/MaterialDatePicker/MaterialMultipleDatePicker';
import { usePopup } from 'components/Modal/Popup/PopupService';
import { ReactComponent as AddIcon } from 'assets/icons/addDocument.svg';
import FileItem from 'components/FileItem';
import { AutocompleteData } from 'utils/models/autocomplete-data';

interface CreateInvoiceScreenProps {
  handleClickCloseModal?: () => void;
  handleClickOptions?: () => void;
  importInvoice?: boolean;
}

const autocompleteData = [
  {
    id: '61e96630d988a1d63d493c3d',
    title: 'do voluptate',
    description: 'est nulla laboris',
  },
  {
    id: '61e966309e4f93239f83ae15',
    title: 'exercitation laborum',
    description: 'velit qui qui',
  },
  {
    id: '61e96630f60756297effa96e',
    title: 'qui dolor',
    description: 'eu sint quis',
  },
  {
    id: '61e96630fc558e8448518399',
    title: 'esse sint',
    description: 'voluptate cupidatat cupidatat',
  },
  {
    id: '61e96630fe58a64f5f3a64c9',
    title: 'est exercitation',
    description: 'esse non elit',
  },
  {
    id: '61e9663073642ea15406bc83',
    title: 'ad fugiat',
    description: 'aute minim esse',
  },
];

const CreateInvoiceScreen: FC<CreateInvoiceScreenProps> = (props) => {
  const { handleClickCloseModal, handleClickOptions, importInvoice = false } = props;
  const classes = useCreateStyles();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [collecteAutocomplete, setCollecteAutocomplete] = useState('');
  const [collectValue, setCollectValue] = useState('');
  const [dropdown, setDropdown] = useState('');
  const [autocompletePredictions, setAutocompletePredictions] = useState<AutocompleteData[]>(
    autocompleteData
  );
  const popConfirm = usePopup();

  return (
    <SideModal handleClickCloseModal={handleClickCloseModal}>
      <Grid container rowSpacing={3}>
        <Grid item container justifyContent="space-between">
          <Typography variant="subtitle2">Crée par Patrick S, le 12/12/2021</Typography>
          <IconButton onClick={handleClickOptions}>
            <MoreIcon width={20} height={20} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ fontSize: '24px' }}>
            Facture N°1243
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item container xs={12} rowSpacing={2}>
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MaterialBasicTextfield
              label="Client"
              type="text"
              placeholder="Decathlon Genève - Centre"
              color="primary"
              value={collectValue}
              handleChange={setCollectValue}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialDropdownForm
              label="Site client"
              // error="Field required"
              color="primary"
              placeholder="Decathlon Genève"
              handleChange={(value) => setDropdown(value)}
              value={dropdown}
              items={[
                { label: 'Decathlon Genève', value: '1' },
                { label: 'Decathlon Lausanne', value: '2' },
                { label: 'Decathlon Marseille', value: '3' },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialBasicTextfield
              label="Adresse 
            de facturation"
              type="text"
              placeholder="Chem. de Riantbosson 5, 1217 
            Meyrin, Suisse"
              color="primary"
              value={collectValue}
              handleChange={setCollectValue}
            />
          </Grid>
          <Grid item xs={12} style={{ width: '100% !important' }}>
            <MaterialMultipleDatePicker
              label="Periode concernée"
              labelFrom="Du"
              labelTo="à"
              valueFrom={startDate ? new Date(startDate) : undefined}
              valueTo={endDate ? new Date(endDate) : undefined}
              handleChangeFrom={setStartDate}
              handleChangeTo={setEndDate}
              withIcon={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item container xs={12} rowSpacing={2}>
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              Paiement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MaterialAutocomplete
              label="Delais"
              color="primary"
              handleChange={(value) => setCollecteAutocomplete(value)}
              // error={errors.currentEmail}
              value={collecteAutocomplete}
              data={autocompletePredictions}
              placeholder="90 jours"
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialBasicTextfield
              label="Total TTC"
              type="text"
              placeholder="156 000 FRC"
              color="primary"
              value={collectValue}
              handleChange={setCollectValue}
            />
          </Grid>
        </Grid>
        {importInvoice ? (
          <>
            <Grid item xs={12}>
              <Divider style={{ width: '100%' }} />
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4">Document</Typography>
              </Grid>
              <Grid item>
                <form encType="multipart/form-data">
                  <label htmlFor="icon-button-file">
                    <input
                      accept="file/*"
                      style={{ display: 'none' }}
                      id="icon-button-file"
                      type="file"
                      multiple
                      onChange={() => undefined}
                    />
                    <IconButton
                      aria-label="upload file"
                      component="span"
                      style={{ padding: '0px' }}
                    >
                      <AddIcon />
                    </IconButton>
                  </label>
                </form>
              </Grid>
            </Grid>
            <Grid item>
              <FileItem handleClose={() => undefined} title="Suivi 1.pdf" size="158ko" />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ width: '100%' }} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              popConfirm({
                title: 'Facture générée avec succès',
                description:
                  'La facture que vous avez générée sera validée par les administrateurs de MetaWaste, puis envoyée à votre client.',
                buttons: [
                  {
                    label: 'OK',
                    color: 'primary',
                    onClick: () => {
                      console.log('OK');
                    },
                  },
                ],
              })
            }
            className={classes.buttonPosition}
          >
            Générer
          </Button>
        </Grid>
      </Grid>
    </SideModal>
  );
};

export default CreateInvoiceScreen;
