import React, { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import MaterialMultiDropdownTag from 'components/MaterialMultiDropdown/MaterialMultiDropdownTag';
import { isMobile } from 'utils/isMobile';
import { useTranslation } from 'react-i18next';
import {
  OrganisationUserDto,
  organisationUserToMaterialDropdownItem,
} from 'api/dto/user/OrganisationUserDto';
import { organisationUnitToMaterialDropdownItem } from 'store/organisation/organisationUnits';

interface SettingsOrganisationComponentProps {
  organisation: OrganisationUserDto;
}

const SettingsOrganisationComponent: FC<SettingsOrganisationComponentProps> = (props) => {
  const { organisation } = props;
  const classes = useCreateStyles();
  const { t } = useTranslation();
  const isMobiles = isMobile();

  const materialOrganisations = useMemo(
    () => organisationUserToMaterialDropdownItem(organisation),
    [organisation]
  );
  const materialOrgUnit = useMemo(
    () => organisation.organisationUnits.map(organisationUnitToMaterialDropdownItem),
    [organisation]
  );

  return (
    <>
      <Grid item xs={12}>
        <MaterialMultiDropdownTag
          label={isMobiles ? undefined : t('fe.settings.organisation')}
          color="primary"
          readOnly
          placeholder={t('fe.settings.placeholder.chooseOrganisation')}
          selectedValues={[organisation.id]}
          items={[materialOrganisations]}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialMultiDropdownTag
          label={isMobiles ? undefined : t('fe.settings.unity')}
          color="primary"
          readOnly
          placeholder={t('fe.settings.placeholder.chooseUnity')}
          selectedValues={materialOrgUnit.map((orgUnit) => orgUnit.value)}
          items={materialOrgUnit}
        />
      </Grid>
      <Grid item xs={12}>
        <hr style={{ borderTop: 'dashed 1px' }} />
      </Grid>
    </>
  );
};

export default SettingsOrganisationComponent;
