import { ID } from '@datorama/akita';
import { Box } from '@mui/material';
import { ReactComponent as IconLocalisation } from 'assets/icons/rework/icon_localisation.svg';
import AppBarDropdown from 'components/AppBar/AppBarDropdown/AppBarDropdown';
import { FC, useState } from 'react';
import { AutocompleteData } from 'utils/models/autocomplete-data';

const fakeOrganisationsUnit: AutocompleteData[] = Array.from({ length: 5 }, (_, i) => ({
  id: i.toString(),
  title: `OrganisationUnit ${i}`,
  description: `description orgaUnit ${i}`,
}));

const ObAppBarDropdownOrgUnit: FC = () => {
  const [searchCurrentValue, setSearchCurrentValue] = useState('');
  const [forgUnits, setFOrgUnits] = useState<AutocompleteData | undefined>(
    fakeOrganisationsUnit[1]
  );

  const filteredFakeOrganisationsUnit = fakeOrganisationsUnit.filter((it) =>
    it.title.toLowerCase().includes(searchCurrentValue.toLowerCase())
  );

  const handleSelectOrganisation = (value?: ID) => {
    const el = filteredFakeOrganisationsUnit.find((it) => it.id === value);

    if (el) {
      setFOrgUnits(el);
    } else {
      setFOrgUnits(undefined);
    }
  };

  return (
    <Box className="step2">
      <AppBarDropdown
        icon={<IconLocalisation />}
        label="Type de dechet 2"
        handleChange={handleSelectOrganisation}
        value={forgUnits}
        data={filteredFakeOrganisationsUnit}
        placeholder="Select your organisation unit"
        handleSearchChange={setSearchCurrentValue}
      />
    </Box>
  );
};

export default ObAppBarDropdownOrgUnit;
