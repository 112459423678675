import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useAzureRedirectStyles = makeStyles((theme: Theme) => ({
  bgImage: {
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: 'url(/images/metawaste-background.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100vh',
  },
}));
