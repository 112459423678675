import { Close } from '@mui/icons-material';
import { Badge, Button, CardActionArea, IconButton, Skeleton } from '@mui/material';
import { AddWhite } from 'assets/icons';
import AlertComponent from 'components/Modal/Alert.component';
import { Colors } from 'constants/Colors';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentInfo } from 'store/issues/issues.model';

interface SelectFileCardComponentProps {
  accept?: string;
  document?: DocumentInfo;
  readonly?: boolean;
  handleUpdateDocument?: (document?: DocumentInfo) => void;
}

const SelectFileCardComponent = (props: SelectFileCardComponentProps) => {
  const { accept, document, readonly, handleUpdateDocument } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [addingDocument, setAddingDocument] = useState(false);

  let documentUrl: string | undefined = undefined;
  if (document) documentUrl = document.file ? URL.createObjectURL(document.file) : undefined;

  const addDocument = (file: File) => {
    setAddingDocument(true);
    // filesService
    //   .uploadFile(file, DocumentKind.ISSUE)
    //   .pipe(finalize(() => setAddingDocument(false)))
    //   .subscribe({
    //     next: handleUpdateDocument,
    //     error: (err) => enqueueSnackbar(err.text, err.options),
    //   });
    setAddingDocument(false);
  };

  return (
    <>
      <input
        type="file"
        accept={accept}
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (!readonly) {
            const file = e.target.files?.[0];
            if (!file) return;

            addDocument(file);
            e.currentTarget.value = '';
          }
        }}
      />
      {addingDocument ? (
        <Skeleton variant="rectangular" height="175px" width="100%" sx={{ borderRadius: '6px' }} />
      ) : (
        <>
          <Badge
            componentsProps={{ badge: { style: { right: 4, top: 4 } } }}
            invisible={!handleUpdateDocument || !document}
            badgeContent={
              <IconButton onClick={() => setConfirmDelete(true)} aria-label="remove-document">
                <Close style={{ color: Colors.white, height: 8, width: 8 }} />
              </IconButton>
            }
          >
            <CardActionArea
              aria-label="big-document"
              onClick={() => (!readonly ? inputFileRef.current?.click() : undefined)}
              disableRipple
              sx={{
                backgroundImage: documentUrl ? `url(${documentUrl})` : undefined,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                width: '100%',
                height: 175,
                backgroundColor: Colors.grey,
                borderRadius: '6px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AddWhite
                style={{
                  backgroundColor: Colors.text,
                  borderRadius: 4,
                  padding: '5px',
                  height: 24,
                  width: 24,
                }}
              />
            </CardActionArea>
          </Badge>
          {confirmDelete && (
            <AlertComponent
              title={t('delete.title')}
              description={t('delete.description.document')}
              handleClose={() => setConfirmDelete(false)}
              actions={
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setConfirmDelete(false)}
                  >
                    {t('global.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleUpdateDocument?.(undefined);
                      setConfirmDelete(false);
                    }}
                  >
                    {t('global.delete')}
                  </Button>
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default SelectFileCardComponent;
