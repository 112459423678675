import OnBordingDashBoard from 'components/Onbording/OnBordingDashBoard.screen';
import { ReactComponent as DashboardOutlined } from '../assets/icons/sidebarDashboardOutlined.svg';
import { ReactComponent as DashboardFilled } from '../assets/icons/sidebarDashboardFilled.svg';
import DashboardScreen from '../screens/auth/dashboard/Dashboard.screen';
import { ReactComponent as OperationsOutlined } from '../assets/icons/sidebarOperationsOutlined.svg';
import { ReactComponent as OperationsFilled } from '../assets/icons/sidebarOperationsFilled.svg';
import OperationsScreen from '../screens/auth/operations/Operations.screen';
// import { ReactComponent as RapportsOutlined } from '../assets/icons/sidebarRapportsOutlined.svg';
// import { ReactComponent as RapportsFilled } from '../assets/icons/sidebarRapportsFilled.svg';
// import ReportsScreen from '../screens/auth/report/Reports.screen';
import { ReactComponent as FactureOutlined } from '../assets/icons/sidebarFactureOutlined.svg';
import { ReactComponent as FactureFilled } from '../assets/icons/sidebarFactureFilled.svg';
// import InvoicesScreen from '../screens/auth/invoice/Invoices.screen';
import { ReactComponent as DocumentsOutlined } from '../assets/icons/sidebarDocumentsOutlined.svg';
import { ReactComponent as DocumentsFilled } from '../assets/icons/sidebarDocumentsFilled.svg';
// import DocumentsScreen from '../screens/auth/document/Documents.screen';
import i18n from 'utils/data/i18n.utils';
import InvoicesScreen from 'screens/auth/invoice/Invoices.screen';
import ContractsScreen from 'screens/auth/contracts/Contracts.screen';

export enum MainSources {
  Dashboard = 'dashboard',
  Operations = 'operations',
  Login = 'login',
  Language = 'language',
  SetPassword = 'setPassword',
  RetrievePassword = 'retrievePassword',
  ResetPassword = 'reset-password',
  PickUps = 'pickups',
  Offline = 'offline',
  Reports = 'reports',
  Issues = 'issues',
  Demo = 'demo',
  AzureRedirect = 'azureRedirect',
  Invoice = 'invoice',
  Contracts = 'contracts',
  Contract = 'contract',
}

export enum RouteResource {
  Clients = 'clients',
  Reports = 'reports',
  Billing = 'billing',
  Team = 'team',
  Archived = 'archived',
  Documents = 'documents',
}

export const RoutePath = {
  Dashboard: `/${MainSources.Dashboard}`,
  Operations: `/${MainSources.Operations}`,
  Invoice: `/${MainSources.Invoice}`,
  Contracts: `/${MainSources.Contracts}`,
  Login: `/${MainSources.Login}`,
  AzureRedirect: `/${MainSources.AzureRedirect}`,
  Language: `/${MainSources.Language}`,
  Demo: `/${MainSources.Demo}`,
  SetPassword: `/${MainSources.SetPassword}`,
  RetrievePassword: `/${MainSources.RetrievePassword}`,
  ResetPassword: `/${MainSources.ResetPassword}`,
  Clients: `/${MainSources.Dashboard}/${RouteResource.Clients}`,
  Reports: `/${MainSources.Reports}`,
  Billing: `/${RouteResource.Billing}`,
  Team: `/${MainSources.Dashboard}/${RouteResource.Team}`,
  Archived: `/${MainSources.Dashboard}/${RouteResource.Archived}`,
  Issues: `/${MainSources.Issues}`,
  IssueDetails: `/${MainSources.Issues}/:id`,
  Documents: `/${RouteResource.Documents}`,
  PickUps: `/${MainSources.PickUps}`,
  PickUpDetails: `/${MainSources.PickUps}/:id`,
  Offline: `/${MainSources.Offline}`,
  AuditProposals: `/audit/:id`,
  Contract: `/${MainSources.Contracts}/:id`,
};

export const sidebarPages = (finance?: boolean) => {
  const pages = [
    {
      route: RoutePath.Dashboard,
      icon: <DashboardOutlined />,
      iconSelected: <DashboardFilled />,
      titleKey: i18n.t('fe.dashboard.title'),
      component: DashboardScreen,
    },
    {
      route: RoutePath.Operations,
      icon: <OperationsOutlined />,
      iconSelected: <OperationsFilled />,
      titleKey: i18n.t('fe.operations.title'),
      component: OperationsScreen,
    },
    // {
    //   route: RoutePath.Billing,
    //   icon: <FactureOutlined />,
    //   iconSelected: <FactureFilled />,
    //   titleKey: 'Factures',
    //   component: InvoicesScreen,
    // },
    // {
    //   route: RoutePath.Documents,
    //   icon: <DocumentsOutlined />,
    //   iconSelected: <DocumentsFilled />,
    //   titleKey: 'Documents',
    //   component: DocumentsScreen,
    // },
    // {
    //   route: RoutePath.Reports,
    //   icon: <RapportsOutlined />,
    //   iconSelected: <RapportsFilled />,
    //   titleKey: 'fe.reports.title',
    //   component: ReportsScreen,
    // },
  ];

  if (finance === true) {
    pages.push(
      {
        route: RoutePath.Contracts,
        icon: <DocumentsOutlined />,
        iconSelected: <DocumentsFilled />,
        titleKey: i18n.t('fe.contracts.title'),
        component: ContractsScreen,
      },
      {
        route: RoutePath.Invoice,
        icon: <FactureOutlined />,
        iconSelected: <FactureFilled />,
        titleKey: i18n.t('fe.invoices.title'),
        component: InvoicesScreen,
      }
    );
  }
  return pages;
};

export const obSidebarPages = () => [
  {
    route: RoutePath.Dashboard,
    icon: <DashboardOutlined />,
    iconSelected: <DashboardFilled />,
    titleKey: i18n.t('fe.dashboard.title'),
    component: OnBordingDashBoard,
  },
];
