import { ActivityDto } from 'api/dto/activities/ActivityDto';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export type ActivitiesState = EntityState<ActivityDto<any>>;

@StoreConfig({
  name: 'activities',
  resettable: true,
  idKey: 'entityId',
})
export class ActivitiesStore extends EntityStore<ActivitiesState> {
  constructor() {
    super();
  }
}

export const activitiesStore = new ActivitiesStore();
