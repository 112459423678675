import React, { FC } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useCreateStyles } from 'components/Modal/SideModal/Create.style';
import SideModal from 'components/Modal/SideModal';
import { isMobile } from 'utils/isMobile';
import MaterialBasicTextfield from 'components/MaterialTextfield/MaterialBasicTextfield';
import MaterialDropdownForm from 'components/MaterialDropdown/MaterialDropdownForm';
import { useTranslation } from 'react-i18next';
import { languageTabsSelect } from 'utils/enums/Language';
import SettingsDetailsComponent from './components/SettingsDetails.component';
import { getCurrentLanguage } from 'utils/data/i18n.utils';
import { sessionQuery } from 'store/session';
import { useObservable } from '@ngneat/react-rxjs';
import SettingsOrganisationComponent from './components/SettingsOrganisation.component';
import SettingsNotificationsComponent from './components/SettingsNotifications.component';

interface SettingsScreenProps {
  handleClickCloseModal?: () => void;
  handleClickOptions?: () => void;
}

const SettingsScreen: FC<SettingsScreenProps> = (props) => {
  const { handleClickCloseModal, handleClickOptions } = props;
  const classes = useCreateStyles();
  const { i18n, t } = useTranslation();
  const isMobiles = isMobile();
  const currentLanguage = getCurrentLanguage();
  const [userRoles] = useObservable(sessionQuery.roles$);
  const [organisations] = useObservable(sessionQuery.organisations$);

  const changeLanguage = (newValue: string) => {
    i18n.changeLanguage(newValue);
  };

  return (
    <SideModal handleClickCloseModal={handleClickCloseModal}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ fontSize: '24px' }}>
            {t('fe.settings.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MaterialDropdownForm
            label={t('fe.settings.language')}
            // error="Field required"
            color="primary"
            placeholder={t('fe.settings.placeholder.chooseLanguage')}
            handleChange={changeLanguage}
            value={currentLanguage}
            items={languageTabsSelect()}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <SettingsDetailsComponent />
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <SettingsNotificationsComponent />
        <Grid item xs={12}>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item container xs={12} rowSpacing={2}>
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              {t('fe.settings.subtitle.professional')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MaterialBasicTextfield
              label={t('fe.settings.type')}
              type="text"
              readOnly
              placeholder={t('fe.settings.placeholder.operatorSite')}
              color="primary"
              value={userRoles?.toString() ?? 'nothing'}
            />
          </Grid>
          {organisations.map((org) => (
            <SettingsOrganisationComponent key={org.id} organisation={org} />
          ))}
        </Grid>
      </Grid>
    </SideModal>
  );
};

export default SettingsScreen;
