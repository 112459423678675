import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import SnackError, { handleBasicSnackError } from 'utils/models/snackError';
import { ExecutionDocumentsStore, executionDocumentsStore } from './execution-documents.store';
import { ID } from '@datorama/akita';
import { ExecutionDetailsUi } from '../createExecution';
import { createExecutionDocument, ExecutionDocument } from './execution-document.model';
import { DocumentKindEnum } from 'utils/enums/DocumentKindEnum';
import { executionDocumentsQuery, ExecutionDocumentsQuery } from './execution-documents.query';
import { isAxiosError } from 'api/axios.utils';
import i18n from 'utils/data/i18n.utils';
import fileDownload from 'js-file-download';

export class ExecutionDocumentsService {
  constructor(private store: ExecutionDocumentsStore, private query: ExecutionDocumentsQuery) {}

  populateDocumentsFromExecution = (execution: ExecutionDetailsUi) => {
    const docs =
      execution.dto?.documents?.map((doc) => createExecutionDocument(execution.id, doc)) ?? [];
    this.store.upsertMany(docs);
    this.store.update((state) => ({
      ...state,
      originalDocuments: [...state.originalDocuments, ...docs],
    }));
  };

  uploadDocument = async (file: File, executionId: ID) => {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('name', file.name);
    formData.append('description', '');
    formData.append('documentKind', DocumentKindEnum.EXECUTION);

    const [document, e] = await resolve(metawasteApi.createDocument(formData));
    if (e || !document) {
      if (isAxiosError(e)) {
        if (e.response?.data.statusCode === 413) {
          throw new SnackError('errors.fileTooLarge', 'error');
        }
      }
      throw handleBasicSnackError(i18n.t('fe.errors.create.document'));
    }
    this.store.upsertMany([createExecutionDocument(executionId, document, file)]);
  };

  updateDocumentsForExecution = async (executionId: ID) => {
    const { documentsToAdd, documentsToDelete } = this.query.documentsUpdateFromExecution(
      executionId
    );
    if (!documentsToAdd.length && !documentsToDelete.length) {
      return;
    }
    const [, e] = await resolve(
      metawasteApi.editExecutionDocuments(executionId, { documentsToAdd, documentsToDelete })
    );
    if (e) {
      throw handleBasicSnackError(i18n.t('fe.errors.uploadDocument'));
    }
  };

  downloadDocument = async (document: ExecutionDocument) => {
    const [data, e] = await resolve(metawasteApi.getDocumentData(document.id));

    if (data) {
      const filename = document.dto?.filename || document.tmpFile?.name || 'file';
      fileDownload(data, filename);
    }
  };

  getDocumentUrl = (document: ExecutionDocument) => {
    if (document.tmpFile) {
      return URL.createObjectURL(document.tmpFile);
    }
    return metawasteApi.getDocumentUrl(document.id);
  };

  fetchDocument = (document: ExecutionDocument) => {
    window.open(this.getDocumentUrl(document), '_blank', '');
  };

  removeDocument = (documentId: ID) => {
    this.store.remove(documentId);
  };

  documentIdsForExecution = (executionId: ID): number[] =>
    this.query.documentIdsFromExecution(executionId);

  resetStore = () => {
    this.store.reset();
  };
}

export const executionDocumentsService = new ExecutionDocumentsService(
  executionDocumentsStore,
  executionDocumentsQuery
);
