import { GridColDef } from '@mui/x-data-grid-pro';
import { ColumnProps } from '../columnTypes.table';
import { Array } from 'utils/extensions/array.extensions';

export const COLUMN_TYPE_CUSTOM = (props: ColumnProps): GridColDef => ({
  cellClassName: (params) =>
    Array.mapNotNull([props.color, props.cellClassName?.(params as any)])?.join(' ') ?? '',
  field: props.field ?? '',
  flex: props.fullWidth ? 1 : undefined,
  headerName: props.label,
  editable: props.editable ?? true,
  minWidth: props.minWidth ?? 50,
  sortable: false,
  width: props.width ?? 150,
  renderCell: props.renderCell as any,
});
