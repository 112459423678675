import { ExecutionIssueKindsStore, executionIssueKindsStore } from './execution-issue-kinds.store';
import { resolve } from 'utils/data/promise-utils';
import { metawasteApi } from 'api/Axios';
import { handleBasicSnackError } from 'utils/models/snackError';
import { executionIssueKindsQuery, ExecutionIssueKindsQuery } from './execution-issue-kinds.query';
import i18n from 'utils/data/i18n.utils';

export class ExecutionIssueKindsService {
  constructor(private store: ExecutionIssueKindsStore, private query: ExecutionIssueKindsQuery) {}

  fetchIssueKinds = async () => {
    if (this.query.numberIssueKinds !== 0) {
      return;
    }
    const [issueKinds, e] = await resolve(metawasteApi.getIssueKinds());
    if (e || !issueKinds) {
      throw handleBasicSnackError(i18n.t('fe.errors.fetch.issueKind'));
    }
    this.store.upsertMany(issueKinds);
  };
}

export const executionIssueKindsService = new ExecutionIssueKindsService(
  executionIssueKindsStore,
  executionIssueKindsQuery
);
