import i18n from 'utils/data/i18n.utils';
import { SelectItem } from 'components/Select/SelectItem.interface';
import { Colors } from 'constants/Colors';
import { alpha } from '@mui/material';

export enum ExecutionStatusEnum {
  CREATED = 'CREATED',
  PLANNED = 'PLANNED_OR_ACCEPTED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE_OR_IN_PROGRESS',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
  EXECUTED = 'EXECUTED',
}

export const execStatusSelectItems = (): SelectItem[] => [
  {
    label: i18n.t('global.statusEnum.created'),
    value: ExecutionStatusEnum.CREATED,
    backgroundColor: alpha(Colors.textWithOpacity, 0.5),
  },
  {
    label: i18n.t('global.statusEnum.planned'),
    value: ExecutionStatusEnum.PLANNED,
    backgroundColor: alpha(Colors.warning, 0.5),
  },
  { label: i18n.t('global.statusEnum.rejected'), value: ExecutionStatusEnum.REJECTED },
  { label: i18n.t('global.statusEnum.active'), value: ExecutionStatusEnum.ACTIVE },
  { label: i18n.t('global.statusEnum.blocked'), value: ExecutionStatusEnum.BLOCKED },
  { label: i18n.t('global.statusEnum.archived'), value: ExecutionStatusEnum.ARCHIVED },
  {
    label: i18n.t('global.statusEnum.executed'),
    value: ExecutionStatusEnum.EXECUTED,
    backgroundColor: alpha(Colors.primary, 0.5),
  },
];

export const executionStatusTabs: string[] = ['ALL', ...Object.values(ExecutionStatusEnum)];

export const translateExecutionStatus = (status: string) =>
  i18n.t(`database.executionStatus.${status}`);
