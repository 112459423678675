import i18n from 'utils/data/i18n.utils';
import { SelectItem } from 'components/Select/SelectItem.interface';
import { Colors } from 'constants/Colors';

export enum AuditOfferSupplierStatusEnum {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  NONE = 'NONE',
}

export interface AuditOfferSelectItem extends SelectItem {
  color?: string;
}

export namespace AuditOfferSupplierStatus {
  export const selectItems = (): AuditOfferSelectItem[] => [
    {
      label: i18n.t('fe.audits.audit-offer-supplier.accepted'),
      value: AuditOfferSupplierStatusEnum.ACCEPTED,
      backgroundColor: '#58e06d33',
      color: Colors.success,
    },
    {
      label: i18n.t('fe.audits.audit-offer-supplier.denied'),
      value: AuditOfferSupplierStatusEnum.DENIED,
      backgroundColor: '#e6433c33',
      color: Colors.error,
    },
    {
      label: i18n.t('fe.select'),
      value: AuditOfferSupplierStatusEnum.NONE,
    },
  ];
}
