import { AddressDto } from './AddressDto';
import { UserLightDto } from '../user/UserLightDto';
import { OrganisationUnit } from 'store/organisation/organisationUnits';

export interface ClientOrganisationUnitDto {
  id: number;
  name?: string;
  unitManagers: UserLightDto[];
  address: AddressDto;
}

export function formatClientOrgUnitDtoToOrganisationUnit({
  id,
  name,
  address,
}: ClientOrganisationUnitDto): OrganisationUnit {
  return {
    id,
    name: name ?? '',
    address,
  };
}
