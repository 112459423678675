import React, { ReactElement } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';

export interface ModalComponentProps {
  handleClose?: () => void;
  title: string;
  description?: string;
  buttons?: {
    label: string;
    color: 'primary' | 'error';
    variant?: 'contained' | 'outlined';
    onClick: () => void;
    disabled?: boolean;
  }[];
  handleSubmit?: () => void;
  children?: ReactElement | ReactElement[];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg';
  maxHeight?: string;
}
const ModalComponent = (props: ModalComponentProps) => {
  const {
    title,
    description,
    children,
    buttons = [],
    handleClose,
    handleSubmit,
    maxWidth,
    maxHeight,
  } = props;
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      sx={{ maxHeight, top: 50 }}
    >
      {!!handleClose && (
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 18, top: 18 }}>
          <CloseIcon style={{ width: 30, height: 30 }} />
        </IconButton>
      )}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {buttons.length ? (
        <DialogActions>
          <Grid container spacing={2}>
            {buttons.map((button) => (
              <Grid item key={`${button.label} - ${button.color}`}>
                <Button
                  key={`${button.label} - ${button.color}`}
                  color={button.color}
                  variant={button.variant ?? 'contained'}
                  onClick={() => {
                    button.onClick();
                    handleSubmit?.();
                  }}
                  disabled={button.disabled}
                >
                  {button.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default ModalComponent;
