import { QueryEntity } from '@datorama/akita';
import { SnackbarState, SnackbarStore, snackbarStore } from './snackbar.store';

export class SnackbarQuery extends QueryEntity<SnackbarState> {
  constructor(protected store: SnackbarStore) {
    super(store);
  }

  messages$ = this.selectAll();
}

export const snackbarQuery = new SnackbarQuery(snackbarStore);
