import { IssueDetailsUi } from 'store/operations/executionIssue';

export interface IssueUpdateDto {
  description?: string;
  status?: string;
  documents: number[];
  phoneNumber?: string;
}

export function formatToIssueUpdateDto(
  issue: IssueDetailsUi,
  documentsId: number[]
): IssueUpdateDto {
  const { description, status, phoneNumber } = issue;
  return {
    phoneNumber,
    status,
    description,
    documents: documentsId,
  };
}
