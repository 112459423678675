import { QueryEntity } from '@datorama/akita';
import { OnbordingState, onbordingStore, OnbordingStore } from './onbording.store';

export class OnbordingQuery extends QueryEntity<OnbordingState> {
  constructor(protected store: OnbordingStore) {
    super(store);
  }

  run$ = this.select((state) => state.run);
  openNewDemande$ = this.select((state) => state.openNewDemande);
  steps$ = this.select((state) => state.steps);
  stepIndex$ = this.select((state) => state.stepIndex);
  isOnbording$ = this.select((state) => state.isOnbording);
}

export const onbordingQuery = new OnbordingQuery(onbordingStore);
