import dayjs, { OpUnitType } from 'dayjs';
import { EvolutionInfosDto } from 'api/dto/dashboard/operations/OperationEvolutionDto';
import { PeriodeStatsDTO } from 'store/dashboard/financeScreenStats/finance-screen-stats.model';

const rotate = (arr: dayjs.WeekdayNames, count = 1) => {
  return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
};

const mapCurrentWeek = (date: Date): string[] => {
  const weekLabels = dayjs(date).localeData().weekdaysMin();
  return rotate(weekLabels, dayjs(date).localeData().firstDayOfWeek());
};

const mapCurrentMonth = (date: Date): string[] => {
  const res: string[] = [];

  const startDate = dayjs(date).startOf('month');
  const endDate = dayjs(date).endOf('month');
  for (let i = 0; startDate.add(i, 'day') <= endDate; i++) {
    const dateIt = startDate.add(i, 'day');
    res.push(dateIt.get('date').toString());
  }

  return res;
};

const mapCurrentYear = (date: Date): string[] => {
  return dayjs(date).localeData().monthsShort();
};

export const getYearStats = (evolution: EvolutionInfosDto[], initialDate: Date) => {
  return [...Array(12).keys()].map((it) => {
    const currentEvolution = evolution.filter((evol) => {
      return dayjs(evol.date).month() === it;
    });
    return {
      execCount: currentEvolution.reduce(
        (previousValue, currentValue) => previousValue + currentValue.execCount,
        0
      ),
      issueCount: currentEvolution.reduce(
        (previousValue, currentValue) => previousValue + currentValue.issueCount,
        0
      ),
      date: currentEvolution.length
        ? currentEvolution[0].date
        : dayjs(initialDate).add(1, 'day').set('month', it).toISOString(),
    };
  });
};

export const getYearPeriodeStats = (evolution: PeriodeStatsDTO[], initialDate: Date) => {
  return [...Array(12).keys()].map((it) => {
    const currentEvolution = evolution.filter((evol) => {
      return dayjs(evol.date).month() === it;
    });
    return {
      totalExpensesInCents: currentEvolution.reduce(
        (previousValue, currentValue) => previousValue + currentValue.totalExpensesInCents,
        0
      ),
      totalValorisationInCents: currentEvolution.reduce(
        (previousValue, currentValue) => previousValue + currentValue.totalValorisationInCents,
        0
      ),
      date: currentEvolution.length
        ? currentEvolution[0].date
        : dayjs(initialDate).add(1, 'day').set('month', it).toISOString(),
    };
  });
};

export const getPeriodLabels = (type: OpUnitType, forDate: Date) => {
  switch (type) {
    case 'week':
      return mapCurrentWeek(forDate);
    case 'month':
      return mapCurrentMonth(forDate);
    case 'year':
      return mapCurrentYear(forDate);
  }
};
