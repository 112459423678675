import { InputLabel } from '@mui/material';
import React, { FC } from 'react';
import style from './MaterialLoginTextfield.style';
import { WithStyles, withStyles } from '@mui/styles';
import MaterialTextfieldBase, { MaterialTextfieldBaseProps } from '../MaterialTextfieldBase';

interface MaterialTextfieldProps extends WithStyles<typeof style>, MaterialTextfieldBaseProps {}

const MaterialLoginTextfield: FC<MaterialTextfieldProps> = (props: MaterialTextfieldProps) => {
  const { classes, error, label, required } = props;

  return (
    <div>
      {!!label && (
        <InputLabel error={!!error} className={classes.inputLabel}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
      )}
      <MaterialTextfieldBase {...props} />
    </div>
  );
};

export default withStyles(style)(MaterialLoginTextfield);
