import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import style from './StatisticTrendData.style';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

interface StatisticTrendDataProps extends WithStyles<typeof style> {
  stat: number;
  backgroundCard?:
    | 'error.light'
    | 'secondary.light'
    | 'blueColored.main'
    | 'greenColored.main'
    | 'white'
    | 'info.light'
    | 'info.dark'
    | 'ecoGreen.main'
    | 'ecoGray.main';
  icon: boolean;
  nameOfCard?: 'operations' | 'ranking' | 'statistic' | 'colored';
  inverse?: boolean;
}

const StatisticTrendData: FC<StatisticTrendDataProps> = (props) => {
  const theme = useTheme();
  const { inverse = false, classes, stat, backgroundCard, icon, nameOfCard } = props;

  const classForDifferentCard = (name?: string) => {
    switch (name) {
      case 'operations':
        return classes.operationsContainer;
      case 'colored':
        return classes.operationsContainer;
      case 'ranking':
        return classes.rankingContainer;
      case 'statistic':
        return classes.statisticContainer;
    }
  };

  const backgroundCondition = (nb: number): string => {
    return nb * (inverse ? -1 : 1) > 0 ? 'success.light' : 'error.dark';
  };

  const backgroundStatistic = (bgColor: any, nb: number) => {
    switch (bgColor) {
      case 'blueColored.main':
        return 'white';
      case 'greenColored.main':
        return 'white';
      case 'info.light' && 'info.dark':
        return 'white';
      case 'white':
        return 'secondary.light';
      case 'ecoGray.main':
        return 'white';
      case 'error.light':
        return backgroundCondition(nb);
      case 'secondary.light':
        return backgroundCondition(nb);
    }
  };

  const iconVisible = (icon: boolean, nb: number, theme: any) => {
    if (icon) {
      return nb > 0 ? (
        !inverse ? (
          <NorthEastIcon fontSize="small" color="success" />
        ) : (
          <SouthEastIcon fontSize="small" htmlColor={theme.palette.error.main} />
        )
      ) : !inverse ? (
        <SouthEastIcon fontSize="small" htmlColor={theme.palette.error.main} />
      ) : (
        <NorthEastIcon fontSize="small" color="success" />
      );
    }
  };

  if (stat === 0) {
    return <div />;
  }

  return (
    <Card
      sx={{ backgroundColor: backgroundStatistic(backgroundCard, stat) }}
      className={classForDifferentCard(nameOfCard)}
    >
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          {iconVisible(icon, stat, theme)}
          <Typography
            color={stat * (inverse ? -1 : 1) > 0 ? 'success.main' : 'error.main'}
            className={classes.police}
          >
            {stat}%
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(StatisticTrendData);
