import React, { FC } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import style from './CardStatistic.style';
import StatisticTrendData from '../TrendData/StatisticTrendData';

interface CardStatisticProps extends WithStyles<typeof style> {
  data?: string;
  devise?: string;
  description?: string;
  title?: string;
  dataStatistic?: number;
  value?: any;
  selected?: any;
  isLoading?: boolean;
  handleClick?: () => void;
}

const CardStatistic: FC<CardStatisticProps> = (props) => {
  const {
    classes,
    data,
    devise,
    description,
    title,
    dataStatistic,
    handleClick,
    value,
    selected,
    isLoading,
  } = props;

  return (
    <Card
      component={handleClick ? CardActionArea : Card}
      className={value === selected ? classes.selected : classes.CardContainer}
      style={{ opacity: isLoading ? 0.5 : 1 }}
      onClick={handleClick}
      elevation={3}
    >
      <CardHeader
        className={classes.header}
        action={
          dataStatistic != null && (
            <StatisticTrendData
              stat={dataStatistic}
              backgroundCard="white"
              icon={true}
              nameOfCard="statistic"
            />
          )
        }
        title={title}
      />
      <CardContent>
        <div className={classes.data}>
          <Typography className={classes.title}>{data}</Typography>
          <span className={classes.devise}> {devise}</span>
          {isLoading && <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />}
        </div>
        <Typography className={classes.subTitle}>{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(CardStatistic);
