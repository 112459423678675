export enum Locale {
  English = 'en-US',
  French = 'fr-CH',
  Italian = 'it-CH',
  German = 'de-CH',
}

export const LocaleFileName = {
  [Locale.English]: 'en',
  [Locale.French]: 'fr',
  [Locale.Italian]: 'it',
  [Locale.German]: 'de',
};
