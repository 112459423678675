import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';

import style from './Sidebar.style';
import { Box, Tab, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'constants/Routes';

interface SidebarProps extends WithStyles<typeof style> {
  tabs: {
    title: string;
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    // eslint-disable-next-line no-undef
    iconSelected: JSX.Element;
  }[];
  value?: number;
  handleChange?: (newValue: number) => void;
}

const Sidebar = (props: SidebarProps) => {
  const { classes, tabs = [], value, handleChange } = props;
  const history = useHistory();

  return (
    <Box className={classes.boxContainer}>
      <div className={classes.logoContainer}>
        <img
          src="/images/metawaste-logo.png"
          width="50px"
          height="auto"
          className={classes.logo}
          onClick={() => {
            history.push(RoutePath.Dashboard);
            handleChange?.(0);
          }}
        />
      </div>
      <Tabs
        orientation="vertical"
        variant="standard"
        value={value}
        onChange={(evt, value) => handleChange?.(value)}
        className={classes.tabsContainer}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.title}
            icon={value === index ? tab.iconSelected : tab.icon}
            label={tab.title}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default withStyles(style)(Sidebar);
