import { useEffect } from 'react';
import { executionIssueKindsService } from '../execution-issue-kinds.service';
import SnackError from 'utils/models/snackError';
import { executionIssueKindsQuery } from '../execution-issue-kinds.query';
import { useSnackbar } from 'notistack';
import { useObservable } from '@ngneat/react-rxjs';

export const useIssueKindsItems = (needToFetch: boolean) => {
  const [issueKindsData] = useObservable(executionIssueKindsQuery.getAllIssueKinds$);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (needToFetch) {
      executionIssueKindsService
        .fetchIssueKinds()
        .catch((e: SnackError) => enqueueSnackbar(e.text, e.options));
    }
  }, []);

  return { issueKindsData };
};
