import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 300,
      maxHeight: 160,
    },
    title: {
      textAlign: 'center',
      fontWeight: 700 + '!important',
      marginTop: '-10px !important',
    },
    statistic: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '17px !important',
    },
    grid: {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
    typography: {
      textAlign: 'center',
    },
  });

export default classes;
