import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const classes = (theme: Theme) =>
  createStyles({
    container: {
      padding: '8px, 8px',
    },
    marginTypography: {
      color: 'black',
      fontSize: '12px !important',
    },
    title: {
      fontSize: '10px !important',
      color: 'black',
      marginBottom: '15px !important',
    },
    subTitle: {
      color: theme.palette.secondary.dark + '!important',
      fontWeight: 700,
    },
  });

export default classes;
