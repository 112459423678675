import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import style from './ActivityRow.style';
import { WithStyles, withStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface ActivityRowProps extends WithStyles<typeof style> {
  leftInfo: {
    title: string;
    subtitle: string;
  };
  middleInfo: {
    title: string;
    subtitle: string;
  };
  time: Date;
  authorName: string;
}

const ActivityRowComponent: FC<ActivityRowProps> = ({
  classes,
  leftInfo,
  middleInfo,
  time,
  authorName,
}: ActivityRowProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" flex={1} pt={2}>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          className={classes.leftContainer}
        >
          <Typography className={classes.subtitle}>{leftInfo.title}</Typography>
          <Typography className={classes.subtitle}>{leftInfo.subtitle}</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="flex-start">
          <Typography className={classes.title}>{middleInfo.title}</Typography>
          <Typography className={classes.subtitle}>{middleInfo.subtitle}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Typography className={classes.subtitle}>
          {[dayjs(time).fromNow(), authorName].join(' - ')}
        </Typography>
      </Stack>
      <Divider className={classes.divider} />
    </Stack>
  );
};

export default withStyles(style)(ActivityRowComponent);
