import React, { useState } from 'react';
import { Badge, Card, CardActionArea, IconButton } from '@mui/material';

import ImageModal from './ImageModal';
import { Colors } from 'constants/Colors';
import { Close } from '@mui/icons-material';

interface FilesCardProps {
  document: string;
  handleDeleteDocument?: () => void;
  disableOnClick?: boolean;
}

const FilesCard = (props: FilesCardProps) => {
  const { document, handleDeleteDocument, disableOnClick } = props;

  const [preview, setPreview] = useState(false);
  console.log('document', document);

  return (
    <>
      <Badge
        componentsProps={{ badge: { style: { right: 4, top: 4 } } }}
        invisible={!handleDeleteDocument}
        badgeContent={
          <IconButton onClick={handleDeleteDocument} aria-label="remove-document">
            <Close style={{ color: Colors.white, height: 8, width: 8 }} />
          </IconButton>
        }
      >
        <Card
          sx={{ backgroundColor: '#FFF', padding: '0px', boxShadow: 'none' }}
          color="secondary"
          component={CardActionArea}
          onClick={() => setPreview(!disableOnClick)}
        >
          <img alt="" style={{ maxHeight: '86px', maxWidth: '86px' }} src={document} />
        </Card>
      </Badge>
      {preview && <ImageModal imageUrl={document} onClose={() => setPreview(false)} />}
    </>
  );
};

export default FilesCard;
