import { ID } from '@datorama/akita';
import { AddressDto } from 'api/dto/client/AddressDto';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';

export interface OrganisationUnit {
  id: ID;
  name: string;
  address: AddressDto;
}

export const organisationUnitToMaterialDropdownItem = (
  organisationUnit: OrganisationUnit
): MaterialDropdownItem => {
  return {
    value: organisationUnit.id,
    label: organisationUnit.name,
  };
};

export interface SupplierOrganisationUnitLight {
  id: number;
  name: string;
  phone: string;
  email: string;
  street: string;
  street2: string;
  street3: string;
  city: string;
  zip: string;
}
