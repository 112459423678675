import {
  createExecutionQuery,
  createExecutionService,
  IdenticalExecution,
} from 'store/operations/createExecution';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { Close } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import RowActivities from '../RowActivities';
import { filterHourAndMinute } from 'utils/data/date.utils';
import { ReactComponent as RecyclingTruck } from 'assets/icons/truckPickup.svg';
import { ReactComponent as TruckWithClock } from 'assets/icons/truckWithClock.svg';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useObservable } from '@ngneat/react-rxjs';

export interface IdenticalExecutionsModalProps {
  identicalExecutions: IdenticalExecution[];
  handleOnForce: () => void;
}

export const IdenticalExecutionsModal = (props: IdenticalExecutionsModalProps) => {
  const { identicalExecutions, handleOnForce } = props;
  const { t } = useTranslation();

  const [isSaving] = useObservable(createExecutionQuery.isSaving$);

  const handleClose = () => {
    createExecutionService.clearIdenticalExecutions();
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Stack direction="column" spacing={2}>
          <Typography textAlign="center" fontSize={20}>
            {t('fe.executionModal.identicalExecutionsModal.description')}
          </Typography>
          <Stack direction="column" spacing={2} mt={3}>
            {identicalExecutions.map((ie) => (
              <RowActivities
                key={ie.id}
                title={ie.name}
                description={
                  (ie.plannedFrom
                    ? dayjs(ie.plannedFrom).format('DD.MM.YYYY') +
                      ' - ' +
                      t('fe.activitiesPanel.row.description', {
                        from: filterHourAndMinute(ie.plannedFrom),
                        to: filterHourAndMinute(ie.plannedTo),
                      })
                    : t('fe.asap')) +
                  ' - ' +
                  [ie.createdBy?.firstName || '', ie.createdBy?.lastName || ''].join(' ')
                }
                icon={ie.isRecurrent ? <RecyclingTruck /> : <TruckWithClock />}
                status={ie.status}
              />
            ))}
          </Stack>
          <Typography textAlign="center" fontSize={20} fontWeight={700} pt={4}>
            {t('fe.executionModal.identicalExecutionsModal.question')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" size="large" onClick={handleClose}>
            {t('fe.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            onClick={handleOnForce}
            loading={isSaving}
          >
            {t('fe.executionModal.identicalExecutionsModal.forceCreation')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
